import axios from "axios";
//Creating the instance of Axios
//Setting up configuration to make API calls using axios instance
//specifying config defaults that will be applied to every request.

export const BASE_URL = process.env.REACT_APP_GEMS_BE_HOST; // "http://localhost:8080/Gems-service" //  //"http://df-app002:8080/Gems-service"
console.log = console.warn = console.error = () => {};
// export const BASE_URL = "http://df-app002:8080/Gems-service";
const instance = axios.create({
  baseURL: BASE_URL
});

export default instance;

