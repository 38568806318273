import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import { FormErrors } from "../commonUtility/FormErrors";
import {
  ACTION_MAIL_ADD,
  ACTION_MAIL_UPDATE,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
} from "../commonUtility/string-const";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LoaderComponent from "../commonUtility/LoaderComponent";

const modules = {
  toolbar: [],
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

class NotificationEmaill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      message: "",
      subjectValid: false,
      messageValid: false,
      formErrors: {
        subject: "",
        message: "",
        error: "",
      },
      action: ACTION_MAIL_ADD,
      isEdit: false,
      focusFirstInput: true,
      currentCharLength: 0,
      remainingCharEditor: 500,
      isLoading: false,
    };

    this.quillRef = null;
    this.reactQuillRef = null;
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  validateForm() {
    this.setState({
      formValid: this.state.subjectValid && this.state.messageValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  validateAndSubmit = (event) => {
    event.preventDefault();
    const regexSubject = "^[A-Za-z0-9&()!-.,: ]*$";

    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.error = "";
    fieldValidationErrors.message = "";
    fieldValidationErrors.subject = "";
    let subjectValid = this.state.subjectValid;
    let messageValid = this.state.messageValid;

    if (this.state.subject.trim().length === 0) {
      fieldValidationErrors.subject = "";
      fieldValidationErrors.subject = "Please enter subject";
      subjectValid = false;
    }
    if (this.state.subject.trim().length > 0) {
      subjectValid =
        this.state.subject.trim().length > 0 &&
        this.state.subject.trim().length <= 100 &&
        this.state.subject.match(regexSubject);
      fieldValidationErrors.subject = subjectValid
        ? ""
        : "Only alphabets, numbers, &, (, ),!,-, . , ,, : allowed";
    }

    if (
      this.state.message.trim().length === 0 ||
      this.state.currentCharLength === 0
    ) {
      fieldValidationErrors.message = "";
      fieldValidationErrors.message = "Please enter message";
      messageValid = false;
    }
    if (
      this.state.message.trim().length > 0 &&
      this.state.currentCharLength > 0
    ) {
      let messageRegexValid = true;

      messageValid = messageRegexValid;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      subjectValid: subjectValid,
      messageValid: messageValid,
    });

    if (subjectValid && messageValid) {
      this.sendEmail();
    }
  };

  static getDerivedStateFromProps(props, state) {
    console.log("from Props: ", props.editInfo);
    console.log("from state: ", state);
    if (props.editInfo) {
      if (props.editInfo !== state) {
        let edit_info = props.editInfo;
        return {
          subject: edit_info.institute_name,
          message: edit_info.message,
          action: ACTION_MAIL_UPDATE,
          isEdit: true,
          formErrors: {
            subject: "",
            message: "",
            error: "",
          },
          focusFirstInput: true,
        };
      }
    }
    return {
      focusFirstInput: false,
    };
  }

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  sendEmail() {
    let input = {
      body: this.state.message,
      subject: this.state.subject,
    };

    const config = {
      headers: {
        ...authHeader(),
      },
    };

    this.setState({
      isLoading: true,
    });

    instance
      .post("/mail/sendMailNotification", input, config)
      .then((response) => {
        console.log(response.data);
        this.setState({
          isLoading: false,
        });

        if (response && response.data) {
          if (response.data.error) {
            this.props.emailSentCallback(
              null,
              "An error occured.",
              true,
              TOAST_TYPE_ERROR
            );
          } else {
            this.clearAll();
            this.props.emailSentCallback(
              null,
              "Email sent successfully.",
              false,
              TOAST_TYPE_SUCCESS
            );
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            this.props.emailSentCallback(
              null,
              "An error occured.",
              true,
              TOAST_TYPE_ERROR
            );
          }
        } else {
          this.props.emailSentCallback(
            null,
            "An error occured.",
            true,
            TOAST_TYPE_ERROR
          );
        }
      });
  }

  clearAll() {
    if (this.state.isEdit) {
      let edit_info = this.props.editInfo;
      this.setState({
        subject: edit_info.institute_name,
        message: edit_info.message,
        action: ACTION_MAIL_UPDATE,
        isEdit: true,
        formErrors: {
          subject: "",
          message: "",
          error: "",
        },
      });
    } else {
      this.setState({
        subject: "",
        message: "",
        subjectValid: false,
        messageValid: false,
        formErrors: {
          subject: "",
          message: "",
          error: "",
        },
        action: ACTION_MAIL_ADD,
        isEdit: false,
      });
      this.props.resetCallback();
    }
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");

      this.closeEmailComposeCallback();
    }
  };

  handleChangeTextEditor(html) {
    console.log(html);
    const limit = 500;
    const quill = this.quillRef;
    quill.on("text-change", function (delta, old, source) {
      if (quill.getLength() > limit) {
        quill.deleteText(limit, quill.getLength());
      }
    });
    this.setState({
      message: html,
      remainingCharEditor:
        this.state.remainingCharEditor -
        (quill.getLength() - this.state.currentCharLength - 1),
      currentCharLength: quill.getLength() - 1,
    });
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className="edit-form-border institute-form">
        {this.state.isLoading ? <LoaderComponent /> : null}
        <div className="form-padd">
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={this.validateAndSubmit}
          >
            <div className="w-100">
              <div className="row">
                <div className="col-sm-4 col-lg-8">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.subject
                    )}`}
                  >
                    <input
                      id="subject"
                      name="subject"
                      label="Subject"
                      className={classes.textField + " w-100 form-control"}
                      type="Text"
                      value={this.state.subject}
                      onChange={this.handleUserInput}
                      margin="normal"
                      placeholder="Subject"
                      maxLength="100"
                    />
                    <div className="error-text">
                      <FormErrors formErrors={this.state.formErrors.subject} />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-lg-12">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.message
                    )}`}
                  >
                    <div className="text-right">
                      Remaining characters: {this.state.remainingCharEditor}
                    </div>
                    <div className="data-form input-tag-library">
                      <ReactQuill
                        placeholder="Message"
                        ref={(el) => {
                          this.reactQuillRef = el;
                        }}
                        value={this.state.message}
                        onChange={this.handleChangeTextEditor.bind(this)}
                        modules={modules}
                      />
                    </div>
                    <div className="error-text">
                      <FormErrors formErrors={this.state.formErrors.message} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto mr-auto text-right">
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-default site"
                      disabled={this.state.isLoading}
                    >
                      {this.state.isEdit ? "Update and Send" : "Send"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-cancel"
                      onClick={() => this.clearAll()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div
                onClick={() => this.props.closeEmailComposeCallback()}
                tabIndex="0"
                className="cross-icon"
                onKeyPress={(event) => this.enterPressed(event)}
              >
                <span>
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
              </div>
            </div>
          </form>
        </div>
      </Paper>
    );
  }
}

NotificationEmaill.propTypes = {
  classes: PropTypes.object.isRequired,
  emailSentCallback: PropTypes.func,
  closeEmailComposeCallback: PropTypes.func,
  sendEmailLoaderCallback: PropTypes.func,
  resetCallback: PropTypes.func,
};

export default withStyles(styles)(NotificationEmaill);
