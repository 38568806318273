import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import parse from "html-react-parser";
class PopupModalInsert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      message: "",
      buttonPositive: this.props.buttonPositive,
      buttonNegative: this.props.buttonNegative,
    };

    this.toggle = this.toggle.bind(this);
    this.cancelToggle = this.cancelToggle.bind(this);
    this.yesToggle = this.yesToggle.bind(this);
    this.noToggle = this.noToggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      message: "",
    }));
  }

  cancelToggle() {
    console.log("cacel roggle called");
    this.setState((prevState) => ({
      modal: !prevState.modal,
      message: "",
    }));
    this.props.cancelClickCallback();
  }

  yesToggle() {
    console.log("cacel roggle called");
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.props.yesClickCallback();
  }

  noToggle() {
    console.log("cacel roggle called");
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.props.noClickCallback();
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          backdrop={false}
          size={this.props.modalSize}
        >
          <ModalHeader toggle={this.cancelToggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.props.hasHtmlElements
              ? parse(this.props.message)
              : this.props.message}
            <div className={`form-group`}>
              <div className="form-check form-check-inline">
                <label className="mb-0">
                  <input
                    type="checkbox"
                    className="margin-bottom-16"
                    name="isDataFinal"
                    value={this.props.isDataFinal}
                    checked={this.props.isDataFinal}
                    onChange={(e) => {
                      this.props.onCheckboxChange(e);
                    }}
                  />{" "}
                  This data is final
                </label>
              </div>
            </div>

            <div className="dis-insert" hidden={!this.props.isDataFinal}>
              By selecting the “This data is final” checkbox you confirm that
              the data is final and will be used for the following annual
              report. Please be aware that the permissions of all users in your
              institution to insert new data will be temporarily revoked until
              the finalization of this Annual Report submission cycle. If you
              still want to insert new data during this time frame, please use
              the button “Request New Upload” and your request will be reviewed.
              Are you sure you want to insert final data?
            </div>
            <div className="mt-3 lib-model-foot">
              <Button
                className="btn btn-default"
                onClick={this.yesToggle}
                hidden={this.state.buttonPositive == null}
              >
                {this.state.buttonPositive}
              </Button>
              <Button
                className="btn btn-cancel"
                onClick={this.noToggle}
                hidden={this.state.buttonNegative == null}
              >
                {this.state.buttonNegative}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

PopupModalInsert.propTypes = {
  yesClickCallback: PropTypes.func,
  noClickCallback: PropTypes.func,
  cancelClickCallback: PropTypes.func,
};

PopupModalInsert.defaultProps = {
  modalSize: "sm",
};

export default PopupModalInsert;
