import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import Footer from "../commonUtility/Footer";
import { Container, Row, Col } from "reactstrap";
import InfoTab from "./InfoTab";
import ScrollUpButton from "react-scroll-up-button";

class Info extends Component {
  render() {
    return (
      <div className="info info-page">
        <Navigation />

        <MainHeading />

        <Container>
          <InfoTab/>
        </Container>
        <ScrollUpButton  ShowAtPosition={20} className="scrolltopbutton"/>    
          
        <Footer />
      </div>
    );
  }
}

export default Info;
