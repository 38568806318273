import React, { Component } from "react";
import Highcharts from "highcharts/highmaps.js";
import Select from "react-select";
import _ from "lodash";
import { connect } from "react-redux";
import { callUserDetails } from "../../store/actions/index";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import { showToast } from "../commonUtility/NewToast";
import { TOAST_TYPE_SUCCESS } from "../commonUtility/string-const";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
NoDataToDisplay(Highcharts);

const options = [
  { value: "ALL", label: "All" },
  { value: "ONLYME", label: "Only Me" },
  { value: "EXCEPTME", label: "Except Me" },
];

const worldMap = require("@highcharts/map-collection/custom/world.geo.json");

const LoaderComponent = (
  <div className="load-absolute">
    <div className="loading">
      <div />
      <div />
      <div />
    </div>
  </div>
);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 0,
    fontSize: 13,
    fontWeight: state.data.value === "ALL" ? "bold" : "normal",
    width: "auto",
  }),
  control: () => ({
    width: 115,
    display: "flex",
    height: 30,
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.6)",
    border: "1px solid #cac7c7",
    boxShadow: "none",
    "&:focus-within": {
      border: "1px solid #233d63",
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.6 : 1;
    const transition = "opacity 100ms";

    return { ...provided, opacity, transition };
  },
};

class ChartSection extends Component {
  constructor() {
    super();
    this.state = {
      width: 510,
      height: 250,
      optionACTIVE: { value: "ALL", label: "All" },
      optionDEFAULT: { value: "ALL", label: "All" },
      optionREGIONRR: { value: "ALL", label: "All" },
      optionPD: { value: "ALL", label: "All" },
      optionMM: { value: "ALL", label: "All" },
      optionACTIVESEC: { value: "ALL", label: "All" },
      optionDEFAULTSEC: { value: "ALL", label: "All" },
      optionSECTORRR: { value: "ALL", label: "All" },
      loadingACTIVE: false,
      loadingDEFAULT: false,
      loadingREGIONRR: false,
      loadingPD: false,
      loadingMM: false,
      loadingACTIVESEC: false,
      loadingDEFAULTSEC: false,
      loadingSECTORRR: false,
      superAdminOptions: [],
      counterpartSectorModal: false,
      defaultSectorModal: false,
      chartData: {
        activeSector: {},
        defaultSector: {},
      },
    };
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps.mapData, this.props.mapData)) {
      this.drawWorldMaps(this.props.mapData);
      this.setGraphOptionsValues();
    }

    if (!_.isEqual(oldProps.graphData, this.props.graphData)) {
      this.drawGraphs(this.props.graphData);
      this.setGraphOptionsValues();
    }

    if (!_.isEqual(oldProps.chartData, this.props.chartData)) {
      this.setState({
        chartData: this.props.chartData,
      });
      this.drawPieCharts(this.props.chartData);
      this.setGraphOptionsValues();
    }

    if (!_.isEqual(oldProps.graphOptions, this.props.graphOptions)) {
      this.setGraphOptionsValues();
    }

    if (!_.isEqual(oldProps.barData, this.props.barData)) {
      this.drawBarCharts(this.props.barData);
      this.setGraphOptionsValues();
    }

    if (
      !_.isEqual(oldProps.refreshGraphs, this.props.refreshGraphs) &&
      this.props.refreshGraphs
    ) {
      this.drawWorldMaps(this.props.mapData);
      this.drawGraphs(this.props.graphData);
      this.drawPieCharts(this.props.chartData);
      this.drawBarCharts(this.props.barData);
      this.setGraphOptionsValues();
    }

    if (!_.isEqual(oldProps.institutionInfo, this.props.institutionInfo)) {
      let superAdminOptions = [];
      superAdminOptions.push({ value: "ALL", label: "All" });
      this.props.institutionInfo.sort().forEach((items) => {
        superAdminOptions.push({ value: items, label: items });
      });
      this.setState({ superAdminOptions: superAdminOptions }, () => {
        this.setGraphOptionsValues();
      });
    }
  }

  setGraphOptionsValues() {
    this.setState({
      optionACTIVE: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.ACTIVE
            )
          : options.find(
              (item) => item.value === this.props.graphOptions.ACTIVE
            )
        : this.props.callUserDetails(),
      optionDEFAULT: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.DEFAULT
            )
          : options.find(
              (item) => item.value === this.props.graphOptions.DEFAULT
            )
        : this.props.callUserDetails(),
      optionREGIONRR: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.REGIONRR
            )
          : options.find(
              (item) => item.value === this.props.graphOptions.REGIONRR
            )
        : this.props.callUserDetails(),
      optionPD: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.PD
            )
          : options.find((item) => item.value === this.props.graphOptions.PD)
        : this.props.callUserDetails(),
      optionMM: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.MM
            )
          : options.find((item) => item.value === this.props.graphOptions.MM)
        : this.props.callUserDetails(),
      optionACTIVESEC: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.ACTIVESEC
            )
          : options.find(
              (item) => item.value === this.props.graphOptions.ACTIVESEC
            )
        : this.props.callUserDetails(),
      optionDEFAULTSEC: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.DEFAULTSEC
            )
          : options.find(
              (item) => item.value === this.props.graphOptions.DEFAULTSEC
            )
        : this.props.callUserDetails(),
      optionSECTORRR: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.graphOptions.SECTORRR
            )
          : options.find(
              (item) => item.value === this.props.graphOptions.SECTORRR
            )
        : this.props.callUserDetails(),
    });
  }

  drawWorldMaps(mapData) {
    this.drawActiveWorldMap(mapData);
    this.drawDefaultWorldMap(mapData);
    this.drawRRMap(mapData);
  }

  drawGraphs(graphData) {
    this.drawPDGraph(graphData);
    this.drawMMGraph(graphData);
  }

  drawPieCharts(chartData) {
    this.drawCounterpartsBySectorChart(chartData, "counterparts-sector");
    this.drawDefaultsBySectorChart(chartData, "defaults-sector");
  }

  drawBarCharts(barData) {
    this.drawRRBySectorChart(barData);
  }

  drawMMGraph(graphData) {
    const startYear = this.props.isDefaultPeriod180 ? 1998 + 1 : 1994 + 1;
    const mean = parseFloat(graphData.mmGraph.mmMean);
    let upRatioList = graphData.mmGraph.mmRatioList;

    let upRatioDataToPlot = [];
    const dataToPlot = JSON.parse(JSON.stringify(upRatioList));
    if (startYear < 1995) {
      for (let i = 1995 - startYear; i <= dataToPlot.length - 1; i++) {
        upRatioDataToPlot.push(dataToPlot[i]);
      }
    } else {
      upRatioDataToPlot = dataToPlot;
    }
    let breakEvenData = [];
    let averageData = [];
    upRatioDataToPlot.forEach((item) => {
      if (item !== null) breakEvenData.push(1);
      averageData.push(parseFloat(mean));
    });

    Highcharts.chart("annual-rating", {
      chart: {
        style: {
          fontFamily: "sans-serif",
        },
      },
      title: {
        text: "",
      },
      noData: {
        position: {
          x: 15,
          y: -5,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        min: 0,
        tickInterval: 0.25,
        labels: {
          formatter: function () {
            var label = this.axis.defaultLabelFormatter.call(this);
            return Number(label).toFixed(2);
          },
        },
        lineWidth: upRatioDataToPlot.length > 0 ? 1 : 0,
      },

      xAxis: {
        tickInterval: 1,
        labels: {
          step: 1,
          rotation: 300,
        },
        showEmpty: false,
      },

      tooltip: {
        enabled: true,
        formatter: function () {
          return (
            "<b>" +
            this.series.name +
            "</b><br>" +
            this.x +
            " : " +
            Number(this.y).toFixed(2)
          );
        },
      },

      legend: {
        enabled: upRatioList.length > 0 ? true : false,
      },

      exporting: {
        enabled: false,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: startYear > 1995 ? startYear : 1995,
          marker: {
            radius: 3,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
            },
          },
        },
      },

      series: [
        {
          name: "Up/Down Ratio",
          color: "#9966ff",
          data: upRatioDataToPlot.map((ratio) => parseFloat(ratio)),
        },
        {
          name: "Breakeven",
          color: "#008b00",
          marker: {
            enabled: false,
          },
          enableMouseTracking: false,
          data: breakEvenData,
        },
        {
          name: "Average Ratio",
          color: "#f00",
          dashStyle: "DashDot",
          marker: {
            enabled: false,
          },
          enableMouseTracking: false,
          data: averageData,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (
                this.point === this.series.data[this.series.data.length - 1]
              ) {
                return "Average: " + Number(this.y).toFixed(2);
              }
            },
            y: parseFloat(mean) > 0.82 && parseFloat(mean) < 0.96 ? -15 : 0,
          },
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }

  drawPDGraph(graphData) {
    const startYear = this.props.isDefaultPeriod180 ? 1998: 1994;
    const defaultAverage = parseFloat(graphData.pdGraph.pdAverage);
    const defaultList =
      graphData.pdGraph.pdAverage === "" || graphData.pdGraph.pdAverage === null
        ? []
        : graphData.pdGraph.dynamicCohortMap.rate.map(
            (item) => item["Portfolio default rate"]
          );

    let defaultRateDataToPlot = [];
    const dataToPlotDefault = JSON.parse(JSON.stringify(defaultList));
    if (startYear < 1995) {
      for (let i = 1995 - startYear; i <= dataToPlotDefault.length - 1; i++) {
        defaultRateDataToPlot.push(dataToPlotDefault[i]);
      }
    } else {
      defaultRateDataToPlot = dataToPlotDefault;
    }

  let averageDataDefault = [];
    defaultRateDataToPlot.forEach(() => {
      averageDataDefault.push(parseFloat(defaultAverage));
    });

    Highcharts.chart("default-rate", {
      chart: {
        style: {
          fontFamily: "sans-serif",
        },
      },
      title: {
        text: "",
      },
      noData: {
        position: {
          x: 15,
          y: -5,
        },
      },

      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "{value}%",
        },
        tickInterval: 2,
        tickAmount: 6,
        showEmpty: true,
        lineWidth: defaultRateDataToPlot.length > 0 ? 1 : 0,
      },

      xAxis: {
        tickInterval: 1,
        labels: {
          step: 1,
          rotation: 300,
        },
        showEmpty: false,
      },

      tooltip: {
        enabled: true,
        formatter: function () {
          return (
            "<b>" +
            this.series.name +
            "</b><br>" +
            this.x +
            " : " +
            Number(this.y).toFixed(1) +
            "%"
          );
        },
      },

      legend: {
        enabled: defaultList.length > 0 ? true : false,
      },

      exporting: {
        enabled: false,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: startYear > 1995 ? startYear : 1995,
          marker: {
            radius: 3,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
            },
          },
        },
      },

      series: [
        {
          name: "Default Rate",
          color: "#274368",
          data: defaultRateDataToPlot.map((ratio) => parseFloat(ratio)),
        },
        {
          name: "Average Rate",
          color: "#f00",
          dashStyle: "DashDot",
          marker: {
            enabled: false,
          },
          enableMouseTracking: false,
          data: averageDataDefault,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (
                this.point === this.series.data[this.series.data.length - 1]
              ) {
                return "Average: " + Number(this.y).toFixed(1) + "%";
              }
            },
          },
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }

  drawActiveWorldMap(mapData) {
    if (mapData.length > 0) {
      let counterpartsData = [];

      let southAfricaData;
      mapData.forEach((items) => {
        if (items.regionCountryMap.id === 7) {
          southAfricaData = items;
        }
      });
      mapData.forEach((regionItems) => {
        regionItems.regionCountryMap.countries.forEach((countryItems) => {
          if (countryItems.code.toLowerCase() === "sx") {
            counterpartsData.push([
              countryItems.code.toLowerCase(),
              southAfricaData.activeCounterPart,
            ]);
          } else {
            counterpartsData.push([
              countryItems.code.toLowerCase(),
              regionItems.activeCounterPart === null
                ? 0
                : regionItems.activeCounterPart,
            ]);
          }
        });
      });

      Highcharts.mapChart("counterparts-region", {
        chart: {
          map: worldMap,
        },

        credits: {
          enabled: false,
        },

        title: {
          text: "",
        },

        mapNavigation: {
          enabled: false,
          buttonOptions: {
            alignTo: "spacingBox",
            theme: {
              fill: "transparent",
              scaleX: 0.7,
              scaleY: 0.7,
            },
          },
          buttons: {
            zoomIn: {
              x: -9,
              y: -5,
            },
            zoomOut: {
              x: -9,
              y: 15,
            },
          },
        },

        colorAxis: {
          dataClasses: [
            {
              to: 200,
              color: "#abe3ff",
            },
            {
              from: 201,
              to: 500,
              color: "#9bb8fb",
            },
            {
              from: 501,
              to: 700,
              color: "#9966ff",
            },
            {
              from: 701,
              to: 1000,
              color: "#2e75f0",
            },
            {
              from: 1000,
              color: "#274368",
            },
          ],
        },

        plotOptions: {
          map: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
        },

        legend: {
          align: "left",
          verticalAlign: "bottom",
          floating: true,
          layout: "vertical",
          valueDecimals: 0,
          itemStyle: {
            fontSize: "11px",
            fontWeight: "normal",
          },
          backgroundColor:
            (Highcharts.defaultOptions &&
              Highcharts.defaultOptions.legend &&
              Highcharts.defaultOptions.legend.backgroundColor) ||
            "rgba(255, 255, 255, 0.85)",
          symbolRadius: 0,
          symbolHeight: 14,
          labelFormatter: function () {
            if (this.from === undefined) return "0 - " + (this.to || "+");
            else if (this.to === undefined) return this.from + "+";
            else return this.from + " - " + this.to;
          },
        },

        tooltip: { enabled: false },
        series: [
          {
            data: counterpartsData,
            animation: true,
            name: "No.of Counterparts",
            states: {
              hover: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
              format: "{point.name}",
            },
            allAreas: true,
          },
        ],
      });
    }
  }

  drawDefaultWorldMap(mapData) {
    if (mapData.length > 0) {
      let defaultsData = [];

      let southAfricaData;
      mapData.forEach((items) => {
        if (items.regionCountryMap.id === 7) {
          southAfricaData = items;
        }
      });
      mapData.forEach((regionItems) => {
        regionItems.regionCountryMap.countries.forEach((countryItems) => {
          if (countryItems.code.toLowerCase() === "sx") {
            defaultsData.push([
              countryItems.code.toLowerCase(),
              southAfricaData.defaultCounterPart,
            ]);
          } else {
            defaultsData.push([
              countryItems.code.toLowerCase(),
              regionItems.defaultCounterPart === null
                ? 0
                : regionItems.defaultCounterPart,
            ]);
          }
        });
      });

      Highcharts.mapChart("defaults-region", {
        chart: {
          map: worldMap,
        },

        credits: {
          enabled: false,
        },

        title: {
          text: "",
        },

        mapNavigation: {
          enabled: false,
          buttonOptions: {
            alignTo: "spacingBox",
            theme: {
              fill: "transparent",
              scaleX: 0.7,
              scaleY: 0.7,
            },
          },
          buttons: {
            zoomIn: {
              x: -9,
              y: -5,
            },
            zoomOut: {
              x: -9,
              y: 15,
            },
          },
        },

        colorAxis: {
          dataClasses: [
            {
              to: 20,
              color: "#aafcdf",
            },
            {
              from: 21,
              to: 50,
              color: "#5dd1b3",
            },
            {
              from: 51,
              to: 100,
              color: "#06bf7f",
            },
            {
              from: 101,
              to: 200,
              color: "#058d5c",
            },
            {
              from: 200,
              color: "#024a30",
            },
          ],
        },

        legend: {
          align: "left",
          verticalAlign: "bottom",
          floating: true,
          layout: "vertical",
          valueDecimals: 0,
          itemStyle: {
            fontSize: "11px",
            fontWeight: "normal",
          },
          backgroundColor:
            (Highcharts.defaultOptions &&
              Highcharts.defaultOptions.legend &&
              Highcharts.defaultOptions.legend.backgroundColor) ||
            "rgba(255, 255, 255, 0.85)",
          symbolRadius: 0,
          symbolHeight: 14,
          labelFormatter: function () {
            if (this.from === undefined) return "0 - " + (this.to || "+");
            else if (this.to === undefined) return this.from + "+";
            else return this.from + " - " + this.to;
          },
        },

        tooltip: { enabled: false },
        series: [
          {
            data: defaultsData,
            animation: true,
            name: "No.of Defaults",
            states: {
              hover: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
              format: "{point.name}",
            },
            allAreas: false,
          },
        ],
      });
    }
  }

  drawRRMap(mapData) {
    if (mapData.length > 0) {
      let rrData = [];

      let southAfricaData;
      mapData.forEach((items) => {
        if (items.regionCountryMap.id === 7) {
          southAfricaData = items;
        }
      });
      mapData.forEach((regionItems) => {
        regionItems.regionCountryMap.countries.forEach((countryItems) => {
          if (countryItems.code.toLowerCase() === "sx") {
            rrData.push([
              countryItems.code.toLowerCase(),
              southAfricaData.mean === null
                ? null
                : Math.round((southAfricaData.mean * 10000) / 100),
            ]);
          } else {
            rrData.push([
              countryItems.code.toLowerCase(),
              regionItems.mean === null
                ? null
                : Math.round((regionItems.mean * 10000) / 100),
            ]);
          }
        });
      });
      console.log(rrData);

      Highcharts.mapChart("rr-region", {
        chart: {
          map: worldMap,
        },

        credits: {
          enabled: false,
        },

        title: {
          text: "",
        },

        mapNavigation: {
          enabled: false,
          buttonOptions: {
            alignTo: "spacingBox",
            theme: {
              fill: "transparent",
              scaleX: 0.7,
              scaleY: 0.7,
            },
          },
          buttons: {
            zoomIn: {
              x: -9,
              y: -5,
            },
            zoomOut: {
              x: -9,
              y: 15,
            },
          },
        },

        colorAxis: {
          dataClasses: [
            {
              from: 0,
              to: 49,
              color: "#e8f7ff",
            },
            {
              from: 50,
              to: 59,
              color: "#abe3ff",
            },
            {
              from: 60,
              to: 69,
              color: "#9bb8fb",
            },
            {
              from: 70,
              to: 79,
              color: "#9966ff",
            },
            {
              from: 80,
              to: 89,
              color: "#2e75f0",
            },
            {
              from: 90,
              to: 100,
              color: "#274368",
            },
          ],
        },

        legend: {
          align: "left",
          verticalAlign: "bottom",
          floating: true,
          layout: "vertical",
          valueDecimals: 0,
          itemStyle: {
            fontSize: "11px",
            fontWeight: "normal",
          },
          backgroundColor:
            (Highcharts.defaultOptions &&
              Highcharts.defaultOptions.legend &&
              Highcharts.defaultOptions.legend.backgroundColor) ||
            "rgba(255, 255, 255, 0.85)",
          symbolRadius: 0,
          symbolHeight: 14,
          labelFormatter: function () {
            return this.from + "% - " + this.to + "%";
          },
        },

        tooltip: { enabled: false },
        series: [
          {
            data: rrData,
            animation: true,
            name: "RR",
            states: {
              hover: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
              format: "{point.name}",
            },
            allAreas: false,
          },
        ],
      });
    }
  }

  drawCounterpartsBySectorChart(chartData, divName) {
    let activeSectorData = [];
    const totalActiveCounterparts = Object.keys(chartData.activeSector).reduce(
      (sum, key) => sum + parseInt(chartData.activeSector[key] || 0),
      0
    );
    for (let key in chartData.activeSector) {
      activeSectorData.push({
        name: key,
        y: (chartData.activeSector[key] / totalActiveCounterparts) * 100,
      });
    }

    Highcharts.chart(divName, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: divName === "counterparts-sector-modal" ? 520 : null,
        style: {
          fontFamily: "sans-serif",
        },
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{point.percentage:.2f}%",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: "pointer",
          colors: [
            "#74A290",
            "#28486E",
            "#DBAA15",
            "#06898B",
            "#EB8A67",
            "#CBE4DA",
            "#FFE9C9",
            "#AABAD7",
            "#D9AAA9",
            "#C8C9CA",
            "#BB8D80",
            "#C9B199",
            "#8D8D8F",
            "#DBAA15",
            "#4E494D",
          ],
          dataLabels: {
            enabled: true,
            format: "{point.name}<br/> {point.percentage:.2f} %",
            style: {
              fontWeight: "normal",
            },
          },
        },
      },
      series: [
        {
          name: "Sectors",
          colorByPoint: true,
          innerSize: "50%",
          data: activeSectorData,
        },
      ],
    });
  }

  drawDefaultsBySectorChart(chartData, divName) {
    let defaultSectorData = [];
    const totalDefaultCounterparts = Object.keys(
      chartData.defaultSector
    ).reduce(
      (sum, key) => sum + parseInt(chartData.defaultSector[key] || 0),
      0
    );
    for (let key in chartData.defaultSector) {
      defaultSectorData.push({
        name: key,
        y: (chartData.defaultSector[key] / totalDefaultCounterparts) * 100,
      });
    }
    Highcharts.chart(divName, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: divName === "defaults-sector-modal" ? 520 : null,
        style: {
          fontFamily: "sans-serif",
        },
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{point.percentage:.2f}%",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: "pointer",
          colors: [
            "#74A290",
            "#28486E",
            "#DBAA15",
            "#06898B",
            "#EB8A67",
            "#CBE4DA",
            "#FFE9C9",
            "#AABAD7",
            "#D9AAA9",
            "#C8C9CA",
            "#BB8D80",
            "#C9B199",
            "#8D8D8F",
            "#DBAA15",
            "#4E494D",
          ],
          dataLabels: {
            enabled: true,
            format: "{point.name}<br/> {point.percentage:.2f} %",
            style: {
              fontWeight: "normal",
            },
          },
        },
      },
      series: [
        {
          name: "Sectors",
          colorByPoint: true,
          innerSize: "50%",
          data: defaultSectorData,
        },
      ],
    });
  }

  drawRRBySectorChart(barData) {
    var xAxisKeys = [];
    var seriesData = [];
    if (!_.isEmpty(barData.rrBySector)) {
      const ordered = {};
      Object.keys(barData.rrBySector)
        .sort()
        .forEach(function (key) {
          ordered[key] = barData.rrBySector[key];
        });
      for (let key in ordered) {
        if (key !== "Overall") {
          xAxisKeys.push(key);
          seriesData.push(
            ordered[key] === null ? 0 : (parseFloat(ordered[key]) * 10000) / 100
          );
        }
      }
      xAxisKeys.push("Overall");
      seriesData.push(
        (parseFloat(barData.rrBySector["Overall"]) * 10000) / 100
      );
    }

    Highcharts.chart("rr-sector", {
      chart: {
        type: "column",
        events: {
          load() {
            let chart = this;
            chart.series.forEach((s) => {
              s.points.forEach((p) => {
                if (p.category === "Overall") {
                  p.update({
                    color: "#EB8A67",
                  });
                }
              });
            });
          },
        },
        style: {
          fontFamily: "sans-serif",
        },
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: xAxisKeys,
        crosshair: true,
        showEmpty: false,
        tickInterval: 1,
        labels: {
          formatter() {
            var label = this.axis.defaultLabelFormatter.call(this);
            if (this.isLast) {
              return "<span style='color: #EB8A67'>" + label + "</span>";
            } else {
              return label;
            }
          },
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 10,
        showEmpty: false,
        title: {
          text: "Recovery Rate",
        },
        labels: {
          formatter: function () {
            var label = this.axis.defaultLabelFormatter.call(this);
            return label + "%";
          },
        },
      },
      tooltip: {
        headerFormat: "<span>{point.key}</span><table>",
        pointFormat: '<tr><td style="padding:0">{point.y:.2f} %</td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          showInLegend: false,
          name: "Recovery Rates",
          data: seriesData,
          colorByPoint: true,
          colors: ["#28486E"],
        },
      ],
    });
  }

  setFilterValues(graphType, value) {
    this.setState(
      {
        ["option" + graphType]: value,
      },
      this.getFilteredGraphData(graphType, value)
    );
  }

  getFilteredGraphData(graphType, options) {
    this.setState({ ["loading" + graphType]: true });
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    instance
      .post(
        "dashboard/tiles/get",
        {
          option: options.value,
          graph: graphType,
          isRefresh: false,
          key: this.props.currentDashboardId,
          isDefaultPeriod180: this.props.isDefaultPeriod180,
          categoryList: _.sortBy(this.props.category),
        },
        config
      )
      .then((response) => {
        this.setState({ ["loading" + graphType]: false });
        if (response.status === 200) {
          const mapData = response.data.listCountryRegion;
          if (graphType === "ACTIVE") this.drawActiveWorldMap(mapData);
          if (graphType === "DEFAULT") this.drawDefaultWorldMap(mapData);
          if (graphType === "REGIONRR") this.drawRRMap(mapData);
          if (graphType === "PD") {
            const graphData = {
              pdGraph: {
                dynamicCohortMap: response.data.dynamicCohortMap,
                pdAverage: response.data.pdAverage,
              },
            };
            this.drawPDGraph(graphData);
          }
          if (graphType === "MM") {
            const graphData = {
              mmGraph: {
                mmRatioList: response.data.mmRatioList,
                mmMean: response.data.mmMean,
              },
            };
            this.drawMMGraph(graphData);
          }
          if (graphType === "ACTIVESEC") {
            const chartData = {
              activeSector: response.data.sectorActiveCounterpartMap,
            };
            let tempActiveChartData = JSON.parse(
              JSON.stringify(this.state.chartData)
            );
            tempActiveChartData.activeSector =
              response.data.sectorActiveCounterpartMap;
            this.setState({ chartData: tempActiveChartData });
            this.drawCounterpartsBySectorChart(
              chartData,
              "counterparts-sector"
            );
          }
          if (graphType === "DEFAULTSEC") {
            const chartData = {
              defaultSector: response.data.sectorDefaultCounterpartMap,
            };
            let tempDefaultChartData = JSON.parse(
              JSON.stringify(this.state.chartData)
            );
            tempDefaultChartData.defaultSector =
              response.data.sectorDefaultCounterpartMap;
            this.setState({ chartData: tempDefaultChartData });
            this.drawDefaultsBySectorChart(chartData, "defaults-sector");
          }
          if (graphType === "SECTORRR") {
            const barData = {
              rrBySector: response.data.mapSectorMean,
            };
            this.drawRRBySectorChart(barData);
          }
        }
      })
      .catch((error) => {
        this.setState({ ["loading" + graphType]: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        } else {
          showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
        }
      });
  }

  toggleModal(modalName) {
    this.setState((prevState) => ({
      [modalName]: !prevState[modalName],
    }));
  }

  openModalView(name) {
      if (name === "counterparts") {
      this.toggleModal("counterpartSectorModal");
      const _this = this;
      setTimeout(function () {
        _this.drawCounterpartsBySectorChart(
          _this.state.chartData,
          "counterparts-sector-modal"
        );
      }, 10);
    } else {
      this.toggleModal("defaultSectorModal");
      const _this = this;
      setTimeout(function () {
        _this.drawDefaultsBySectorChart(
          _this.state.chartData,
          "defaults-sector-modal"
        );
      }, 10);
    }
  }

  render() {
    return (
      <div className="charts-section">
        <div />
        <div className="row">
          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto ">
                    <h3 className="font-weight-bold">
                      Distribution of counterparts by region
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      styles={customStyles}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                      value={this.state.optionACTIVE}
                      onChange={this.setFilterValues.bind(this, "ACTIVE")}
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingACTIVE ? LoaderComponent : null}
              <div id="counterparts-region" className="myImgContainer" />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h3 className="font-weight-bold">
                      Distribution of counterparts by sector
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      styles={customStyles}
                      value={this.state.optionACTIVESEC}
                      onChange={this.setFilterValues.bind(this, "ACTIVESEC")}
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingACTIVESEC ? LoaderComponent : null}
              <div
                id="counterparts-sector"
                className="myImgContainer pointer-cursor"
                onClick={this.openModalView.bind(this, "counterparts")}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h3 className="font-weight-bold">
                      Distribution of defaults by region
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      styles={customStyles}
                      value={this.state.optionDEFAULT}
                      onChange={this.setFilterValues.bind(this, "DEFAULT")}
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingDEFAULT ? LoaderComponent : null}
              <div id="defaults-region" className="myImgContainer" />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h3 className="font-weight-bold">
                      Distribution of defaults by sector
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      styles={customStyles}
                      value={this.state.optionDEFAULTSEC}
                      onChange={this.setFilterValues.bind(this, "DEFAULTSEC")}
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingDEFAULTSEC ? LoaderComponent : null}
              <div
                id="defaults-sector"
                className="myImgContainer pointer-cursor"
                onClick={this.openModalView.bind(this, "defaults")}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h3 className="font-weight-bold">
                      Annual rating upgrade vs. downgrade ratio
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      styles={customStyles}
                      value={this.state.optionMM}
                      onChange={this.setFilterValues.bind(this, "MM")}
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingMM ? LoaderComponent : null}
              <div id="annual-rating" className="myImgContainer" />
            </div>
          </div>

          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h3 className="font-weight-bold">
                      Dynamic annual default rate
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      styles={customStyles}
                      value={this.state.optionPD}
                      onChange={this.setFilterValues.bind(this, "PD")}
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingPD ? LoaderComponent : null}
              <div id="default-rate" className="myImgContainer" />
            </div>
          </div>

          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h3 className="font-weight-bold">
                      Distribution of simple average recovery rates by region
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      styles={customStyles}
                      value={this.state.optionREGIONRR}
                      onChange={this.setFilterValues.bind(this, "REGIONRR")}
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingREGIONRR ? LoaderComponent : null}
              <div id="rr-region" className="myImgContainer" />
            </div>
          </div>

          <div className="col-sm-6 mb-4">
            <div className="chart-bg">
              <div className="head3">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h3 className="font-weight-bold">
                      Simple average recovery rates by sector (incl. contagion)
                    </h3>
                  </div>
                  <div className="col-auto">
                    <Select
                      styles={customStyles}
                      value={this.state.optionSECTORRR}
                      onChange={this.setFilterValues.bind(this, "SECTORRR")}
                      options={
                        this.props.userData
                          ? this.props.userData.userRole === 1
                            ? this.state.superAdminOptions
                            : options
                          : this.props.callUserDetails()
                      }
                      maxMenuHeight={250}
                      noOptionsMessage={() => "No Results"}
                      className="react-select"
                      classNamePrefix="react-select-menulist"
                    />
                  </div>
                </div>
              </div>
              {this.state.loadingSECTORRR ? LoaderComponent : null}
              <div id="rr-sector" className="myImgContainer" />
            </div>
          </div>

          <Modal
            className="max-width-800"
            isOpen={this.state.counterpartSectorModal}
            toggle={this.toggleModal.bind(this, "counterpartSectorModal")}
            backdrop={false}
          >
            <ModalHeader
              toggle={this.toggleModal.bind(this, "counterpartSectorModal")}
            >
              Distribution of counterparts by sector
            </ModalHeader>
            <ModalBody>
              <div id="counterparts-sector-modal" />
            </ModalBody>
          </Modal>

          <Modal
            className="max-width-800"
            isOpen={this.state.defaultSectorModal}
            toggle={this.toggleModal.bind(this, "defaultSectorModal")}
            backdrop={false}
          >
            <ModalHeader
              toggle={this.toggleModal.bind(this, "defaultSectorModal")}
            >
              Distribution of defaults by sector
            </ModalHeader>
            <ModalBody>
              <div id="defaults-sector-modal" />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, { callUserDetails })(ChartSection);
