import React, { Component } from "react";
import {
  PD,
  MM,
  RR,
  SOV_DEFAULT_RESTRICT,
  SOV_DEFAULT_NOT_RESTRICT,
  SOV_FLAG_ALL,
  SOV_FLAG_YES,
  SOV_FLAG_NO,
} from "../commonUtility/string-const";
import _ from "lodash";

class ResultlistPara extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollRef = React.createRef();
  }

  getCalType(type) {
    switch (type) {
      case PD:
        return "Default Probability";
      case MM:
        return "Migration Matrix";
      case RR:
        return "Recovery Rate";
      default:
        break;
    }
    return "";
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.computeResponses != null &&
      !_.isEqual(prevProps.computeResponses, this.props.computeResponses)
    ) {
      this.scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }

  parameterBoxDisplayFilters(parameter_name, variable_name, needs_sorting, multiple_options){
    return (
    <div className="row mt-2">
    <div className="col-4 bold">{parameter_name}</div>
    <div className="col-8">{
      multiple_options === true ? (
        needs_sorting === true ? (
            variable_name.length > 0
            ? variable_name.sort().join(", ")
            : "No Restrictions"
          ) : (
            variable_name.length > 0
              ? variable_name.join(", ")
              : "No Restrictions"
          )
      ) : (
         variable_name
      )
      }
    </div>
  </div>
    );
  }

  render() {
    console.log(this.props);
    let paramModule = (
      <div className="computeoption-sec">
        <h1 className="heading">Parameters</h1>
        <div className="parameters-list">
          <div className="row" ref={this.scrollRef}>
            <div className="col-4 bold">Mode</div>
            <div className="col-8">
              {this.props.computeResponses.params.mode}
            </div>
          </div>
          {this.parameterBoxDisplayFilters("Default Period", this.props.computeResponses.params.default_period, false, false)}

          {this.parameterBoxDisplayFilters("Calculation Type", this.getCalType(this.props.computeResponses.params.calType, false, false))}

          {this.parameterBoxDisplayFilters("Starting Year", this.props.computeResponses.params.startYear, false, false)}

          {this.parameterBoxDisplayFilters("End Year", this.props.computeResponses.params.endYear, false, false)}

          {this.parameterBoxDisplayFilters("Duration", this.props.computeResponses.params.endYear + 1 - this.props.computeResponses.params.startYear + " years", false, false)}

          {this.props.computeResponses.params.calType === "MIGRATION_MATRIX" ? (
                      this.parameterBoxDisplayFilters ("Migration Period", this.props.computeResponses.params.period === 0
                      ? this.props.computeResponses.mmMaps.durationSet.toString()
                      : this.props.computeResponses.params.period, false, false)
          ) : null}

          {this.parameterBoxDisplayFilters ("Member", this.props.computeResponses.params.members, true, true)}

          {this.parameterBoxDisplayFilters ("Category", this.props.computeResponses.params.category, true, true)}
          
          {this.parameterBoxDisplayFilters ("Cross-default Clause", this.props.computeResponses.params.cdc_cprt, false, true)}

          {this.parameterBoxDisplayFilters ("Sub Sub Industry", this.props.computeResponses.params.subSubIndustryGrp, true, true)}

          {this.parameterBoxDisplayFilters ("Sub Industry Group", this.props.computeResponses.params.subIndustryGrp, true, true)}
          
          {this.parameterBoxDisplayFilters ("Industry Group", this.props.computeResponses.params.industryGrp, true, true)}
          
          {this.parameterBoxDisplayFilters ("Sector", this.props.computeResponses.params.sector, true, true)}

          {this.parameterBoxDisplayFilters ("Counterpart Region", this.props.computeResponses.params.region, true, true)}

          {this.parameterBoxDisplayFilters ("Counterpart Country", this.props.computeResponses.params.country, true, true)}

          {this.parameterBoxDisplayFilters ("Contract Region", this.props.computeResponses.params.regionContract, true, true)}

          {this.parameterBoxDisplayFilters ("Contract Country", this.props.computeResponses.params.countryContract, true, true)}

          {this.parameterBoxDisplayFilters ("Sovereign Default Definition", this.props.computeResponses.params.sovDefaultDefinition
                ? SOV_DEFAULT_RESTRICT
                : SOV_DEFAULT_NOT_RESTRICT, false, false)}

          {this.parameterBoxDisplayFilters ("Sector Classification", this.props.computeResponses.params.sector_classification, false, true)}

          {this.parameterBoxDisplayFilters ("Contagion", this.props.computeResponses.params.contagion, false, false)}

          {this.parameterBoxDisplayFilters ("Cross-default Clause (Contract)", this.props.computeResponses.params.cdc_crt, false, true)}

          {this.parameterBoxDisplayFilters ("Currency Type", this.props.computeResponses.params.currency_type, false, true)}

          {this.parameterBoxDisplayFilters ("Loan Type", this.props.computeResponses.params.loan_type, false, true)}

          {this.parameterBoxDisplayFilters ("Loan Amount Type", this.props.computeResponses.params.loan_amount, false, true)}

          {this.parameterBoxDisplayFilters ("Sovereign Flag", this.props.computeResponses.params.sovFlag === 1
                ? SOV_FLAG_ALL
                : this.props.computeResponses.params.sovFlag === 2
                ? SOV_FLAG_YES
                : SOV_FLAG_NO, false, false)}

          {this.props.computeResponses.params.calType === RR ? (
            this.parameterBoxDisplayFilters ("Resolution Type", this.props.computeResponses.params.resolution_type, false, true)        
          ) : null}

          {this.parameterBoxDisplayFilters ("Report Period", this.props.computeResponses.params.isNewPeriod, false, false)}

          {this.props.computeResponses.params.calType === RR ? (
            this.parameterBoxDisplayFilters ("Unresolved contracts", this.props.computeResponses.params.includeUnresolvedContracts ? "Included" : "Excluded" + " ", false, false) 
          ) : null}

          {this.props.computeResponses.params.calType === RR ? (
            this.parameterBoxDisplayFilters ("Threshold unresolved contracts", this.props.computeResponses.params.thresholdUnresolvedContracts + " ", false, false)
          ) : null}

          {this.props.computeResponses.params.calType !== RR ? (
            this.parameterBoxDisplayFilters ("Outlook from external rating agencies",this.props.computeResponses.params.includeOutlook ? "Included" : "Excluded", false, false)
          ) : null}

        </div>
      </div>
    );

    return paramModule;
  }
}

export default ResultlistPara;
