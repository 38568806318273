import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import { connect } from "react-redux";
import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  TOAST_TYPE_ERROR,
  ANNUAL_MODE_SWITCH,
  ACTION_UPLOAD,
  ACTION_MOD,
  ACTION_DB,
} from "../commonUtility/string-const";
import MaterialTable, { MTableCell } from "material-table";
import LoaderComponent from "../commonUtility/LoaderComponent";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import Select from "react-select";
import customStyles from "../commonUtility/ReactSelectCss";
import Switch from "react-switch";
import * as actions from "../../store/actions/index";
import { BASE_URL } from "../../instance";
/**
 * Patch for showing the select popup above other containers
 */

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class DashboardManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      isLoading: false,
      firstTymLoad: false,
      searchText: "",
      searchClass: "fa fa-search",
      clearClass: "fa fa-times",
      currentPageSize: 10,
      isAnnualMode: false,
      instituteList: [],
      instituteSelected: "*",
      datasetYearList: [],
      datasetYearSelected: "*",
      fileList: [],
      filteredFileList: [],
    };
    this.startSearching = this.startSearching.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createDatasetYear = this.createDatasetYear.bind(this);
    this.updateAnnualModeToServer = this.updateAnnualModeToServer.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props != null && props.isAnnualMode != undefined) {
      return {
        ...state,
        isAnnualMode: props.isAnnualMode,
      };
    } else {
      return { ...state };
    }
  }

  filterOptionDs = (option, inputValue) => {
    const { label, value } = option;
    if (value === -1) {
      return false;
    }
    return label.toString().startsWith(inputValue.toString());
  };

  filterOption = (option, inputValue) => {
    const { label, value } = option;
    if (value === -1) {
      return false;
    }
    return label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  filterChange(dataset_year, institute, fromSearch) {
    if (!fromSearch) {
      this.searchIconClick(true);
    }
    let instituteCode = institute.value;
    let datasetYearValue = dataset_year.value;
    let filteredFileList = [];
    let fileListAll = this.state.fileList;
    if (datasetYearValue == "*" && instituteCode == "*") {
      //Return all
      filteredFileList = fileListAll;
    } else if (datasetYearValue == "*" && instituteCode != "*") {
      //All dataset_year and selected institute
      for (var i = 0; i < fileListAll.length; i++) {
        if (fileListAll[i].code == instituteCode) {
          filteredFileList.push(fileListAll[i]);
        }
      }
    } else if (datasetYearValue != "*" && instituteCode == "*") {
      //Selected dataset_year and all institute
      for (var i = 0; i < fileListAll.length; i++) {
        if (fileListAll[i].dataset_year == datasetYearValue) {
          filteredFileList.push(fileListAll[i]);
        }
      }
    } else if (dataset_year != "*" && instituteCode != "*") {
      //Selected dataset_year and selected institute
      for (var i = 0; i < fileListAll.length; i++) {
        if (
          fileListAll[i].code == instituteCode &&
          fileListAll[i].dataset_year == datasetYearValue
        ) {
          filteredFileList.push(fileListAll[i]);
        }
      }
    }
    this.setState({
      filteredFileList: filteredFileList,
      instituteSelected: institute,
      datasetYearSelected: dataset_year,
    });
    return filteredFileList;
  }

  componentDidUpdate() {
    if (this.state.filteredFileList.length === 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  handleChange(name, event) {
    //event.preventDefault();
    switch (name) {
      case ANNUAL_MODE_SWITCH:
        this.updateAnnualModeToServer(event);
        break;
    }
  }

  updateAnnualModeToServer(isAnnualMode) {
    this.setState({ firstTymLoad: true });
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
        "Content-Type": "text/plain",
      },
    };

    instance
      .post("/api/setEnvFlag", isAnnualMode, config)
      .then((response) => {
        this.setState({ firstTymLoad: false });
        if (response != null && response.data) {
          if (response.data.error == false) {
            //All is OK
            this.props.onUploadEnvChange(
              response.data.isAnnualMode,
              this.props.userData
            );
            //Clear upload summary as well, force user to reupload
            sessionStorage.removeItem("excel_summary");
            sessionStorage.removeItem("runcheck_summary");
            sessionStorage.removeItem("runcheck_list");
          } else {
            //Error
            if (response.status == 401) {
              //UnAuth -> logout
              sessionStorage.clear();
              // window.location.reload();
              window.location.replace("/");
            } else {
              //Something went wrong
              showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
            }
          }
        } else {
          //No response
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            // window.location.reload();
            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv !== undefined) {
      pageDiv.hidden = flag;
    }
  }

  loadTableColumns() {
    let tempColumns = [
      {
        title: "Dataset Year",
        type: "numeric",
        field: "dataset_year",
        editable: "never",
        sorting: true,
        hidden: false,
      },
      {
        title: "Institution",
        type: "string",
        field: "Abbr",
        editable: "never",
        sorting: true,
        hidden: false,
      },
      {
        title: "Default Period",
        field: "defaultPeriod180",
        type: "boolean",
        editable: "never",
        sorting: true,
        render: (rowData) =>
          rowData.defaultPeriod180 ? "180 Days" : "90 Days",
      },
      {
        title: "File Uploaded",
        field: "last_excel_upload_filename",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={
              rowData.last_excel_upload_filename != "-"
                ? "download-link"
                : "text-center"
            }
            onClick={(e) =>
              /*console.log(rowData)*/ this.downloadFile(
                rowData.Abbr,
                true,
                rowData.defaultPeriod180,
                rowData.dataset_year,
                /*rowData.modified==0?*/ ACTION_UPLOAD /*:ACTION_MOD*/,
                rowData.last_excel_upload_filename
              )
            }
          >
            {rowData.last_excel_upload_filename}
          </div>
        ),
      },
      {
        title: "Last Uploaded",
        field: "last_excel_upload_date",
        type: "string",
        editable: "never",
        sorting: true,
      },
      {
        title: "File Updated",
        field: "last_version_file_name",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={
              rowData.last_version_file_name != "-"
                ? "download-link"
                : "text-center"
            }
            onClick={(e) =>
              /*console.log(rowData)*/ this.downloadFile(
                rowData.Abbr,
                true,
                rowData.defaultPeriod180,
                rowData.dataset_year,
                rowData.modified == 0 ? ACTION_UPLOAD : ACTION_MOD,
                rowData.last_version_file_name
              )
            }
          >
            {rowData.last_version_file_name}
          </div>
        ),
      },
      {
        title: "Last Updated",
        field: "last_version_date",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={rowData.last_version_date != "-" ? "" : "text-center"}
          >
            {rowData.last_version_date}
          </div>
        ),
      },
      {
        title: "File Inserted",
        field: "last_persist_filename",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={
              rowData.last_persist_filename != "-"
                ? "download-link"
                : "text-center"
            }
            onClick={(e) =>
              /*console.log(rowData)*/ this.downloadFile(
                rowData.Abbr,
                true,
                rowData.defaultPeriod180,
                rowData.dataset_year,
                ACTION_DB,
                rowData.last_persist_filename
              )
            }
          >
            {rowData.last_persist_filename}
          </div>
        ),
      },
      {
        title: "Last Inserted",
        field: "last_persist_date",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={rowData.last_persist_date != "-" ? "" : "text-center"}
          >
            {rowData.last_persist_date}
          </div>
        ),
      },
    ];
    this.setState({ columns: tempColumns, firstTymLoad: true });
  }

  createDatasetYear(duration) {
    let datasetYearList = [{ label: "All", value: "*" }];
    let currentYear = new Date().getFullYear();
    for (var i = 1; i <= duration; i++) {
      datasetYearList.push({ label: currentYear - i, value: currentYear - i });
    }
    this.setState({
      datasetYearList: datasetYearList,
      datasetYearSelected: datasetYearList[0],
    });
  }

  componentDidMount() {
    this.setState({ firstTymLoad: true });
    this.createDatasetYear(5);
    this.getInstituteList();
    this.getFileList();
  }

  downloadFile(abbr, eoy, defaultPeriod180, dataset_year, type, fileName) {
    if (fileName == "-") {
      return;
    }
    this.setState({ isLoading: true });
    let uploadedFileRes = {
      abbr: abbr,
      eoy: eoy,
      defaultPeriod180: defaultPeriod180,
      dataset_year: dataset_year,
      type: type,
      fileName: fileName,
    };
    let b_token = sessionStorage.getItem("gems_bearer_token");
    let xhr = new XMLHttpRequest();
    xhr.open("POST", BASE_URL + "/annualpage/download/uploadedfile");
    xhr.responseType = "blob";
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + b_token);
    xhr.send(JSON.stringify(uploadedFileRes));

    xhr.onload = function (e) {
      if (this.status == 200) {
        //this.setState({ isLoading: false });
        if (this.response.size > 0) {
          if (type != ACTION_UPLOAD) {
            let fileNameWithExt = fileName;
            let index = fileNameWithExt.lastIndexOf(".");
            let name = fileNameWithExt.substring(0, index);
            let fileType = fileNameWithExt.substring(index + 1);
            if (fileType == "xlsm") {
              fileName = name + "." + "xlsx";
            }
          }
          var blob = new Blob([this.response], {
            type: "application/vnd.ms-excel",
          });
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      } else {
        showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        //deal with your error state here
      }
    };

    xhr.onerror = function (e) {
      // this.setState({ isLoading: false });
      showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
    };
    this.setState({ isLoading: false });
  }

  getFileList() {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    this.loadTableColumns();
    instance
      .post("/annualpage/getAnnualModeFileList", "isEoy=true", config)
      .then((response) => {
        if (response.data != null && response.data.error == false) {
          //All OK
          this.setState({
            fileList: response.data.data,
            filteredFileList: response.data.data,
            firstTymLoad: false,
          });
        } else {
          //Error
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          this.setState({ firstTymLoad: false });
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            // window.location.reload();
            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  getInstituteList() {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };

    instance
      .get("/annualpage/getInstList", config)
      .then((response) => {
        if (response != null && response.data) {
          if (response.data.error == false) {
            //All is OK
            let instObj = response.data.instList;
            let instituteList = [{ label: "All", value: "*" }];
            Object.keys(instObj).forEach((i) => {
              instituteList.push({ label: i, value: instObj[i] });
            });
            this.setState({
              instituteList: instituteList,
              instituteSelected: instituteList[0],
            });
          } else {
            //Error
            if (response.status == 401) {
              //UnAuth -> logout
              sessionStorage.clear();
              // window.location.reload();
              window.location.replace("/");
            } else {
              //Something went wrong
              showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
            }
          }
        } else {
          //No response
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            // window.location.reload();
            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  startSearching(e) {
    let nonsearchFilterList = this.filterChange(
      this.state.datasetYearSelected,
      this.state.instituteSelected,
      true
    );
    if (e != null) {
      e = e.target.value;
      this.setState({ searchText: e });
      if (e != null && e.toString().trim() != "") {
        let fileList = nonsearchFilterList;
        let searchItems = [];
        for (var j = 0; j < fileList.length; j++) {
          for (var key in fileList[j]) {
            if (
              key != "tableData" &&
              key != "code" &&
              key != "modified" &&
              fileList[j][key] != null
            ) {
              if (
                fileList[j][key]
                  .toString()
                  .toLowerCase()
                  .includes(e.toLowerCase())
              ) {
                searchItems.push(fileList[j]);
                break;
              }
            }
          }
        }
        this.setState({ filteredFileList: searchItems });
      } else {
        this.setState({ filteredFileList: nonsearchFilterList });
      }
    } else {
      this.setState({ filteredFileList: nonsearchFilterList });
    }
  }

  searchIconClick(clear) {
    if (clear) {
      this.setState({ searchText: "" });
      this.startSearching(null);
    }
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      console.log("Enter pressed");

      this.searchIconClick(this.state.searchText.trim().length > 0);
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <div className="annual-mode-main">
            <div className="annual-mode-top d-flex align-items-center">
              <span className="">Annual Mode</span>
              <Switch
                onChange={(e) => this.handleChange(ANNUAL_MODE_SWITCH, e)}
                checked={this.state.isAnnualMode}
                uncheckedIcon={false}
                checkedIcon={false}
                height={14}
                width={35}
              />
            </div>
            <Paper className={classes.root + " event-list mt-2"}>
              {this.state.isLoading ? <LoaderComponent /> : null}
              {this.state.firstTymLoad ? (
                <FullScreenLoaderComponent msg="" />
              ) : null}
              {this.state.firstTymLoad ? null : (
                <div className="top-bar ">
                  <div className="anual-mode-text">
                    <h6 className="mt-1 mb-2">
                      Data Submission in Annual Mode
                    </h6>
                  </div>

                  <div className="row">
                    <div className="col-auto">
                      <div className="form-group p-0">
                        <div className="row">
                          <div className="col-auto">
                            <div className="mt-1">Dataset Year</div>
                          </div>
                          <div className="col-auto">
                            <Select
                              required
                              name="datasetYear"
                              id="datasetYear"
                              value={this.state.datasetYearSelected}
                              onChange={(e) =>
                                this.filterChange(
                                  e,
                                  this.state.instituteSelected,
                                  false
                                )
                              }
                              filterOption={this.filterOptionDs}
                              className="react-select"
                              isSearchable={true}
                              noOptionsMessage={() => "No Results"}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              options={this.state.datasetYearList}
                              classNamePrefix="react-select-menulist annaul-mode"
                            />
                          </div>
                        </div>

                        <div className="error-text">
                          {/* <FormErrors  /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="form-group p-0">
                        <div className="row">
                          <div className="col-auto">
                            <div className="mt-1">Institution</div>
                          </div>
                          <div className="col-auto">
                            <Select
                              required
                              name="instituteList"
                              id="instituteList"
                              value={this.state.instituteSelected}
                              filterOption={this.filterOption}
                              onChange={(e) =>
                                this.filterChange(
                                  this.state.datasetYearSelected,
                                  e,
                                  false
                                )
                              }
                              className="react-select"
                              isSearchable={true}
                              noOptionsMessage={() => "No Results"}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              options={this.state.instituteList}
                              classNamePrefix="react-select-menulist annaul-mode"
                            />
                          </div>
                        </div>

                        <div className="error-text"></div>
                      </div>
                    </div>

                    <div className="col-auto align-self-center ml-auto">
                      <div className="search-bar">
                        <div id="custom-search-input">
                          <div className="input-group">
                            <input
                              type="text"
                              className="search-query form-control"
                              placeholder="Search"
                              onChange={this.startSearching}
                              value={this.state.searchText}
                              tabIndex="0"
                            />
                            <span className="input-group-btn" tabIndex="-1">
                              <button
                                className="btn btn-danger"
                                type="button"
                                tabIndex="-1"
                              >
                                <i
                                  className={
                                    this.state.searchText.trim().length > 0
                                      ? this.state.clearClass
                                      : this.state.searchClass
                                  }
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.searchIconClick(
                                      this.state.searchText.trim().length > 0
                                    )
                                  }
                                  onKeyUp={(event) => this.enterPressed(event)}
                                  tabIndex={
                                    this.state.searchText.trim().length > 0
                                      ? "0"
                                      : "-1"
                                  }
                                />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.firstTymLoad ? null : (
                <MaterialTable
                  columns={this.state.columns}
                  data={this.state.filteredFileList}
                  onChangeRowsPerPage={(pageSize) => {
                    this.setState({ currentPageSize: pageSize });
                    let timesRun = 0;
                    let firstTimeExecuted = false;
                    var hasVScroll =
                      window.innerHeight /* + window.scrollY*/ <=
                      document.body.offsetHeight;
                    this.interval = setInterval(() => {
                      if (timesRun == 5) {
                        clearInterval(this.interval);
                      }
                      timesRun++;
                      console.log("service ", hasVScroll);
                      if (hasVScroll) {
                        window.scrollTo(0, 0);
                        clearInterval(this.interval);
                      } else {
                        if (firstTimeExecuted) {
                          clearInterval(this.interval);
                        }
                        firstTimeExecuted = true;
                      }
                    }, 1);
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "No records to display",
                    },
                  }}
                  options={{
                    showTitle: false,
                    searchFieldAlignment: "left",
                    // actionsColumnIndex: -1,
                    pageSize: this.state.currentPageSize,
                    pageSizeOptions: [10, 20, 25],
                    sorting: this.state.filteredFileList.length > 0,
                    search: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    thirdSortClick: false,
                  }}
                />
              )}
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Annual mode page received", state);
  return {
    isAnnualMode: state.auth.isAnnualMode,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadEnvChange: (isAnnualMode, userData) => {
      dispatch(actions.annualModeChange(isAnnualMode, userData));
    },
  };
};

DashboardManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  passMessage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardManagement));
