import React, { Component } from "react";
import MainHeading from "./Heading";
import Options from "./Options";
import Runcheck from "../commonUtility/Runcheck";
import Footer from "../commonUtility/Footer";
import { Container, Row, Col } from "reactstrap";
import Navigation from "../navbar/Navigation";
import GenerateSummary from "./Generatesummary";
import instance from "../../instance";
import LoaderComponent from "../commonUtility/LoaderComponent";
import { authHeader } from "../commonUtility/auth-header";
import { showToast } from "../commonUtility/NewToast";
import { ToastContainer } from "react-toastify";
import { arrayEqual } from "../commonUtility/method-utils";
import {
  TOAST_TYPE_ERROR,
  RUN_CHECK_LIST,
  DATA_VALIDATION_COMPLETED,
  DATA_VALIDATION_COMPLETED_NO_ERROR_ALL_RC,
  DATA_VALIDATION_COMPLETED_WITH_ALL_RC_ERROR_CAT_4_5,
  UPLOAD_DISABLED_TITLE,
} from "../commonUtility/string-const";
import PopupModal from "../commonUtility/PopupModal";
import { connect } from "react-redux";
import { callUserDetails } from "../../store/actions/index";
import * as actions from "../../store/actions/index";
import ScrollUpButton from "react-scroll-up-button";

class HomePage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isDefault180: false,
      runCheckExceptionMap: null,
      renderRunCheck: false,
      isLoading: false,
      hasDataUploaded: false,
      isUnauthorized: false,
      canInsertData: false,
      displayRunCheckModal: false,
      modalBodyMessage: "",
      modalTitle: "Data Validation Complete",
      ownerName: null,
      ownerId: null,
      displayUploadDsbldPopup: false,
      uploadDsbldTitle: null,
      uploadDsbldMsg: null,
    };
    this.closePopup = this.closePopup.bind(this);
  }
  fromOptions(is180Period) {
    sessionStorage.removeItem("runcheck_summary");
    sessionStorage.removeItem("runcheck_list");
    sessionStorage.setItem("isDefault180", is180Period);
    this.setState({
      isDefault180: is180Period,
      runCheckExceptionMap: null,
      renderRunCheck: false,
      canInsertData: false,
    });
  }

  updateDefaultPeriod(is180Period) {
    this.setState({ isDefault180: is180Period });
  }

  isDataInStorage() {
    let data = sessionStorage.getItem("excel_summary");
    if (data != null && data != "null") {
      data = JSON.parse(data);
      if (data != null) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  dataUploaded(uploaded) {
    if (this._isMounted) {
      this.setState({ hasDataUploaded: uploaded, clearContent: false });
    }
  }

  clear(isDefault180) {
    this.dataUploaded(false);
    this.setState({ runCheckExceptionMap: null });
    this.setState({
      renderRunCheck: false,
      canInsertData: false,
      isDefault180: isDefault180,
    });
  }

  runCheckClicked(runchecksList, clickFromButton) {
    if (this.props.runCheckExecuting) {
      return;
    }
    this.props.onRunCheck(true);
    if (this.props.userData == undefined) {
      this.props.callUserDetails();
    }
    this.setState({ isLoading: true });
    this.setState({ renderRunCheck: false });
    this.setState({ canInsertData: false });
    let isReferseh = false;
    if (clickFromButton) {
      let runchkList = sessionStorage.getItem("runcheck_list");
      if (runchkList != null) {
        runchkList = JSON.parse(runchkList);
        if (
          arrayEqual(
            runchecksList,
            runchkList
          ) /*runchecksList.length===runchkList.length*/
        ) {
          isReferseh = true;
        }
      }
    }

    if (runchecksList != null && runchecksList.length > 0) {
      sessionStorage.setItem("runcheck_list", JSON.stringify(runchecksList));
    }
    instance
      .post("/data/upload/runChecks/" + isReferseh, runchecksList, {
        headers: {
          ...authHeader(),
          isPeriod180: this.state.isDefault180,
          isEoy: this.props.isAnnualMode,
        },
      })
      .then((res) => {
        this.props.onRunCheck(false);
        if (res && res.data.error !== undefined && res.data.error === false) {
          let runChkExp = res.data;
          this.setState({ runCheckExceptionMap: runChkExp });
          this.setState({ isLoading: false });
          if (runChkExp != null) {
            if (this.props.userData && this.props.userData.privilege_id === 2) {
              if (
                runChkExp.priviledge &&
                runChkExp.priviledge != -1 &&
                runChkExp.priviledge === 1
              ) {
                this.props.updateUploadPermission(
                  null,
                  this.props.userData.email,
                  true,
                  { ...this.props.userData, privilege_id: runChkExp.priviledge }
                );
              }
            }

            this.setState({
              ownerName: runChkExp.owner,
              ownerId: runChkExp.ownerid,
            });
            if (Object.keys(runChkExp.errorMap).length > 0) {

              this.setState({ renderRunCheck: true });
              this.setState({ canInsertData: runChkExp.insertAllowed });
              if (!clickFromButton) {
                if (
                  runchecksList.length == Object.keys(RUN_CHECK_LIST).length
                ) {
                  if (runChkExp.insertAllowed == false) {
                    this.setState({
                      displayRunCheckModal: true,
                      modalBodyMessage:
                        DATA_VALIDATION_COMPLETED_WITH_ALL_RC_ERROR_CAT_4_5,
                    });
                  } else {
                    let isInsertPrivAllowed = this.props.userData
                      ? this.props.userData.privilege_id === 1 &&
                        this.props.userData.upload_allowed
                      : false;
                    if (isInsertPrivAllowed) {
                      this.setState({
                        displayRunCheckModal: true,
                        modalBodyMessage:
                          DATA_VALIDATION_COMPLETED_NO_ERROR_ALL_RC,
                      });
                    } else {
                      this.setState({
                        displayRunCheckModal: true,
                        modalBodyMessage: DATA_VALIDATION_COMPLETED,
                      });
                    }
                  }
                } else {
                  this.setState({
                    displayRunCheckModal: true,
                    modalBodyMessage: DATA_VALIDATION_COMPLETED,
                  });
                }
              }
            } else {
              this.setState({
                isLoading: false,
                canInsertData: runChkExp.insertAllowed,
              });
              if (!clickFromButton) {
                let isInsertPrivAllowed = this.props.userData
                  ? this.props.userData.privilege_id === 1 &&
                    this.props.userData.upload_allowed
                  : false;
                if (isInsertPrivAllowed) {
                  this.setState({
                    displayRunCheckModal: true,
                    modalBodyMessage: runChkExp.insertAllowed
                      ? DATA_VALIDATION_COMPLETED_NO_ERROR_ALL_RC
                      : DATA_VALIDATION_COMPLETED,
                  });
                } else {
                  this.setState({
                    displayRunCheckModal: true,
                    modalBodyMessage: DATA_VALIDATION_COMPLETED,
                  });
                }
              }
            }
          } else {
            this.setState({ isLoading: false });
            this.setState({ renderRunCheck: false });
          }
        } else {
          this.setState({ isLoading: false });
          this.setState({ renderRunCheck: false });
          if (
            res.data.upload_allowed != null &&
            res.data.upload_allowed == false
          ) {
            if (res.data.message != undefined) {
              this.setState({
                displayUploadDsbldPopup: true,
                uploadDsbldTitle: UPLOAD_DISABLED_TITLE,
                uploadDsbldMsg: res.data.message,
              });
            }
          } else {
            showToast("Unknown error occured");
          }
        }
      })
      .catch((error) => {
        this.props.onRunCheck(false);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.reload();
          } else {
            this.setState({ isLoading: false });
            showToast("Unknown error occured", TOAST_TYPE_ERROR);
            console.log("Unknown error occured", TOAST_TYPE_ERROR);
          }
        } else {
          this.setState({ isLoading: false });
          console.log("Unknown error occured", TOAST_TYPE_ERROR);
          showToast("Unknown error occured", TOAST_TYPE_ERROR);
        }
      });
  }

  isRunCheckInStorage() {
    let runChkSumry = sessionStorage.getItem("runcheck_summary");
    if (runChkSumry && runChkSumry != null) {
      return true;
    }
    return false;
  }

  closePopup() {
    this.setState({ displayRunCheckModal: false, modalBodyMessage: "" });
  }

  cancelPopup() {
    this.setState({ displayRunCheckModal: false, modalBodyMessage: "" });
  }

  refreshPage() {
    this.setState({ runCheckExceptionMap: null });
    this.setState({
      renderRunCheck: false,
      canInsertData: false,
      isDefault180: false,
    });
    sessionStorage.removeItem("excel_summary");
    sessionStorage.removeItem("runcheck_summary");
    sessionStorage.removeItem("runcheck_list");
    window.location.reload();
  }

  render() {
    console.log(
      this.state.hasDataUploaded,
      this.isDataInStorage(),
      !this.state.hasDataUploaded && !this.isDataInStorage()
    );
    return (
      <div className="home-page">
        <Navigation />
        <MainHeading />
        <div className="mt-3">
          {this.state.displayUploadDsbldPopup ? (
            <PopupModal
              modalSize="md"
              title={this.state.uploadDsbldTitle}
              message={this.state.uploadDsbldMsg}
              yesClickCallback={this.refreshPage.bind(this)}
              cancelClickCallback={this.refreshPage.bind(this)}
              buttonPositive="OK"
            />
          ) : null}
          {this.state.displayRunCheckModal ? (
            <PopupModal
              title={this.state.modalTitle}
              message={this.state.modalBodyMessage}
              yesClickCallback={this.closePopup.bind(this)}
              noClickCallback={this.closePopup.bind(this)}
              cancelClickCallback={this.cancelPopup.bind(this)}
              buttonPositive="OK"
              modalSize="md"
            />
          ) : null}
          <Container>
            <Row>
              <Col lg="4" xl="3" className="pr-2">
                <Options
                  callback={this.fromOptions.bind(this)}
                  clearCallBack={this.clear.bind(this)}
                  uploadedDataCallback={this.dataUploaded.bind(this)}
                  isDataInLocal={this.isDataInStorage.bind(this)}
                  updateDefaultPeriod={this.updateDefaultPeriod.bind(this)}
                />
              </Col>
              <Col lg="8" xl="9" className="pl-2">
                <Runcheck
                  defaultType={this.state.isDefault180}
                  callback={this.runCheckClicked.bind(this)}
                  isRunCheckInStorage={this.isRunCheckInStorage.bind(this)}
                  clearAll={this.state.renderRunCheck}
                  disabled={
                    !this.state.hasDataUploaded && !this.isDataInStorage()
                  }
                  saveAllowed={this.state.canInsertData}
                  ownerName={this.state.ownerName}
                  ownerId={this.state.ownerId}
                />
                {this.state.renderRunCheck || this.isRunCheckInStorage() ? (
                  <GenerateSummary errormap={this.state.runCheckExceptionMap} />
                ) : this.state.isLoading ? (
                  <LoaderComponent />
                ) : null}
              </Col>
            </Row>
          </Container>
          <ScrollUpButton ShowAtPosition={20} className="scrolltopbutton" />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    isAnnualMode: state.auth.isAnnualMode,
    runCheckExecuting: state.auth.runCheckExecuting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRunCheck: (runCheckExecuting) => {
      dispatch(actions.runCheckExecuting(runCheckExecuting));
    },
    callUserDetails: () => {
      dispatch(actions.callUserDetails());
    },
    updateUploadPermission: (idToken, userId, isAuthenticated, userData) => {
      dispatch(actions.authSuccess(idToken, userId, isAuthenticated, userData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
