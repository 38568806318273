import React from "react";
import PubSub from "pubsub-js";

export default class Textbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    PubSub.subscribe("TRIGGER_ROW_VALIDATION", this.runValidation);
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.runValidation);
  }

  runValidation = () => {
    if (
      this.props.required &&
      (this.props.value === "" ||
        this.props.value === null ||
        this.props.value === undefined)
    ) {
      this.setState({
        error: true,
        errorMessage: "Please enter " + this.props.name,
      });
    }
  };

  handleChange(e) {
    this.setState({ error: false, errorMessage: "" });
    if (this.props.maxLength !== undefined) {
      if (e.target.value.length <= this.props.maxLength) {
        this.props.onChange(e.target.value);
      }
    } else {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <input
              type="text"
              value={this.props.value === undefined ? "" : this.props.value}
              onChange={this.handleChange.bind(this)}
              className="form-control"
              name={this.props.name}
              placeholder={
                this.props.required ? this.props.name + "*" : this.props.name
              }
              autoFocus={this.props.autoFocus === undefined ? false : true}
            />
          </div>
          <span
            className={this.state.error ? "errordisplay" : "errornotdisplay"}
          >
            <div className="formErrors">
              <div className="alert-warning">
                {this.state.error ? this.state.errorMessage : ""}
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }
}
