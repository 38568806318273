import React from "react";

const Checkbox = ({ label, isSelected, onCheckboxChange, disabled }) => (
  <label>
    <input
      type="checkbox"
      name={label}
      checked={isSelected}
      onChange={onCheckboxChange}
      className="form-check-input"
      disabled={disabled}
    />
    {label}
  </label>
);

export default Checkbox;
