/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import MMResultTable from "./MMResultTable";
import MMRaingChart from "./MMRatingChart";
import _ from "lodash";
import { NO_DISPLAY_RESULT } from "../commonUtility/string-const";

class MMResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mmData: this.props.mmMapData.mmMaps,
      selectedDuration:
        this.props.mmMapData.mmMaps.durationSet.length > 0
          ? this.props.mmMapData.mmMaps.durationSet[0]
          : 0,
    };
  }

  componentDidUpdate(oldProps) {
    if (
      !_.isEqual(oldProps.mmMapData, this.props.mmMapData) &&
      this.props.mmMapData !== undefined
    ) {
      this.setState({
        mmData: this.props.mmMapData.mmMaps,
        selectedDuration:
          this.props.mmMapData.mmMaps.durationSet.length > 0
            ? this.props.mmMapData.mmMaps.durationSet[0]
            : 0,
      });
    }
  }

  getMMChartSVG() {
    return this.refs.MMRatingChart.getMMChartSVG();
  }

  setSelectedDuration(item) {
    this.setState({ selectedDuration: item });
  }

  render() {
    if (
      this.props.mmMapData !== undefined &&
      this.props.mmMapData.params !== undefined
    ) {
      return (
        <div>
          {this.props.renderHiddenMMChart ? (
            <div className="display-none">
              <MMRaingChart
                ref="MMRatingChart"
                mmData={this.state.mmData}
                startYear={parseInt(this.props.mmMapData.params.startYear) + 1}
                endYear={parseInt(this.props.mmMapData.params.endYear)}
                renderHiddenMMChart={this.props.renderHiddenMMChart}
              />
            </div>
          ) : (
            <div>
              {!_.isEmpty(this.props.mmMapData.mmMaps.valueTrue) &&
              !_.isEmpty(this.props.mmMapData.mmMaps.valueFalse) &&
              this.props.mmMapData.mmMaps.upRatioList.length > 0 ? (
                <div className="mm-table">
                  {this.props.mmMapData.mmMaps.durationSet.length > 1 ? (
                    <div
                      className="rating-summary"
                      style={{ textAlign: "left" }}
                    >
                      Available Duration :
                      {this.props.mmMapData.mmMaps.durationSet.map((item) => {
                        return (
                          <a
                            onClick={this.setSelectedDuration.bind(this, item)}
                          >
                            {item} {"  "}
                          </a>
                        );
                      })}
                    </div>
                  ) : null}
                  {this.state.selectedDuration > 0 ? (
                    <div>
                      <p className="select-duration">
                        Selected Duration : {this.state.selectedDuration}
                      </p>
                      <MMResultTable
                        selectedDuration={this.state.selectedDuration}
                        mmData={this.state.mmData}
                        startYear={
                          parseInt(this.props.mmMapData.params.startYear) + 1
                        }
                        endYear={parseInt(this.props.mmMapData.params.endYear)}
                      />
                      <div className="mt-4">
                        <MMRaingChart
                          ref="MMRatingChart"
                          mmData={this.state.mmData}
                          startYear={
                            parseInt(this.props.mmMapData.params.startYear) + 1
                          }
                          endYear={parseInt(
                            this.props.mmMapData.params.endYear
                          )}
                          renderHiddenMMChart={this.props.renderHiddenMMChart}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="result-for-table">{NO_DISPLAY_RESULT}</div>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default MMResult;
