import React, { Component } from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";

class CounterpartCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
    };
  }

  componentDidMount() {
    if (this.props.isUploading == false) {
      this.setState({ refresh: true });
    }
  }

  individualSheetHeading(summary, sheet, displayName){
    return(
    <ListGroupItemHeading>
      <span>
        {summary != null && summary[sheet] != null ? (
          summary[sheet][0]
        ) : (
          <span>0</span>
        )}{" "}
        {displayName}
      </span>
    </ListGroupItemHeading>
    )
  }

  individualSheetSubItem(summary, sheet, foreignKey){
    return (
    <ListGroupItemText>
      <i className="fa fa-arrow-right" />{" "}
      <span>
        {summary != null && summary[sheet] != null ? (
          summary[sheet][1][foreignKey] == null ? (
            "0"
          ) : (
            summary[sheet][1][foreignKey]
          )
        ) : (
          <span>0</span>
        )}{" "}
        {foreignKey}
      </span>
    </ListGroupItemText>
      )
  }

  render() {
    let summary = this.props.data;
    if (summary == null) {
      summary = sessionStorage.getItem("excel_summary");
      if (summary != null) {
        summary = JSON.parse(summary);
      } else {
        summary = null;
      }
    }
    let uniqueCpts = "Unique counterpart(s)";
    let uniqueContracts = "Unique contract(s)";
    let uniqueGtors = "Unique guarantor(s)";
    return (
      <ListGroup className="option-list">
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "ACT_COUNTERPART", "Act Counterpart")}
          {this.individualSheetSubItem(summary, "ACT_COUNTERPART", uniqueCpts)}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "REF_COUNTERPART", "Ref Counterpart")}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "REF_CONTRACT", "Ref Contract")}
          {this.individualSheetSubItem(summary, "REF_CONTRACT", uniqueCpts)}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "DEFAULT_COUNTERPART", "Default Counterpart")}
          {this.individualSheetSubItem(summary, "DEFAULT_COUNTERPART", uniqueCpts)}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "DEFAULT_CONTRACT", "Default Contract")}
          {this.individualSheetSubItem(summary, "DEFAULT_CONTRACT", uniqueCpts)}
          {this.individualSheetSubItem(summary, "DEFAULT_CONTRACT", uniqueContracts)}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "GEM_IMPORT", "Gem Import")}
          {this.individualSheetSubItem(summary, "GEM_IMPORT", uniqueCpts)}
          {this.individualSheetSubItem(summary, "GEM_IMPORT", uniqueContracts)}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "REF_COLLATERAL", "Ref Collateral")}
          {this.individualSheetSubItem(summary, "REF_COLLATERAL", uniqueContracts)}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "REF_GUARANTOR", "Ref Guarantor")}
          {this.individualSheetSubItem(summary, "REF_GUARANTOR", uniqueContracts)}
        </ListGroupItem>
        <ListGroupItem className="justify-content-between">
          {this.individualSheetHeading(summary, "REF_GUARANTEE", "Ref Guarantee")}
          {this.individualSheetSubItem(summary, "REF_GUARANTEE", uniqueGtors)}
          {this.individualSheetSubItem(summary, "REF_GUARANTEE", uniqueContracts)}
        </ListGroupItem>
      </ListGroup>
    );
  }
}

export default CounterpartCount;
