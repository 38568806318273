import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

class AllCounterpartsRatings extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  state = {
    isOpen: this.props.isOpen,
    activeSlide: this.props.clickedIndex,
  };

  handleClose() {
    this.setState({ isOpen: false });
    this.props.toggleCallback(false);
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: parseInt(this.props.clickedIndex),
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
    };
    return (
      <div className="pd-popup-slider">
        <Modal
          isOpen={this.state.isOpen}
          toggle={(e) => this.handleClose()}
          backdrop={false}
          className="pd-popup-slider"
          size="lg"
        >
          <ModalHeader toggle={(e) => this.handleClose()}>
            Rating {this.props.ratingToShow} &nbsp;&nbsp;&nbsp; Starting Year :{" "}
            {this.props.selectedYear} &nbsp;&nbsp;&nbsp; Duration:{" "}
            {this.state.activeSlide}
          </ModalHeader>
          <ModalBody>
            <Slider {...settings}>
              {Object.keys(this.props.selectedYearData).map((i) => {
                if (
                  this.props.selectedYearData[i] !== null &&
                  !(
                    this.props.selectedYearData[i].outstanding === 0 &&
                    this.props.selectedYearData[i].matured === 0 &&
                    this.props.selectedYearData[i].defaulted === 0
                  )
                ) {
                  return (
                    <div
                      key={this.props.selectedYearData[i]}
                      className="d-flex m-0 justify-content-between"
                    >
                      <div className="border-slide w-100">
                        <div className="maxheightslide">
                          <h6>
                            Outstanding{" "}
                            <span className="badge badge-secondary">
                              <strong>
                                {this.props.selectedYearData[i].outstanding}
                              </strong>
                            </span>
                          </h6>
                          {this.props.selectedYearData[
                            i
                          ].outstandingSovList.map((items) => {
                            return <div key={items}>{items}</div>;
                          })}
                          {this.props.selectedYearData[i].outstandingList.map(
                            (items) => {
                              return <div key={items}>{items}</div>;
                            }
                          )}
                        </div>
                      </div>
                      <div className="border-slide w-100">
                        <div className="maxheightslide">
                          <h6>
                            Matured{" "}
                            <span className="badge badge-secondary">
                              <strong>
                                {this.props.selectedYearData[i].matured}
                              </strong>
                            </span>
                          </h6>
                          {this.props.selectedYearData[i].maturedSovList.map(
                            (items) => {
                              return <div key={items}>{items}</div>;
                            }
                          )}
                          {this.props.selectedYearData[i].maturedList.map(
                            (items) => {
                              return <div key={items}>{items}</div>;
                            }
                          )}
                        </div>
                      </div>
                      <div className="border-slide w-100">
                        <div className="maxheightslide">
                          <h6>
                            Default{" "}
                            <span className="badge badge-secondary">
                              <strong>
                                {this.props.selectedYearData[i].defaulted}
                              </strong>
                            </span>
                          </h6>
                          {this.props.selectedYearData[i].defaultedSovList.map(
                            (items) => {
                              return <div key={items}>{items}</div>;
                            }
                          )}
                          {this.props.selectedYearData[i].defaultedList.map(
                            (items) => {
                              return <div key={items}>{items}</div>;
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </Slider>
            <div className="mt-2 lib-model-foot">
              <Button
                className="btn btn-default"
                onClick={(e) => this.handleClose()}
              >
                OK
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AllCounterpartsRatings.propsType = {
  toggleCallback: PropTypes.func,
};

export default AllCounterpartsRatings;
