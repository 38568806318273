import React, { Component } from "react";
import { Table } from "reactstrap";
import {
  RR_HEADERS,
  RR_PARAMS_HEADERS,
  RR_DISPLAY_RESULT,
  NO_DISPLAY_RESULT,
} from "../commonUtility/string-const";
import moment from "moment";
import { connect } from "react-redux";
import { callUserDetails } from "../../store/actions/index";

class RRResultTable extends Component {
  getRRHeaders() {
    let tableHeaders = [];
    if (
      this.props.rrParams.mode === "Annual Report Environment" &&
      this.props.userData.userRole !== 1 &&
      this.props.rrParams.members.length !== 1
    ) {
      RR_HEADERS.forEach((key, index) => {
        if (
          key !== "Counterpart ID" &&
          key !== "Contract ID" &&
          key !== "Default ID" &&
          key !== "Default Date"
        ) {
          tableHeaders.push(<th key={key}>{key}</th>);
        }
      });
    } else {
      RR_HEADERS.forEach((key, index) => {
        tableHeaders.push(<th key={key}>{key}</th>);
      });
    }
    return tableHeaders;
  }

  toFixed(num) {
    num = ((num * 10000) / 100).toString();
    if (num.indexOf(".") > -1) {
      num = num + "00";
      num = num.slice(0, num.indexOf(".") + 3);
    } else {
      num = num + ".00";
    }
    return num;
  }

  to2DecimalPlaces(num) {
    num = num.toString();
    if (num.indexOf(".") > -1) {
      num = num + "00";
      num = num.slice(0, num.indexOf(".") + 3);
    } else {
      num = num + ".00";
    }
    return num;
  }

  getRRRows() {
    let rrRowData = [];
    this.props.rrData.recoveryRateResult.forEach((row, index) => {
      let rrRowWiseData = [];
      rrRowWiseData.push(
        <td key={"category" + index + this.props.rrData.key}>{row.category}</td>
      );
      rrRowWiseData.push(
        <td key={"region" + index + this.props.rrData.key}>{row.region}</td>
      );
      rrRowWiseData.push(
        <td key={"industryGroup" + index + this.props.rrData.key}>
          {row.industryGroup}
        </td>
      );
      rrRowWiseData.push(
        <td key={"regionCtp" + index + this.props.rrData.key}>
          {row.regionCtp}
        </td>
      );
      rrRowWiseData.push(
        <td key={"industryGroupCtp" + index + this.props.rrData.key}>
          {row.industryGroupCtp}
        </td>
      );
      if (
        !(
          this.props.rrParams.mode === "Annual Report Environment" &&
          this.props.userData.userRole !== 1 &&
          this.props.rrParams.members.length !== 1
        )
      ) {
        rrRowWiseData.push(
          <td key={"counterpartId" + index + this.props.rrData.key}>
            {row.counterpartId}
          </td>
        );
        rrRowWiseData.push(
          <td key={"contractId" + index + this.props.rrData.key}>
            {row.contractId}
          </td>
        );
        rrRowWiseData.push(
          <td key={"defaultId" + index + this.props.rrData.key}>
            {row.defaultId}
          </td>
        );
        rrRowWiseData.push(
          <td
            key={"defaultDate" + index + this.props.rrData.key}
            style={{ whiteSpace: "nowrap" }}
          >
            {moment(row.defaultDate).format("DD-MMM-YYYY")}
          </td>
        );
      }

      rrRowWiseData.push(
        <td key={"denominator" + index + this.props.rrData.key}>
          {this.to2DecimalPlaces(
            Number(row.denominator.toFixed(2)).toLocaleString()
          )}
        </td>
      );
      rrRowWiseData.push(
        <td key={"numerator" + index + this.props.rrData.key}>
          {this.to2DecimalPlaces(
            Number(row.numerator.toFixed(2)).toLocaleString()
          )}
        </td>
      );
      rrRowWiseData.push(
        <td key={"recoveryRateMax100" + index + this.props.rrData.key}>
          {/* {this.toFixed(row.recoveryRateMax100)}% */}
          {(100 * row.recoveryRateMax100).toFixed(2)}%
        </td>
      );
      rrRowData.push(
        <tr key={index + this.props.rrData.key}>{rrRowWiseData}</tr>
      );
    });
    return rrRowData;
  }

  getRRParamsHeaders() {
    let tableHeaders = [];
    RR_PARAMS_HEADERS.forEach((key, index) => {
      tableHeaders.push(<th key={key}>{key}</th>);
    });
    return tableHeaders;
  }

  getRRParamsRows() {
    let rrParamsRowData = [];
    let rrRowWiseData = [];
    rrRowWiseData.push(<td key="nbLine">{this.props.rrData.nbLine}</td>);
    rrRowWiseData.push(
      <td key="mean">{this.toFixed(this.props.rrData.mean)}%</td>
    );
    rrRowWiseData.push(
      <td key="stdDeviation">
        {this.to2DecimalPlaces(this.props.rrData.stdDeviation)}
      </td>
    );
    rrParamsRowData.push(<tr key="rrParams">{rrRowWiseData}</tr>);
    return rrParamsRowData;
  }

  render() {
    console.log(this.props);
    if (this.props.rrData.nbLine > 0) {
      return (
        <div className="result-for-table rr-table">
          {this.props.userData ? (
            this.props.userData.userRole === 1 ||
            this.props.rrData.isDisplay ? (
              <div>
                <Table bordered="true" responsive>
                  <thead>
                    <tr>{this.getRRHeaders()}</tr>
                  </thead>
                  <tbody>{this.getRRRows()}</tbody>
                </Table>

                <Table bordered="true" responsive className="mt-4">
                  <thead>
                    <tr>{this.getRRParamsHeaders()}</tr>
                  </thead>
                  <tbody>{this.getRRParamsRows()}</tbody>
                </Table>
              </div>
            ) : (
              <div className="result-for-table">{RR_DISPLAY_RESULT}</div>
            )
          ) : (
            this.props.callUserDetails()
          )}
        </div>
      );
    } else {
      return <div className="result-for-table">{NO_DISPLAY_RESULT}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  console.log("Compute Options Props received", state);
  return {
    userData: state.auth.userData,
  };
};

export default connect(
  mapStateToProps,
  { callUserDetails }
)(RRResultTable);
