import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import {
  ALL,
  NONE,
  PARAM_MEMBERS,
  PARAM_CATEGORY,
  PARAM_CPRT_CROSS_DEFAULT_CLAUSE,
  PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE,
  PARAM_CONTAGION,
  PARAM_CURRENCY_TYPE,
  PARAM_LOAN_TYPE,
  PARAM_RESOLUTION_TYPE,
  PARAM_ORGINAL_AMT_LOAN,
  PARAM_REGION,
  PARAM_COUNTRY,
  PARAM_SECTOR,
  PARAM_INDUSTRY,
  PARAM_SUB_INDUSTRY,
  ONLY_ME,
  EXCEPT_ME,
  PARAM_SOV_DEFINITION,
  PARAM_SOV_FLAG,
  SOV_DEFAULT_RESTRICT,
  SOV_DEFAULT_NOT_RESTRICT,
  SOV_FLAG_ALL,
  SOV_FLAG_YES,
  SOV_FLAG_NO,
  PARAM_SUB_SUB_INDUSTRY,
  PARAM_SECTOR_CLASSIFICATION
} from "../commonUtility/string-const";
import MultiSelectCheckbox from "../commonUtility/MultiSelectCheckbox";

export const Category = (props) => {
  if (props.catList !== undefined) {
    let cat = Object.keys(props.catList).map((key, index) => {
      return (
        <div className="col-md-12 ">
          <div className="form-group ">
            <label>
              <input
                type="checkbox"
                name={key}
                onChange={(e) => props.handleClick(PARAM_CATEGORY, key, e)}
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
            </label>{" "}
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row category-margin">{cat}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_CATEGORY, ALL)}
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_CATEGORY, NONE)}
            >
              None
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const CrossDefaultClause = (props) => {
  if (props.crossDefaultList !== undefined) {
    let crossList = Object.keys(props.crossDefaultList).map((key, index) => {
      return (
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name={key}
                onChange={(e) =>
                  props.handleClick(
                    props.type === "counterpart"
                      ? PARAM_CPRT_CROSS_DEFAULT_CLAUSE
                      : PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE,
                    key,
                    e
                  )
                }
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key}{" "}
            </label>
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row category-margin">{crossList}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) =>
                props.handleClickAllNone(
                  props.type === "counterpart"
                    ? PARAM_CPRT_CROSS_DEFAULT_CLAUSE
                    : PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE,
                  ALL
                )
              }
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) =>
                props.handleClickAllNone(
                  props.type === "counterpart"
                    ? PARAM_CPRT_CROSS_DEFAULT_CLAUSE
                    : PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE,
                  NONE
                )
              }
            >
              None
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const Region = (props) => {
  if (
    props.region !== undefined &&
    props.region.length >
      0
  ) {
    let region = props.region;
    let country = props.country;
    
    region.sort(function (l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      
      return 0;
    });

    country.sort(function (l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      
      return 0;
    });

    let regionListSelected = props.regionListSelected;
    let countryListSelected = props.countryListSelected;
    let countryRegionMap = {};
   
    Object.keys(regionListSelected).forEach((c) => {
      if (regionListSelected[c]["value"] !== "*") {
        
        let countryList = [];
        countryListSelected.forEach((cc) => {
          if (cc["region"] === regionListSelected[c]["label"]) {
            countryList.push(cc["value"]);
          }
        });
        countryList.sort();
        countryRegionMap[regionListSelected[c]["label"]] = countryList;
      }
    });
    let sortedRegionList = Object.keys(countryRegionMap).sort();
    var regionCountryTblRow = [];
    Object.keys(sortedRegionList).forEach((m) => {
      let cList = [];
      let cListFull = [];
      Object.keys(countryRegionMap[sortedRegionList[m]]).forEach((c) => {
        cList.push(
          <li className="col-auto">
            {countryRegionMap[sortedRegionList[m]][c]}
          </li>
        );
      });
      if (cList.length === 0) {
        cList.push(<li className="col-auto">No Restrictions</li>);
      }
      cListFull.push(<ul className="country-list row">{cList}</ul>);
      regionCountryTblRow.push(
        <tr>
          <td>{sortedRegionList[m]}</td>
          <td>{cListFull}</td>
        </tr>
      );
    });
    return (
      <div>
        <div className="row">
          <div className="col-auto pr-0">
            <div className="dropdown w-100">
              <MultiSelectCheckbox
                name={"Region"}
                list={region}
                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
                handleChange={(e, ref) =>
                  props.handleClick(props.handleRegion, this, e, ref)
                }
                optionSelected={regionListSelected}
                shouldFocus={props.lastParameterChange === props.handleRegion}
              />
            </div>
          </div>
          <div className="col-auto mr-auto">
            <div className="dropdown w-100">
              <MultiSelectCheckbox
                name={"Country"}
                list={country}
                disabled={country == null || country.length === 0}
                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
                handleChange={(e, ref) =>
                  props.handleClick(props.handleCountry, this, e, ref)
                }
                optionSelected={countryListSelected}
                shouldFocus={props.lastParameterChange === props.handleCountry}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="common-table">
              <div className="table-responsive mt-2">
                <table className="table  table-bordered">
                  <thead hidden={regionCountryTblRow.length === 0}>
                    <tr>
                      <th className="width-10">Region</th>
                      <th>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regionCountryTblRow.length > 0 ? (
                      regionCountryTblRow
                    ) : (
                      <tr>No Restrictions</tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
  return null;
};

//export const SectorIndustry = (props) => {
//  if (
//    props.sector !== undefined &&
//    props.sector.length >
//      0
//  ) {
//    let sector = props.sector;
//    let industry = props.industry;
//    let subIndustry = props.subIndustry;
//
//
//    sector.sort(function (l1, l2) {
//      if (l1.label < l2.label) return -1;
//      if (l1.label > l2.label) return 1;
//
//      return 0;
//    });
//
//    industry.sort(function (l1, l2) {
//      if (l1.label < l2.label) return -1;
//      if (l1.label > l2.label) return 1;
//
//      return 0;
//    });
//
//    subIndustry.sort(function (l1, l2) {
//      if (l1.label < l2.label) return -1;
//      if (l1.label > l2.label) return 1;
//
//      return 0;
//    });
//
//    let sectorListSelected = props.sectorListSelected;
//    let industryListSelected = props.industryListSelected;
//    let subIndustryListSelected = props.subIndustryListSelected;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//    subIndustryListSelected.sort(function (l1, l2) {
//      if (l1.label < l2.label) return -1;
//      if (l1.label > l2.label) return 1;
//
//      return 0;
//    });
//
//    let sectorIndustryMap = {};
//    Object.keys(sectorListSelected).forEach((s) => {
//      let industryArr = [];
//
//      if (sectorListSelected[s]["value"] !== "*") {
//        let industryLst = [];
//        industryListSelected.forEach((e) => {
//          if (sectorListSelected[s]["label"] === e["sector"]) {
//            industryLst.push(e["label"]);
//          }
//        });
//        industryLst.sort();
//
//        Object.keys(industryLst).forEach((i) => {
//          let subIndustryLst = [];
//          let industryMap = {};
//          subIndustryListSelected.forEach((e) => {
//            if (industryLst[i] === e["industry"]) {
//              subIndustryLst.push(e["label"]);
//            }
//          });
//          subIndustryLst.sort();
//          industryMap[industryLst[i]] = subIndustryLst;
//          industryArr.push(industryMap);
//        });
//        sectorIndustryMap[sectorListSelected[s]["label"]] = industryArr;
//      }
//    });
//    let sortedSectorIndusty = Object.keys(sectorIndustryMap).sort();
//    let sectorIndustryAllRows = [];
//    Object.keys(sortedSectorIndusty).forEach((s) => {
//      let sectorIndustryList = [];
//      let sectorIndustryListFull = [];
//      let sectorAdded = false;
//      Object.keys(sectorIndustryMap[sortedSectorIndusty[s]]).forEach((i) => {
//        let industryList = [];
//        let innerSubIndustryListFull = [];
//        Object.keys(sectorIndustryMap[sortedSectorIndusty[s]][i]).forEach(
//          (si) => {
//            industryList = [];
//            sectorIndustryList = [];
//            if (!sectorAdded) {
//              sectorIndustryList.push(
//                <td rowSpan={sectorIndustryMap[sortedSectorIndusty[s]].length}>
//                  {sortedSectorIndusty[s]}
//                </td>
//              );
//            }
//            industryList.push(<td>{si}</td>);
//            sectorAdded = true;
//            let innerSubIndustryList = [];
//            innerSubIndustryListFull = [];
//            Object.keys(
//              sectorIndustryMap[sortedSectorIndusty[s]][i][si]
//            ).forEach((k) => {
//              innerSubIndustryList.push(
//                <div>{sectorIndustryMap[sortedSectorIndusty[s]][i][si][k]}</div>
//              );
//            });
//            if (innerSubIndustryList.length > 0) {
//              innerSubIndustryListFull.push(<td>{innerSubIndustryList}</td>);
//            } else {
//              innerSubIndustryListFull.push(<td>{"No Restrictions"}</td>);
//            }
//          }
//        );
//        sectorIndustryListFull.push(
//          <tr>
//            {sectorIndustryList}
//            {industryList}
//            {innerSubIndustryListFull}
//          </tr>
//        );
//      });
//      if (sectorIndustryListFull.length > 0) {
//        sectorIndustryAllRows.push(sectorIndustryListFull);
//      } else {
//        sectorIndustryAllRows.push(
//          <tr>
//            <td>{sortedSectorIndusty[s]}</td>
//            <td>{"No Restrictions"}</td>
//            <td>{"No Restrictions"}</td>
//          </tr>
//        );
//      }
//    });
//    return (
//      <div>
//        <div className="row">
//          <div className="col-auto pr-0">
//            <div className="dropdown w-100">
//              <MultiSelectCheckbox
//                name={"Sector"}
//                list={sector}
//                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
//                handleChange={(e, ref) =>
//                  props.handleClick(PARAM_SECTOR, this, e, ref)
//                }
//                optionSelected={sectorListSelected}
//                shouldFocus={props.lastParameterChange === PARAM_SECTOR}
//              />
//            </div>
//          </div>
//          <div className="col-auto pr-0">
//            <div className="dropdown w-100">
//              <MultiSelectCheckbox
//                name={"Industry Group"}
//                list={industry}
//                disabled={industry == null || industry.length === 0}
//                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
//                handleChange={(e, ref) =>
//                  props.handleClick(PARAM_INDUSTRY, this, e, ref)
//                }
//                optionSelected={industryListSelected}
//                shouldFocus={props.lastParameterChange === PARAM_INDUSTRY}
//              />
//            </div>
//          </div>
//
//          <div className="col-auto mr-auto">
//            <div className="dropdown w-100">
//              <MultiSelectCheckbox
//                name={"Sub-Industry"}
//                list={subIndustry}
//                disabled={
//                  subIndustry == null ||
//                  subIndustry.length === 0 ||
//                  industryListSelected == null ||
//                  industryListSelected.length === 0
//                }
//                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
//                handleChange={(e, ref) =>
//                  props.handleClick(PARAM_SUB_INDUSTRY, this, e, ref)
//                }
//                optionSelected={subIndustryListSelected}
//                shouldFocus={props.lastParameterChange === PARAM_SUB_INDUSTRY}
//              />
//            </div>
//          </div>
//        </div>
//        <div className="row">
//          <div className="col-12">
//            <div className="common-table">
//              <div className="table-responsive mt-2">
//                <table className="table table-bordered">
//                  <thead hidden={sectorIndustryAllRows.length === 0}>
//                    <tr>
//                      <th>Sector</th>
//                      <th>Industry Group</th>
//                      <th>Sub-Industry</th>
//                    </tr>
//                  </thead>
//                  <tbody>
//                    {sectorIndustryAllRows.length > 0 ? (
//                      sectorIndustryAllRows
//                    ) : (
//                      <tr>No Restrictions</tr>
//                    )}
//                  </tbody>
//                </table>
//              </div>
//            </div>
//          </div>
//        </div>
//
//      </div>
//    );
//  }
//  return null;
//};


export const SectorIndustry = (props) => {
  if (
    props.sector !== undefined &&
    props.sector.length >
      0 /* && props.industry!=undefined && props.industry.length>0*/
  ) {
    let sector = props.sector;
    let industry = props.industry;
    let subIndustry = props.subIndustry;
    let subSubIndustry = props.subSubIndustry;

    //Sort
    sector.sort(function(l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      // a must be equal to b
      return 0;
    });

    industry.sort(function(l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      // a must be equal to b
      return 0;
    });

    subIndustry.sort(function(l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      // a must be equal to b
      return 0;
    });

    subSubIndustry.sort(function(l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      // a must be equal to b
      return 0;
    });

    let sectorListSelected = props.sectorListSelected;
    let industryListSelected = props.industryListSelected;
    let subIndustryListSelected = props.subIndustryListSelected;
    let subSubIndustryListSelected = props.subSubIndustryListSelected;
    // sectorListSelected.sort(function(l1,l2){
    //     if (l1.label<l2.label)
    //     return -1;
    //  if (l1.label>l2.label)
    //     return 1;
    //  // a must be equal to b
    //  return 0;
    // });

    // industryListSelected.sort(function(l1,l2){
    //     if (l1.label<l2.label)
    //     return -1;
    //  if (l1.label>l2.label)
    //     return 1;
    //  // a must be equal to b
    //  return 0;
    // });

    subIndustryListSelected.sort(function(l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      // a must be equal to b
      return 0;
    });

    subSubIndustryListSelected.sort(function(l1, l2) {
      if (l1.label < l2.label) return -1;
      if (l1.label > l2.label) return 1;
      // a must be equal to b
      return 0;
    });

    let sectorIndustryMap = {};
    /*Object.keys(subIndustryListSelected).map((i)=>{
            if(subIndustryListSelected[i]["value"]!="*"){
                let scM = sectorIndustryMap[subIndustryListSelected[i]["sector"]];
                if(scM==undefined){
                    let innerIndustry = [];
                    let innserSubIndustry = [];
                    innserSubIndustry.push(subIndustryListSelected[i]["label"]);
                    innerIndustry.push({[subIndustryListSelected[i]["industry"]]:innserSubIndustry});
                    sectorIndustryMap[subIndustryListSelected[i]["sector"]] = innerIndustry;

                }
                else{
                    let industryFound = false;
                    let foundAtIndex = -1;
                    for(var j=0;j<scM.length;j++){
                        for(var ctry in scM[j]){
                            if(ctry==subIndustryListSelected[i]["industry"]){
                                industryFound = true;
                                foundAtIndex = j;
                                break;
                            }
                        }
                        if(industryFound){
                            break;
                        }
                    }
                    if(industryFound){
                        let subIndustryMap = scM[foundAtIndex][subIndustryListSelected[i]["industry"]];
                        if(subIndustryMap.indexOf(subIndustryListSelected[i]["label"])==-1){
                            subIndustryMap.push(subIndustryListSelected[i]["label"]);
                            subIndustryMap.sort();
                        }
                    }
                    else{
                        let innerSubIndustry = [];
                        let innerIndustry = [];
                        innerSubIndustry.push(subIndustryListSelected[i]["label"]);
                        let tempObj = {};
                        tempObj[subIndustryListSelected[i]["industry"]] = innerSubIndustry;
                        innerIndustry = tempObj;
                        sectorIndustryMap[subIndustryListSelected[i]["sector"]].push(innerIndustry);
                        sectorIndustryMap[subIndustryListSelected[i]["sector"]].sort(function(l1,l2){
                            let k1 = "";
                            let k2 = "";
                            for(var k in l1){
                                k1=k;
                                break;
                            }
                            for(var k in l2){
                                k2=k;
                                break;
                            }
                            if (k1<k2)
                            return -1;
                         if (l1>l2)
                            return 1;
                         // a must be equal to b
                         return 0;
                        });
                    }
                }
            }
        });*/
    Object.keys(sectorListSelected).forEach((s) => {
      let industryArr = [];
      //Get all industry related to sector
      if (sectorListSelected[s]["value"] !== "*") {
        let industryLst = [];
        industryListSelected.forEach((e) => {
          if (sectorListSelected[s]["label"] === e["sector"]) {
            industryLst.push(e["label"]);
          }
        });
        industryLst.sort();
        //Iterate over industry to get its specific subindustry
        Object.keys(industryLst).forEach((i) => {
          let subIndustryMap = {};
          let industryMap = {};
          subIndustryListSelected.forEach((e) => {
            if (industryLst[i] === e["industry"]) {
              //Iterate over subIndustry to get subsub industries
              let subSubIndustryLst = [];
              Object.keys(subSubIndustryListSelected).forEach((ss)=>{
                  if(subSubIndustryListSelected[ss]["subIndustry"]==e["label"]){
                    subSubIndustryLst.push(subSubIndustryListSelected[ss]["label"]);
                  }
              });

              subIndustryMap[e["label"]] = subSubIndustryLst
            }
          });
          //subIndustryMap.sort();
          industryMap[industryLst[i]] = subIndustryMap;
          industryArr.push(industryMap);
          //sectorIndustryMap[sectorListSelected[s]["label"]] = industryArr;
        });
        sectorIndustryMap[sectorListSelected[s]["label"]] = industryArr;
      }
    });
    let sortedSectorIndusty = Object.keys(sectorIndustryMap).sort();
    let sectorIndustryAllRows = [];
    Object.keys(sortedSectorIndusty).forEach((s) => {
      let sectorIndustryList = [];
      let sectorIndustryListFull = [];
      let sectorAdded = false;
      Object.keys(sectorIndustryMap[sortedSectorIndusty[s]]).forEach((i) => {
        let industryList = [];
        let innerSubIndustryListFull = [];
        let innerSubSubIndustryListFull = [];
        Object.keys(sectorIndustryMap[sortedSectorIndusty[s]][i]).forEach(
          (si) => {
            industryList = [];
            sectorIndustryList = [];
            if (!sectorAdded) {
              sectorIndustryList.push(
                <td rowSpan={sectorIndustryMap[sortedSectorIndusty[s]].length}>
                  {sortedSectorIndusty[s]}
                </td>

              );
            }
            industryList.push(<td>{si}</td>);
            sectorAdded = true;
            let innerSubIndustryList = [];
            innerSubIndustryListFull = [];
            let innerSubSubIndustryList = [];
            let containsWaterUtilities = false;
            let renewIndex = 0;
            Object.keys(
              sectorIndustryMap[sortedSectorIndusty[s]][i][si]
            ).forEach((k, index) => {
              /*if(k==="Water Utilities"){
                containsWaterUtilities = true;
              }*/
              //innerSubSubIndustryListFull = [];
              if(k=="Independent Power and Renewable Electricity Producers"){
                renewIndex = index;
              }
              innerSubIndustryList.push(
                <tr><td>{k}</td></tr>
              );
              //innerSubSubIndustryList = [];
              Object.keys(sectorIndustryMap[sortedSectorIndusty[s]][i][si][k]).forEach((ssi, index)=>{
                //console.log(`sectorIndustryMap[sortedSectorIndusty[s]][i][si][k])`)
                innerSubSubIndustryList.push(<tr rowSpan="7"><td>{sectorIndustryMap[sortedSectorIndusty[s]][i][si][k][ssi]}</td></tr>)

                if(index>0){

                  innerSubIndustryList.push(
                    <tr rowSpan="7">
                      <td>&nbsp;</td>
                      </tr>
                                    );

                }
              });
            });
            console.log(renewIndex);
            if(innerSubSubIndustryList.length>0){

              let trr = [];
             for(i=0; i<renewIndex; i++){
              trr.push(<tr rowSpan="4"><td>&nbsp;</td></tr>)
             }

            //  if(!containsWaterUtilities){
            //   trr.push(<tr rowSpan="4"><td>&nbsp;</td></tr>)
            //  }




              innerSubSubIndustryListFull.push(<><table className="childtable">
                {trr}
               {innerSubSubIndustryList}
               {/* <tr><td>sfdsnfsffbss</td></tr> */}
               </table></>)

            }else{
              innerSubSubIndustryListFull.push(<td>{innerSubSubIndustryList}</td>)

            }

            if (innerSubIndustryList.length > 0) {
            innerSubIndustryListFull.push(<td><table className="childtable">{innerSubIndustryList}</table></td>);
            innerSubIndustryListFull.push(<td>

                {innerSubSubIndustryListFull}

            </td>);
            } else {
              innerSubIndustryListFull.push(<td>{"No Restrictions"}</td>);
            }


          }


        );
        sectorIndustryListFull.push(
          <tr>
            {sectorIndustryList}
            {industryList}
            {innerSubIndustryListFull}
            {/* {innerSubSubIndustryListFull} */}
          </tr>
        );
      });
      if (sectorIndustryListFull.length > 0) {
        sectorIndustryAllRows.push(sectorIndustryListFull);
      } else {
        sectorIndustryAllRows.push(
          <tr>
            <td>{sortedSectorIndusty[s]}</td>
            <td>{"No Restrictions"}</td>
            <td>{"No Restrictions"}</td>
            <td>{"No Restrictions"}</td>
            {/* <td>{"No Restrictions"}</td> */}
          </tr>
        );
      }
    });
    return (
      <div>
        <div className="row sector-indust-css">
          <div className="col-auto pr-0">
            <div className="dropdown w-100">
              <MultiSelectCheckbox
                name={"Sector"}
                list={sector}
                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
                handleChange={(e, ref) =>
                  props.handleClick(PARAM_SECTOR, this, e, ref)
                }
                optionSelected={sectorListSelected}
                shouldFocus={props.lastParameterChange === PARAM_SECTOR}
                width={150}
              />
            </div>
          </div>
          <div className="col-auto pr-0 pl-2">
            <div className="dropdown w-100">
              <MultiSelectCheckbox
                name={"Industry Group"}
                list={industry}
                disabled={industry == null || industry.length === 0}
                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
                handleChange={(e, ref) =>
                  props.handleClick(PARAM_INDUSTRY, this, e, ref)
                }
                optionSelected={industryListSelected}
                shouldFocus={props.lastParameterChange === PARAM_INDUSTRY}
              />
            </div>
          </div>

          <div className="col-auto pr-0 pl-2">
            <div className="dropdown w-100">
              <MultiSelectCheckbox
                name={"Sub-Industry"}
                list={subIndustry}
                disabled={
                  subIndustry == null ||
                  subIndustry.length === 0 ||
                  industryListSelected == null ||
                  industryListSelected.length === 0
                }
                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
                handleChange={(e, ref) =>
                  props.handleClick(PARAM_SUB_INDUSTRY, this, e, ref)
                }
                optionSelected={subIndustryListSelected}
                shouldFocus={props.lastParameterChange === PARAM_SUB_INDUSTRY}
              />
            </div>
          </div>

          <div className="col-auto pr-0 pl-2">
            <div className="dropdown w-100">
              <MultiSelectCheckbox
                name={"Sub-Sub-Industry"}
                list={subSubIndustry}
                disabled={
                  subSubIndustry == null ||
                  subSubIndustry.length === 0 ||
                  subIndustryListSelected == null ||
                  subIndustryListSelected.length === 0
                }
                updateMenuOpenRef={(ref) => props.updateMenuOpenRef(ref)}
                handleChange={(e, ref) =>
                  props.handleClick(PARAM_SUB_SUB_INDUSTRY, this, e, ref)
                }
                optionSelected={subSubIndustryListSelected}
                shouldFocus={props.lastParameterChange === PARAM_SUB_SUB_INDUSTRY}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="common-table">
              <div className="table-responsive mt-2">
                <table className="table table-bordered sector-industry-group">
                  <thead hidden={sectorIndustryAllRows.length === 0}>
                    <tr>
                      <th>Sector</th>
                      <th>Industry Group</th>
                      <th>Sub-Industry</th>
                      <th>Sub-Sub-Industry</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
          <td rowspan="3">Industrials</td>
          <td>Capital Googs</td>
          <td>
              <ul>
                  <li>Aerospace and defende</li>
                  <li>Harry potter</li>
                  <li>Operation Vijay</li>
              </ul>
          </td>
              </tr>
              <tr>
              <td>Capital Googs1</td>
          <td>
              <ul>
                  <li>Aerospace and defende1</li>
                  <li>Harry potter1</li>
                  <li>Operation Vijay1</li>
              </ul>
          </td>
              </tr> */}
                    {sectorIndustryAllRows.length > 0 ? (
                      sectorIndustryAllRows
                    ) : (
                      <tr>No Restrictions</tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
                    {region}
                </div> */}
        {/* <div className="row">
                    <div className="col-12 mt-2">

                        <button type="button" id="all" className="btn btn-default site">All</button>
                        <button type="button" id="none" className="btn btn-default site">None</button>
                    </div>

                </div> */}
      </div>
    );
  }
  return null;
};

export const Members = (props) => {
  if (props.members !== undefined) {
    let membersList = Object.keys(props.members).map((key, index) => {
      return (
        <div className="col-md-2">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                disabled={props.isInstAdmin}
                name={key}
                onChange={(e) => props.handleClick(PARAM_MEMBERS, key, e)}
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key}
            </label>
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row">{membersList}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_MEMBERS, ALL)}
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_MEMBERS, NONE)}
            >
              None
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_MEMBERS, ONLY_ME)}
              hidden={!props.isInstAdmin}
            >
              Only Me
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) =>
                props.handleClickAllNone(PARAM_MEMBERS, EXCEPT_ME)
              }
              hidden={!props.isInstAdmin}
            >
              Except Me
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const Contagion = (props) => {
  if (props.contagion !== undefined) {
    let contagionList = (
      <div className="col-md-12">
        <div className="form-group">
          <FormGroup check inline>
            <Label check className="mb-2">
              <Input
                type="radio"
                name="contagion"
                onChange={(e) => {
                  props.handleClick(PARAM_CONTAGION, null, true)
                  props.handleClickAllNone(PARAM_RESOLUTION_TYPE, ALL)
                }}  
                /* if contagion is included, then no filtering on resolution type allowed. 
                Thus, setting all values for resolution type as selected */
                checked={props.defaultValue}
                value={true}
              />
              Include contagion
            </Label>
          </FormGroup>
        </div>
        <div className="form-group">
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="contagion"
                onChange={(e) =>
                  props.handleClick(PARAM_CONTAGION, null, false)
                }
                checked={!props.defaultValue}
                value={false}
              />
              Exclude contagion
            </Label>
          </FormGroup>{" "}
        </div>
      </div>
    );
    return (
      <div>
        <div className="row category-margin">{contagionList}</div>
      </div>
    );
  }
  return null;
};

export const CurrencyType = (props) => {
  if (props.currencyType !== undefined) {
    let currencyTypeList = Object.keys(props.currencyType).map((key, index) => {
      return (
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name={key}
                onChange={(e) => props.handleClick(PARAM_CURRENCY_TYPE, key, e)}
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key}
            </label>{" "}
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row category-margin">{currencyTypeList}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) =>
                props.handleClickAllNone(PARAM_CURRENCY_TYPE, ALL)
              }
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) =>
                props.handleClickAllNone(PARAM_CURRENCY_TYPE, NONE)
              }
            >
              None
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const LoanType = (props) => {
  if (props.loanType !== undefined) {
    let loanTypeList = Object.keys(props.loanType).map((key, index) => {
      return (
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name={key}
                onChange={(e) => props.handleClick(PARAM_LOAN_TYPE, key, e)}
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
            </label>{" "}
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row mt-2">{loanTypeList}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_LOAN_TYPE, ALL)}
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_LOAN_TYPE, NONE)}
            >
              None
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const ResolutionType = (props) => {
  if (props.resolutionType !== undefined) {
    let resolutionTypeList = Object.keys(props.resolutionType).map((key, index) => {
      return (
        <div className="col-md-6">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name={key}
                onChange={(e) => props.handleClick(PARAM_RESOLUTION_TYPE, key, e)}
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key.charAt(0).toUpperCase() + key.slice(1).toUpperCase()}
            </label>{" "}
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row mt-2">{resolutionTypeList}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_RESOLUTION_TYPE, ALL)}
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_RESOLUTION_TYPE, NONE)}
            >
              None
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const SectorClassification = (props) => {
  if (props.sectorClassification !== undefined) {
    let sectorClassificationList = Object.keys(props.sectorClassification).map((key, index) => {
      return (
        <div className="col-md-12">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name={key}
                onChange={(e) => props.handleClick(PARAM_SECTOR_CLASSIFICATION, key, e)}
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key.charAt(0).toUpperCase() + key.slice(1).toUpperCase()}
            </label>{" "}
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row mt-2">{sectorClassificationList}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_SECTOR_CLASSIFICATION, ALL)}
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) => props.handleClickAllNone(PARAM_SECTOR_CLASSIFICATION, NONE)}
            >
              None
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const OriginalAmountOfLoan = (props) => {
  if (props.loanAmount !== undefined) {
    let loanAmountList = Object.keys(props.loanAmount).map((key, index) => {
      return (
        <div className="col-md-6">
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name={key}
                onChange={(e) =>
                  props.handleClick(PARAM_ORGINAL_AMT_LOAN, key, e)
                }
                checked={props.defaultValue[key]}
                value={props.defaultValue[key]}
              />
              {key}
            </label>{" "}
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="row mt-2">{loanAmountList}</div>
        <div className="row">
          <div className="col-12 mt-2">
            <button
              type="button"
              id="all"
              className="btn btn-default site"
              onClick={(e) =>
                props.handleClickAllNone(PARAM_ORGINAL_AMT_LOAN, ALL)
              }
            >
              All
            </button>
            <button
              type="button"
              id="none"
              className="btn btn-default site"
              onClick={(e) =>
                props.handleClickAllNone(PARAM_ORGINAL_AMT_LOAN, NONE)
              }
            >
              None
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const SovereignDefaultDefinition = (props) => {
  let sovDefaultDefinitionList = (
    <div className="col-md-12">
      <div className="form-group">
        <FormGroup check inline>
          <Label check className="mb-2">
            <Input
              type="radio"
              name="sovDefaultDefinition"
              onChange={(e) =>
                props.handleClick(PARAM_SOV_DEFINITION, null, false)
              }
              checked={!props.value}
              value={false}
            />
            {SOV_DEFAULT_NOT_RESTRICT}
          </Label>
        </FormGroup>
      </div>
      <div className="form-group">
        <FormGroup check inline>
          <Label check>
            <Input
              type="radio"
              name="sovDefaultDefinition"
              onChange={(e) =>
                props.handleClick(PARAM_SOV_DEFINITION, null, true)
              }
              checked={props.value}
              value={true}
            />
            {SOV_DEFAULT_RESTRICT}
          </Label>
        </FormGroup>{" "}
      </div>
    </div>
  );
  return (
    <div>
      <div className="row category-margin">{sovDefaultDefinitionList}</div>
    </div>
  );
};

export const SovereignFlag = (props) => {
  let sovFlagList = (
    <div className="col-md-12">
      <div className="form-group">
        <FormGroup check inline>
          <Label check>
            <Input
              type="radio"
              name="sovFlag"
              onChange={(e) => props.handleClick(PARAM_SOV_FLAG, null, 1)}
              checked={props.value === 1}
              value={1}
            />
            {SOV_FLAG_ALL}
          </Label>
        </FormGroup>
      </div>
      <div className="form-group">
        <FormGroup check inline>
          <Label check>
            <Input
              type="radio"
              name="sovFlag"
              onChange={(e) => props.handleClick(PARAM_SOV_FLAG, null, 2)}
              checked={props.value === 2}
              value={2}
            />
            {SOV_FLAG_YES}
          </Label>
        </FormGroup>
      </div>
      <div className="form-group">
        <FormGroup check inline>
          <Label check>
            <Input
              type="radio"
              name="sovFlag"
              onChange={(e) => props.handleClick(PARAM_SOV_FLAG, null, 3)}
              checked={props.value === 3}
              value={3}
            />
            {SOV_FLAG_NO}
          </Label>
        </FormGroup>
      </div>
    </div>
  );
  return (
    <div>
      <div className="row category-margin">{sovFlagList}</div>
    </div>
  );
};
