import { put } from "redux-saga/effects";
import * as actions from "../actions/auth";
import instance from "../../instance";
import { authHeader } from "../../components/commonUtility/auth-header";
import * as actionTypes from "../actions/actionTypes";
var moment = require("moment-timezone");
export function* logoutSaga(action) {
  console.log("logoutSaga");
  try {
    const url = "/api/logout";
    //const b_token = sessionStorage.getItem("gems_bearer_token");
    const b_token = action.b_token;
    console.log("logoutSaga ", action);
    const reponse = yield instance.post(url, b_token, {
      headers: {
        Authorization: "Bearer " + b_token,
        "Content-Type": "text/plain",
      },
    });
    if (reponse.status == "200") {
      yield sessionStorage.clear();
      yield put(actions.logoutSucceed());
    }
  } catch (err) {
    yield sessionStorage.clear();
    console.log("An error occured during logout");
    yield put(actions.logoutSucceed());
  }
}

//User first level Authentication saga
//This will be use to make api call to service with userEmail and password
//In response it will recieve a session token (12 digit alphanumeric value)
//Session Expiry time
//In case of error in response it will set action type as authFail in action with error
export function* authUserSaga(action) {
  console.log("Getting into Auth Saga");
  yield put(actions.authStart());
  const url = "/api/authenticate";
  const payload = {
    username: action.email,
    password: action.password,
  };
  try {
    const response = yield instance.post(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    console.log("token from auth ", response.data);
    if (response.data.error && response.data.error == "true") {
      yield put(actions.authFail(response.data.message, false));
    } else {
      //Setting temprory session in Local Storage for OTP Validation
      //This temprory session is a alphanumeric value to track the user OTP in validate request
      yield sessionStorage.setItem("gems_session", response.data.session);
      //Setting up OTP expiry time
      // yield sessionStorage.setItem(
      //   "tokenGenerationDate",
      //   response.data.tokenGenerationDate
      // );
      //Setting user loggedIn email Id in local Storage
      yield sessionStorage.setItem("gems_userId", response.data.userId);
      //yielding for first level Auth Done for state update
      yield put(
        actions.firstLevelAuthDone(
          action.email,
          response.data.token,
          response.data.message,
          true
        )
      );
    }
  } catch (err) {
    console.log("Error ", err);
    //yielding in case of auth fail ie user is unauthorized
    yield put(actions.authFail("An error occured. Please try again", false));
  }
}

export function* authResendOTP(action) {
  console.log("Getting into Auth resend otp");
  yield put(actions.authStart());
  const url = "/api/authenticate";
  const payload = {
    username: action.email,
    password: action.password,
  };
  // let session = sessionStorage.getItem("session");
  // let timestampOfToken = sessionStorage.getItem("tokenGenerationDate");
  try {
    const response = yield instance.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
        session: action.session,
        // tokenGenerationDate: action.timestampOfToken
      },
    });
    // sessionStorage.setItem(
    //   "tokenGenerationDate",
    //   response.data.tokenGenerationDate
    // );
    sessionStorage.setItem("gems_session", response.data.session);

    yield put(
      actions.firstLevelAuthDone(
        action.email,
        response.data.token,
        response.data.message,
        true
      )
    );
  } catch (err) {
    yield put(actions.authFail("Error occured while receiving OTP again"));
  }
}

export function* authCheckStateSaga(action) {
  const token = yield sessionStorage.getItem("token");
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationDate = yield new Date(
      sessionStorage.getItem("expirationDate")
    );

    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      const userId = yield sessionStorage.getItem("gems_userId");

      yield put(actions.authSuccess(token, userId));
      yield put(
        actions.checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    }
  }
}

//Saga for second level Authentication
//This function will make a API call using axios and validate the OTP entered by user
//IF OTP is verfied a bearer token is stored in local storage
//And temprory token will be removed from local storage
//In case of error response a error is set in AuthFail Action
export function* authCheckOTP(action) {
  yield put(actions.authStart());
  const url = "/api/validate";
  let headerConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      LoginVerifiedToken: sessionStorage.getItem("gems_session"),
      timeZone: moment.tz.guess(),
      // tokenGenerationDate: sessionStorage.getItem("tokenGenerationDate")
    },
  };
  const payload = {
    otp: action.otp,
    username: action.email,
  };
  try {
    const response = yield instance.post(url, payload, headerConfig);
    console.log("=================response auth after otp ", response.data);
    if (response.data.error && response.data.error == "true") {
      console.log(response.data.message);
      yield put(actions.authFail(response.data.message, true));
    } else {
      //Setting up bearer token in local storage
      yield sessionStorage.setItem("gems_bearer_token", response.data.token);
      //Removing temprory token from session
      yield sessionStorage.removeItem("gems_session");
      yield put(
        actions.authSuccess(action.otp, action.email, true, response.data)
      );
      instance.defaults.headers.common["Authorization"] =
        "Bearer " + sessionStorage.getItem("gems_bearer_token");
      var config = {
        headers: { ...authHeader() },
      };
      instance.get("/api/getRefData", config);

      yield put(actions.getUserNotificationsAndEvents());
      yield put(actions.setAuthRedirectPath("/home"));
    }
  } catch (err) {
    console.log("Error in Login ");
    var res =
      err.response && err.response.status == "401"
        ? "Unauthrorized"
        : "Error while login. Please try again.";
    yield put(actions.authFail(res, true));
  }
}

export function* authCheckUserSession(action) {
  const url = "/api/checkAuth";
  const b_token = sessionStorage.getItem("gems_bearer_token");
  let headerConfig = {
    headers: {
      "Content-Type": "text/plain",
      ...authHeader(),
      // tokenGenerationDate: sessionStorage.getItem("tokenGenerationDate")
    },
  };
  try {
    const response = yield instance.post(url, b_token, headerConfig);
    console.log("authCheckUserSession ", response);
    if (
      (response.data.error && response.data.error == "true") ||
      response.status == "401"
    ) {
      console.log(response.data.message);
      yield put({ type: actionTypes.AUTH_INITIATE_LOGOUT, b_token: b_token });
    } else {
      yield put(
        actions.authSuccess(null, response.data.email, true, response.data)
      );
    }
  } catch (err) {
    // yield put(actions.logout(b_token));
    yield put({ type: actionTypes.AUTH_INITIATE_LOGOUT, b_token: b_token });
  }
}
