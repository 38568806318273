import React from "react";
export const FormErrors = ({ formErrors }) => (
  <div className="formErrors">
    {formErrors.length > 0 ? (
      <div className="alert-warning">{formErrors}</div>
    ) : (
      ""
    )}
  </div>
);
