import React from "react";

import {
  Button,
  Form,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap";
import Checkbox from "./Checkbox";
import PropTypes from "prop-types";
import instance from "../../instance";
import { authHeader } from "./auth-header";
import { connect } from "react-redux";
import FullScreenLoaderComponent from "./FullScreenLoaderComponent";
import { ToastContainer } from "react-toastify";
import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  UNKNOWN_ERROR,
  TOAST_TYPE_INFO,
  TOAST_TYPE_ERROR,
  RUN_CHECK_LIST,
  UPLOAD_INSERT_TITLE,
  AUDIT_INSERT,
} from "./string-const";
import PopupModal from "./PopupModal";
import PopupModalInsert from "./PopupModalInsert";
import * as actions from "../../store/actions/index";
import { recordForAuditTrail } from "./method-utils";

const OPTIONS = RUN_CHECK_LIST;

const OPTIONS_DEFAULT = {
  "Check Header": false,
  "Check Mandatory Fields": false,
  "Check Data Consistency": false,
  "Check D Rating": false,
  "Check Data Properties": false,
  "Check Voluntary Fields": false,
  "Check Table Relationships": false,
  "Check Default After Maturity": false,
  "Check Dates": false,
  "Check Data Accuracy": false,
  "Check Defaults": false,
  "Check Missing E Line": false,
};

class Runcheck extends React.Component {
  constructor(props) {
    super(props);
    this.runCheckAndSummary = this.runCheckAndSummary.bind(this);
    this.insertData = this.insertData.bind(this);
    this.checkSelected = this.checkSelected.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.cancelToggle = this.cancelToggle.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.confirmInsert = this.confirmInsert.bind(this);
  }
  state = {
    isLoading: false,
    renderExceptions: false,
    isUnauthorized: false,
    canInsertData: this.props.saveAllowed,
    noCheckBoxSelected: true,
    checkboxes: OPTIONS_DEFAULT,
    selectedRunCheck: [],
    modal: false,
    uploadSuccessMessage: "",
    uploadSummary: null,
    displayInsertConfirm: false,
    modalTitle: "Insert Data Confirmation",
    modalBodyMessage:
      "You are about to insert data for <b>INST_NAME</b>. This will delete the existing data. Do you want to proceed?",
    displayInsertDsbldPopup: false,
    insertDsbldTitle: null,
    insertDsbldMsg: null,
    isDataFinal: false,
  };

  componentDidMount() {
    let runchkList = sessionStorage.getItem("runcheck_list");
    if (runchkList != null) {
      let selectedRunCheck = JSON.parse(runchkList);
      let checkboxes = { ...this.state.checkboxes };

      for (var i = 0; i < selectedRunCheck.length; i++) {
        checkboxes[OPTIONS[selectedRunCheck[i]]] = true;
      }
      this.setState({ checkboxes, selectedRunCheck });
      this.runCheckAndSummary(selectedRunCheck, true);
    }
  }

  checkSelected() {
    for (var key in this.state.checkboxes) {
      if (this.state.checkboxes[key] === true) {
        return false;
      }
    }
    return true;
  }

  selectAllCheckboxes = (isSelected) => {
    let allCheck = [];
    let checkboxes = { ...this.state.checkboxes };
    Object.keys(this.state.checkboxes).forEach((checkbox) => {
      if (isSelected) {
        let rcKey = this.getKeybasedOnValue(OPTIONS, checkbox);
        allCheck.push(rcKey);
      }
      checkboxes[checkbox] = isSelected;
    });

    this.setState({ checkboxes });
    this.setState({ selectedRunCheck: allCheck });
    if (this.props.runCheckListCallback) {
      this.props.runCheckListCallback(allCheck);
    }
    console.log(this.state.checkboxes);
  };
  selectAll = () => this.selectAllCheckboxes(true);

  deselectAll = () => this.selectAllCheckboxes(false);

  handleCheckboxChange = (changeEvent) => {
    const { name } = changeEvent.target;
    if (this.state.checkboxes[name] === false) {
      var rcKey = this.getKeybasedOnValue(OPTIONS, name);
      if (this.state.selectedRunCheck.indexOf(rcKey) >= 0) {
      } else {
        this.state.selectedRunCheck.push(rcKey);
        if (this.props.runCheckListCallback) {
          this.props.runCheckListCallback(this.state.selectedRunCheck);
        }
        this.setState({ selectedRunCheck: this.state.selectedRunCheck });
      }
    } else {
      var rcKey = this.getKeybasedOnValue(OPTIONS, name);
      let index = this.state.selectedRunCheck.indexOf(rcKey);
      if (index >= 0) {
        this.state.selectedRunCheck.splice(index, 1);
        this.setState({ selectedRunCheck: this.state.selectedRunCheck });
        if (this.props.runCheckListCallback) {
          this.props.runCheckListCallback(this.state.selectedRunCheck);
        }
      } else {
      }
    }

    this.setState((prevState) => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name],
      },
    }));
  };

  getKeybasedOnValue(map, value) {
    return Object.keys(map).find((key) => map[key] === value);
  }

  handleFormSubmit = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();

    Object.keys(this.state.checkboxes)
      .filter((checkbox) => this.state.checkboxes[checkbox])
      .forEach((checkbox) => {
        console.log(checkbox, "is selected.");
      });
  };

  createCheckbox = (option) => (
    <Col md={3} key={option}>
      <FormGroup key={option}>
        <Checkbox
          key={option}
          label={OPTIONS[option]}
          isSelected={this.state.checkboxes[OPTIONS[option]]}
          onCheckboxChange={this.handleCheckboxChange}
          disabled={this.props.disabled}
        />
      </FormGroup>
    </Col>
  );

  createCheckboxType2 = (option) => (
    <Checkbox
      label={option}
      isSelected={this.state.checkboxes[option]}
      onCheckboxChange={this.handleCheckboxChange}
      key={option}
      disabled={false}
    />
  );

  createCheckboxes = () => Object.keys(OPTIONS).map(this.createCheckbox);

  createCheckboxesType2 = () =>
    Object.values(OPTIONS).map(this.createCheckboxType2);

  runCheckAndSummary(selectedRunCheck, runcheckclick) {
    this.setState({ canInsertData: this.props.saveAllowed });
    if (this.props.callback) {
      this.props.callback(/*this.state.*/ selectedRunCheck, runcheckclick);
    }
  }

  insertData() {
    this.setState({ isLoading: true, displayInsertConfirm: false });
    console.log("Insert clicked ", this.props.defaultType);
    let isDataFinal = this.state.isDataFinal;
    var config = {
      headers: {
        ...authHeader(),
        "content-type": "application/x-www-form-urlencoded",
        isPeriod180: this.props.defaultType,
        isEoy: this.props.isAnnualMode,
        isDataFinal: isDataFinal,
      },
    };
    instance
      .post("/data/upload/insertData", {}, config)
      .then((res) => {
        this.setState({ isLoading: false, isDataFinal: false });
        if (res && res.data.error && res.data.error === "false") {
          this.setState({ modal: true });
          let heading = res.data.message;
          let countRes = res.data.status;
          this.setState({
            uploadSuccessMessage: heading,
            uploadSummary: countRes,
          });
          this.props.onInsert(true, heading, countRes);
          this.props.callUserDetails();

          config = {
            headers: { ...authHeader() },
          };
          if (!this.props.isAnnualMode) {
            this.props.getUserNotificationsAndEvents();
            if (isDataFinal) {
              instance.get(
                "/mail/sendMail?action=insert&&owner=" + this.props.ownerId,
                config
              );
            }
          }

          recordForAuditTrail(AUDIT_INSERT);
        } else {
          if (res && res.data.error && res.data.error === "true") {
            if (
              res.data.upload_allowed != null &&
              res.data.upload_allowed == false
            ) {
              this.setState({
                displayInsertDsbldPopup: true,
                insertDsbldTitle: UPLOAD_INSERT_TITLE,
                insertDsbldMsg: res.data.message,
              });
            } else {
              this.props.onShowToast(res.data.message, TOAST_TYPE_ERROR);
            }
          } else {
            this.props.onShowToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        }
        console.log(res.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false, isDataFinal: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
            this.props.onShowToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          console.log("Unknown error occured");
          this.props.onShowToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      });
  }

  componentDidUpdate(oldProps, prevState) {
    console.log("componentDidUpdate ", prevState, " ", this.props);
    if (this.props.disabled && oldProps.disabled != this.props.disabled) {
      console.log("Run check compDidUpdate ");
      this.setState({ canInsertData: false });
      if (this.props.disabled /*this.props.clearAll*/) {
        this.deselectAll();
        this.setState({
          checkboxes: OPTIONS_DEFAULT,
          selectedRunCheck: [],
        });
      }
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  cancelToggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      uploadSuccessMessage: "",
      uploadSummary: null,
    }));
  }

  closePopup() {
    this.setState({ displayInsertConfirm: false, isDataFinal: false });
  }

  confirmInsert() {
    if (this.props.userData && this.props.userData.userRole === 1) {
      this.setState({ isDataFinal: true });
    }
    this.setState({ displayInsertConfirm: true });
  }

  cancelPopup() {
    this.setState({ displayInsertConfirm: false, isDataFinal: false });
  }

  refreshPage() {
    window.location.reload();
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");
      if (event.target.id == "all") {
        this.selectAll();
      } else if (event.target.id == "none") {
        this.deselectAll();
      }
    }
  };

  onCheckboxChange(isDataFinal) {
    this.setState({ isDataFinal: isDataFinal.target.checked });
  }

  render() {
    console.log(this.state.selectedRunCheck);
    console.log(this.state.checkboxes);

    return (
      <div
        className="comm-section"
        style={
          this.props.disabled ? { pointerEvents: "none", opacity: "1" } : {}
        }
      >
        {this.state.displayInsertDsbldPopup ? (
          <PopupModal
            modalSize="md"
            title={this.state.insertDsbldTitle}
            message={this.state.insertDsbldMsg}
            yesClickCallback={this.refreshPage.bind(this)}
            cancelClickCallback={this.refreshPage.bind(this)}
            buttonPositive="OK"
          />
        ) : null}
        {/* <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              backdrop={false}
            >
              <ModalHeader toggle={this.cancelToggle}>
               {this.state.uploadSuccessMessage}
              </ModalHeader>
              <ModalBody>
                <div className="data-insert">
                  {this.state.uploadSummary?Object.keys(this.state.uploadSummary).map((k,v)=>
                      <div className="row">
                        <div className="col-8 record">{k}</div>
                        <div className="col-4 record-count">{this.state.uploadSummary[k]}</div>
                      </div>
               ):null}
               
               <div className="d-flex"> <div className="ml-auto mt-1"><Button className="btn btn-default" onClick={this.cancelToggle}>
                    Ok
                </Button></div></div>
                </div>
              </ModalBody>

            </Modal> */}
        {/* <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        /> */}
        {this.state.isLoading ? (
          <FullScreenLoaderComponent msg="Data is being inserted" />
        ) : (
          false
        )}
        <div className="runcheck ">
          {this.state.displayInsertConfirm ? (
            this.props.userData && this.props.userData.userRole === 1 ? (
              <PopupModal
                title={this.state.modalTitle}
                message={this.state.modalBodyMessage.replace(
                  "INST_NAME",
                  this.props.ownerName
                )}
                yesClickCallback={this.insertData.bind(this)}
                cancelClickCallback={this.cancelPopup.bind(this)}
                noClickCallback={this.closePopup.bind(this)}
                hasHtmlElements={true}
                buttonPositive="Yes"
                buttonNegative="No"
                modalSize="md"
              />
            ) : (
              <PopupModalInsert
                title={this.state.modalTitle}
                message={this.state.modalBodyMessage.replace(
                  "INST_NAME",
                  this.props.ownerName
                )}
                yesClickCallback={this.insertData.bind(this)}
                cancelClickCallback={this.cancelPopup.bind(this)}
                noClickCallback={this.closePopup.bind(this)}
                hasHtmlElements={true}
                buttonPositive="Yes"
                buttonNegative="No"
                modalSize="md"
                onCheckboxChange={this.onCheckboxChange.bind(this)}
                isDataFinal={this.state.isDataFinal}
              />
            )
          ) : null}

          <Form onSubmit={this.handleFormSubmit}>
            <div className="checkbox-form">
              <div className="row">
                {this.props.isDropdown
                  ? this.createCheckboxesType2()
                  : this.createCheckboxes()}
              </div>
            </div>
            <div className="">
              <Button
                color="default site"
                id="all"
                onClick={this.selectAll}
                disabled={this.props.disabled}
                onKeyUp={(event) => this.enterPressed(event)}
              >
                All
              </Button>
              <Button
                color="default site"
                id="none"
                onClick={this.deselectAll}
                disabled={this.props.disabled}
                onKeyUp={(event) => this.enterPressed(event)}
              >
                None
              </Button>

              {this.props.disabled ? null : (
                <Button
                  color="default site"
                  hidden={this.props.isDropdown}
                  onClick={() =>
                    this.runCheckAndSummary(this.state.selectedRunCheck, false)
                  }
                  disabled={this.checkSelected()}
                >
                  Run Checks
                </Button>
              )}
              {this.props.userData ? (
                this.props.userData.privilege_id === 1 &&
                this.props.userData.upload_allowed ? (
                  this.props.saveAllowed ? (
                    <Button color="default" onClick={this.confirmInsert}>
                      Insert Data
                    </Button>
                  ) : null
                ) : null
              ) : null}
            </div>
          </Form>
        </div>
        {/* {this.state.renderExceptions ? <GenerateSummary /> : null} */}
      </div>
    );
  }
}

Runcheck.propsType = {
  callback: PropTypes.func,
  runCheckListCallback: PropTypes.func,
};

const mapStateToProps = (state) => {
  console.log("ButtonCompo received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
    isAnnualMode: state.auth.isAnnualMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowToast: (message, toastType) => {
      dispatch(actions.showToast(message, toastType));
    },
    onInsert: (showModal, uploadSuccessMessage, uploadSummary) => {
      dispatch(
        actions.broadcastInsertSummary(
          showModal,
          uploadSuccessMessage,
          uploadSummary
        )
      );
    },
    callUserDetails: () => {
      dispatch(actions.callUserDetails());
    },
    getUserNotificationsAndEvents: () => {
      dispatch(actions.getUserNotificationsAndEvents());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Runcheck);
