import React, { Component } from "react";
import { Row } from "reactstrap";
import _ from "lodash";

class InfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tilesData: {
        nbActCounterparts: 0,
        nbDefaultCounterparts: 0,
        nbCounterpartRR: 0,
        pdAverage: 0,
        mmMean: 0,
        rrMean: 0,
      },
    };
  }

  componentDidMount() {
    this.setState({ tilesData: this.props.tilesData });
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps.tilesData, this.props.tilesData)) {
      this.setState({ tilesData: this.props.tilesData });
    }
  }
  render() {
    return (
      <Row>
        <div className="col-sm-4 col-xl-2">
          <div className="card counterpart order-card">
            <div className="card-block">
              <h6 className="m-b-20">
                Number of Active
                <br /> Counterparts
              </h6>
              <h2>
                <div className="d-flex">
                  <div className="mr-auto">
                    <i className="fa fa-university" aria-hidden="true" />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span>
                      {this.state.tilesData.nbActCounterparts.toLocaleString()}
                    </span>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-xl-2">
          <div className="card recovery-rates order-card">
            <div className="card-block">
              <h6 className="m-b-20">
                Number of <br />
                Defaults
              </h6>
              <h2>
                <div className="d-flex">
                  <div className="mr-auto">
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span>
                      {this.state.tilesData.nbDefaultCounterparts.toLocaleString()}
                    </span>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>

        <div className="col-sm-4 col-xl-2">
          <div className="card defaults order-card">
            <div className="card-block">
              <h6 className="m-b-20">
                Contracts for <br />
                Recovery Rates
              </h6>
              <h2>
                <div className="d-flex">
                  <div className="mr-auto">
                    <i class="fa fa-files-o" aria-hidden="true" />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span>
                      {this.state.tilesData.nbCounterpartRR.toLocaleString()}
                    </span>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-xl-2">
          <div className="card downgrade-ratio order-card">
            <div className="card-block">
              <h6 className="m-b-20">
                Average <br />
                Default Rate
              </h6>
              <h2>
                <div className="d-flex">
                  <div className="mr-auto">
                    <i className="fa fa-bar-chart" aria-hidden="true" />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span>
                      {this.state.tilesData.pdAverage === undefined ||
                      this.state.tilesData.pdAverage === null ||
                      this.state.tilesData.pdAverage === ""
                        ? 0
                        : this.state.tilesData.pdAverage}
                      %
                    </span>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-xl-2">
          <div className="card default-rate order-card">
            <div className="card-block">
              <h6 className="m-b-20">
                Average <br />
                Up/Down Ratio
              </h6>
              <h2>
                <div className="d-flex">
                  <div className="mr-auto">
                    <i className="fa fa-exchange rotate" aria-hidden="true" />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span>
                      {this.state.tilesData.mmMean === undefined ||
                      this.state.tilesData.mmMean === null ||
                      this.state.tilesData.mmMean === ""
                        ? 0
                        : this.state.tilesData.mmMean}
                    </span>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>

        <div className="col-sm-4 col-xl-2">
          <div className="card avg-recovery-rate order-card">
            <div className="card-block">
              <h6 className="m-b-20">
                Average
                <br /> Recovery Rate
              </h6>
              <h2>
                <div className="d-flex">
                  <div className="mr-auto">
                    <i className="fa fa-money" aria-hidden="true" />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span>
                      {this.state.tilesData.rrMean === undefined ||
                      this.state.tilesData.rrMean === null ||
                      this.state.tilesData.rrMean === ""
                        ? 0
                        : (this.state.tilesData.rrMean * 100000) / 1000}
                      %
                    </span>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}

export default InfoCard;
