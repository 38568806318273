import React, { Component } from "react";
import ReactDOM from "react-dom";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect.js";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, getValue, ...props }) => {
  let value = getValue();
  let valueToSub = 0;
  if (value != null && value.length > 0) {
    for (var i = 0; i < value.length; i++) {
      if (value[i].value == "*") {
        valueToSub = 1;
        break;
      }
    }
  }
  var valueLength = getValue().length - valueToSub;
  var optionsLength = props.selectProps.options.length;

  return (
    <components.ValueContainer {...props}>
      {React.Children.map(children, (child) => {
        return child != null
          ? child.type === components.Input
            ? child
            : null
          : null;
      })}
      {props.selectProps.inputValue == ""
        ? valueLength == 0
          ? "Select " + props.name
          : `${valueLength} Item${valueLength != 1 ? "s" : ""} selected`
        : ""}
    </components.ValueContainer>
  );
};

const animatedComponents = makeAnimated();
export default class MultiSelectCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
    };
  }

  render() {
    return (
      <MySelect
        tabSelectsValue={false}
        options={this.props.list}
        backspaceRemovesValue={false}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        name={"name"}
        components={{
          Option,
          ValueContainer: (valueProps) => (
            <ValueContainer {...valueProps} name={this.props.name} />
          ),
          animatedComponents,
        }}
        onChange={this.props.handleChange}
        allowSelectAll={true}
        value={this.props.optionSelected}
        isDisabled={this.props.disabled}
        shouldFocus={this.props.shouldFocus}
        menuIsOpen={this.props.disabled ? false : undefined}
        updateMenuOpenRef={this.props.updateMenuOpenRef}
        classNamePrefix='SertorDropDown'
      />
    );
  }
}
