import React, { Component } from "react";
import { Table } from "reactstrap";

class MMResultTable extends Component {
  getMMHeaders() {
    let tableHeaders = [];
    tableHeaders.push(<th key="rowLabel">&nbsp;&nbsp;&nbsp;</th>);
    Object.keys(
      this.props.mmData.valueTrue[this.props.selectedDuration]
    ).forEach((key, index) => {
      tableHeaders.push(<th key={key}>{key}</th>);
    });
    return tableHeaders;
  }

  getYearHeaders() {
    let tableHeaders = [];
    tableHeaders.push(<th key="rowLabel">&nbsp;&nbsp;&nbsp;&nbsp;</th>);
    for (let year = this.props.startYear; year <= this.props.endYear; year++) {
      tableHeaders.push(<th key={year}>{year}</th>);
    }
    return tableHeaders;
  }

  getMMRowsPercent() {
    let mmRowData = [];
    Object.keys(
      this.props.mmData.valueTrue[this.props.selectedDuration]
    ).forEach((row, index) => {
      let mmRowWiseData = [];
      mmRowWiseData.push(<td key={row + index}>{row}</td>);
      Object.keys(
        this.props.mmData.valueTrue[this.props.selectedDuration][row]
      ).forEach((rowItems, rowIndex) => {
        mmRowWiseData.push(
          <td key={row + index + rowIndex}>
            {
              this.props.mmData.valueTrue[this.props.selectedDuration][row][
                rowItems
              ]
            }
            %
          </td>
        );
      });
      mmRowData.push(<tr key={index}>{mmRowWiseData}</tr>);
    });
    return mmRowData;
  }

  getMMRows() {
    let mmRowData = [];
    Object.keys(
      this.props.mmData.valueFalse[this.props.selectedDuration]
    ).forEach((row, index) => {
      let mmRowWiseData = [];
      mmRowWiseData.push(<td key={row + index}>{row}</td>);
      Object.keys(
        this.props.mmData.valueFalse[this.props.selectedDuration][row]
      ).forEach((rowItems, rowIndex) => {
        mmRowWiseData.push(
          <td key={row + index + rowIndex}>
            {this.props.mmData.valueFalse[this.props.selectedDuration][row][
              rowItems
            ].toLocaleString()}
          </td>
        );
      });
      mmRowData.push(<tr key={index}>{mmRowWiseData}</tr>);
    });
    return mmRowData;
  }

  getYearlyRowsData() {
    let yearlyRowData = [];

    let stablesRowData = [];
    stablesRowData.push(<td key="stables">Stables</td>);
    this.props.mmData.stablesList.forEach((row, index) => {
      stablesRowData.push(
        <td key={"stables" + index}>{row.toLocaleString()}</td>
      );
    });
    yearlyRowData.push(<tr key="stablesRowData">{stablesRowData}</tr>);

    let upgradesRowData = [];
    upgradesRowData.push(<td key="upgrades">Upgrades</td>);
    this.props.mmData.upgradesList.forEach((row, index) => {
      upgradesRowData.push(
        <td key={"upgrades" + index}>{row.toLocaleString()}</td>
      );
    });
    yearlyRowData.push(<tr key="upgradesRowData">{upgradesRowData}</tr>);

    let downgradesRowData = [];
    downgradesRowData.push(<td key="downgrades">Downgrades</td>);
    this.props.mmData.downgradesList.forEach((row, index) => {
      downgradesRowData.push(
        <td key={"downgrades" + index}>{row.toLocaleString()}</td>
      );
    });
    yearlyRowData.push(<tr key="downgradesRowData">{downgradesRowData}</tr>);

    let upRatioRowData = [];
    upRatioRowData.push(<td key="upRatio">Up/Down Ratio</td>);
    this.props.mmData.upRatioList.forEach((row, index) => {
      upRatioRowData.push(<td key={"upRatio" + index}>{row}</td>);
    });
    yearlyRowData.push(<tr key="upRatioRowData">{upRatioRowData}</tr>);

    return yearlyRowData;
  }

  render() {
    return (
      <div className="result-for-table">
        <Table bordered="true" responsive>
          <thead>
            <tr>{this.getMMHeaders()}</tr>
          </thead>
          <tbody>{this.getMMRows()}</tbody>
        </Table>

        <Table bordered="true" responsive className="mt-4">
          <thead>
            <tr>{this.getMMHeaders()}</tr>
          </thead>
          <tbody className="mm-right">{this.getMMRowsPercent()}</tbody>
        </Table>
        <Table bordered="true" responsive className="mt-4 last-table">
          <thead>
            <tr>{this.getYearHeaders()}</tr>
          </thead>
          <tbody>{this.getYearlyRowsData()}</tbody>
        </Table>
      </div>
    );
  }
}

export default MMResultTable;
