import React from "react";
import PropTypes from "prop-types";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Tooltip from "@material-ui/core/Tooltip";
import {
  UPLOAD_TOOLTIP,
  REVOKE_CHECK_MY_DATA,
  ERROR_OCCURED,
  FIFTY_MB,
  FILE_SIZE_LIMIT_EXCEED,
  UPLOAD_FORBIDDEN,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  UNKNOWN_ERROR,
  UPLOAD_DISABLED_TITLE,
  AUDIT_UPLOAD,
} from "../commonUtility/string-const";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "reactstrap";
import { showToast } from "../commonUtility/NewToast";
import showAlert from "../commonUtility/confirmAlert";
import { ToastContainer } from "react-toastify";
import PopupModal from "../commonUtility/PopupModal";
import { recordForAuditTrail } from "../commonUtility/method-utils";

class ButtonComponent extends React.Component {
  _isMount = false;
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      uploadProgress: 0,
      isUploading: false,
      isUnauthorized: false,
      mounted: true,
      uploadAllowed: false,
      displayUploadDsbldPopup: false,
      uploadDsbldTitle: null,
      uploadDsbldMsg: null,
      displayRevokeCheckMyDataConfirmModal: false,
      hasSyncedUploadPermission: false,
      _ivl: null,
    };
    this.onChange = this.onChange.bind(this);
    this.confirmRevokeCheckMyData = this.confirmRevokeCheckMyData.bind(this);
    this.sendRevokeCheckMyData = this.sendRevokeCheckMyData.bind(this);
    this.closeRevokeCheckMyDataModal =
      this.closeRevokeCheckMyDataModal.bind(this);
  }

  componentDidMount() {
    this.setPrevProgessState();
    this._isMount = true;
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  refreshPage() {
    window.location.reload();
  }

  setPrevProgessState() {
    if (this.props.uploading) {
      let progress = this.props.progress;
      var _ivl = setInterval(() => {
        let min = 4;
        let max = 9;
        let incr = min + Math.random() * (max - min);
        incr = parseInt(incr);
        progress += incr;
        if (progress < 95) {
          this.props.ProgressCallback(progress);
        }
      }, 500);
      this.setState({ _ivl: _ivl, isUploading: true });
    }
  }

  setFileContents(response) {
    this.props.ProgressCallback(0);
    clearInterval(this.interval);
    this.props.onFileUpload(false);
    clearInterval(this.state._ivl);
    if (this._isMount) {
      this.setState({ file: response.data, isUploading: false });
    }
    if (
      response.data.upload_allowed != null &&
      response.data.upload_allowed == false
    ) {
      this.setState({
        displayUploadDsbldPopup: true,
        uploadDsbldTitle: UPLOAD_DISABLED_TITLE,
        uploadDsbldMsg: response.data.message,
      });
    } else {
      this.props.onShowToast("Upload successful", TOAST_TYPE_SUCCESS);

      let t = this.state.file;
      this.props.callback(response.data);
      sessionStorage.setItem("excel_summary", JSON.stringify(response.data));

      recordForAuditTrail(AUDIT_UPLOAD);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fileUploadRes != null &&
      this.props.fileUploadRes.data != null &&
      this.props.uploading == true
    ) {
      this.setFileContents(this.props.fileUploadRes);
    }
  }

  onChange(e) {
    this.props.clear();
    let f = e.target.files[0];
    if (f.size <= FIFTY_MB) {
      if (this.props.userData.privilege_id != 3) {
        if (this._isMount) {
          this.setState({ uploading: "uploading" });
        }
        e.target.value = null;
        const formData = new FormData();
        formData.append("uploadedFile", f);
        if (this.props.isSovereignData) {
          formData.append("sovFlagIsSovPeriod180", this.props.isSovereign180);
        }
        const config = {
          headers: {
            ...authHeader(),
            "content-type": "multipart/form-data",
            is180Period: this.props.is180Period,
            isAnnualMode: this.props.isAnnualMode,
          },
          onUploadProgress: (progressEvent) => {
            if (this._isMount) {
              this.setState({ isUploading: true });
            }
          },
          onDownloadProgress: (progressEvent) => {},
        };
        let progress = 0;
        this.interval = setInterval(() => {
          let min = 4;
          let max = 9;
          let incr = min + Math.random() * (max - min);
          incr = parseInt(incr);
          progress += incr;
          if (progress < 95) {
            this.props.ProgressCallback(progress);
            this.props.updateProgress(progress);
          }
        }, 500);
        this.props.onFileUpload(true);
        this.props.uploadDSFile(f, formData, config);
      } else {
        showToast(UPLOAD_FORBIDDEN, TOAST_TYPE_ERROR);
      }
    } else {
      e.target.value = null;
      showToast(FILE_SIZE_LIMIT_EXCEED, TOAST_TYPE_ERROR);
    }
  }

  getUploadAllowedStatus() {
    let b_token = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        "content-type": "text/plain",
        b_token: b_token,
        ...authHeader(),
      },
    };
    instance
      .post("/api/getUploadAllowed", b_token, config)
      .then((response) => {
        this.setState({ hasSyncedUploadPermission: true });
        if (response.data != null) {
          this.props.updateUploadPermission(
            null,
            this.props.userData.email,
            true,
            {
              ...this.props.userData,
              upload_allowed: response.data.upload_allowed,
              privilege_id: response.data.privilege,
              request_upload: response.data.request_upload,
            }
          );
        } else {
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      })
      .catch((error) => {
        this.setState({ hasSyncedUploadPermission: true });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          console.log("Unknown error occured");
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      })
      .finally((e) => {
        this.setState({ hasSyncedUploadPermission: true });
      });
  }

  sendRevokeCheckMyData() {
    this.props.revokeChkMyDataLoading(true);
    console.log("sendDataforReview");
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    var action = REVOKE_CHECK_MY_DATA;
    instance
      .post("api/review/" + action, null, config)
      .then((response) => {
        this.props.revokeChkMyDataLoading(false);
        this.closeRevokeCheckMyDataModal();
        if (
          response &&
          response.data &&
          response.data.error != undefined &&
          response.data.error == "false"
        ) {
          showToast(response.data.message, TOAST_TYPE_INFO);

          this.props.callUserDetails();
        } else {
          if (
            response &&
            response.data &&
            response.data.error != undefined &&
            response.data.error == "true"
          ) {
            showToast(response.data.message, TOAST_TYPE_ERROR);
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        }
      })
      .catch((error) => {
        this.closeRevokeCheckMyDataModal();
        this.props.revokeChkMyDataLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  confirmRevokeCheckMyData() {
    this.setState({ displayRevokeCheckMyDataConfirmModal: true });
  }

  closeRevokeCheckMyDataModal() {
    this.setState({ displayRevokeCheckMyDataConfirmModal: false });
  }

  render() {
    if (this.props.userData != null && !this.state.hasSyncedUploadPermission) {
      this.getUploadAllowedStatus();
    }
    return (
      <div>
        {this.state.displayRevokeCheckMyDataConfirmModal ? (
          <PopupModal
            modalSize="md"
            title="Confirm!"
            message="Do you want to send request to enable upload for your institution?"
            yesClickCallback={this.sendRevokeCheckMyData}
            noClickCallback={this.closeRevokeCheckMyDataModal}
            cancelClickCallback={this.closeRevokeCheckMyDataModal}
            buttonPositive="Yes"
            buttonNegative="No"
          />
        ) : null}
        {this.state.displayUploadDsbldPopup ? (
          <PopupModal
            modalSize="md"
            title={this.state.uploadDsbldTitle}
            message={this.state.uploadDsbldMsg}
            yesClickCallback={this.refreshPage.bind(this)}
            cancelClickCallback={this.refreshPage.bind(this)}
            buttonPositive="OK"
          />
        ) : null}
        {this.props.userData ? (
          this.state.isUploading ? null : (
            <label
              className={
                "custom-file-upload " +
                (this.props.userData.privilege_id === 3 ? "disabled" : "")
              }
              hidden={
                this.props.userData.privilege_id != 3
                  ? this.props.userData.upload_allowed === false
                  : false
              }
            >
              <span>Upload</span>
              <input
                type="file"
                onChange={this.onChange}
                accept=".xlsx, .xlsm"
                disabled={this.props.userData.privilege_id === 3}
              />
            </label>
          )
        ) : null}

        {this.props.userData ? (
          this.props.userData.upload_allowed === true ? (
            this.state.isUploading ? null : this.props.userData ? (
              this.state.isUploading ? null : this.props.userData
                  .upload_allowed === true ? (
                this.props.userData ? (
                  this.props.userData.userRole === 1 ||
                  this.state.isUploading ||
                  this.props.userData.upload_allowed === false ? null : (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <b>{UPLOAD_TOOLTIP}</b>
                        </React.Fragment>
                      }
                    >
                      <span className="pull-right ml-1">
                        <i className="fa fa-info-circle" id="tooltipUpload" />
                      </span>
                    </HtmlTooltip>
                  )
                ) : null
              ) : null
            ) : null
          ) : null
        ) : null}

        {this.props.userData ? (
          this.props.userData.privilege_id === 3 ? null : this.props.userData
              .upload_allowed === false ? (
            <Button
              color="default"
              onClick={this.confirmRevokeCheckMyData}
              disabled={this.props.userData.request_upload}
            >
              Request New Upload
            </Button>
          ) : null
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("ButtonCompo received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
    isAnnualMode: state.auth.isAnnualMode,
    uploading: state.auth.uploading,
    progress: state.auth.progress,
    fileUploadRes: state.auth.fileUploadRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPageLoad: () => {
      dispatch(actions.checkAuth());
    },
    onFileUpload: (uploading) => {
      dispatch(actions.isFileUploading(uploading));
    },
    onShowToast: (message, toastType) => {
      dispatch(actions.showToast(message, toastType));
    },
    updateUploadPermission: (idToken, userId, isAuthenticated, userData) => {
      dispatch(actions.authSuccess(idToken, userId, isAuthenticated, userData));
    },
    callUserDetails: () => {
      dispatch(actions.callUserDetails());
    },
    updateProgress: (progress) => {
      dispatch(actions.updateProgress(progress));
    },
    uploadDSFile: (f, formData, config) => {
      dispatch(actions.uploadDSFile(f, formData, config));
    },
  };
};

ButtonComponent.propTypes = {
  callback: PropTypes.func,
  ProgressCallback: PropTypes.func,
  errorOccuredCallback: PropTypes.func,
  revokeChkMyDataLoading: PropTypes.func,
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default connect(mapStateToProps, mapDispatchToProps)(ButtonComponent);
