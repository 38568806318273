import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import NotificationAccordion from "./NotificationAccordion";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserNotificationsAndEvents,
  updateUserNotificationsAndEvents,
} from "../../store/actions/index";

const useStyles = makeStyles({
  list: {
    width: 400,
  },
  fullList: {
    width: "auto",
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 3,
    
    
    height: "16px",
    minWidth: "16px",
    fontSize: "0.7rem",
  },
}))(Badge);

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const dispatch = useDispatch();
  const reducerProps = useSelector((state) => state.auth);

  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log(open);
    if (open) dispatch(getUserNotificationsAndEvents());
    else
      dispatch(
        updateUserNotificationsAndEvents(reducerProps.userNotificationsEvents)
      );
    setState({ ...state, [side]: open });
  };

  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <div>
        <NotificationAccordion />
      </div>
    </div>
  );

  return (
    <div>
      <IconButton color="inherit" onClick={toggleDrawer("right", true)}>
        <StyledBadge
          badgeContent={
            reducerProps.userNotificationsEvents.totalUnreadCount < 0
              ? 0
              : reducerProps.userNotificationsEvents.totalUnreadCount
          }
          max={9}
          color="secondary"
        >
          <NotificationsIcon />
        </StyledBadge>
      </IconButton>

      <SwipeableDrawer
        id="drawer"
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {sideList("right")}
      </SwipeableDrawer>
    </div>
  );
}
