import React, { Component } from "react";

export default class FullScreenLoaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: this.props.msg,
    };
  }

  render() {
    let data;
    data = (
      <div className="full-screen-load">
        <div className="loading">
          <div />
          <div />
          <div />
          <br />
        </div>
        <div className="loading-mess whiteText">{this.props.msg}</div>
      </div>
    );

    return <div>{data}</div>;
  }
}
