import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import NotificationEmaill from "./NotificationEmail";
import { authHeader } from "../commonUtility/auth-header";
import { connect } from "react-redux";
import { showToast } from "../commonUtility/NewToast";
import { ERROR_OCCURED, TOAST_TYPE_ERROR } from "../commonUtility/string-const";
import MaterialTable from "material-table";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailInfo: [],
      tempEmailInfo: [],
      showEmailForm: false,
      columns: [],
      isFormOpen: false,
      isLoading: false,
      searchText: "",
      searchClass: "fa fa-search",
      clearClass: "fa fa-times",
      firstTymLoad: false,
      currentPageSize: 10,
      viewMailBody: false,
      selectedMailSubject: "",
      selectedMailBody: "",
    };
    this.startSearching = this.startSearching.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  componentDidMount() {
    this.setState({ firstTymLoad: true });
    let tempColumns = [
      {
        title: "Sr. No.",
        type: "numeric",
        field: "id",
        editable: "never",
        sorting: true,
        hidden: true,
      },
      {
        title: "Subject",
        field: "subject",
        type: "string",
      },
      { title: "Date & Time", field: "dateTime", type: "date" },
      {
        title: "Sender Name",
        field: "fromName",
        type: "string",
      },
      {
        title: "Email",
        field: "fromEmail",
        type: "string",
      },
    ];
    this.setState({ columns: tempColumns });
    this.loadEmailGridData();
  }

  loadEmailGridData() {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };

    instance
      .post("/mail/getMailNotification", {}, config)
      .then((response) => {
        this.setState({ firstTymLoad: false });
        let emailList = response.data.emailList.map((list, index) => {
          return {
            ...list,
            id: index + 1,
          };
        });
        this.setState({
          emailInfo: emailList,
          tempEmailInfo: emailList,
        });
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  showEmailForm() {
    this.setState({ showEmailForm: true, editInfo: null, isFormOpen: true });
  }

  closeEmailForm() {
    this.setState({ showEmailForm: false, editInfo: null, isFormOpen: false });
  }

  clearAll() {
    this.setState({ editInfo: null });
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  startSearching(e) {
    if (e != null) {
      e = e.target.value;
      this.setState({ searchText: e });
      if (e != null && e.toString().trim() !== "") {
        let emailList = this.state.tempEmailInfo;
        let searchItems = [];
        for (var j = 0; j < emailList.length; j++) {
          for (var key in emailList[j]) {
            if (
              key !== "tableData" &&
              key !== "id" &&
              key !== "body" &&
              emailList[j][key] != null
            ) {
              if (
                emailList[j][key]
                  .toString()
                  .toLowerCase()
                  .includes(e.toLowerCase())
              ) {
                searchItems.push(emailList[j]);
                break;
              }
            }
          }
        }
        console.log(searchItems);
        this.setState({ emailInfo: searchItems });
      } else {
        this.setState({ emailInfo: this.state.tempEmailInfo });
      }
    } else {
      this.setState({ emailInfo: [] });
      this.setState({ emailInfo: this.state.tempEmailInfo });
    }
  }

  searchIconClick(clear) {
    if (clear) {
      this.setState({ searchText: "" });
      this.startSearching(null);
    }
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");
      this.searchIconClick(this.state.searchText.trim().length > 0);
    }
  };

  emailSent(data, message, error, type) {
    this.setState({ searchText: "", firstTymLoad: true });
    this.startSearching(null);
    if (!error) {
      this.loadEmailGridData();
    }
    showToast(message, type);
    this.setState({ firstTymLoad: false });
  }

  toggleMailModal() {
    this.setState((prevState) => ({
      viewMailBody: !prevState.viewMailBody,
    }));
  }

  cancelMailModalToggle() {
    this.setState((prevState) => ({
      viewMailBody: false,
    }));
  }

  setMailBodyToView(rowData) {
    console.log(rowData);
    this.setState({
      viewMailBody: true,
      selectedMailSubject: rowData.subject,
      selectedMailBody: rowData.body,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.showEmailForm ? (
          <NotificationEmaill
            emailSentCallback={this.emailSent.bind(this)}
            editInfo={this.state.editInfo}
            closeEmailComposeCallback={this.closeEmailForm.bind(this)}
            resetCallback={this.clearAll.bind(this)}
            sendEmailLoaderCallback={this.startLoader.bind(this)}
          />
        ) : null}
        <div>
          <Paper className={classes.root + " admin-user-list"}>
            <div className="top-bar">
              {this.state.firstTymLoad ? (
                <FullScreenLoaderComponent msg="" />
              ) : null}
              {this.state.firstTymLoad ? null : (
                <div className="row">
                  <div className="col-auto align-self-center">
                    <div className="search-bar">
                      <div id="custom-search-input">
                        <div className="input-group">
                          <input
                            type="text"
                            className="search-query form-control"
                            placeholder="Search"
                            onChange={this.startSearching}
                            value={this.state.searchText}
                            tabIndex="0"
                          />
                          <span className="input-group-btn" tabIndex="-1">
                            <button
                              className="btn btn-danger"
                              type="button"
                              tabIndex="-1"
                            >
                              <i
                                className={
                                  this.state.searchText.trim().length > 0
                                    ? this.state.clearClass
                                    : this.state.searchClass
                                }
                                aria-hidden="true"
                                onClick={() =>
                                  this.searchIconClick(
                                    this.state.searchText.trim().length > 0
                                  )
                                }
                                onKeyUp={(event) => this.enterPressed(event)}
                                tabIndex={
                                  this.state.searchText.trim().length > 0
                                    ? "0"
                                    : "-1"
                                }
                              />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto ml-auto align-self-center">
                    {this.state.isFormOpen ? null : (
                      <div
                        aria-label="Add"
                        className="addbutton"
                        onClick={this.showEmailForm.bind(this)}
                      >
                        <button className="btn btn-default">Compose</button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {this.state.firstTymLoad ? null : (
              <MaterialTable
                columns={this.state.columns}
                data={this.state.emailInfo}
                onChangeRowsPerPage={(pageSize) => {
                  this.setState({ currentPageSize: pageSize });
                  let timesRun = 0;
                  let firstTimeExecuted = false;
                  var hasVScroll =
                    window.innerHeight <= document.body.offsetHeight;
                  this.interval = setInterval(() => {
                    if (timesRun === 5) {
                      clearInterval(this.interval);
                    }
                    timesRun++;
                    console.log("service ", hasVScroll);
                    if (hasVScroll) {
                      window.scrollTo(0, 0);
                      clearInterval(this.interval);
                    } else {
                      if (firstTimeExecuted) {
                        clearInterval(this.interval);
                      }
                      firstTimeExecuted = true;
                    }
                  }, 1);
                }}
                options={{
                  showTitle: false,
                  searchFieldAlignment: "left",
                  actionsColumnIndex: -1,
                  pageSize: this.state.currentPageSize,
                  pageSizeOptions: [10, 20, 25],
                  search: false,
                  emptyRowsWhenPaging: false,
                  draggable: false,
                  thirdSortClick: false,
                  sorting: this.state.emailInfo.length > 0,
                }}
                actions={[
                  {
                    icon: "visibility",
                    tooltip: "View Content",
                    onClick: (event, rowData) =>
                      this.setMailBodyToView(rowData),
                  },
                ]}
              />
            )}
          </Paper>
        </div>

        {this.state.viewMailBody ? (
          <Modal
            isOpen={this.state.viewMailBody}
            toggle={this.toggleMailModal.bind(this)}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelMailModalToggle.bind(this)}>
              Subject: {this.state.selectedMailSubject}
            </ModalHeader>
            <ModalBody>
              <div className="mail-body-content">
                <Form
                  className="lib-model"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup className="pt-1">
                    <div className="row">
                      <div className="col-12 ql-editor pt-0">
                        <h5 className="mb-2">Message:</h5>
                        {parse(this.state.selectedMailBody)}
                      </div>
                    </div>
                  </FormGroup>
                </Form>
              </div>
              <div className="mt-2 lib-model-foot">
                <Button
                  className="btn btn-default"
                  onClick={this.cancelMailModalToggle.bind(this)}
                >
                  OK
                </Button>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {},
    errorActionToast: (message) => {},
  };
};

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  passMessage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomizedTable));
