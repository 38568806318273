import React from "react";
import {
    isColumnMandatory,
    returnLookupObject,
    customDropdownFilterAndSearch,
  } from "../../commonUtility/method-utils";
import {
  YEAR_MIN,
  YEAR_MAX,
} from "../../commonUtility/string-const";

import Dropdown from "./Dropdown";
import NumericInput from "./NumericInput";
import Textbox from "./Textbox";

export function getExcelRowIdColumnEditTable() {
    return(
    { title: "Sr. No.", field: "id", editable: "never" }
    );
}

  
export function getRowValiditycolumnEditTable() {
    return(
    {
        title: "Valid",
        field: "valid",
        editable: "never",
        lookup: {
        true: "true",
        false: "false",
        },
    }
    );
}

export function getIdColumnEditColumn(obj, columnName, sheetName){
    return({
        title: columnName,
        field: columnName,
        editComponent: (props) => (
          <NumericInput
            name={columnName}
            value={props.value}
            onChange={props.onChange.bind(obj)}
            required={isColumnMandatory(sheetName, columnName)}
            autoFocus={true}
          />
        )
      });
}

export function getNumericEditColumn(obj, columnName, sheetName){
    return(
        {
            title: columnName,
            field: columnName,
            editComponent: (props) => (
              <NumericInput
                name={columnName}
                value={props.value}
                onChange={props.onChange.bind(obj)}
                required={isColumnMandatory(sheetName, columnName)}
              />
            ),
          }  
    );
}

export function getYearEditColumn(obj, columnName, sheetName){
    return(
        {
            title: columnName,
            field: columnName,
            editComponent: (props) => (
              <NumericInput
                name={columnName}
                value={props.value}
                onChange={props.onChange.bind(obj)}
                min={YEAR_MIN}
                max={YEAR_MAX}
                maxLength={4}
                required={isColumnMandatory(sheetName, columnName)}
                autoFocus={true}
              />
            ),
          }
    );
}

export function getTextEditColumn(obj, columnName, sheetName){
    return(
    {
        title: columnName,
        field: columnName,
        editComponent: (props) => (
            <Textbox
            name={columnName}
            value={props.value}
            onChange={props.onChange.bind(obj)}
            required={isColumnMandatory(sheetName, columnName)}
            />
        ),
    });
}


export function getDropdownEditColumn(obj, columnName, sheetName, dropdownOptionsName){
    let paramListIntern = obj["props"]["paramList"];
    return (    
    {
        title: columnName,
        field: columnName,
        editComponent: (props) => (
            <Dropdown
            name={columnName}
            value={props.value}
            onChange={props.onChange.bind(obj)}
            options={
                paramListIntern === null
                ? []
                : paramListIntern[dropdownOptionsName]
            }
            required={isColumnMandatory(sheetName, columnName)}
            />
        ),
        render: (rowData) => <span>{rowData[columnName]}</span>,
        lookup:
            paramListIntern === null
            ? {}
            : returnLookupObject(paramListIntern[dropdownOptionsName]),
        customFilterAndSearch: (term, rowData) =>
            customDropdownFilterAndSearch(term, rowData, columnName),
        });
    }
  