import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "../../components/login/LoginComponent";
import HomePage from "../../components/homePage/Index";
import ExcelView from "../../components/excelView/Index";
import Compute from "../../components/compute/Index";
import Library from "../../components/library/Index";
import Info from "../../components/info/Index";
import Notfound from "../../notfound";
import Dashboard from "../../components/dashboard/Index";
import PrivateRoute from "../../components/commonUtility/PrivateRoute";
import Logout from "../../components/commonUtility/Logout";
import AdminPage from "../../components/adminPage/Index";
import Help from "../../components/help/Index";
import ChangePasswordForm from "../../components/resetPassword/Index";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

class RouterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      uploadSuccessMessage: "",
      uploadSummary: null,
    };
    this.toggle = this.toggle.bind(this);
    this.cancelToggle = this.cancelToggle.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("gems_userId")) {
      this.props.getUserNotificationsAndEvents();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props) {
      return {
        ...state,
        modal: props.modal,
        uploadSuccessMessage: props.uploadSuccessMessage,
        uploadSummary: props.uploadSummary,
      };
    }
    return { ...state };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  cancelToggle() {
    this.props.onInsert(false, "", null);
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          backdrop={false}
        >
          <ModalHeader toggle={this.cancelToggle}>
            {this.state.uploadSuccessMessage}
          </ModalHeader>
          <ModalBody>
            <div className="data-insert">
              {this.state.uploadSummary
                ? Object.keys(this.state.uploadSummary).map((k, v) => (
                    <div className="row">
                      <div className="col-8 record">{k}</div>
                      <div className="col-4 record-count">
                        {this.state.uploadSummary[k]}
                      </div>
                    </div>
                  ))
                : null}

              <div className="d-flex">
                {" "}
                <div className="ml-auto mt-1">
                  <Button
                    className="btn btn-default"
                    onClick={this.cancelToggle}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <BrowserRouter>
          <div>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute
                exact
                path="/"
                component={Dashboard}
                data={this.props.userData}
              />
              <PrivateRoute
                exact
                path="/submit"
                component={HomePage}
                data={this.props.userData}
              />
              <PrivateRoute
                data={this.props.userData}
                exact
                path="/Dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                data={this.props.userData}
                exact
                path="/edit"
                component={ExcelView}
              />
              <PrivateRoute
                data={this.props.userData}
                exact
                path="/query"
                component={Compute}
              />
              <PrivateRoute
                data={this.props.userData}
                exact
                path="/info"
                component={Info}
              />
              <PrivateRoute
                data={this.props.userData}
                exact
                path="/library"
                component={Library}
              />
              <PrivateRoute
                data={this.props.userData}
                exact
                path="/help"
                component={Help}
              />
              <Route exact path="/logout" component={Logout} />
              <PrivateRoute
                data={this.props.userData}
                exact
                path="/adminPage"
                component={AdminPage}
              />
              <PrivateRoute
                data=""
                exact
                path="/changePassword"
                component={ChangePasswordForm}
              />

              <PrivateRoute data="" component={Notfound} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

RouterComponent.defaultProps = {
  modal: false,
  uploadSuccessMessage: "",
  uploadSummary: null,
};

const mapStateToProps = (state) => {
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
    modal: state.auth.showModal,
    uploadSuccessMessage: state.auth.uploadSuccessMessage,
    uploadSummary: state.auth.uploadSummary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRouteLoad: () => {
      dispatch(actions.checkAuth());
    },
    onInsert: (showModal, uploadSuccessMessage, uploadSummary) => {
      dispatch(
        actions.broadcastInsertSummary(
          showModal,
          uploadSuccessMessage,
          uploadSummary
        )
      );
    },
    getUserNotificationsAndEvents: () => {
      dispatch(actions.getUserNotificationsAndEvents());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);
