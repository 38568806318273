import React from "react";
import logo from "./images/logo.png";
import "./App.scss";
import { Link } from "react-router-dom";
const Notfound = () => (
  <div className="error-404">
    <div className="jumbotron vertical-center text-center">
      <div className=" container ">
        <img src={logo} alt="logo" className="logo" />

        <h1>PAGE NOT FOUND</h1>
        <p>
          The link you clicked may be broken or the page may have been removed.
        </p>
        <p>
          VISIT THE{" "}
          <Link to="/" className="btn btn-default">
            HOMEPAGE
          </Link>{" "}
          OR{" "}
          <Link to="/" className="btn btn-default">
            CONTACT
          </Link>{" "}
          US ABOUT THE PROBLEM
        </p>
      </div>
    </div>
  </div>
);
export default Notfound;
