import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import _ from "lodash";

import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: "150%",
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function NotificationAccordion() {
  const classes = useStyles();

  const reducerProps = useSelector((state) => state.auth);
  console.log(reducerProps);

  const eventsList = [];
  const notificationList = [];

  if (!_.isEmpty(reducerProps.userNotificationsEvents)) {
    reducerProps.userNotificationsEvents.eventList.forEach((item, i) => {
      console.log(item);
      eventsList.push(<li key={i}>{item.description}</li>);
    });
    reducerProps.userNotificationsEvents.notificationMsg.forEach((item, i) => {
      console.log(item);
      notificationList.push(<li key={i}>{item}</li>);
    });
  }

  return (
    <div id="accordion-panel" className={classes.root}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>
              <i className="fa fa-exclamation-circle" /> &nbsp;Notifications{" "}
              <Chip
                size="small"
                color="secondary"
                label={
                  reducerProps.userNotificationsEvents.notificationUnreadCount
                }
              />
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <ul className="notification">
            {/* <li>{notificationToShow}</li> */}
            {notificationList}
          </ul>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2c-content"
          id="panel2c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>
              <i className="fa fa-calendar" aria-hidden="true" /> &nbsp;Events{" "}
              <Chip
                size="small"
                color="secondary"
                label={reducerProps.userNotificationsEvents.eventUnreadCout}
              />
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <ul>{eventsList}</ul>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
