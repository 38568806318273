import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { toast } from "react-toastify";
import { showToast } from "../../components/commonUtility/NewToast";
import { TOAST_TYPE_SUCCESS } from "../../components/commonUtility/string-const";
//Setting initial state for redux
//Token : OTP for 2nd level authentication
//userId : Email of User
//error : error in case of user validation in 1st or 2nd factor
//Loading : intermediate variable to maintain time between request and response
//authRedirectPath : state to maintain current path of application

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
  showComponent: false,
  isLogout: false,
  isAnnualMode:false,
  userNotificationsEvents: {},
  runCheckExecuting:false,
  savedQueryPlaylist:[],
  fileUploadRes:null
};
//Changing state when auth start
//Setting error to null and loading to true when Application Authentication starts
const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    authRedirectPath: "/",
  });
};
//Changing state when auth success setting up token
//Once Authentication success then setting authentication token from BE
//Setting up UserId with email
//Setting authentication to true
//error to null and loading to false
const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    isAuthenticated: action.isAuthenticated,
    error: null,
    loading: false,
    isLogout: false,
    userData: action.userData,
    isAnnualMode:action.userData.isAnnualMode
  });
};

//Changing state when auth fail
//In case of authentication failure setting error object from error coming from BE
//and setting loading to false
const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    showComponent: action.showComponent,
    token: null,
    isLogout: true,
  });
};

//Changing state post OTP validation
//This will change the state post  OTP authentication
//setting up OTP to blank
//Setting up email to user email Id
//Setting up Authenticated to true
//Setting Loading to False
const checkAuthOTP = (state, action) => {
  return updateObject(state, {
    otp: action.otp,
    email: action.email,
    isAuthenticated: action.isAuthenticated,
    loading: false,
  });
};
//Changing state post first level authentication completed
//This will change the state post userId and password validation
//Show component is being used for showing OTP screen on UI
//setting up error to blank
//Setting up email to user email Id
//Setting up showComponent to true
//Setting Loading to False
//Setting up token from action
const firstLevelAuthDone = (state, action) => {
  return updateObject(state, {
    error: "",
    showComponent: true,
    email: action.email,
    loading: false,
    token: action.token,
    isLogout: false,
    authRedirectPath: "/",
    message: action.message,
  });
};
//Changing state for logout
//This will change the state when user click on Logout
//Setting up token to null
//Setting up UserId to null
const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    authRedirectPath: "/",
    email: null,
    isAuthenticated: false,
    otp: null,
    isLogout: true,
  });
};

//Changing state for redirecting URL
//This will maintain the URL post authentication by updating the URL path in state
//Setting up authRedirectPath on the basis of URL from Action
const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const setFileUploadingState = (state, action) => {
  if(action.uploading){
    return updateObject(state, {
      uploading: action.uploading,
      fileUploadRes:null
    });
  }
  return updateObject(state, {
    uploading: action.uploading,
  });
};

const setFileProgressState = (state, action) => {
  return updateObject(state, {
    progress: action.progress,
  });
};


const setUploadEnvStatus= (state, action) => {
  return updateObject(state,{
    isAnnualMode:action.isAnnualMode,
    userData:{
      ...action.userData,
      isAnnualMode:action.isAnnualMode
    }
  });
}

const setInsertedSummaryState = (state, action) => {
  return updateObject(state, {
    uploadSuccessMessage: action.uploadSuccessMessage,
    uploadSummary: action.uploadSummary,
    showModal: action.showModal,
  });
};

//Changing state when notifications recieved
const setUserNotificationsEvents = (state, action) => {
  return updateObject(state, {
    userNotificationsEvents: action.notifications,
  });
};

const setRuncheckExecStatus = (state, action) => {
  return updateObject(state, {
    runCheckExecuting: action.runCheckExecuting,
  });
};

const setSavedQueryPlaylist = (state, action) => {
  return updateObject(state, {
    savedQueryPlaylist: action.savedQueryPlaylist,
  });
};

const setFileUploadResInDS = (state, action) => {
  return updateObject(state, {
    fileUploadRes: action.fileUploadRes,
  });
};

const setClearFileUploadResInDS = (state, action) => {
  return updateObject(state, {
    fileUploadRes: null,
  });
};

//Reducer selection on on the basis of action passed
//Based on Action a reducer get called and update the state based on action type passed
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.FIRST_LEVEL_AUTH_VALIDATED:
      return firstLevelAuthDone(state, action);
    case actionTypes.AUTH_CHECK_OTP:
      return checkAuthOTP(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.RESEND_OTP:
      return firstLevelAuthDone(state, action);
    case actionTypes.SUCCESS_TOAST:
      //toast.info(action.message);
      return state;
    case actionTypes.ERROR_TOAST:
      //toast.warn(action.message);
      return state;
    case actionTypes.CHECK_AUTH:
      return authSuccess(state, action);
    case actionTypes.CHECK_AUTH_ASYNC_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.CHECK_AUTH_ASYNC_FAILURE:
      return authFail(state, action);
    case actionTypes.FILE_UPLOADING:
      return setFileUploadingState(state, action);
    case actionTypes.PROGRESSING:
      return setFileProgressState(state, action); 
    case actionTypes.RUN_CHECK_EXEC:
      return setRuncheckExecStatus(state, action);
    case actionTypes.SAVED_QUERY_PLAYLIST:
      return setSavedQueryPlaylist(state, action);  
    case actionTypes.ANNUAL_MODE_CHANGE:
        return setUploadEnvStatus(state, action);   
    case actionTypes.SHOW_INSERT_SUMMARY:
      return setInsertedSummaryState(state, action);
    case actionTypes.FILE_UPLOADED:
      return setFileUploadResInDS(state, action);
    case actionTypes.DELETE_UPLOAD_RES:
      return setClearFileUploadResInDS(state, action);      
    case actionTypes.SHOW_TOAST:
      showToast(action.message, action.toastType);
      return state;
    case actionTypes.NOTIFICATIONS_EVENTS:
      return setUserNotificationsEvents(state, action);
    default:
      return state;
  }
};

export default reducer;
