import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import * as actions from "../../store/actions/index";
import { useDispatch } from "react-redux";

const PrivateRoute = ({
  component: Component,
  data: UserData,
  history,
  ...rest
}) => {
  const dispatch = useDispatch();

  React.useEffect(
    () =>
      history.listen(() => {
        if (sessionStorage.getItem("gems_userId")) {
          dispatch(actions.getUserNotificationsAndEvents());
        }
      }),
    []
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        sessionStorage.getItem("gems_userId") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
