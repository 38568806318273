import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import { Button } from "reactstrap";
import blue from "@material-ui/core/colors/blue";
import { authHeader } from "../commonUtility/auth-header";
import { FormErrors } from "../commonUtility/FormErrors";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import LoaderComponent from "../commonUtility/LoaderComponent";
import { Checkbox } from "@material-ui/core";
import {
  ACTION_USER_ADD,
  ACTION_USER_UPDATE,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
} from "../commonUtility/string-const";
import showAlert from "../commonUtility/confirmAlert";

import Select from "react-select";
import customStyles from "../commonUtility/ReactSelectCss";

const styles = (theme) => ({
  colorSwitchBase: {
    color: blue[400],
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[700],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class AdminUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      userRole: -1,
      institution: -1,
      role_id: null,
      isActive: true,
      institutionList: [],
      roleList: this.props.roleNonSAdmin,
      institutionListSpecific: this.props.institution,
      roleListSAdmin: this.props.roleSAdmin,
      roleListNonSAdmin: this.props.roleNonSAdmin,
      userPrivilegeLst: this.props.userPrivilege,
      userPrivilegeLstAll: this.props.userPrivilege,
      privilege: -1,
      validationError: null,
      firstNameValid: false,
      lastNameValid: false,
      usernameValid: false,
      instituionValid: false,
      roleValid: false,
      userPrivilegeValid: false,
      formValid: false,
      showLoader: false,
      formErrors: {
        firstName: "",
        lastName: "",
        username: "",
        userRole: "",
        institution: "",
        userPrivilegeErrors: "",
        error: "",
      },
      action: ACTION_USER_ADD,
      isEdit: false,
      adminRole: this.props.roleAdmin,
      noOfAdminByInst: this.props.noOfAdminByInst,
      instituteCodeWithAdminAllowed: this.props.instituteCodeWithAdminAllowed,
      memberRole: this.props.memberRole,
      multiple_admin_allowed: this.props.isMultipleAdminAllowed,
      superAdminPriv: this.props.superAdminPriv,
      focusFirstInput: true,
    };

    this.addEditUser = this.addEditUser.bind(this);
    this.isAdminAllowed = this.isAdminAllowed.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  handleChangeSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
    this.validateInstitutionRoleOnSelect(name, value);
  };

  handleUserSelect = (field, selectedOption) => {
    const name = field;
    const value = selectedOption;

    this.setState({ [name]: value });

    this.validateInstitutionRoleOnSelect(field, selectedOption.value);
  };

  validateForm() {
    this.setState({
      formValid:
        this.state.firstNameValid &&
        this.state.usernameValid &&
        this.state.instituionValid &&
        this.state.roleValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  validateAndSumit = (event) => {
    event.preventDefault();
    const regexName =
      "^[a-zA-Z\u00D8-\u00f6\u00f8-\u00ff]+[a-zA-Z\u00D8-\u00f6\u00f8-\u00ff,'-\\s]*$";
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.error = "";
    fieldValidationErrors.firstName = "";
    fieldValidationErrors.lastName = "";
    fieldValidationErrors.username = "";
    fieldValidationErrors.userRole = "";
    fieldValidationErrors.institution = "";
    fieldValidationErrors.userPrivilegeErrors = "";
    let firstNameValid = this.state.firstNameValid;

    let usernameValid = this.state.usernameValid;
    let instituionValid = this.state.instituionValid;
    let roleValid = this.state.roleValid;
    let userPrivilegeValid = this.state.userPrivilegeValid;

    if (this.state.firstName.trim().length == 0) {
      fieldValidationErrors.firstName = "";
      fieldValidationErrors.firstName = "Please enter Name";
      firstNameValid = false;
    }
    if (this.state.firstName.trim().length > 0) {
      let firstNameRegexValid = this.state.firstName.match(regexName);

      let firstNameLengthValid =
        this.state.firstName.trim().length > 0 &&
        this.state.firstName.trim().length <= 100;

      if (!firstNameRegexValid) {
        fieldValidationErrors.firstName = "Only alphabets with spaces allowed";
      }

      if (!firstNameLengthValid) {
        fieldValidationErrors.firstName =
          "Length can't be more than 100 characters";
      }

      firstNameValid = firstNameRegexValid && firstNameLengthValid;
    }

    if (this.state.username.trim().length == 0) {
      fieldValidationErrors.username = "";
      fieldValidationErrors.username = "Please enter Email";
      usernameValid = false;
    }
    if (
      this.state.username.trim().length > 0 &&
      this.state.username.trim().length <= 100
    ) {
      usernameValid = this.state.username.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      fieldValidationErrors.username = usernameValid
        ? ""
        : "Invalid email format.";
    }
    if (this.state.username.trim().length > 100) {
      fieldValidationErrors.username =
        "Length can't be more than 100 characters";
      usernameValid = false;
    }

    console.log("In if institure error ", this.state.institution.value);
    if (
      this.state.institution.value == null ||
      this.state.institution.value == -1
    ) {
      fieldValidationErrors.institution = "";
      fieldValidationErrors.institution = "Please select Institution";
      instituionValid = false;
    }
    if (
      this.state.institution.value != null &&
      this.state.institution.value != -1
    ) {
      instituionValid = true;
      fieldValidationErrors.institution = "";
    }

    console.log("userRole ", this.state.userRole.value);
    if (this.state.userRole.value == null || this.state.userRole.value == -1) {
      fieldValidationErrors.userRole = "";
      fieldValidationErrors.userRole = "Please select User Type";
      roleValid = false;
    }
    if (this.state.userRole.value != null && this.state.userRole.value != -1) {
      roleValid = true;
      fieldValidationErrors.userRole = "";
    }

    if (
      this.state.privilege.value == null ||
      this.state.privilege.value == -1
    ) {
      fieldValidationErrors.userPrivilegeErrors = "";
      fieldValidationErrors.userPrivilegeErrors = "Please select Privileges";
      roleValid = false;
    }
    if (
      this.state.privilege.value != null &&
      this.state.privilege.value != -1
    ) {
      userPrivilegeValid = true;
      fieldValidationErrors.userPrivilegeErrors = "";
      fieldValidationErrors.userPrivilegeValid = "";
    }

    this.setState({
      formErrors: fieldValidationErrors,
      firstNameValid: firstNameValid,

      usernameValid: usernameValid,
      instituionValid: instituionValid,
      userPrivilegeValid: userPrivilegeValid,
      roleValid: roleValid,
    });

    if (
      firstNameValid &&
      usernameValid &&
      instituionValid &&
      roleValid &&
      userPrivilegeValid
    ) {
      this.addEditUser();
    }
  };

  validateInstitutionRoleOnSelect(fieldName, fieldValue) {
    console.log("Admin Role ", fieldName, fieldValue);
    switch (fieldName) {
      case "institution":
        if (fieldValue == "111") {
          console.log("Admin Role ", this.state.roleListSAdmin);
          this.setState({
            roleList: [{ value: -1, display: "Select User Type" }].concat(
              this.state.roleListSAdmin
            ),
            userRole: -1,
            privilege: -1,
            userPrivilegeLst: [
              { value: -1, display: "(Select Privilege)" },
            ].concat(this.state.superAdminPriv),
          });
        } else {
          if (this.isAdminAllowed(fieldValue)) {
            console.log("Multiple admin allowed");
            this.setState({
              roleList: this.state.roleListNonSAdmin,
              userRole: -1,
              privilege: -1,
              userPrivilegeLst: this.state.userPrivilegeLstAll,
            });
          } else {
            console.log("Multiple admin not allowed");
            this.setState({
              roleList: [{ value: -1, display: "Select User Type" }].concat(
                this.state.memberRole
              ),
              userRole: -1,
              privilege: -1,
              userPrivilegeLst: this.state.userPrivilegeLstAll,
            });
          }
        }
        break;
      case "userRole":
        break;
    }
  }

  isAdminAllowed(code) {
    let hasNoAdmin = false;
    let isMultipleAdminAllowed = false;
    for (var i = 0; i < this.state.instituteCodeWithAdminAllowed.length; i++) {
      if (this.state.instituteCodeWithAdminAllowed[i].code == code) {
        if (
          this.state.instituteCodeWithAdminAllowed[i].multiple_admin_allowed ==
          true
        ) {
          isMultipleAdminAllowed = true;
        }
      }
    }
    if (isMultipleAdminAllowed) {
      return true;
    } else {
      let noOfAdminByInst = this.props.noOfAdminByInst;
      if (!(code in noOfAdminByInst)) {
        return true;
      }
      Object.keys(noOfAdminByInst).forEach(function (cd) {
        if (cd == code) {
          if (noOfAdminByInst[cd][0] === 0) {
            hasNoAdmin = true;
          }
        }
      });
      if (hasNoAdmin) {
        return true;
      } else {
        return false;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    console.log("Get Derived From Props called");
    console.log("from Props: ", props);
    console.log("from state: ", state);
    if (props.editInfo) {
      if (props.editInfo.username != state.username) {
        console.log("Inside");
        let edit_info = props.editInfo;
        return {
          firstName: edit_info.firstName,

          username: edit_info.username,
          institution: {
            value: edit_info.institution_code,
            label: state.institutionListSpecific.find((item) => {
              return item.value === edit_info.institution_code;
            }).display,
          },
          userRole: {
            value: edit_info.role_id,
            label: props.allRolelist.find((item) => {
              return item.value === edit_info.role_id;
            }).display,
          },
          isActive: edit_info.isActive == "Active" ? true : false,

          roleList:
            edit_info.institution_code === 111
              ? [{ value: -1, display: "Select User Type" }].concat(
                  state.roleListSAdmin
                )
              : props.isMultipleAdminAllowed
              ? state.roleListNonSAdmin
              : edit_info.role_id == 2
              ? state.roleListNonSAdmin
              : [{ value: -1, display: "Select Role" }].concat(
                  state.memberRole
                ),
          userPrivilegeLst:
            edit_info.institution_code === 111
              ? [{ value: -1, display: "(Select Privilege)" }].concat(
                  state.superAdminPriv
                )
              : state.userPrivilegeLstAll,
          action: ACTION_USER_UPDATE,
          isEdit: true,
          privilege: {
            value: edit_info.privilege_id,
            label: state.userPrivilegeLstAll.find((item) => {
              return item.value === edit_info.privilege_id;
            }).display,
          },
          formErrors: {
            firstName: "",
            lastName: "",
            username: "",
            userRole: "",
            institution: "",
            userPrivilegeErrors: "",
            error: "",
          },
          focusFirstInput: true,
        };
      }
    }
    return {
      focusFirstInput: false,
    };
  }

  componentDidUpdate() {
    console.log("Is update ", this.state.isEdit, "action ", this.state.action);
  }

  containsRole(roleId, roleList) {
    let ret_value = false;
    roleList.map((rid) => {
      if (roleId === rid.value) {
        ret_value = true;
      }
    });
    return ret_value;
  }

  componentDidMount() {}

  closeUserAddEditForm() {
    this.setState({ isEdit: false });
    this.props.closeUserFormCallback();
  }

  addEditUser() {
    let input = {
      firstName: this.state.firstName.trim(),

      username: this.state.username.trim().toString().toLowerCase(),
      role_id: this.state.userRole.value,
      isActive: this.state.isActive,
      institute_id: this.state.institution.value,
      privilege_id: this.state.privilege.value,
    };
    const config = {
      headers: {
        ...authHeader(),
      },
    };

    this.props.addEditUserLoaderCallback(true);
    console.log("In save user");
    console.log("Input ", input);
    var action = this.state.isEdit ? ACTION_USER_UPDATE : ACTION_USER_ADD;
    instance
      .post("/api/saveUser/" + action, input, config)
      .then((response) => {
        this.props.addEditUserLoaderCallback(false);
        if (response && response.data && response.data.error) {
          if (response.data.error == "false") {
            this.props.addedUserCallback(
              response.data.data,
              response.data.message,
              false,
              TOAST_TYPE_SUCCESS
            );
            this.setState({
              firstName: "",
              lastName: "",
              institution: -1,
              username: "",
              userRole: -1,
              privilege: -1,
              action: ACTION_USER_ADD,
              isActive: true,
              isEdit: false,
            });
          } else {
            this.props.addedUserCallback(
              null,
              response.data.message,
              true,
              TOAST_TYPE_ERROR
            );
          }
        } else {
          this.props.addedUserCallback(
            null,
            "An error occured.",
            true,
            TOAST_TYPE_ERROR
          );
        }
      })
      .catch((error) => {
        this.props.addEditUserLoaderCallback(false);

        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            this.props.addedUserCallback(
              null,
              "An error occured.",
              true,
              TOAST_TYPE_ERROR
            );
          }
        } else {
          this.props.addedUserCallback(
            null,
            "An error occured.",
            true,
            TOAST_TYPE_ERROR
          );
        }
      });
  }

  clearAll() {
    if (this.state.isEdit) {
      let edit_info = this.props.editInfo;
      this.setState({
        firstName: edit_info.firstName,
        username: edit_info.username,
        institution: {
          value: edit_info.institution_code,
          label: this.state.institutionListSpecific.find((item) => {
            return item.value === edit_info.institution_code;
          }).display,
        },
        userRole: {
          value: edit_info.role_id,
          label: this.props.allRolelist.find((item) => {
            return item.value === edit_info.role_id;
          }).display,
        },
        isActive: edit_info.isActive == "Active" ? true : false,

        action: ACTION_USER_UPDATE,
        isEdit: true,
        privilege: {
          value: edit_info.privilege_id,
          label: this.state.userPrivilegeLstAll.find((item) => {
            return item.value === edit_info.privilege_id;
          }).display,
        },
        formErrors: {
          firstName: "",
          lastName: "",
          username: "",
          userRole: "",
          institution: "",
          userPrivilegeErrors: "",
          error: "",
        },
      });
    } else {
      this.setState({
        firstName: "",
        lastName: "",
        username: "",
        userRole: -1,
        institution: -1,
        role_id: null,
        isActive: true,
        institutionList: [],
        roleList: this.props.roleNonSAdmin,
        institutionListSpecific: this.props.institution,
        roleListSAdmin: this.props.roleSAdmin,
        roleListNonSAdmin: this.props.roleNonSAdmin,
        userPrivilegeLst: this.props.userPrivilege,
        privilege: -1,
        validationError: null,
        firstNameValid: false,
        lastNameValid: false,
        usernameValid: false,
        instituionValid: false,
        roleValid: false,
        userPrivilegeValid: false,
        formValid: false,
        showLoader: false,
        formErrors: {
          firstName: "",
          lastName: "",
          username: "",
          userRole: "",
          institution: "",
          userPrivilegeErrors: "",
          error: "",
        },
        action: ACTION_USER_ADD,
        isEdit: false,
        adminRole: this.props.roleAdmin,
        noOfAdminByInst: this.props.noOfAdminByInst,
        instituteCodeWithAdminAllowed: this.props.instituteCodeWithAdminAllowed,
        memberRole: this.props.memberRole,
      });

      this.props.resetCallback();
    }
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");

      this.closeUserAddEditForm();
    }
  };

  filterOption = (option, inputValue) => {
    const { label, value } = option;

    if (value === -1) {
      return false;
    }
    return label
      .toLowerCase()
      .startsWith(inputValue.toLowerCase()) /* || otherKey.length > 0*/;
  };

  filterOptionUserType = (option, inputValue) => {
    const { label, value } = option;
    const otherKey = this.state.roleList.filter((opt) =>
      label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    return (
      label.toLowerCase().startsWith(inputValue.toLowerCase()) ||
      otherKey.length > 0
    );
  };

  filterOptionPriv = (option, inputValue) => {
    const { label, value } = option;
    const otherKey = this.state.userPrivilegeLst.filter((opt) =>
      label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    return (
      label.toLowerCase().startsWith(inputValue.toLowerCase()) ||
      otherKey.length > 0
    );
  };

  render() {
    const { classes } = this.props;
    console.log(
      "userForm ",
      this.state.instituteCodeWithAdminAllowed,
      this.props.noOfAdminByInst
    );
    return (
      <Paper className="edit-form-border">
        <div className="form-padd">
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={this.validateAndSumit}
          >
            <div className="w-100">
              <div className="row">
                <div className="col-md-3 col-sm-4">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.firstName
                    )}`}
                  >
                    <input
                      ref={(input) =>
                        this.state.focusFirstInput
                          ? input && input.focus()
                          : null
                      }
                      id="firstname"
                      name="firstName"
                      label="Name"
                      className={classes.textField + " w-100 form-control"}
                      type="Text"
                      value={this.state.firstName}
                      onChange={this.handleUserInput}
                      autoComplete="current-firstname"
                      margin="normal"
                      placeholder="Name"
                      autoComplete="off"
                      maxLength="100"
                      autoFocus={true}
                    />
                    <div className="error-text">
                      <FormErrors
                        formErrors={this.state.formErrors.firstName}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-4">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.username
                    )}`}
                  >
                    <input
                      id="username"
                      name="username"
                      label="Email Id"
                      className={classes.textField + " w-100 form-control"}
                      type="email"
                      value={this.state.username}
                      onChange={this.handleUserInput}
                      autoComplete="current-username"
                      margin="normal"
                      disabled={this.state.isEdit}
                      placeholder="Email"
                      autoComplete="off"
                      maxLength="100"
                    />
                    <div className="error-text">
                      <FormErrors formErrors={this.state.formErrors.username} />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.institution
                    )}`}
                  >
                    <Select
                      required
                      filterOption={this.filterOption}
                      name="institution"
                      id="institution"
                      value={this.state.institution}
                      onChange={this.handleUserSelect.bind(this, "institution")}
                      className="react-select"
                      placeholder="Select Institution"
                      isSearchable={true}
                      disabled={
                        this.state.institutionList.length == 2 /* ||
         this.state.isEdit*/
                      }
                      noOptionsMessage={() => "No Results"}
                      options={this.state.institutionListSpecific.map(
                        (team) => ({ value: team.value, label: team.display })
                      )}
                      classNamePrefix="react-select-menulist"
                      styles={customStyles}
                    />

                    <div className="error-text">
                      <FormErrors
                        formErrors={this.state.formErrors.institution}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.userRole
                    )}`}
                  >
                    <Select
                      required
                      name="userRole"
                      id="userrole"
                      value={this.state.userRole}
                      className="react-select"
                      placeholder="Select User Type"
                      onChange={this.handleUserSelect.bind(this, "userRole")}
                      options={this.state.roleList.map((team) => ({
                        value: team.value,
                        label: team.display,
                      }))}
                      classNamePrefix="react-select-menulist"
                      isSearchable={true}
                      filterOption={this.filterOption}
                      noOptionsMessage={() => "No Results"}
                      isDisabled={this.state.institution == "-1"}
                      styles={customStyles}
                    ></Select>
                    <div className="error-text">
                      <FormErrors formErrors={this.state.formErrors.userRole} />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.userPrivilegeErrors
                    )}`}
                  >
                    <Select
                      required
                      name="privilege"
                      id="privilege"
                      value={this.state.privilege}
                      className="react-select"
                      placeholder="Select Privilege"
                      onChange={this.handleUserSelect.bind(this, "privilege")}
                      options={this.state.userPrivilegeLst.map((team) => ({
                        value: team.value,
                        label: team.display,
                      }))}
                      classNamePrefix="react-select-menulist"
                      isSearchable={true}
                      filterOption={this.filterOption}
                      noOptionsMessage={() => "No Results"}
                      isDisabled={this.state.institution == "-1"}
                      styles={customStyles}
                    ></Select>
                    <div className="error-text">
                      <FormErrors
                        formErrors={this.state.formErrors.userPrivilegeErrors}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 form-controll-padd">
                  <div className="">
                    <input
                      id="active-1"
                      type="checkbox"
                      checked={this.state.isActive}
                      onChange={this.handleChangeSwitch("isActive")}
                      value="isActive"
                      label="Primary"
                    />
                    &nbsp;<label htmlFor="active-1">Active</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-auto mr-auto text-right">
                  <div className="form-group">
                    <button type="submit" className="btn btn-default site">
                      {this.state.isEdit ? "Update" : "Add User"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-cancel"
                      onClick={() => this.clearAll()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div
                onClick={() => this.closeUserAddEditForm()}
                tabIndex="0"
                className="cross-icon"
                onKeyPress={(event) => this.enterPressed(event)}
              >
                <span>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {},
    errorActionToast: (message) => {},
  };
};

AdminUserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  addedUserCallback: PropTypes.func,
  closeUserFormCallback: PropTypes.func,
  addEditUserLoaderCallback: PropTypes.func,
  resetCallback: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminUserForm));
