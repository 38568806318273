import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

import instance from "../../instance";
import AdminInstituteForm from "./instituteForm";
import { authHeader } from "../commonUtility/auth-header";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import showAlert from "../commonUtility/confirmAlert";
import { ToastContainer } from "react-toastify";
import { showToast } from "../commonUtility/NewToast";
import Icon from "@material-ui/core/Icon";
import {
  ERROR_OCCURED,
  ENABLED,
  DISABLED,
  ALLOWED,
  NOT_ALLOWED,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_INFO,
  TOAST_TYPE_ERROR,
} from "../commonUtility/string-const";
import MaterialTable, { MTableToolbar } from "material-table";
import LoaderComponent from "../commonUtility/LoaderComponent";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";

const AdminInstituteList = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#111",
    fontSize: 15,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserForm: false,
      institutionInfo: [],
      columns: [],

      institutionList: [],
      roleList: [],
      institutionListSpecific: [],
      roleListSpecific: [],
      userPrivilege: [],
      message: "",
      isFormOpen: false,
      adminRole: null,
      isLoading: false,
      tempInstitutionInfo: [],
      searchText: "",
      searchClass: "fa fa-search",
      clearClass: "fa fa-times",
      firstTymLoad: false,
      currentPageSize: 10,
      editDomainList: [],
    };
    this.showAdmin = this.showAdmin.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.startSearching = this.startSearching.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  componentDidUpdate() {
    if (Object.keys(this.state.institutionInfo).length == 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv != undefined) {
      pageDiv.hidden = flag;
    }
  }

  loadInstituteList() {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .get("/api/getIntituteListAll", config)
      .then((response) => {
        this.setState({ firstTymLoad: false });
        if (response != null && response.data != null) {
          let instituteList = response.data.institution.map((list, index) => {
            return {
              ...list,
              id: index + 1,
              upload_allowed: list.upload_allowed === true ? ENABLED : DISABLED,
              multiple_admin_allowed:
                list.multiple_admin_allowed === true ? ALLOWED : NOT_ALLOWED,
            };
          });
          this.setState({
            institutionInfo: instituteList,
            tempInstitutionInfo: instituteList,
          });
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  startSearching(e) {
    if (e != null) {
      e = e.target.value;
      this.setState({ searchText: e });
      if (e != null && e.toString().trim() != "") {
        let instLst = this.state.tempInstitutionInfo;
        let searchItems = [];
        for (var j = 0; j < instLst.length; j++) {
          for (var key in instLst[j]) {
            if (
              key != "tableData" &&
              key != "id" &&
              key != "code" &&
              instLst[j][key] != null
            ) {
              if (
                instLst[j][key]
                  .toString()
                  .toLowerCase()
                  .includes(e.toLowerCase())
              ) {
                searchItems.push(instLst[j]);
                break;
              }
            }
          }
        }
        console.log(searchItems);
        this.setState({ institutionInfo: searchItems });
      } else {
        this.setState({ institutionInfo: this.state.tempInstitutionInfo });
      }
    } else {
      this.setState({ institutionInfo: [] });
      this.setState({ institutionInfo: this.state.tempInstitutionInfo });
    }
  }

  componentDidMount() {
    this.setState({ firstTymLoad: true });
    this.loadInstituteList();
    let tempColumns = [
      {
        title: "Sr. No.",
        type: "numeric",
        field: "id",
        editable: "never",
        sorting: true,
        hidden: true,
      },
      {
        title: "Institution Name",
        field: "institute_name",
        type: "string",
        customSort: (a, b) =>
          a.institute_name.toLowerCase() < b.institute_name.toLowerCase()
            ? -1
            : 1,
      },
      {
        title: "Abbreviation",
        field: "abbreviation",
        type: "string",
        customSort: (a, b) =>
          a.abbreviation.toLowerCase() < b.abbreviation.toLowerCase() ? -1 : 1,
      },
      {
        title: "Domain",
        field: "domains",
        type: "string",
        customSort: (a, b) =>
          a.domains.toLowerCase() < b.domains.toLowerCase() ? -1 : 1,
        render: (rowData) => {
          let arrCommaSeparated = [];
          if (rowData.domains != null) {
            let arrRow = rowData.domains.split(",");
            for (var i = 0; i < arrRow.length; i++) {
              if (i == arrRow.length - 1) {
                arrCommaSeparated.push(arrRow[i]);
              } else {
                arrCommaSeparated.push(arrRow[i] + ", ");
              }
            }
          }

          return arrCommaSeparated;
        },
      },
      { title: "Data Submission", field: "upload_allowed", type: "string" },
      {
        title: "Multiple Admins",
        field: "multiple_admin_allowed",
        type: "string",
      },
    ];
    this.setState({ columns: tempColumns });
  }

  showAdmin() {
    this.setState({ showUserForm: true, editInfo: null, isFormOpen: true });
  }

  hasUserSelectedSorting() {
    let isActiveIndex = -1;
    var p = document.getElementsByClassName("tab-pane active")[0];
    p = p.getElementsByClassName("MuiTableSortLabel-root");
    for (var i = 0; i < p.length; i++) {
      if (p[i].getAttribute("class").indexOf("active") > 0) {
        isActiveIndex = i;
        break;
      }
    }
    return isActiveIndex;
  }

  instituteAdd(data, message, error, type) {
    this.setState({ searchText: "", firstTymLoad: true });
    this.startSearching(null);

    let foundIdex = -1;
    let tempInstituteData = [];
    if (!error) {
      let instituteData = {
        code: data.code,
        abbreviation: data.abbreviation,
        institute_name: data.name,
        domains: data.domains,
        upload_allowed: data.uploadAllowed ? ENABLED : DISABLED,
        multiple_admin_allowed: data.multipleAdmin ? ALLOWED : NOT_ALLOWED,
      };
      console.log(
        "Institute add ",
        instituteData,
        " ",
        data,
        " ",
        this.state.institutionInfo
      );
      for (var i = 0; i < this.state.institutionInfo.length; i++) {
        if (this.state.institutionInfo[i].code === instituteData.code) {
          foundIdex = i;
          break;
        }
      }
      console.log("Found index ", foundIdex);
      if (foundIdex != -1) {
        instituteData["id"] = foundIdex + 1;
        tempInstituteData = this.state.institutionInfo;
        tempInstituteData.splice(foundIdex, 1, instituteData);
        this.setState({ institutionInfo: tempInstituteData, editInfo: null });
      } else {
        instituteData["id"] = 1;

        this.state.institutionInfo.map((inst) => {
          inst.id = inst.id + 1;
        });
        console.log(this.state.institutionInfo);
        this.state.institutionInfo.unshift(instituteData);
        this.setState({
          institutionInfo: this.state.institutionInfo,
          editInfo: null,
        });
      }
    }
    showToast(message, type);
    this.setState({ firstTymLoad: false });
  }

  enterEdit(dataItem) {
    if (dataItem.domains == "") {
      dataItem.domains = null;
    }
    let _domains = [];
    if (dataItem.domains != null && dataItem.domains.split(",").length > 0) {
      let domainsArray = dataItem.domains.split(",");
      for (var i = 0; i < domainsArray.length; i++) {
        _domains.push({ name: domainsArray[i], id: domainsArray[i] });
      }
    }
    dataItem["editDomainList"] = _domains;

    console.log("Enter edit institution", dataItem);
    this.setState({
      editInfo: dataItem,
      showUserForm: true,
      isFormOpen: true,
      editDomainList: _domains,
    });
    window.scrollTo(0, 0);
  }

  confirmResetPwd(row) {
    showAlert(
      "Confirm!",
      "Are you sure you want to reset password for " + row.username + " ?",
      [
        {
          label: "Yes",
          onClick: () => this.resetPwd(row),
        },
        {
          label: "No",
        },
      ]
    );
  }

  closeUserForm() {
    this.setState({ showUserForm: false, editInfo: null, isFormOpen: false });
  }

  clearAll() {
    this.setState({ editInfo: null });
  }

  resetPwd(row) {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };
    let reset = true;
    let data = {
      username: row.username,
    };
    instance
      .put("/api/changePassword/" + reset, data, config)
      .then((response) => {
        console.log("password change ", response);
        if (
          response.data &&
          response.data.error &&
          response.data.error == "false"
        ) {
          this.props.passMessage(response.data.message, TOAST_TYPE_SUCCESS);
        } else {
          if (response.data && response.data.message)
            this.props.passMessage(response.data.message, TOAST_TYPE_INFO);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            this.props.passMessage(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          this.props.passMessage(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  searchIconClick(clear) {
    if (clear) {
      this.setState({ searchText: "" });
      this.startSearching(null);
    }
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");
      this.searchIconClick(this.state.searchText.trim().length > 0);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.showUserForm ? (
          <AdminInstituteForm
            addedInstituteCallback={this.instituteAdd.bind(this)}
            editInfo={this.state.editInfo}
            closeUserFormCallback={this.closeUserForm.bind(this)}
            resetCallback={this.clearAll.bind(this)}
            addEditInstituteLoaderCallback={this.startLoader.bind(this)}
          />
        ) : null}
        <div>
          <Paper className={classes.root + " admin-user-list"}>
            {/* <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
/> */}
            <div className="top-bar">
              {this.state.isLoading ? <LoaderComponent /> : null}
              {this.state.firstTymLoad ? (
                <FullScreenLoaderComponent msg="" />
              ) : null}
              {this.state.firstTymLoad ? null : (
                <div className="row">
                  <div className="col-auto align-self-center">
                    <div className="search-bar">
                      <div id="custom-search-input">
                        <div className="input-group">
                          <input
                            type="text"
                            className="search-query form-control"
                            placeholder="Search"
                            onChange={this.startSearching}
                            value={this.state.searchText}
                            tabIndex="0"
                          />
                          <span className="input-group-btn" tabIndex="-1">
                            <button
                              className="btn btn-danger"
                              type="button"
                              tabIndex="-1"
                            >
                              <i
                                className={
                                  this.state.searchText.trim().length > 0
                                    ? this.state.clearClass
                                    : this.state.searchClass
                                }
                                aria-hidden="true"
                                onClick={() =>
                                  this.searchIconClick(
                                    this.state.searchText.trim().length > 0
                                  )
                                }
                                onKeyUp={(event) => this.enterPressed(event)}
                                tabIndex={
                                  this.state.searchText.trim().length > 0
                                    ? "0"
                                    : "-1"
                                }
                              ></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto ml-auto align-self-center">
                    {this.state.isFormOpen ? null : (
                      <div
                        aria-label="Add"
                        className="addbutton"
                        onClick={this.showAdmin}
                      >
                        {/* <Icon>add_box</Icon> */}
                        <button className="btn btn-default">
                          Add Institution
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {this.state.firstTymLoad ? null : (
              <MaterialTable
                columns={this.state.columns}
                data={this.state.institutionInfo}
                onChangeRowsPerPage={(pageSize) => {
                  this.setState({ currentPageSize: pageSize });
                  let timesRun = 0;
                  let firstTimeExecuted = false;
                  var hasVScroll =
                    window.innerHeight /* + window.scrollY*/ <=
                    document.body.offsetHeight;
                  this.interval = setInterval(() => {
                    if (timesRun == 5) {
                      clearInterval(this.interval);
                    }
                    timesRun++;
                    console.log("service ", hasVScroll);
                    if (hasVScroll) {
                      window.scrollTo(0, 0);
                      clearInterval(this.interval);
                    } else {
                      if (firstTimeExecuted) {
                        clearInterval(this.interval);
                      }
                      firstTimeExecuted = true;
                    }
                  }, 1);
                }}
                options={{
                  showTitle: false,
                  searchFieldAlignment: "left",
                  actionsColumnIndex: -1,
                  pageSize: this.state.currentPageSize,
                  pageSizeOptions: [10, 20, 25],
                  search: false,
                  emptyRowsWhenPaging: false,
                  draggable: false,
                  thirdSortClick: false,
                  sorting: this.state.institutionInfo.length > 0,
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => this.enterEdit(rowData),
                  },
                ]}
              />
            )}
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {},
    errorActionToast: (message) => {},
  };
};

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  passMessage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomizedTable));
