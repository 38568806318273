import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import Footer from "../commonUtility/Footer";
import { Container, Row, Col } from "reactstrap";
import LibList from "./libList";
import ScrollUpButton from "react-scroll-up-button";

class Library extends Component {
  render() {
    return (
      <div className="Library">
        <Navigation />
        <MainHeading />
        <Container>
          <Row>
            <Col sm="12">
              <LibList />
            </Col>
          </Row>
        </Container>
        <ScrollUpButton ShowAtPosition={20} className="scrolltopbutton" />
        <Footer />
      </div>
    );
  }
}

export default Library;
