import React from "react";
import { Button } from "reactstrap";
import Accordion from "./accordion";
import classnames from "classnames";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import showAlert from "../commonUtility/confirmAlert";
import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  REQUEST_CHECK_MY_DATA,
  TOAST_TYPE_INFO,
  TOAST_TYPE_ERROR,
  UPLOAD_DISABLED_TITLE,
  AUDIT_CHK_MY_DATA,
} from "../commonUtility/string-const";
import { ToastContainer } from "react-toastify";
import { callUserDetails } from "../../store/actions/index";
import { connect } from "react-redux";
import LoaderComponent from "../commonUtility/LoaderComponent";
import PopupModal from "../commonUtility/PopupModal";
import { getUserNotificationsAndEvents } from "../../store/actions/index";
import * as actions from "../../store/actions/index";
import { recordForAuditTrail } from "../commonUtility/method-utils";
class GenerateSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      response: null,
      isUnauthorized: false,
      isLoading: false,
      displayCheckMyDataConfirmModal: false,
      displayUploadDsbldPopup: false,
      uploadDsbldTitle: null,
      uploadDsbldMsg: null,
    };
    this.sendDataforReview = this.sendDataforReview.bind(this);
    this.confirmCheckMyData = this.confirmCheckMyData.bind(this);
    this.generateSummary = this.generateSummary.bind(this);
    this.closeCheckMyDataModal = this.closeCheckMyDataModal.bind(this);
  }

  componentDidMount() {
    if (this.props.errormap != null) {
      this.setState({ response: this.props.errormap });
    } else {
      let runChk = sessionStorage.getItem("runcheck_summary");
      if (runChk != null) {
        runChk = JSON.parse(runChk);
        this.setState({ response: runChk });
      }
    }
  }

  generateSummary() {
    this.setState({ isLoading: true });
    instance({
      url: "/data/upload/generateSummary",
      method: "GET",
      responseType: "blob",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        this.setState({ isLoading: false });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ErrorSummary.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
  }

  sendDataforReview() {
    console.log("sendDataforReview");
    this.setState({ isLoading: true });
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    var action = REQUEST_CHECK_MY_DATA;
    instance
      .post("api/review/" + action, null, config)
      .then((response) => {
        this.setState({ isLoading: false });
        if (
          response &&
          response.data &&
          response.data.error &&
          response.data.error == "false"
        ) {
          showToast(response.data.message, TOAST_TYPE_INFO);
          this.props.callUserDetails();
          this.props.getUserNotificationsAndEvents();

          recordForAuditTrail(AUDIT_CHK_MY_DATA);
        } else {
          if (
            response.data.upload_allowed != null &&
            response.data.upload_allowed == false
          ) {
            this.setState({
              displayUploadDsbldPopup: true,
              uploadDsbldTitle: UPLOAD_DISABLED_TITLE,
              uploadDsbldMsg: response.data.message,
            });
          } else {
            if (
              response &&
              response.data &&
              response.data.error &&
              response.data.error == "true"
            ) {
              showToast(response.data.message, TOAST_TYPE_ERROR);
            } else {
              showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
            }
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  confirmCheckMyData() {
    this.setState({ displayCheckMyDataConfirmModal: true });
  }

  isSummaryButtonHidden(errMap) {
    let hidden = false;
    if (errMap != undefined && errMap.errorMap != undefined) {
      Object.keys(errMap.errorMap).forEach((k) => {
        if (k.charAt(0) == "5") {
          hidden = true;
        }
      });
    }
    return hidden;
  }

  closeCheckMyDataModal() {
    this.setState({ displayCheckMyDataConfirmModal: false });
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    var exceptionModel = null;
    if (this.state.response != null) {
      exceptionModel = (
        <div className="comm-section mt-3">
          {this.state.displayUploadDsbldPopup ? (
            <PopupModal
              modalSize="md"
              title={this.state.uploadDsbldTitle}
              message={this.state.uploadDsbldMsg}
              yesClickCallback={this.refreshPage.bind(this)}
              cancelClickCallback={this.refreshPage.bind(this)}
              buttonPositive="OK"
            />
          ) : null}
          {this.state.displayCheckMyDataConfirmModal ? (
            <PopupModal
              modalSize="md"
              title="Confirm!"
              message="Do you want to submit the data for review?"
              yesClickCallback={this.sendDataforReview}
              noClickCallback={this.closeCheckMyDataModal}
              cancelClickCallback={this.closeCheckMyDataModal}
              buttonPositive="Yes"
              buttonNegative="No"
            />
          ) : null}
          {this.state.isLoading ? <LoaderComponent /> : null}
          <div className="d-flex">
            <div className="p-2 flex-grow-1"></div>
            <div className="">
              {this.props.userData &&
              this.props.userData.userRole &&
              this.props.userData.userRole != 1 ? (
                <Button
                  className="btn-default site"
                  onClick={this.confirmCheckMyData}
                  hidden={
                    this.props.userData.upload_allowed === false ||
                    this.props.userData.privilege_id == 3
                  }
                >
                  Check My Data
                </Button>
              ) : null}
            </div>
            <div className="">
              <Button
                color="default"
                onClick={this.generateSummary}
                hidden={this.isSummaryButtonHidden(this.props.errormap)}
              >
                Generate Summary
              </Button>
            </div>
          </div>
          <Accordion expMap={this.props.errormap} />
          <div></div>
        </div>
      );
    }
    return exceptionModel;
  }
}

const mapStateToProps = (state) => {
  console.log("generate summary Props received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserNotificationsAndEvents: () => {
      dispatch(actions.getUserNotificationsAndEvents());
    },
    callUserDetails: () => {
      dispatch(actions.callUserDetails());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSummary);
