import React from "react";
import PubSub from "pubsub-js";
import _ from "lodash";

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      required: false,
    };
  }

  componentDidMount() {
    PubSub.subscribe("TRIGGER_ROW_VALIDATION", this.runValidation);
    this.setState({
      required: this.props.required,
    });
    this.checkForRequiredConditions();
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.runValidation);
  }

  checkForRequiredConditions() {
    console.log(this);
    if (this.props.name === "cod_region_ctp") {
      if (
        this.props.rowProps !== undefined &&
        this.props.rowProps.rowData !== undefined &&
        this.props.rowProps.rowData.cod_country_ctp !== undefined &&
        this.props.rowProps.rowData.cod_country_ctp.toLowerCase() === "reg"
      ) {
        this.setState({
          required: true,
        });
      } else {
        this.setState({
          required: false,
        });
      }
    }
    if (this.props.name === "cod_region") {
      if (
        this.props.rowProps !== undefined &&
        this.props.rowProps.rowData !== undefined &&
        this.props.rowProps.rowData.cod_country !== undefined &&
        this.props.rowProps.rowData.cod_country.toLowerCase() === "reg"
      ) {
        this.setState({
          required: true,
        });
      } else {
        this.setState({
          required: false,
        });
      }
    }
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps, this.props)) {
      this.checkForRequiredConditions();
    }
  }

  runValidation = () => {
    if (
      this.state.required &&
      (this.props.value === "" ||
        this.props.value === null ||
        this.props.value === undefined)
    ) {
      this.setState({
        error: true,
        errorMessage: "Please enter " + this.props.name,
      });
    }
  };

  handleChange(e) {
    this.setState({ error: false, errorMessage: "" });
    let value = e.target.value;
    if (this.props.name === "currency_type") {
      value = parseInt(e.target.value);
    }
    this.props.onChange(value);
  }

  render() {
    let optionsToRender = [];
    this.props.options.forEach((items) => {
      optionsToRender.push(
        <option key={items.value} value={items.value}>
          {items.label}
        </option>
      );
    });
    if (
      this.props.value !== undefined &&
      this.props.value !== null &&
      this.props.options.filter((item) => item.value === this.props.value)
        .length === 0
    ) {
      optionsToRender.push(
        <option
          key={this.props.value}
          value={this.props.value}
          className="display-none"
        >
          {this.props.value}
        </option>
      );
    }
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <select
              name={this.props.name}
              className="form-control"
              value={
                this.props.value === undefined || this.props.value === null
                  ? ""
                  : this.props.value
              }
              onChange={this.handleChange.bind(this)}
              autoFocus={this.props.autoFocus === undefined ? false : true}
            >
              <option value="" disabled={this.state.required}>
                {this.state.required ? this.props.name + "*" : this.props.name}
              </option>

              {optionsToRender}
            </select>
          </div>
          <span
            className={this.state.error ? "errordisplay" : "errornotdisplay"}
          >
            <div className="formErrors">
              <div className="alert-warning">
                {this.state.error && this.state.required
                  ? this.state.errorMessage
                  : ""}
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }
}
