import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import Footer from "../commonUtility/Footer";
import compute from "./../../images/compute.jpg";
import ComputeMain from "./Compute";
import { authHeader } from "../commonUtility/auth-header";
import instance from "../../instance";
import ScrollUpButton from "react-scroll-up-button";

class Compute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataset_year: -1,
      active_year_from: -1,
      active_year_to: -1,
      isWorkEnvironment: true,
      memberInstititute: [],
      sector: [],
      subIndustry: [],
      region: [],
      country: [],
      crossDefaultClause_cprt: [],
      crossDefaultClause_cntrct: [],
      category: [],
      include_contagion: true,
      currencyType: [],
      loanType: [],
      originalAmountLoan: [],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="compute">
        <Navigation />
        <MainHeading />
        <div className="container">
          <ComputeMain />
          <ScrollUpButton ShowAtPosition={20} className="scrolltopbutton" />
        </div>

        <Footer />
      </div>
    );
  }
}

export default Compute;
