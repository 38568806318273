import React from "react";
import { Table } from "reactstrap";
import { toLocaleStringWithDecimal } from "../commonUtility/method-utils";

const StaticCohort = (props) => {
  let tableHeader = (
    <thead>
      <tr>
        {Object.keys(props.static_cohort.rate[0]).map((key) => {
          return <th className="cohort-th" key={key}>{key}</th>;
        })}
      </tr>
    </thead>
  );

  let tableBody = (
    <tbody>
      {Object.keys(props.static_cohort.rate).map((index) => {
        if (parseInt(props.static_cohort.rate[index]["Year"]) > 1993) {
          return (
            <tr key={index}>
              <td>{props.static_cohort.rate[index]["Year"]}</td>
              <td>
                {props.static_cohort.rate[index]["Portfolio default rate"] ==
                  null ||
                props.static_cohort.rate[index]["Portfolio default rate"] == ""
                  ? "n.a."
                  : props.static_cohort.rate[index]["Portfolio default rate"] +
                    "%"}
              </td>
              <td>
                {toLocaleStringWithDecimal(
                  props.static_cohort.rate[index][
                    "Number of counterparts"
                  ].toString()
                )}
              </td>
            </tr>
          );
        }
      })}
    </tbody>
  );
  return (
    <div className="cohort-table">
      <Table responsive>
        {tableHeader}
        {tableBody}
        <tbody>
        <tr className="bod-cohort">
          <td>
            <b>
              Average
              <br /> St. Deviation
            </b>
          </td>
          <td>
            <b>
              {props.static_cohort.averageStatic + "%"}
              <br />
              {props.static_cohort.stdDevStatic}
            </b>
          </td>
          <td>&nbsp;</td>
        </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default StaticCohort;
