import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

class RatingDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  state = {
    isOpen: this.props.isOpen,
  };

  handleClose() {
    this.setState({ isOpen: false });
    this.props.toggleCallback(false);
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={(e) => this.handleClose()}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            {this.props.status}
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              {Object.keys(this.props.ids).map((i) => {
                return (
                  <div key={this.props.id + "-" + this.props.ids[i]}>
                    {this.props.ids[i]}
                  </div>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.handleClose()} color="primary">
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RatingDetailDialog.propsType = {
  toggleCallback: PropTypes.func,
};

export default RatingDetailDialog;
