import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Redirect } from "react-router-dom";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogout: false,
    };
  }

  componentDidMount() {
    var b_token = sessionStorage.getItem("gems_bearer_token");
    sessionStorage.clear();
    this.props.onLogout(b_token);
  }

  render() {
    if (this.props.isLogout) {
      console.log("rende cakke ", sessionStorage.getItem("gems_bearer_token"));
      return <Redirect push to={this.props.authRedirectPath} />;
    }
    return "";
  }
}

const mapStateToProps = (state) => {
  console.log("Props received ", sessionStorage.getItem("gems_bearer_token"));
  return {
    isLogout: state.auth.isLogout,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (b_token) => {
      dispatch(actions.logout(b_token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
