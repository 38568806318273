import React, { Component } from "react";
import RRResultTable from "./RRResultTable";
import _ from "lodash";

class RRResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rrData: this.props.rrMapData.rrMaps,
      rrParams: this.props.rrMapData.params,
    };
  }

  componentDidUpdate(oldProps) {
    if (
      !_.isEqual(oldProps.rrMapData, this.props.rrMapData) &&
      this.props.rrMapData !== undefined
    ) {
      this.setState({
        rrData: this.props.rrMapData.rrMaps,
        rrParams: this.props.rrMapData.params,
      });
    }
  }

  render() {
    if (this.state.rrData !== undefined) {
      return (
        <div className="rr-table">
          <RRResultTable
            rrData={this.state.rrData}
            rrParams={this.state.rrParams}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default RRResult;
