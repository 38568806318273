import React, { Component } from "react";
import { Card, Button, CardBody } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Timer from "react-compound-timer";
class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      token: this.props.token,
      isAuthenticated: false,
      disableOTPSubmit: true,
      displayPopup: false,
      resendClicked: false,
      timerStarted: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("Rec ", nextProps.dsplyModel);
    this.setState({ displayPopup: nextProps.dsplyModel });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value, displayPopup: false });
    let otpValid = this.isOTPValid(value);
    if (otpValid) {
      this.setState({ disableOTPSubmit: false });
    } else {
      this.setState({ disableOTPSubmit: true });
    }
  };

  handleResendOTP = (e) => {
    document.getElementById("form-otp").reset();
    this.setState({
      resendClicked: true,
      timerStarted: true,
      displayPopup: false,
    });
    this.props.resendOTPClicked();
  };

  isOTPValid(value) {
    let otpValid = false;
    if (value.trim().length == 6) {
      otpValid = value.length == 6;
      return otpValid;
    }
    return otpValid;
  }

  cancelLoginProcess = (e) => {
    this.props.hideOTPComponent(true);
  };

  handleSubmitOTP = (event) => {
    event.preventDefault();
    this.props.onOTPSubmit(
      this.props.email,
      this.state.otp,
      this.state.isAuthenticated
    );
  };
  componentDidCatch(error, info) {
    console.log("Error " + error);
    console.log("info " + info);
  }
  render() {
    if (this.props.isAuthenticated) {
      return <Redirect push to="/" />;
    }
    var timer = (
      <Timer
        initialTime={60000}
        direction="backward"
        checkpoints={[
          {
            time: 0,
            callback: () =>
              this.setState({ timerStarted: false, displayPopup: false }),
          },
        ]}
      >
        {() => (
          <React.Fragment>
            <div className="timer-opt">
              00 : <Timer.Seconds />
            </div>
          </React.Fragment>
        )}
      </Timer>
    );
    return (
      <form className="" id="form-otp" onSubmit={this.handleSubmitOTP}>
        <Card className="otp-section">
          <CardBody className="p-0">
            <div className="d-flex flex-row">
              <div className="p-2">
                <input
                  type="text"
                  onChange={this.handleUserInput}
                  required
                  className="form-control otp-input"
                  name="otp"
                  autoComplete="off"
                  placeholder="OTP"
                />
              </div>
              <div className="p-2">
                <Button
                  type="submit"
                  className="btn btn-default otp-btn-submit"
                  disabled={this.state.disableOTPSubmit}
                >
                  Submit
                </Button>
              </div>
              <div className="p-2">
                {this.state.timerStarted ? (
                  timer
                ) : (
                  <Button
                    className="btn btn-default otp-btn"
                    onClick={this.handleResendOTP}
                  >
                    Resend OTP
                  </Button>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("mapStateToProps : " + state.isAuthenticated);
  return {
    email: state.auth.email,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOTPSubmit: (email, otp, isAuthenticated) => {
      console.log("mapDispatchToProps : " + isAuthenticated);
      dispatch(actions.checkAuthOTP(email, otp, isAuthenticated));
    },
  };
};

Otp.propTypes = {
  hideOTPComponent: PropTypes.func,
  resendOTPClicked: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
