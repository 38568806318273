import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import { connect } from "react-redux";
import { showToast } from "../commonUtility/NewToast";
import { ERROR_OCCURED, TOAST_TYPE_ERROR } from "../commonUtility/string-const";
import LoaderComponent from "../commonUtility/LoaderComponent";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import Select from "react-select";
import customStyles from "../commonUtility/ReactSelectCss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";
import MaterialTable from "material-table";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

/**
 * Patch for showing the select popup above other containers
 */

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class CustomInput extends React.Component {
  render() {
    return (
      <div className="custom-datepicker">
        <input
          className="w-100 form-control date-contol"
          value={this.props.value}
          type="text"
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          onKeyDown={() => {
            return false;
          }}
          onChange={() => {
            return false;
          }}
        />
        <i
          onClick={this.props.onClick}
          aria-hidden="true"
          className="fa fa-calendar"
        />
        <i
          onClick={this.props.clearCalender}
          aria-hidden="true"
          className="fa fa-times"
          hidden={this.props.hidden}
        />
      </div>
    );
  }
}

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

class ExchangeRateInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyFilterList: [],
      currencyFilterListSelected: [],
      selectedDate: "",
      currencyDataList: [],

      columns: [],
      isLoading: false,
      firstTymLoad: false,
      currentPageSize: 10,
      maxDate: new Date(),
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.filterChangeCurrency = this.filterChangeCurrency.bind(this);
    this.clearCalender = this.clearCalender.bind(this);
    this.loadTableColumns = this.loadTableColumns.bind(this);
    this.getOldSavedExchangeFilter = this.getOldSavedExchangeFilter.bind(this);
  }

  handleChangeDate(dateInStr) {
    this.setState({ selectedDate: dateInStr });
  }

  filterChangeCurrency(currencyFilterListSelected) {
    this.setState({ currencyFilterListSelected: currencyFilterListSelected });
  }

  componentDidUpdate() {
    if (
      this.state.currencyDataList == null ||
      this.state.currencyDataList.length === 0
    ) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv !== undefined) {
      pageDiv.hidden = flag;
    }
  }

  clearCalender() {
    this.setState({ selectedDate: "" });
  }

  loadTableColumns() {
    let tempColumns = [
      {
        title: "Date",
        type: "date",
        field: "date",
        editable: "never",
        sorting: true,
        hidden: false,
        customSort: (a, b) =>
          moment(a.date, "DD-MM-YYYY").toDate() <
          moment(b.date, "DD-MM-YYYY").toDate()
            ? -1
            : 1,
      },
      {
        title: "Currency",
        type: "string",
        field: "currency",
        editable: "never",
        sorting: true,
        hidden: false,
      },
      {
        title: "Rate",
        field: "rate",
        type: "numeric",
        editable: "never",
        sorting: true,
      },
    ];
    this.setState({ columns: tempColumns });
  }

  componentDidMount() {
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() - 1);
    this.setState({ firstTymLoad: true, maxDate: maxDate });
    this.getCurrencyData();
  }

  getOldSavedExchangeFilter() {
    let exchangeDtoNoSerial = sessionStorage.getItem("xchangeFilter");
    if (exchangeDtoNoSerial !== null) {
      let exchangeDtoJson = JSON.parse(exchangeDtoNoSerial);
      if (exchangeDtoJson !== null && exchangeDtoJson.currency !== null) {
        if (exchangeDtoJson.date !== "") {
          this.fetchCurrencyRecords(
            new Date(exchangeDtoJson.date),
            exchangeDtoJson.days,
            exchangeDtoJson.currency,
            false
          );
          this.setState({
            selectedDate: new Date(exchangeDtoJson.date),
            currencyFilterListSelected: exchangeDtoJson.currency,
          });
        } else {
          this.fetchCurrencyRecords(
            exchangeDtoJson.date,
            exchangeDtoJson.days,
            exchangeDtoJson.currency,
            false
          );
          this.setState({
            currencyFilterListSelected: exchangeDtoJson.currency,
          });
        }
      }
    }
  }

  getCurrencyData() {
    this.loadTableColumns();
    const config = {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
    };
    instance
      .get("/infopage/currencyList", config)
      .then((response) => {
        this.setState({ firstTymLoad: false });
        if (response.data != null && response.data.error === false) {
          let optSelect = { label: "Select", value: "*" };
          let currencyFilterList = Object.keys(response.data.cList).map((c) => {
            return {
              label: response.data.cList[c],
              value: response.data.cList[c],
            };
          });
          currencyFilterList.unshift(optSelect);
          this.setState({
            currencyFilterList: currencyFilterList,
            firstTymLoad: false,
            currencyFilterListSelected: optSelect,
          });
          this.getOldSavedExchangeFilter();
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          this.setState({ firstTymLoad: false });
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  fetchCurrencyRecords(date, days, currency, isNew) {
    if (isNew && date !== "") {
      days = moment(date).diff("1970-01-01", "days");
    }

    this.setState({ firstTymLoad: true });
    let exchangeDto = {
      date: days,
      currency: currency.value,
    };
    const config = {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
    };
    if (isNew) {
      sessionStorage.removeItem("xchangeFilter");
      sessionStorage.setItem(
        "xchangeFilter",
        JSON.stringify({ days: days, currency: currency, date: date })
      );
    }
    instance
      .post("/infopage/exchangeRate", exchangeDto, config)
      .then((response) => {
        this.setState({ firstTymLoad: false });
        if (response.data != null && response.data.currencyMap != null) {
          let currencyDataList = [];
          if (Object.keys(response.data.currencyMap).length > 0) {
            currencyDataList = Object.keys(response.data.currencyMap).map(
              (cm) => {
                return {
                  date: cm,
                  currency: response.data.currency,
                  rate: response.data.currencyMap[cm],
                };
              }
            );
          } else {
            currencyDataList = [];
            if (response.data.recentRate != null) {
              currencyDataList.push({
                date: moment(date).format("DD-MM-YYYY"),
                currency: response.data.currency,
                rate: response.data.recentRate,
              });
            }
          }
          this.setState({ currencyDataList: currencyDataList });
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <div className="exchangerateinfo">
            <Paper className={classes.root + " event-list mt-2"}>
              {this.state.isLoading ? <LoaderComponent /> : null}
              {this.state.firstTymLoad ? (
                <FullScreenLoaderComponent msg="" />
              ) : null}
              {this.state.firstTymLoad ? null : (
                <div className="top-bar ">
                  <div className="row">
                    <div className="col-auto">
                      <div className="form-group p-0">
                        <div className="row">
                          <div className="col-auto">
                            <div className="mt-1">Date to Retrieve</div>
                          </div>
                          <div className="col-auto">
                            {/* {ReactDatePicker} */}
                            <DatePicker
                              selected={this.state.selectedDate}
                              onChange={this.handleChangeDate.bind(this)}
                              value={this.state.selectedDate}
                              dateFormat="dd-MM-yyyy"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              customInput={
                                <CustomInput
                                  clearCalender={this.clearCalender.bind(this)}
                                  hidden={
                                    this.state.selectedDate == null ||
                                    this.state.selectedDate.length === 0
                                  }
                                />
                              }
                              popperContainer={CalendarContainer}
                              placeholderText="Date"
                              maxDate={this.state.maxDate}
                            />
                          </div>
                        </div>

                        <div className="exchange-help">
                          <div>
                            Note: Currency exchange rates are available till{" "}
                            {moment(this.state.maxDate).format("DD-MM-YYYY")}
                          </div>
                        </div>

                        {/* <div className="error-text">
                  <div class="alert-warning mt-2">Currency exchange rates are available till 01-11-2020</div>
                </div> */}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="form-group p-0">
                        <div className="row">
                          <div className="col-auto">
                            <div className="mt-1">Currency</div>
                          </div>
                          <div className="col-auto">
                            <Select
                              required
                              name="currencyFilterList"
                              id="currencyFilterList"
                              value={this.state.currencyFilterListSelected}
                              filterOption={this.filterOption}
                              onChange={(e) => this.filterChangeCurrency(e)}
                              className="react-select"
                              isSearchable={true}
                              noOptionsMessage={() => "No Results"}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              options={this.state.currencyFilterList}
                              classNamePrefix="react-select-menulist annaul-mode"
                            />
                          </div>
                        </div>
                        <div className="error-text">
                          {/* <FormErrors  /> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-auto pt3px">
                      <button
                        className="btn btn-default"
                        disabled={
                          this.state.currencyFilterListSelected == null ||
                          this.state.currencyFilterListSelected.value === "*"
                        }
                        onClick={(e) =>
                          this.fetchCurrencyRecords(
                            this.state.selectedDate,
                            null,
                            this.state.currencyFilterListSelected,
                            true
                          )
                        }
                      >
                        Get Information
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.firstTymLoad ? null : (
                <MaterialTable
                  columns={this.state.columns}
                  data={this.state.currencyDataList}
                  onChangeRowsPerPage={(pageSize) => {
                    this.setState({ currentPageSize: pageSize });
                    let timesRun = 0;
                    let firstTimeExecuted = false;
                    var hasVScroll =
                      window.innerHeight <= document.body.offsetHeight;
                    this.interval = setInterval(() => {
                      if (timesRun == 5) {
                        clearInterval(this.interval);
                      }
                      timesRun++;
                      console.log("service ", hasVScroll);
                      if (hasVScroll) {
                        window.scrollTo(0, 0);
                        clearInterval(this.interval);
                      } else {
                        if (firstTimeExecuted) {
                          clearInterval(this.interval);
                        }
                        firstTimeExecuted = true;
                      }
                    }, 1);
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "No records to display",
                    },
                  }}
                  options={{
                    showTitle: false,
                    searchFieldAlignment: "left",

                    pageSize: this.state.currentPageSize,
                    pageSizeOptions: [10, 20, 25],
                    sorting:
                      this.state.currencyDataList != null &&
                      this.state.currencyDataList.length > 0,
                    search: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    thirdSortClick: false,
                  }}
                />
              )}
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

ExchangeRateInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  passMessage: PropTypes.func,
};

export default connect(null, null)(withStyles(styles)(ExchangeRateInfo));
