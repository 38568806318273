import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Select from "react-select";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import {
  PARAM_TYPE_LIST_COUNTERPART,
  PARAM_TYPE_LIST_CONTRACT,
  PARAM_MEMBERS,
  PARAM_CATEGORY,
  PARAM_CPRT_CROSS_DEFAULT_CLAUSE,
  PARAM_REGION,
  PARAM_CONTRACT_REGION,
  PARAM_SECTOR_INDUSTRY,
  PARAM_COUNTRY,
  PARAM_CONTRACT_COUNTRY,
  PARAM_CONTAGION,
  PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE,
  PARAM_CURRENCY_TYPE,
  PARAM_LOAN_TYPE,
  PARAM_RESOLUTION_TYPE,
  PARAM_ORGINAL_AMT_LOAN,
  ALL,
  PARAM_SECTOR,
  PARAM_INDUSTRY,
  PARAM_SUB_INDUSTRY,
  PARAM_SECTOR_CLASSIFICATION,
  NONE,
  ONLY_ME,
  EXCEPT_ME,
  PARAM_SOV_DEFINITION,
  PARAM_SOV_FLAG,
  PARAM_SUB_SUB_INDUSTRY,
} from "../commonUtility/string-const";
import {
  Category,
  CrossDefaultClause,
  Region,
  Contagion,
  CurrencyType,
  LoanType,
  ResolutionType,
  OriginalAmountOfLoan,
  Members,
  SectorIndustry,
  SectorClassification,
  SovereignDefaultDefinition,
  SovereignFlag,
} from "./ParamType";
import { callUserDetails } from "../../store/actions/index";
import customStyles from "../commonUtility/ReactSelectCss";
import _ from "lodash";
import { FormGroup, Label, Input } from "reactstrap";

class ParametersCompute extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      paramList: null,
      paramTypeCprt: PARAM_TYPE_LIST_COUNTERPART,
      paramTypeContract: PARAM_TYPE_LIST_CONTRACT,
      paramCategory: [
        {
          value: 0,
          label: "Member",
        },
        {
          value: 1,
          label: "By Counterpart",
        },
        {
          value: 2,
          label: "By Contract",
        },
      ],
      paramCategoryDefault: [
        {
          value: 0,
          label: "Member",
        },
        {
          value: 1,
          label: "By Counterpart",
        },
        {
          value: 2,
          label: "By Contract",
        },
      ],
      paramCategoryNonSuperAdmin: [
        {
          value: 1,
          label: "By Counterpart",
        },
        {
          value: 2,
          label: "By Contract",
        },
      ],
      paramCategorySelected: {
        value: 0,
        label: "Member",
      },
      paramCategorySelectedDefault: {
        value: 0,
        label: "Member",
      },
      paramCategorySelectedNonSuperAdmin: {
        value: 1,
        label: "By Counterpart",
      },
      defaultMembers: {},
      countryList: [],
      countryContractList: [],
      regionList: {},
      regionContractList: {},
      regionListSelected: [],
      regionContractListSelected: [],
      countryListSelected: [],
      countryContractListSelected: [],
      defaultCountryList: [],
      defaultCountryContractList: [],
      countryListDropDown: [],
      countryContractListDropDown: [],
      industryListDropDown: [],
      subIndustryListDropDown: [],
      subSubIndustryListDropDown:[],
      sectorList: [],
      industryList: [],
      subIndustryList: [],
      subSubIndustryList:[],
      sectorListSelected: [],
      industryListSelected: [],
      subIndustryListSelected: [],
      subSubIndustryListSelected:[],
      isEoy: false,
      lastParameterChange: null,
      lastRef: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleClickAllNone = this.handleClickAllNone.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        lastParameterChange: null,
        lastRef: null,
      });
    }
  }

  componentDidMount() {
    this.props.onCompute(this);
  }

  componentWillUnmount() {
    this.props.onCompute(undefined);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.paramList != null) {
      if (props.userData === undefined) {
        props.callUserDetails();
      }
      if (props.isEoy !== state.isEoy) {
        return {
          ...state,
          isEoy: props.isEoy,
          paramCategory:
            props.userData !== undefined
              ? props.userData.userRole !== 1
                ? props.isEoy
                  ? state.paramCategoryDefault
                  : state.paramCategoryNonSuperAdmin
                : state.paramCategoryDefault
              : state.paramCategoryDefault,
          paramCategorySelected:
            props.userData !== undefined
              ? props.userData.userRole !== 1
                ? props.isEoy
                  ? state.paramCategorySelectedDefault
                  : state.paramCategorySelectedNonSuperAdmin
                : state.paramCategorySelectedDefault
              : state.paramCategorySelectedDefault,
        };
      } else {
        return {
          ...state,
          isEoy: props.isEoy,
          paramCategory:
            props.userData !== undefined
              ? props.userData.userRole !== 1
                ? props.isEoy
                  ? state.paramCategoryDefault
                  : state.paramCategoryNonSuperAdmin
                : state.paramCategoryDefault
              : state.paramCategoryDefault,
        };
      }
    } else {
      if (props.userData === undefined) {
        props.callUserDetails();
      }
      return {
        ...state,
        ...props,

        countryListSelected: [
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.selectedCountry),
        countryContractListSelected: [
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.selectedCountryContract),
        defaultCountryList: props.selectedCountry,
        defaultCountryContractList: props.selectedCountryContract,
        regionListSelected: [
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.regionList),
        regionContractListSelected: [
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.regionContractList),
        sectorListSelected: [
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.sectorList),
        industryListSelected: [
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.industryList),
        subIndustryListSelected: [
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.subIndustryList),
        subSubIndustryListSelected:[
          {
            label: "Select all",
            value: "*",
          },
        ].concat(props.subSubIndustryList).fill(false),   // in the initial build up of the page the sub sub indursty values are deselected
        isEoy: props.isEoy,
        paramCategory:
          props.userData !== undefined
            ? props.userData.userRole !== 1
              ? props.isEoy
                ? state.paramCategoryDefault
                : state.paramCategoryNonSuperAdmin
              : state.paramCategoryDefault
            : state.paramCategoryNonSuperAdmin,
        paramCategorySelected:
          props.userData !== undefined
            ? props.userData.userRole !== 1
              ? props.isEoy
                ? state.paramCategorySelectedDefault
                : state.paramCategorySelectedNonSuperAdmin
              : state.paramCategorySelectedDefault
            : state.paramCategorySelectedNonSuperAdmin,
        countryListDropDown: props.selectedCountry,
        countryContractListDropDown: props.selectedCountryContract,
        industryListDropDown: props.industryList,
        subIndustryListDropDown: props.subIndustryList,
        subSubIndustryListDropDown: props.subSubIndustryList
      };
    }
  }

//  showToggleSectorMapping(){
//
//
//this.setState({
//        sectorListSelected: [
//          {
//            label: "Select all",
//            value: "*",
//          },
//        ].concat(this.state.sectorList),
//        industryListSelected: [
//          {
//            label: "Select all",
//            value: "*",
//          },
//        ].concat(this.state.industryList),
//        subIndustryListSelected: [
//          {
//            label: "Select all",
//            value: "*",
//          },
//        ].concat(this.state.subIndustryList),
//        subSubIndustryListSelected:[
//          {
//            label: "Select all",
//            value: "*",
//          },
//        ].concat(this.state.subSubIndustryList),
//        industryListDropDown: this.state.industryList,
//        subIndustryListDropDown: this.state.subIndustryList,
//        subSubIndustryListDropDown: this.state.subSubIndustryList,
//        lastParameterChange: null,
//        lastRef: null,
//      });
//
//      this.props.parametersCallback(
//        {
//          sector: [
//            {
//              label: "Select all",
//              value: "*",
//            },
//          ].concat(this.state.sectorList),
//          industry: [
//            {
//              label: "Select all",
//              value: "*",
//            },
//          ].concat(this.state.industryList),
//          subIndustry: [
//            {
//              label: "Select all",
//              value: "*",
//            },
//          ].concat(this.state.subIndustryList),
//          subSubIndustry : [
//            {
//              label: "Select all",
//              value: "*",
//            },
//          ].concat(this.state.subSubIndustryList)
//        },
//        PARAM_SUB_INDUSTRY
//
//      );
//      }
  enterPressed = (event, num) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.toggle(num);
    }
  };

  doesRegionCountryExist(regionOrCountry, list, attribute) {
    for (var c = 0; c < list.length; c++) {
      if (list[c][attribute] === regionOrCountry) {
        return true;
      }
    }
    return false;
  }

  getObject(list, search, attribute) {
    for (var i = 0; i < list.length; i++) {
      if (list[i][attribute] === search) {
        return list[i];
      }
    }
    return null;
  }

  getObjectAll(list, search, attribute) {
    let listFilter = [];
    for (var i = 0; i < list.length; i++) {
      if (list[i][attribute] === search) {
        listFilter.push(list[i]);
      }
    }
    return listFilter;
  }

  updateMenuOpenRef(ref) {
    this.setState({ lastRef: ref, lastParameterChange: null });
  }

  onCompute() {
    this.setState({ lastParameterChange: null });
  }

  handleClick(type, paramKey, e, ref) {
    if (
      ref !== null &&
      e.length === 0 &&
      ref != null &&
      ref != undefined &&
      ref !== "null" &&
      ref !== "undefined"
    ) {
      let lastRef = this.state.lastRef;
      if (
        lastRef !== null &&
        lastRef !== undefined &&
        lastRef != null &&
        lastRef != undefined &&
        lastRef !== "null" &&
        lastRef !== "undefined"
      ) {
        lastRef.blur();
        lastRef.onMenuClose();
      }
    }
    this.setState({ lastRef: ref });

    switch (type) {
      case PARAM_MEMBERS:
        let defaultMembers = this.state.defaultMembers;
        defaultMembers[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultMembers, type);
        this.setState({ defaultMembers: defaultMembers });
        break;
      case PARAM_CATEGORY:
        let defaultCategory = this.state.defaultCategory;
        defaultCategory[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultCategory, type);
        this.setState({ defaultCategory: defaultCategory });
        break;
      case PARAM_CPRT_CROSS_DEFAULT_CLAUSE:
        let defaultCrossDefault = this.state.defaultCrossDefault;
        defaultCrossDefault[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultCrossDefault, type);
        this.setState({ defaultCrossDefault: defaultCrossDefault });
        break;
      case PARAM_REGION:
        let selectedRegion = e;

        let countryListAll = this.state.defaultCountryList;
        let tempCountryListSelected = [];

        if (selectedRegion != null) {
          Object.keys(selectedRegion).forEach((r) => {
            let countryObj = this.getObjectAll(
              countryListAll,
              selectedRegion[r]["label"],
              "region"
            );
            if (countryObj.length > 0) {
              if (tempCountryListSelected.indexOf(countryObj) === -1) {
                tempCountryListSelected =
                  tempCountryListSelected.concat(countryObj);
              }
            }
          });
        }
        this.props.parametersCallback(
          { region: selectedRegion, country: tempCountryListSelected },
          PARAM_COUNTRY
        );
        this.setState({
          regionListSelected: selectedRegion,
          countryListSelected: [
            {
              label: "Select all",
              value: "*",
            },
          ].concat(tempCountryListSelected),
          countryListDropDown: tempCountryListSelected,
          lastParameterChange: type,
        });
        break;
        case PARAM_CONTRACT_REGION:
          let selectedRegionContract = e;
  
          let countryContractListAll = this.state.defaultCountryContractList;
          let tempCountryContractListSelected = [];
  
          if (selectedRegionContract != null) {
            Object.keys(selectedRegionContract).forEach((r) => {
              let countryObj = this.getObjectAll(
                countryContractListAll,
                selectedRegionContract[r]["label"],
                "region" // "regionContract"
              );
              if (countryObj.length > 0) {
                if (tempCountryContractListSelected.indexOf(countryObj) === -1) {
                  tempCountryContractListSelected =
                    tempCountryContractListSelected.concat(countryObj);
                }
              }
            });
          }
          this.props.parametersCallback(
            { regionContract: selectedRegionContract, countryContract: tempCountryContractListSelected },
            PARAM_CONTRACT_COUNTRY
          );
          this.setState({
            regionContractListSelected: selectedRegionContract,
            countryContractListSelected: [
              {
                label: "Select all",
                value: "*",
              },
            ].concat(tempCountryContractListSelected),
            countryContractListDropDown: tempCountryContractListSelected,
            lastParameterChange: type,
          });
          break;
        case PARAM_SECTOR:
        let sectorListSelected = e;
        let industryListAll = this.state.industryList;
        let subIndustryListAll = this.state.subIndustryList;
        let subSubIndustryListAll = this.state.subSubIndustryList;
        let tempIndustryListSelected = [];
        let tempSubIndustryListSelected = [];
        let tempSubSubIndustryListSelected = [];
        if (sectorListSelected != null) {
          Object.keys(sectorListSelected).forEach((s) => {
            let industryObj = this.getObjectAll(
              industryListAll,
              sectorListSelected[s]["label"],
              "sector"
            );
            if (industryObj.length > 0) {
              tempIndustryListSelected =
                tempIndustryListSelected.concat(industryObj);
            }
          });

          Object.keys(tempIndustryListSelected).forEach((si) => {
            let subIndustryObj = this.getObjectAll(
              subIndustryListAll,
              tempIndustryListSelected[si]["label"],
              "industry"
            );
            if (subIndustryObj.length > 0) {
              tempSubIndustryListSelected = tempSubIndustryListSelected.concat(
              subIndustryObj
              );
            }
          });
          Object.keys(tempSubIndustryListSelected).forEach((ssi) => {
                        let subSubIndustryObj = this.getObjectAll(
                          subSubIndustryListAll,
                          tempSubIndustryListSelected[ssi]["label"],
                          "subIndustry"
                        );
                        if (subSubIndustryObj.length > 0) {
                          tempSubSubIndustryListSelected = tempSubSubIndustryListSelected.concat(
                            subSubIndustryObj
                          );
                        }
                      });
        }

        this.props.parametersCallback(
          {
            sector: sectorListSelected,
            industry: tempIndustryListSelected,
            subIndustry: tempSubIndustryListSelected,
            subSubIndustry : tempSubSubIndustryListSelected
          },
          PARAM_SUB_INDUSTRY
        );
        this.setState({
          sectorListSelected: sectorListSelected,
          industryListSelected: [
            {
              label: "Select all",
              value: "*",
            },
          ].concat(tempIndustryListSelected),
          subIndustryListSelected: [
            {
              label: "Select all",
              value: "*",
            },
          ].concat(tempSubIndustryListSelected),
          subSubIndustryListSelected:[
                        {
                          label: "Select all",
                          value: "*",
                        },
                      ].concat(tempSubSubIndustryListSelected),
          industryListDropDown: tempIndustryListSelected,
          subIndustryListDropDown: tempSubIndustryListSelected,
            subSubIndustryListDropDown: tempSubSubIndustryListSelected,
          lastParameterChange: type,
        });
        break;
      case PARAM_INDUSTRY:
        let industryListSelected = e;
        subIndustryListAll = this.state.subIndustryList;
        subSubIndustryListAll = this.state.subSubIndustryList;
        tempSubIndustryListSelected = [];
        tempSubSubIndustryListSelected = [];
        /*sectorListSelected = this.state.sectorListSelected;
        let subIndustryListSelected = this.state.subIndustryListSelected;
        let sectorListAll = this.state.sectorList;
        let tempSectorListSelected = [];
        tempSubIndustryListSelected = [];
        Object.keys(industryListSelected).forEach((ind)=>{
          if(industryListSelected[ind]["value"]!="*"){
            let objSector = this.getObject(sectorListAll,industryListSelected[ind]["sector"],"label");
            if(objSector!=null){
              if(tempSectorListSelected.indexOf(objSector)==-1)
                  tempSectorListSelected.push(objSector);
            }
          }
        });
        Object.keys(subIndustryListSelected).forEach((si)=>{
          if(subIndustryListSelected[si]["value"]!="*"){
            let isAvai = this.doesRegionCountryExist(subIndustryListSelected[si]["industry"],industryListSelected,"label");
            if(isAvai){
              tempSubIndustryListSelected.push(subIndustryListSelected[si]);
            }
          }
        });*/

        //Check if all industrties are deselected then deselect sector also

        sectorListSelected = [{label: "Select all", value: "*"}];
        let sectorInIndustry = [];
        industryListSelected.forEach((i)=>{
          if(i.value!="*"){
            if(!sectorInIndustry.includes(i.sector)){
              sectorInIndustry.push(i.sector);
              let obj = this.getObject(this.state.sectorList, i.sector, "label");
              sectorListSelected.push(obj);
            }
          }
        });

        if(sectorListSelected.length==1 && sectorListSelected[0].value=="*"){
          sectorListSelected= [];
        }

        if (industryListSelected != null) {
          Object.keys(industryListSelected).forEach((si) => {
            let subIndustryObj = this.getObjectAll(
              subIndustryListAll,
              industryListSelected[si]["label"],
              "industry"
            );
            if (subIndustryObj.length > 0) {
              tempSubIndustryListSelected = tempSubIndustryListSelected.concat(
              subIndustryObj
              );
            }
          });
                      Object.keys(tempSubIndustryListSelected).forEach((ssi) => {
                        let subSubIndustryObj = this.getObjectAll(
                          subSubIndustryListAll,
                          tempSubIndustryListSelected[ssi]["label"],
                          "subIndustry"
                        );
                        if (subSubIndustryObj.length > 0) {
                          tempSubSubIndustryListSelected = tempSubSubIndustryListSelected.concat(
                            subSubIndustryObj
                          );
                        }
                      });
        }

        if (
          this.state.subIndustryListDropDown.length ===
          tempSubIndustryListSelected.length
        ) {
          tempSubIndustryListSelected = [
            {
              label: "Select all",
              value: "*",
            },
          ].concat(tempSubIndustryListSelected);
                      tempSubSubIndustryListSelected = [
                        {
                          label: "Select all",
                          value: "*",
                        },
                      ].concat(tempSubSubIndustryListSelected);
        }
        this.props.parametersCallback(
          {
            sector: this.state.sectorListSelected,
            industry: industryListSelected,
            subIndustry: tempSubIndustryListSelected,
            subSubIndustry: tempSubSubIndustryListSelected
          },
          PARAM_SUB_INDUSTRY
        );
        this.setState({
        sectorListSelected: sectorListSelected,
          industryListSelected: industryListSelected,
          subIndustryListSelected: tempSubIndustryListSelected,
          subSubIndustryListSelected : tempSubSubIndustryListSelected,
          lastParameterChange: type,
        });
        break;
      case PARAM_SUB_INDUSTRY:
        let subIndustryListSelected = e;
        subSubIndustryListAll = this.state.subSubIndustryList;
        tempSubSubIndustryListSelected = [];
        /*sectorListSelected = this.state.sectorListSelected;
        industryListSelected = this.state.industryListSelected;
        let sectorListAll = this.state.sectorList;
        let tempSectorListSelected = [];
        tempIndustryListSelected = [];
        industryListAll = this.state.industryList;
        Object.keys(subIndustryListSelected).map((si)=>{
          if(subIndustryListSelected[si]["value"]!="*"){
            let objSector = this.getObject(sectorListAll,subIndustryListSelected[si]["sector"],"label");
            if(objSector!=null){
              if(tempSectorListSelected.indexOf(objSector)==-1)
                  tempSectorListSelected.push(objSector);
            }
          }
        });
        Object.keys(subIndustryListSelected).map((si)=>{
          if(subIndustryListSelected[si]["value"]!="*"){
            let objIndustry = this.getObject(industryListAll,subIndustryListSelected[si]["industry"],"label");
            if(objIndustry!=null){
              if(tempIndustryListSelected.indexOf(objIndustry)==-1)
              tempIndustryListSelected.push(objIndustry);
            }
          }
        });*/

        let industryListInSubIndustry = [];
        industryListSelected = [{label: "Select all", value: "*"}];
        subIndustryListSelected.forEach((i)=>{
          if(i.value!="*"){
           if(!industryListInSubIndustry.includes(i.industry)){
            industryListInSubIndustry.push(i.industry);
            let obj = this.getObject(this.state.industryList,i.industry,"label");
            industryListSelected.push(obj);
           }
          }
        });


        if(industryListSelected.length==1 && industryListSelected[0].value=="*"){
          industryListSelected= [];
        }

        sectorListSelected = [{label: "Select all", value: "*"}];
        sectorInIndustry = [];
        industryListSelected.forEach((i)=>{
          if(i.value!="*"){
            if(!sectorInIndustry.includes(i.sector)){
              sectorInIndustry.push(i.sector);
              let obj = this.getObject(this.state.sectorList, i.sector, "label");
              sectorListSelected.push(obj);
            }
          }
        });

        if(sectorListSelected.length==1 && sectorListSelected[0].value=="*"){
          sectorListSelected= [];
        }


        if (subIndustryListSelected == null) {
          subIndustryListSelected = [];
        }
        Object.keys(subIndustryListSelected).forEach((ssi) => {
                    let subSubIndustryObj = this.getObjectAll(
                      subSubIndustryListAll,
                      subIndustryListSelected[ssi]["label"],
                      "subIndustry"
                    );
                    if (subSubIndustryObj.length > 0) {
                      tempSubSubIndustryListSelected = tempSubSubIndustryListSelected.concat(
                        subSubIndustryObj
                      );
                    }
                  });
        this.props.parametersCallback(
          {
            sector: this.state.sectorListSelected,
            industry: this.state.industryListSelected,
            subIndustry: subIndustryListSelected,
            subSubIndustry : tempSubSubIndustryListSelected
          },
          type
        );
        this.setState({
          sectorListSelected: sectorListSelected,
          industryListSelected: industryListSelected,
          subIndustryListSelected: subIndustryListSelected,
          subSubIndustryListSelected:tempSubSubIndustryListSelected,
          lastParameterChange: type
        });
        break;
        case PARAM_SUB_SUB_INDUSTRY:
                let subSubIndustryListSelected = e;
                if (subSubIndustryListSelected == null) {
                  subIndustryListSelected = [];
                }

                  this.props.parametersCallback(
                    {
                      sector: this.state.sectorListSelected,
                      industry: this.state.industryListSelected,
                      subIndustry: this.state.subIndustryListSelected,
                      subSubIndustry : subSubIndustryListSelected
                    },
                    PARAM_SUB_INDUSTRY

                  );
                  this.setState({
                    subSubIndustryListSelected : subSubIndustryListSelected /*,sectorListSelected:tempSectorListSelected,industryListSelected:tempIndustryListSelected*/,
                    lastParameterChange: type,
                  });

                break;
      case PARAM_COUNTRY:
        let selectedCountry = e;

        if (selectedCountry == null) {
          selectedCountry = [];
        }
        this.props.parametersCallback(
          { region: this.state.regionListSelected, country: selectedCountry },
          type
        );
        this.setState({
          countryListSelected: selectedCountry,
          lastParameterChange: type,
        });
        break;
        case PARAM_CONTRACT_COUNTRY:
          let selectedCountryContract = e;
  
          if (selectedCountryContract == null) {
            selectedCountryContract = [];
          }
          this.props.parametersCallback(
            { regionContract: this.state.regionContractListSelected, countryContract: selectedCountryContract },
            type
          );
          this.setState({
            countryContractListSelected: selectedCountryContract,
            lastParameterChange: type,
          });
          break;
        case PARAM_CONTAGION:
        let defaultContagion = this.state.defaultContagion;
        defaultContagion = e;

        this.props.parametersCallback(defaultContagion, type);
        this.setState({ defaultContagion: defaultContagion });
        break;
      case PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE:
        let defaultCrossDefaultContract =
          this.state.defaultCrossDefaultContract;
        defaultCrossDefaultContract[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultCrossDefaultContract, type);
        this.setState({
          defaultCrossDefaultContract: defaultCrossDefaultContract,
        });
        break;
      case PARAM_CURRENCY_TYPE:
        let defaultCurrencyType = this.state.defaultCurrencyType;
        defaultCurrencyType[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultCurrencyType, type);
        this.setState({ defaultCurrencyType: defaultCurrencyType });
        break;
      case PARAM_LOAN_TYPE:
        let defaultLoanType = this.state.defaultLoanType;
        defaultLoanType[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultLoanType, type);
        this.setState({ defaultLoanType: defaultLoanType });
        break;
        case PARAM_RESOLUTION_TYPE:
          let defaultResolutionType = this.state.defaultResolutionType;
          defaultResolutionType[paramKey] = e.target.checked;
  
          this.props.parametersCallback(defaultResolutionType, type);
          this.setState({ defaultResolutionType: defaultResolutionType });
          break;
      case PARAM_SECTOR_CLASSIFICATION:
        let defaultSectorClassification = this.state.defaultSectorClassification;
        defaultSectorClassification[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultSectorClassification, type);
        this.setState({ defaultSectorClassification: defaultSectorClassification });
        break;
      case PARAM_ORGINAL_AMT_LOAN:
        let defaultLoanAmount = this.state.defaultLoanAmount;
        defaultLoanAmount[paramKey] = e.target.checked;

        this.props.parametersCallback(defaultLoanAmount, type);
        this.setState({ defaultLoanAmount: defaultLoanAmount });
        break;
      case PARAM_SOV_DEFINITION:
        let sovDefaultDefinition = this.state.sovDefaultDefinition;
        sovDefaultDefinition = e;
        this.props.parametersCallback(sovDefaultDefinition, type);
        this.setState({ defaultSovDefaultDefinition: sovDefaultDefinition });
        break;
      case PARAM_SOV_FLAG:
        let sovFlag = this.state.sovFlag;
        sovFlag = e;
        this.props.parametersCallback(sovFlag, type);
        this.setState({ defaultSovFlag: sovFlag });
        break;
      default:
        break;
    }
  }

  handleClickAllNone(type, allOrNone) {
    switch (type) {
      case PARAM_MEMBERS:
        if (allOrNone === ALL) {
          let defaultMembers = this.state.defaultMembers;
          Object.keys(defaultMembers).forEach((key) => {
            defaultMembers[key] = true;
          });
          this.props.parametersCallback(defaultMembers, type);
          this.setState({ defaultMembers: defaultMembers });
        } else if (allOrNone === NONE) {
          let defaultMembers = this.state.defaultMembers;
          Object.keys(defaultMembers).forEach((key) => {
            defaultMembers[key] = false;
          });
          this.props.parametersCallback(defaultMembers, type);
          this.setState({ defaultMembers: defaultMembers });
        } else if (allOrNone === ONLY_ME) {
          let defaultMembers = this.state.defaultMembers;
          Object.keys(defaultMembers).forEach((key) => {
            if (key === this.props.userData.institutionAbbr) {
              defaultMembers[key] = true;
            } else {
              defaultMembers[key] = false;
            }
          });
          this.props.parametersCallback(defaultMembers, type);
          this.setState({ defaultMembers: defaultMembers });
        } else if (allOrNone === EXCEPT_ME) {
          let defaultMembers = this.state.defaultMembers;
          Object.keys(defaultMembers).forEach((key) => {
            if (key === this.props.userData.institutionAbbr) {
              defaultMembers[key] = false;
            } else {
              defaultMembers[key] = true;
            }
          });
          this.props.parametersCallback(defaultMembers, type);
          this.setState({ defaultMembers: defaultMembers });
        }
        break;
      case PARAM_CATEGORY:
        let defaultCategory = this.state.defaultCategory;
        Object.keys(defaultCategory).forEach((key) => {
          defaultCategory[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultCategory, type);
        this.setState({ defaultCategory: defaultCategory });
        break;
      case PARAM_CPRT_CROSS_DEFAULT_CLAUSE:
        let defaultCrossDefault = this.state.defaultCrossDefault;
        Object.keys(defaultCrossDefault).forEach((key) => {
          defaultCrossDefault[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultCrossDefault, type);
        this.setState({ defaultCrossDefault: defaultCrossDefault });
        break;
      case PARAM_REGION:
        break;
      case PARAM_CONTRACT_REGION:
        break;
        case PARAM_SECTOR_INDUSTRY:
        break;
      case PARAM_COUNTRY:
        break;
      case PARAM_CONTRACT_COUNTRY:
        break;
        case PARAM_CONTRACT_CROSS_DEFAULT_CLAUSE:
        let defaultCrossDefaultContract =
          this.state.defaultCrossDefaultContract;
        Object.keys(defaultCrossDefaultContract).forEach((key) => {
          defaultCrossDefaultContract[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultCrossDefaultContract, type);
        this.setState({
          defaultCrossDefaultContract: defaultCrossDefaultContract,
        });
        break;
      case PARAM_CURRENCY_TYPE:
        let defaultCurrencyType = this.state.defaultCurrencyType;
        Object.keys(defaultCurrencyType).forEach((key) => {
          defaultCurrencyType[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultCurrencyType, type);
        this.setState({ defaultCurrencyType: defaultCurrencyType });
        break;
      case PARAM_LOAN_TYPE:
        let defaultLoanType = this.state.defaultLoanType;
        Object.keys(defaultLoanType).forEach((key) => {
          defaultLoanType[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultLoanType, type);
        this.setState({ defaultLoanType: defaultLoanType });
        break;
      case PARAM_RESOLUTION_TYPE:
        let defaultResolutionType = this.state.defaultResolutionType;
        Object.keys(defaultResolutionType).forEach((key) => {
          defaultResolutionType[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultResolutionType, type);
        this.setState({ defaultResolutionType: defaultResolutionType });
        break;
      case PARAM_SECTOR_CLASSIFICATION:
        let defaultSectorClassification = this.state.defaultSectorClassification;
        Object.keys(defaultSectorClassification).forEach((key) => {
          defaultSectorClassification[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultSectorClassification, type);
        this.setState({ defaultSectorClassification: defaultSectorClassification });
        break;
      case PARAM_ORGINAL_AMT_LOAN:
        let defaultLoanAmount = this.state.defaultLoanAmount;
        Object.keys(defaultLoanAmount).forEach((key) => {
          defaultLoanAmount[key] = allOrNone === ALL ? true : false;
        });

        this.props.parametersCallback(defaultLoanAmount, type);
        this.setState({ defaultLoanAmount: defaultLoanAmount });
        break;
      default:
        break;
    }
  }

  handleUserSelect = (field, selectedOption) => {
    const name = field;
    const value = selectedOption;
    this.setState({ [name]: value, activeTab: "1", lastRef: null });
  };

  render() {
    let counterpartParams = (
      <div>
        <Nav fill tabs className="tab-sec admin-section">
          <NavItem>
            {this.state.paramTypeCprt.map((prmCprt, index) => {
              let tabIndex = (index + 1).toString();
              return (
                <NavItem>
                  <NavLink
                    tabIndex="0"
                    className={classnames({
                      active: this.state.activeTab === tabIndex,
                    })}
                    onClick={() => {
                      this.toggle(tabIndex);
                    }}
                    onKeyUp={(event) => this.enterPressed(event, tabIndex)}
                  >
                    {prmCprt}
                  </NavLink>
                </NavItem>
              );
            })}
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <Category
                catList={this.state.paramList.category}
                defaultValue={this.state.defaultCategory}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="2">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <CrossDefaultClause
                type="counterpart"
                crossDefaultList={this.state.paramList.crossDefaultClause}
                defaultValue={this.state.defaultCrossDefault}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="3">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <Region
                region={this.state.regionList}
                regionListSelected={this.state.regionListSelected}
                countryListSelected={this.state.countryListSelected}
                country={this.state.countryListDropDown}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
                lastParameterChange={this.state.lastParameterChange}
                updateMenuOpenRef={this.updateMenuOpenRef.bind(this)}
                handleRegion = {PARAM_REGION}
                handleCountry = {PARAM_COUNTRY}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="4">
            {/* <SectorIndustryGroup/> */}
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
            <SectorIndustry
              sector={this.state.sectorList}
              sectorListSelected={this.state.sectorListSelected}
              industryListSelected={this.state.industryListSelected}
              subIndustryListSelected={this.state.subIndustryListSelected}
              subSubIndustryListSelected={this.state.subSubIndustryListSelected}
              industry={this.state.industryListDropDown}
              subIndustry={this.state.subIndustryListDropDown}
              subSubIndustry={this.state.subSubIndustryListDropDown}
              handleClick={this.handleClick.bind(this)}
              checked={true}
              value={true}
              lastParameterChange={this.state.lastParameterChange}
              updateMenuOpenRef={this.updateMenuOpenRef.bind(this)}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="5">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <SectorClassification
                sectorClassification={this.state.paramList.sectorClassification}
                defaultValue={this.state.defaultSectorClassification}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>

          <TabPane tabId="6">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <SovereignDefaultDefinition
                value={this.state.defaultSovDefaultDefinition}
                handleClick={this.handleClick.bind(this)}
              />
            ) : null}
          </TabPane>
        </TabContent>
      </div>
    );

    let membersParams = (
      <div>
        {this.state.paramList !== undefined &&
        !_.isEmpty(this.state.paramList) ? (
          <div className="member-section">
            <Members
              members={this.state.paramList.members}
              defaultValue={this.state.defaultMembers}
              handleClick={this.handleClick.bind(this)}
              handleClickAllNone={this.handleClickAllNone.bind(this)}
              isInstAdmin={
                this.props.userData
                  ? this.props.userData.userRole !== 1
                    ? true
                    : false
                  : false
              }
            />
          </div>
        ) : null}
      </div>
    );

    let paramTypeContract = this.state.paramTypeContract.map(
      (prmContract, index) => {
        let tabIndex = (index + 1).toString();
        return (
          <NavItem>
            <NavLink
              style={{ minHeight: '55px' }}
              tabIndex="0"
              className={classnames({
                active: this.state.activeTab === tabIndex,
              })}
              onClick={() => {
                this.toggle(tabIndex);
              }}
              onKeyUp={(event) => this.enterPressed(event, tabIndex)}
            >
              {prmContract}
            </NavLink>
          </NavItem>
        );
      }
    );

    let contractParams = (
      <div>
        <Nav tabs className="tab-sec admin-section">
          <NavItem>{paramTypeContract}</NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <Contagion
                contagion={this.state.paramList.contagion}
                defaultValue={this.state.defaultContagion}
                handleClick={this.handleClick.bind(this)}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="2">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <CrossDefaultClause
                type="contract"
                defaultValue={this.state.defaultCrossDefaultContract}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                crossDefaultList={this.state.paramList.crossDefaultClause}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="3">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <Region
                region={this.state.regionContractList}
                regionListSelected={this.state.regionContractListSelected}
                countryListSelected={this.state.countryContractListSelected}
                country={this.state.countryContractListDropDown}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
                lastParameterChange={this.state.lastParameterChange}
                updateMenuOpenRef={this.updateMenuOpenRef.bind(this)}
                handleRegion = {PARAM_CONTRACT_REGION}
                handleCountry = {PARAM_CONTRACT_COUNTRY}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="4">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <CurrencyType
                currencyType={this.state.paramList.currencyType}
                defaultValue={this.state.defaultCurrencyType}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="5">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <LoanType
                loanType={this.state.paramList.loanType}
                defaultValue={this.state.defaultLoanType}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="6">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <OriginalAmountOfLoan
                loanAmount={this.state.paramList.loanAmount}
                defaultValue={this.state.defaultLoanAmount}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="7">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <SovereignFlag
                value={this.state.defaultSovFlag}
                handleClick={this.handleClick.bind(this)}
              />
            ) : null}
          </TabPane>
          {(!this.state.defaultContagion) ? (
           <TabPane tabId="8">
            {this.state.paramList !== undefined &&
            !_.isEmpty(this.state.paramList) ? (
              <ResolutionType
                resolutionType={this.state.paramList.resolutionType}
                defaultValue={this.state.defaultResolutionType}
                handleClickAllNone={this.handleClickAllNone.bind(this)}
                handleClick={this.handleClick.bind(this)}
                checked={true}
                value={true}
              />
            ) : null}
          </TabPane>)
          : (
            <TabPane tabId="8">
              Not possible to filter on Resolution Type if contagion is included.
            </TabPane>
          ) 
          }
        </TabContent>
      </div>
    );

    this.state.paramTypeCprt.map((prmCprt, index) => {
      let tabIndex = (index + 1).toString();
      return (
        <NavItem>
          <NavLink 
            tabIndex="0"
            className={classnames({
              active: this.state.activeTab === tabIndex,
            })}
            onClick={() => {
              this.toggle(tabIndex);
            }}
            onKeyUp={(event) => this.enterPressed(event, tabIndex)}
          >
            {prmCprt}
          </NavLink>
        </NavItem>
      );
    });
    let paramModule = null;
    switch (this.state.paramCategorySelected.value) {
      case 0:
        paramModule = membersParams;
        break;
      case 1:
        paramModule = counterpartParams;
        break;
      case 2:
        paramModule = contractParams;
        break;
      default:
        break;
    }
    return (
      <div className="parameters-sec">
        <h1 className="heading mb-3">Parameters</h1>
        <div className="dropdown">
          <Select
            isSearchable={false}
            styles={customStyles}
            options={this.state.paramCategory}
            value={this.state.paramCategorySelected}
            onChange={this.handleUserSelect.bind(this, "paramCategorySelected")}
            className="react-select"
            classNamePrefix="react-select-menulist"
          />
        </div>
        <div className="mt-3">
          <div className="tab">
            {/* {this.state.paramCategorySelected.value===2?contractParams:counterpartParams} */}
            {paramModule}
          </div>
        </div>
      </div>
    );
  }
}

ParametersCompute.propTypes = {
  parametersCallback: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, { callUserDetails })(ParametersCompute);
