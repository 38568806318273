import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import AdminUserList from "./userList";
import Footer from "../commonUtility/Footer";
import { callUserDetails } from "../../store/actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AdminTab from "./AdminTab";
import ScrollUpButton from "react-scroll-up-button";

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.userData) {
      this.props.callUserDetails();
    }
    if (this.props.isLogout) {
      console.log("navigation logout");
      sessionStorage.clear();
      return <Redirect push to="/" />;
    }
    if (this.props.userData && this.props.userData.userRole == 3) {
      return <Redirect push to="/notfound" />;
    }
    return (
      <div className="AdminPage">
        <Navigation />

        <MainHeading />

        <div className="container">
          <AdminTab />
        </div>
        <ScrollUpButton ShowAtPosition={20} className="scrolltopbutton" />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Navigation Props received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, { callUserDetails })(AdminPage);
