import React from "react";
import instance from "../../instance";
import PropTypes from "prop-types";
import ButtonComponent from "./ButtonComponent";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Progress,
  Label,
  Input,
} from "reactstrap";
import CounterpartCount from "../commonUtility/CounterpartCount";
import { authHeader } from "../commonUtility/auth-header";
import { ToastContainer } from "react-toastify";
import { showToast } from "../commonUtility/NewToast";
import {
  UNKNOWN_ERROR,
  UPLOAD_TOOLTIP,
  TOAST_TYPE_INFO,
  TOAST_TYPE_ERROR,
} from "../commonUtility/string-const";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";

var interval = null;
class Options extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      data: null,
      errorMsg: false,
      isDefault180: false,
      ProgressUpdate: 0,
      clearSummary: false,
      hasFileContent: false,
      isUnauthorized: false,
      isLoading: false,
      isSovereignData: false,
      isSovereign180: false,
    };
    this.clearUploadDataStatus = this.clearUploadDataStatus.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let defaultPeriod = sessionStorage.getItem("isDefault180");
    if (defaultPeriod != null) {
      let defPrd = defaultPeriod == "true" ? true : false;
      this.props.updateDefaultPeriod(defPrd);
      this.setState({ isDefault180: defPrd });
    }
    let isSovereignData = sessionStorage.getItem("isSovereignData");
    if (isSovereignData !== null) {
      this.setState({
        isSovereignData: isSovereignData === "true" ? true : false,
      });
      let isSovereign180 = sessionStorage.getItem("isSovereign180");
      if (isSovereign180 !== null) {
        this.setState({
          isSovereign180: isSovereign180 === "true" ? true : false,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitHandler = (event) => {
    let is180Period = event.target.value == "true" ? true : false;
    this.setState({
      isDefault180: is180Period,
      isSovereignData: false,
      isSovereign180: false,
    });
    this.props.callback(is180Period);
    this.clearUploadDataStatus(false);
  };

  errorGenerated() {
    if (this._isMounted) {
      this.setState({ ProgressUpdate: 0 });
    }
    this.props.errorActionToast("An error occured while uploding file");
  }

  fromButtonComponent(summary) {
    if (this._isMounted) {
      this.setState({ ProgressUpdate: 0 });
      this.setState({
        data: summary,
      });
    }
    this.hasDataUploaded();
    if (this.state.data != null && this.state.data.error != null) {
      if (this._isMounted) {
        this.setState({ errorMsg: true });
      }
      showToast(this.state.data.error, TOAST_TYPE_INFO);
    } else {
      if (this._isMounted) {
        this.setState({ errorMsg: false });
      }
    }
  }

  hasDataUploaded() {
    if (this.state.data != null) {
      this.props.uploadedDataCallback(true);
      if (this._isMounted) {
        this.setState({ hasFileContent: true });
      }
    } else {
      this.props.uploadedDataCallback(false);
      if (this._isMounted) {
        this.setState({ hasFileContent: false });
      }
    }
  }

  updateUploadProgress(progress) {
    console.log(progress);

    this.setState({
      ProgressUpdate: progress,
    });
  }

  clearUploadDataStatus(clearClick) {
    instance
      .post(
        "/data/upload/clear",
        {},
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        this.setState({ status: response.data });
        let summary = this.state.status;
        this.setState({
          data: summary,
        });
        if (summary != null) {
          console.log("Clear clled ", this.state.clearSummary);
          this.setState({ clearSummary: true, hasFileContent: false });
        }
        this.setState({ ProgressUpdate: 0 });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log(UNKNOWN_ERROR);
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          console.log("Unknown error occured");
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      })
      .finally((e) => {
        sessionStorage.removeItem("excel_summary");
        sessionStorage.removeItem("runcheck_summary");
        sessionStorage.removeItem("runcheck_list");
        this.props.setClearUploadDSFile();
        if (clearClick) {
          sessionStorage.removeItem("isDefault180");
          this.setState({
            hasFileContent: false,
            isDefault180: false,
          });
          this.props.clearCallBack(false);
        } else {
          let def180 = this.state.isDefault180;
          this.setState({
            hasFileContent: false,
          });
          this.props.clearCallBack(def180);
        }
      });
  }

  hideToast() {
    this.setState({ clearSummary: false });
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  render() {
    console.log("Clear status ", this.state.clearSummary);
    return (
      <Card id="options-section" className="card card-block h-100">
        <CardHeader>Options</CardHeader>
        {this.state.isLoading ? <FullScreenLoaderComponent /> : null}
        <CardBody>
          {/* <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
          /> */}
          {this.props.isAnnualMode ? (
            <CardTitle className="mt-3">
              Annual Mode <span className="annaulmodeon">Active</span>{" "}
            </CardTitle>
          ) : null}

          <CardTitle className="mt-3">Default Period </CardTitle>
          <Form>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="defaultPeriod"
                  value={false}
                  onChange={(e) => this.submitHandler(e)}
                  checked={this.state.isDefault180 === false}
                />
                90 days
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="defaultPeriod"
                  value={true}
                  onChange={(e) => this.submitHandler(e)}
                  checked={this.state.isDefault180 === true}
                />
                180 days
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="checkbox"
                name="isSovereignData"
                value={true}
                onChange={(e) => {
                  sessionStorage.setItem("isSovereignData", e.target.checked);
                  sessionStorage.setItem("isSovereign180", false);
                  this.setState({
                    isSovereignData: e.target.checked,
                    isSovereign180: false,
                  });
                }}
                checked={this.state.isSovereignData === true}
              />
              <Label
                check
                className="mt-3 line-height-12"
                onClick={() => {
                  sessionStorage.setItem(
                    "isSovereignData",
                    !this.state.isSovereignData
                  );
                  sessionStorage.setItem("isSovereign180", false);
                  this.setState({
                    isSovereignData: !this.state.isSovereignData,
                    isSovereign180: false,
                  });
                }}
              >
                Data includes Sovereign counterparts
              </Label>
            </FormGroup>
            {this.state.isSovereignData ? (
              <div>
                <Label className="mt-2 line-height-12">
                  Please specify sovereign default internal definition
                </Label>
                <FormGroup check inline>
                  <Label check className="mt-2">
                    <Input
                      type="radio"
                      name="sovereignDefaultPeriod"
                      value={false}
                      onChange={(e) => {
                        sessionStorage.setItem(
                          "isSovereign180",
                          e.target.value === "true" ? true : false
                        );
                        this.setState({
                          isSovereign180:
                            e.target.value === "true" ? true : false,
                        });
                      }}
                      checked={this.state.isSovereign180 === false}
                    />
                    90 days
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="sovereignDefaultPeriod"
                      value={true}
                      onChange={(e) => {
                        sessionStorage.setItem(
                          "isSovereign180",
                          e.target.value === "true" ? true : false
                        );
                        this.setState({
                          isSovereign180:
                            e.target.value === "true" ? true : false,
                        });
                      }}
                      checked={this.state.isSovereign180 === true}
                    />
                    180 days
                  </Label>
                </FormGroup>
              </div>
            ) : null}
            <FormGroup>
              <div>
                {this.state.ProgressUpdate > 0 ? (
                  <Progress color="primary" value={this.state.ProgressUpdate}>
                    {this.state.ProgressUpdate}%
                  </Progress>
                ) : null}
              </div>
              <div className=" d-flex justify-content-center">
                {/* {this.state.hideUploadButton ? null : ( */}
                {/* <label className="custom-file-upload">
                    <span>Upload</span> */}
                <ButtonComponent
                  callback={this.fromButtonComponent.bind(this)}
                  ProgressCallback={this.updateUploadProgress.bind(this)}
                  clear={this.clearUploadDataStatus.bind(this)}
                  errorOccuredCallback={this.errorGenerated.bind(this)}
                  revokeChkMyDataLoading={this.startLoader.bind(this)}
                  is180Period={this.state.isDefault180}
                  isSovereignData={this.state.isSovereignData}
                  isSovereign180={this.state.isSovereign180}
                />
                {/* {this.props.userData ? this.props.userData.userRole===1?null:<HtmlTooltip
                    title={
                      <React.Fragment>
                        <b>{UPLOAD_TOOLTIP}</b>
                      </React.Fragment>
                    }
                  >
                    <span className="pull-right"><i className="fa fa-info-circle" id="tooltipUpload"></i></span>
                  </HtmlTooltip>:null} */}
              </div>
            </FormGroup>
          </Form>
          <div className="Counterpart">
            <CounterpartCount data={this.state.data} />
            {this.state.hasFileContent || this.props.isDataInLocal() ? (
              <div className="d-flex justify-content-end clear-load-btn">
                <Button
                  color="default"
                  onClick={(e) => this.clearUploadDataStatus(true)}
                >
                  Clear Loaded Items
                </Button>
              </div>
            ) : null}

            {/* <span>
              {this.state.clearSummary ? (
                <Toast msg="Content cleaned" errorType="info" />
              ) : null}
            </span> */}
            {/* <ToastContainer autoClose={2000} /> */}
          </div>
          {/* )} */}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
  userData: state.auth.userData,
  isAnnualMode: state.auth.isAnnualMode,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {
      dispatch(actions.successActionToast(message));
    },
    errorActionToast: (message) => {
      dispatch(actions.errorActionToast(message));
    },
    setClearUploadDSFile: () => {
      dispatch(actions.deleteUploadRes());
    },
  };
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

Options.propsType = {
  callback: PropTypes.func,
  clearCallBack: PropTypes.func,
  uploadedDataCallback: PropTypes.func,
  updateDefaultPeriod: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
