import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import { Button } from "reactstrap";
import blue from "@material-ui/core/colors/blue";
import { authHeader } from "../commonUtility/auth-header";
import { FormErrors } from "../commonUtility/FormErrors";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import LoaderComponent from "../commonUtility/LoaderComponent";
import { Checkbox } from "@material-ui/core";
import {
  ACTION_USER_ADD,
  ACTION_USER_UPDATE,
  ACTION_INSTITUTE_ADD,
  ACTION_INSTITUTE_UPDATE,
  ALLOWED,
  ENABLED,
  DISABLED,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
  ADD,
  REMOVE,
} from "../commonUtility/string-const";
import showAlert from "../commonUtility/confirmAlert";
import PopupModal from "../commonUtility/PopupModal";
import DomainTagInput from "./domainTag";

const styles = (theme) => ({
  colorSwitchBase: {
    color: blue[400],
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[700],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class AdminInstituteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      institution_name: "",
      abbreviation: "",
      institutionNameValid: false,
      abbreviationValid: false,
      domainsValid: false,
      upload_allowed: true,
      multiple_admin: false,
      code: 0,

      showLoader: false,
      formErrors: {
        institution_name: "",
        abbreviation: "",
        domains: "",
        error: "",
      },
      action: ACTION_INSTITUTE_ADD,
      isEdit: false,
      isDataCheckPerformed: false,
      displayAllowDataSubmissionModal: false,
      domains: "",
      domainList: [],
      focusFirstInput: true,
    };

    this.addEditInstitute = this.addEditInstitute.bind(this);
    this.changeInDataSubmission = this.changeInDataSubmission.bind(this);
  }

  handleChangeSwitch = (name) => (event) => {
    if (this.state.isEdit && name == "upload_allowed") {
      if (
        event.target.checked === true &&
        this.props.editInfo.upload_allowed == DISABLED
      ) {
        this.changeInDataSubmission();
      }
    }
    this.setState({ [name]: event.target.checked });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  validateForm() {
    this.setState({
      formValid:
        this.state.institutionNameValid && this.state.abbreviationValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  validateAndSumit = (event) => {
    event.preventDefault();
    const regexName =
      "^[a-zA-Z\u00D8-\u00f6\u00f8-\u00ff]+[a-zA-Z\u00D8-\u00f6\u00f8-\u00ff,.'-\\s]*$";
    const regexAbbreviation =
      "^[a-zA-Z\u00D8-\u00f6\u00f8-\u00ff]+[a-zA-Z\u00D8-\u00f6\u00f8-\u00ff,'-]*$";
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.error = "";
    fieldValidationErrors.abbreviation = "";
    fieldValidationErrors.institute_name = "";
    fieldValidationErrors.domains = "";
    let institutionNameValid = this.state.institutionNameValid;
    let abbreviationValid = this.state.abbreviationValid;
    let domainsValid = this.state.domainsValid;

    if (this.state.institution_name.trim().length == 0) {
      fieldValidationErrors.institution_name = "";
      fieldValidationErrors.institution_name = "Please enter Institution Name";
      institutionNameValid = false;
    }
    if (this.state.institution_name.trim().length > 0) {
      institutionNameValid =
        this.state.institution_name.trim().length > 0 &&
        this.state.institution_name.trim().length <= 100 &&
        this.state.institution_name.match(regexName);
      fieldValidationErrors.institution_name = institutionNameValid
        ? ""
        : "Only alphabets with spaces allowed";
    }

    if (this.state.abbreviation.trim().length == 0) {
      fieldValidationErrors.abbreviation = "";
      fieldValidationErrors.abbreviation = "Please enter Abbreviation";
      abbreviationValid = false;
    }
    if (this.state.abbreviation.trim().length > 0) {
      let abbreviationRegexValid =
        this.state.abbreviation.match(regexAbbreviation);
      let abbreviationLengthValid =
        this.state.abbreviation.trim().length > 0 &&
        this.state.abbreviation.trim().length <= 5;

      if (!abbreviationLengthValid) {
        fieldValidationErrors.abbreviation =
          "Length can't be more than 10 characters";
      }
      if (!abbreviationRegexValid) {
        fieldValidationErrors.abbreviation =
          "Only alphabets without spaces allowed";
      }

      abbreviationValid = abbreviationLengthValid && abbreviationRegexValid;
    }

    if (this.state.domains.trim().length == 0) {
      fieldValidationErrors.domains = "";
      fieldValidationErrors.domains = "Please enter Domain";
      domainsValid = false;
    }
    if (this.state.domains.trim().length > 0) {
      domainsValid = this.state.domains.trim().length > 0;
      fieldValidationErrors.domains = domainsValid ? "" : "Please enter Domain";
    }

    this.setState({
      formErrors: fieldValidationErrors,
      institutionNameValid: institutionNameValid,
      abbreviationValid: abbreviationValid,
      domainsValid: domainsValid,
    });

    if (institutionNameValid && abbreviationValid && domainsValid) {
      this.addEditInstitute();
    }
  };

  raiseDomainError(errMsg) {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.domains = errMsg;
    this.setState({
      formErrors: fieldValidationErrors,
    });
  }

  static getDerivedStateFromProps(props, state) {
    console.log("from Props: ", props.editInfo);
    console.log("from state: ", state);
    if (props.editInfo) {
      if (props.editInfo.code != state.code) {
        let edit_info = props.editInfo;
        return {
          institution_name: edit_info.institute_name,
          abbreviation: edit_info.abbreviation,
          multiple_admin:
            edit_info.multiple_admin_allowed == ALLOWED ? true : false,
          upload_allowed: edit_info.upload_allowed == ENABLED ? true : false,
          action: ACTION_INSTITUTE_UPDATE,
          isEdit: true,
          code: edit_info.code,
          domainList: edit_info.editDomainList,
          domains: edit_info.domains + ",",
          formErrors: {
            institution_name: "",
            abbreviation: "",
            domains: "",
            error: "",
          },
          focusFirstInput: true,
        };
      }
    }
    return {
      focusFirstInput: false,
    };
  }
  componentDidMount() {}

  closeUserAddEditForm() {
    this.setState({ isEdit: false });
    this.props.closeUserFormCallback();
  }

  addEditInstitute() {
    let input = {
      code: this.state.code,
      abbreviation: this.state.abbreviation.trim().toUpperCase(),
      name: this.state.institution_name.trim(),
      uploadAllowed: this.state.upload_allowed,
      multipleAdmin: this.state.multiple_admin,
      domains: this.state.domains.substring(0, this.state.domains.length - 1),
    };

    console.log(this.state.domainList);

    let dataCheckDone = this.state.isDataCheckPerformed;

    const config = {
      headers: {
        ...authHeader(),
      },
    };
    this.props.addEditInstituteLoaderCallback(true);
    console.log("Input ", input);
    var action = this.state.isEdit
      ? ACTION_INSTITUTE_UPDATE
      : ACTION_INSTITUTE_ADD;
    instance
      .post(
        "/api/saveInstitution/" + action + "/" + dataCheckDone,
        input,
        config
      )
      .then((response) => {
        console.log(response.data);
        this.props.addEditInstituteLoaderCallback(false);
        if (response && response.data && response.data.error) {
          if (response.data.error == "false") {
            this.props.getUserNotificationsAndEvents();

            console.log(this.state);
            this.props.addedInstituteCallback(
              response.data.data,
              response.data.message,
              false,
              TOAST_TYPE_SUCCESS
            );

            this.setState({
              code: 0,
              institution_name: "",
              abbreviation: "",
              upload_allowed: true,
              multiple_admin: false,
              action: ACTION_INSTITUTE_ADD,
              isEdit: false,
              domains: "",
              domainList: [],
            });
          } else {
            this.props.addedInstituteCallback(
              null,
              response.data.message,
              true,
              TOAST_TYPE_ERROR
            );
            console.log(this.props.editInfo);
            if (this.props.editInfo) {
              this.setState({
                upload_allowed:
                  this.props.editInfo.upload_allowed === ENABLED ? true : false,
                multiple_admin:
                  this.props.editInfo.multiple_admin_allowed === ALLOWED
                    ? true
                    : false,
              });
            }
          }
        } else {
          this.props.addedInstituteCallback(
            null,
            "An error occured.",
            true,
            TOAST_TYPE_ERROR
          );
        }
      })
      .catch((error) => {
        this.props.addEditInstituteLoaderCallback(false);

        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            this.props.addedInstituteCallback(
              null,
              "An error occured.",
              true,
              TOAST_TYPE_ERROR
            );
          }
        } else {
          this.props.addedInstituteCallback(
            null,
            "An error occured.",
            true,
            TOAST_TYPE_ERROR
          );
        }
      });
  }

  clearAll() {
    if (this.state.isEdit) {
      let edit_info = this.props.editInfo;
      this.setState({
        institution_name: edit_info.institute_name,
        abbreviation: edit_info.abbreviation,
        multiple_admin:
          edit_info.multiple_admin_allowed == ALLOWED ? true : false,
        upload_allowed: edit_info.upload_allowed == ENABLED ? true : false,
        action: ACTION_INSTITUTE_UPDATE,
        isEdit: true,
        code: edit_info.code,
        domainList: edit_info.editDomainList,
        domains: edit_info.domains,
        formErrors: {
          institution_name: "",
          abbreviation: "",
          error: "",
          domains: "",
        },
      });
    } else {
      this.setState({
        institution_name: "",
        abbreviation: "",
        institutionNameValid: false,
        abbreviationValid: false,
        upload_allowed: true,
        multiple_admin: false,
        domainList: [],
        domains: "",
        code: 0,

        showLoader: false,
        formErrors: {
          institution_name: "",
          abbreviation: "",
          error: "",
          domains: "",
        },
        action: ACTION_INSTITUTE_ADD,
        isEdit: false,
      });

      this.props.resetCallback();
    }
  }

  changeInDataSubmission() {
    this.setState({ displayAllowDataSubmissionModal: true });
  }

  setDataCheckPerformed(isDataCheckPerformed) {
    this.setState({
      isDataCheckPerformed: isDataCheckPerformed,
      displayAllowDataSubmissionModal: false,
    });
  }

  closePopup() {
    this.setState({ displayAllowDataSubmissionModal: false });
  }

  cancelPopup() {
    this.setState({
      displayAllowDataSubmissionModal: false,
      upload_allowed: !this.state.upload_allowed,
      isDataCheckPerformed: false,
    });
  }

  addDomains(domains, addOrRemove, domainList) {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.domains = "";
    this.setState({
      formErrors: fieldValidationErrors,
    });

    if (addOrRemove === ADD) {
      this.state.domains += domains.trim();
      this.setState({ domains: this.state.domains, domainList: domainList });
      console.log("intituteForm ", this.state.domains);
    } else if (addOrRemove === REMOVE) {
      let localDomain = this.state.domains;
      localDomain = localDomain.replace(domains.trim(), "");
      this.setState({ domains: localDomain.trim(), domainList: domainList });
      console.log("instituteForm ", this.state.domains);
    } else {
    }
  }
  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");

      this.closeUserAddEditForm();
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className="edit-form-border institute-form">
        <div className="form-padd">
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={this.validateAndSumit}
          >
            <div className="w-100">
              {this.state.displayAllowDataSubmissionModal ? (
                <PopupModal
                  title="Data Submission"
                  message="Is the data check performed?"
                  yesClickCallback={this.setDataCheckPerformed.bind(this, true)}
                  noClickCallback={this.setDataCheckPerformed.bind(this, false)}
                  cancelClickCallback={this.cancelPopup.bind(this)}
                  buttonPositive="Yes"
                  buttonNegative="No"
                  modalSize="sm"
                />
              ) : null}
              <div className="row">
                <div className="col-sm-4 col-lg-3">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.institution_name
                    )}`}
                  >
                    <input
                      id="institution_name"
                      name="institution_name"
                      label="Institution Name"
                      className={classes.textField + " w-100 form-control"}
                      type="Text"
                      value={this.state.institution_name}
                      onChange={this.handleUserInput}
                      autoComplete="current-institution_name"
                      margin="normal"
                      placeholder="Institution Name"
                      autoComplete="off"
                      maxLength="100"
                      autoFocus={true}
                      ref={(input) =>
                        this.state.focusFirstInput
                          ? input && input.focus()
                          : null
                      }
                    />
                    <div className="error-text">
                      <FormErrors
                        formErrors={this.state.formErrors.institution_name}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-lg-3">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.abbreviation
                    )}`}
                  >
                    <input
                      id="abbreviation"
                      name="abbreviation"
                      label="Abbreviation"
                      className={classes.textField + " w-100 form-control"}
                      type="Text"
                      value={this.state.abbreviation}
                      onChange={this.handleUserInput}
                      autoComplete="current-abbreviation"
                      margin="normal"
                      placeholder="Abbreviation"
                      autoComplete="off"
                      maxLength="5"
                    />
                    <div className="error-text">
                      <FormErrors
                        formErrors={this.state.formErrors.abbreviation}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-lg-6">
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.formErrors.domains
                    )}`}
                  >
                    <div className="data-form input-tag-library">
                      <DomainTagInput
                        addDomainCallback={this.addDomains.bind(this)}
                        domainList={this.state.domainList}
                        raiseErrorCallback={this.raiseDomainError.bind(this)}
                      />
                    </div>
                    <div className="error-text">
                      <FormErrors formErrors={this.state.formErrors.domains} />
                    </div>
                  </div>
                </div>

                <div className="col-auto form-controll-padd">
                  <div className="">
                    <input
                      id="datasub"
                      type="checkbox"
                      checked={this.state.upload_allowed}
                      onChange={this.handleChangeSwitch("upload_allowed")}
                      value={this.state.upload_allowed}
                      label="Primary"
                    />
                    <label htmlFor="datasub">&nbsp;Allow Data Submission</label>
                  </div>
                </div>
                <div className="col-auto mr-auto form-controll-padd">
                  <div className="">
                    <input
                      id="multiadmin"
                      type="checkbox"
                      checked={this.state.multiple_admin}
                      onChange={this.handleChangeSwitch("multiple_admin")}
                      value={this.state.multiple_admin}
                      label="Primary"
                    />
                    <label htmlFor="multiadmin">
                      &nbsp;Allow Multiple Admins
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto mr-auto text-right">
                  <div className="form-group">
                    <button type="submit" className="btn btn-default site">
                      {this.state.isEdit ? "Update" : "Add Institution"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-cancel"
                      onClick={() => this.clearAll()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div
                onClick={() => this.closeUserAddEditForm()}
                tabIndex="0"
                className="cross-icon"
                onKeyPress={(event) => this.enterPressed(event)}
              >
                <span>
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
              </div>
            </div>
          </form>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {},
    errorActionToast: (message) => {},
    getUserNotificationsAndEvents: () => {
      dispatch(actions.getUserNotificationsAndEvents());
    },
  };
};

AdminInstituteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  addedInstituteCallback: PropTypes.func,
  closeUserFormCallback: PropTypes.func,
  addEditInstituteLoaderCallback: PropTypes.func,
  resetCallback: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminInstituteForm));
