import React, { Component } from "react";
import Form from "./Form.js";
import logo from "./../../images/logo.png";
import Footer from "../commonUtility/Footer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      modal2: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  toggle2() {
    this.setState((prevState) => ({
      modal2: !prevState.modal2,
    }));
  }
  render() {
    return (
      <div className="login-page">
        <div className="">
          <div className="d-flex">
            <div className="flex-shrink-1  login-sidebar">
              <div className="w-100 height-200 d-flex align-items-center justify-content-center">
                <div>
                  {" "}
                  <Form />
                </div>

                <div className="disclamer mt-auto">
                  <p>
                    <a onClick={this.toggle}>Disclaimer</a> &nbsp;&nbsp;
                    <a onClick={this.toggle2}>Privacy Statement</a>&nbsp;&nbsp;{" "}
                    <a target="_blank" href="https://www.gemsriskdatabase.org/">
                      GEMs Website
                    </a>
                  </p>
                  <p className="copyright">
                    &copy; Copyright {(new Date().getFullYear())} European Investment Bank{" "}
                  </p>
                </div>
              </div>

              <div className="disclaimer">
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                  backdrop={false}
                >
                  <ModalHeader toggle={this.toggle}>DISCLAIMER</ModalHeader>
                  <ModalBody>
                    <div className="disclaimer">
                      <p>
                        This IT system and all related programs and data are the
                        property of the European Investment Bank. Access to them
                        is restricted and their use is intended solely for
                        persons who are authorised expressly and by name to
                        access them.
                      </p>

                      <p>
                        If you are not one of those persons, please be notified
                        that your connection is illegal. In that event, you are
                        requested to close down your connection immediately,
                        failing which the Bank may take all necessary
                        disciplinary and/or legal measures against you. For this
                        purpose, any or all uses of this system and all files on
                        this system may be intercepted, monitored, recorded,
                        copied, audited, inspected and disclosed.
                      </p>
                    </div>

                    <div className="mt-3 lib-model-foot">
                      <Button className="btn btn-default" onClick={this.toggle}>
                        OK
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
              </div>

              <div className="private-statment">
                <Modal
                  isOpen={this.state.modal2}
                  toggle={this.toggle2}
                  className={this.props.className}
                  backdrop={false}
                >
                  <ModalHeader toggle={this.toggle2} className="private-stat">
                    EUROPEAN INVESTMENT BANK - PRIVACY STATEMENT
                    <br />{" "}
                    <span className="samll-font">
                      Global Emerging Markets Risk Database Consortium (GEMs)
                    </span>
                  </ModalHeader>
                  <ModalBody>
                    <div className="pop-content">
                      <ol>
                        <li>
                          Description of the processing operation
                          <p>
                            This privacy statement provides information
                            regarding the processing of personal data carried
                            out by the European Investment Bank (the EIB or we /
                            our) in the course of performing the Secretariat
                            tasks for the Global Emerging Markets Risk Database
                            Consortium (GEMs).
                          </p>
                          <p>
                            It describes how the EIB, in the course of those
                            activities, processes personal data relating to
                            individuals (you) who are representatives of GEMs
                            member institutions.
                          </p>
                        </li>
                        <li>
                          Legal basis and the controller
                          <p>
                            Personal data are processed by the EIB (“EIB” or
                            “Controller”) in accordance with Regulation 1(EC)
                            2018/1725 of 23 October 2018 on the protection of
                            natural persons with regard to the processing of
                            personal data by the Union institutions, bodies and
                            agencies and on the free movement of such data, and
                            repealing Regulation (EC) No 45/2001 and Decision No
                            1247/2002/EC.
                          </p>
                          <p>
                            In accordance with the Cooperation Agreement
                            relating to the participation in GEMs, by sharing
                            risk data and risk methodologies related to default
                            and recovery rates, the GEMs Consortium would
                            contribute to the strengthening of the risk
                            management practices among its Members and to the
                            cooperation between member MDBs and member IFIs in a
                            domain of expertise where additional synergies are
                            desired.
                          </p>
                          <p>
                            All of the processing of personal data is necessary
                            so that the EIB can carry out its tasks in the
                            public interest in performing its role as
                            Secretariat of the GEMs Consortium. In some cases,
                            processing is also necessary so that the EIB can
                            comply with its legal obligations. Consent is in
                            general not the legal basis justifying our
                            processing of your personal data. If we do propose
                            to rely on your consent, we will make this clear at
                            the time.
                          </p>
                        </li>
                        <li>
                          Why do we process your personal data
                          <p>
                            The EIB processes your personal data as reasonably
                            necessary so that it can conduct and manage its
                            Secretariat functions of the GEMs Consortium in a
                            reasonable and proper matter, in accordance with
                            applicable law and regulation. Specifically, we
                            process your personal data for the following
                            purposes:
                          </p>
                          <ul>
                            <li>
                              Maintaining an up-to date list of office E-mail
                              addresses, office phone numbers [and personalized
                              log-ins] of representatives of GEMs member
                              organizations, which are involved in GEMs-related
                              work, for the purpose of contacting such
                              representatives on GEMs-related matters [and for
                              the purpose of enabling such representatives
                              access to the GEMs web tool].
                            </li>
                          </ul>
                        </li>
                        <li>
                          What personal data do we process?
                          <p>
                            We collect and process office E-mail addresses and
                            office phone numbers [and personalized log-ins].
                          </p>
                        </li>
                        <li>
                          Where do we obtain your personal data?
                          <p>
                            We may obtain your personal data directly from you
                            or from the organisation that you represent or with
                            which you are associated.
                          </p>
                        </li>
                        <li>
                          To whom is your data disclosed?
                          <p>
                            We may disclose personal data about you to member
                            organizations of the GEMs Consortium and their
                            representatives as well as to Acuity Knowledge
                            Partners as the data management service provider
                            according to the terms of the concluded Service
                            Agreement.
                          </p>
                        </li>

                        <li>
                          How long do we keep your personal data?
                          <p>
                            We keep your data only for as long as it is
                            necessary for the purposes described in this privacy
                            statement. At the time we are informed that the
                            representative of the GEMs member organization is no
                            longer representing the organization to the
                            Consortium, we delete stored personal data of such
                            former representative. For more specific information
                            as to the period for which we will keep your
                            personal data, please contact us (see the section
                            headed "Contact us", below).
                          </p>
                        </li>
                        <li>
                          What are your rights and how can you exercise them?
                          <p>
                            Your rights are set out in the Regulation (EU) No
                            2018/1725.
                          </p>
                          <p>
                            You have the right to ask us to (i) provide you with
                            a copy of your personal data; (ii) correct your
                            personal data; (iii) erase your personal data; or
                            (iv) restrict our processing of your personal data.
                            You can also object to our processing of your
                            personal data.
                          </p>
                          <p>
                            You can also lodge a complaint about our processing
                            of your personal data with the EIB’s Data Protection
                            Officer, Mr. Pelopidas Donos (
                            <a href="mailto:p.donos@eib.org">p.donos@eib.org</a>
                            ), or the European Data Protection Supervisor (
                            <a href="mailto:edps@edps.europa.eu">
                              edps@edps.europa.eu
                            </a>
                            ) at any time if you consider that your rights under
                            Regulation (EU) 2018/1725 have been infringed as a
                            result of the processing of your personal data by
                            the EIB.
                          </p>
                        </li>
                        <li>
                          Contact us
                          <p>
                            If you have any questions about our processing of
                            your personal data, or wish to exercise any of the
                            rights described above, please contact the GEMs
                            Secretariat, (
                            <a href="mailto:info@gems-riskdatabase.org">
                              info@gems-riskdatabase.org
                            </a>
                            ) or the EIB's Data Protection Officer, Mr.
                            Pelopidas Donos, by email at{" "}
                            <a href="mailto:p.donos@eib.org">p.donos@eib.org</a>{" "}
                            or at the following address:
                          </p>
                          <p>
                            Mr. Pelopidas Donos <br /> European Investment Bank{" "}
                            <br />
                            98-100 Boulevard Konrad Adenauer <br /> L-2950
                            Luxembourg (Grand Duchy of Luxembourg)
                          </p>
                        </li>
                      </ol>
                    </div>

                    <div className="mt-3 lib-model-foot">
                      <Button
                        className="btn btn-default"
                        onClick={this.toggle2}
                      >
                        OK
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>

            <div className="w-100">
              {" "}
              <div className="login-page-bg d-flex align-items-center justify-content-center">
                <img src={logo} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
