import React from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import RunCheck from "../commonUtility/Runcheck";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import PropTypes from "prop-types";
import { arrayEqual } from "../commonUtility/method-utils";
import {
  RUN_CHECK_LIST,
  DATA_VALIDATION_COMPLETED,
  DATA_VALIDATION_COMPLETED_NO_ERROR_ALL_RC,
  DATA_VALIDATION_COMPLETED_WITH_ALL_RC_ERROR_CAT_4_5,
} from "../commonUtility/string-const";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import PopupModal from "../commonUtility/PopupModal";

export class RuncheckButton extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.exportExcelView = this.exportExcelView.bind(this);
    this.callRunCheck = this.callRunCheck.bind(this);
    this.state = {
      dropdownOpen: false,
      runchecksList: [],
      runCheckExceptionMap: [],
      displayRunCheckModal: false,
      modalBodyMessage: "",
      modalTitle: "Data Validation Complete",
    };
  }

  getRunCheckList(rcl) {
    console.log(rcl);
    this.setState({ runchecksList: rcl });
  }

  componentDidMount() {
    let runchkList = sessionStorage.getItem("runcheck_list");
    if (runchkList != null) {
      runchkList = JSON.parse(runchkList);
      this.setState({ runchecksList: runchkList });
      this.callRunCheck(runchkList, true);
    }
  }

  closePopup() {
    this.setState({ displayRunCheckModal: false, modalBodyMessage: "" });
  }

  cancelPopup() {
    this.setState({ displayRunCheckModal: false, modalBodyMessage: "" });
  }


  callRunCheck(runchecksList, clickFromButton) {
    if (this.props.userData === undefined) {
      this.props.callUserDetails();
    }
    if (this.props.runCheckExecuting) {
      return;
    }
    this.props.setUnsetLoadingScreen(true);
    let isReferseh = false;
    if (clickFromButton) {
      let runchkList = sessionStorage.getItem("runcheck_list");
      if (runchkList != null) {
        runchkList = JSON.parse(runchkList);
        if (arrayEqual(runchecksList, runchkList)) {
          isReferseh = true;
        }
      }
    }
    if (runchecksList != null && runchecksList.length > 0) {
      sessionStorage.setItem("runcheck_list", JSON.stringify(runchecksList));
    }
    let defaultPeriod = sessionStorage.getItem("isDefault180");
    let defPrd = false;
    if (defaultPeriod != null) {
      defPrd = defaultPeriod == "true" ? true : false;
    }
    instance
      .post("/data/upload/runChecks/" + isReferseh, runchecksList, {
        headers: {
          ...authHeader(),
          isPeriod180: defPrd,
          isEoy: this.props.isAnnualMode,
        },
      })
      .then((res) => {
        let runChkExp = res.data;
        if (res && res.data.error !== undefined && res.data.error === false) {
          this.props.triggerReload(true);
          this.setState({ runCheckExceptionMap: runChkExp });
          this.props.callback(runChkExp);
          if (runChkExp != null && Object.keys(runChkExp.errorMap).length > 0) {

            if (!clickFromButton) {
              if (runchecksList.length === Object.keys(RUN_CHECK_LIST).length) {
                if (runChkExp.insertAllowed == false) {
                  this.setState({
                    displayRunCheckModal: true,
                    modalBodyMessage:
                      DATA_VALIDATION_COMPLETED_WITH_ALL_RC_ERROR_CAT_4_5,
                  });
                } else {
                  let isInsertPrivAllowed = this.props.userData
                    ? this.props.userData.privilege_id === 1 &&
                      this.props.userData.upload_allowed
                    : false;
                  if (isInsertPrivAllowed) {
                    this.setState({
                      displayRunCheckModal: true,
                      modalBodyMessage:
                        DATA_VALIDATION_COMPLETED_NO_ERROR_ALL_RC,
                    });
                  } else {
                    this.setState({
                      displayRunCheckModal: true,
                      modalBodyMessage: DATA_VALIDATION_COMPLETED,
                    });
                  }
                }
              } else {
                this.setState({
                  displayRunCheckModal: true,
                  modalBodyMessage: DATA_VALIDATION_COMPLETED,
                });
              }
            }
          } else {
            if (!clickFromButton) {
              let isInsertPrivAllowed = this.props.userData
                ? this.props.userData.privilege_id === 1 &&
                  this.props.userData.upload_allowed
                : false;
              if (isInsertPrivAllowed) {
                this.setState({
                  displayRunCheckModal: true,
                  modalBodyMessage: runChkExp.insertAllowed
                    ? DATA_VALIDATION_COMPLETED_NO_ERROR_ALL_RC
                    : DATA_VALIDATION_COMPLETED,
                });
              } else {
                this.setState({
                  displayRunCheckModal: true,
                  modalBodyMessage: DATA_VALIDATION_COMPLETED,
                });
              }
            }
          }
        }
        this.props.setUnsetLoadingScreen(false);
      })
      .catch((error) => {
        this.props.setUnsetLoadingScreen(false);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.reload();
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
  }

  clearAllCheckIfNotInLocalStorage() {}

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  exportExcelView() {
    instance({
      url: "/data/upload/generateSummary",
      method: "GET",
      responseType: "blob",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "excelview.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
  }

  render() {
    return (
      <div className="d-flex align-items-end flex-column runcheck_dropdown">
        {/* <Button id="caret" color="default mr-0" onClick={this.exportExcelView}>
          Export
        </Button> */}
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <Button
            id="caret"
            color="default mr-0"
            disabled={
              !(
                this.props.isRunCheckDisabled &&
                this.state.runchecksList.length > 0
              )
            }
            onClick={() => {
              if (this.state.dropdownOpen) {
                this.toggle();
              }
              this.props.triggerReload(false);
              this.callRunCheck(this.state.runchecksList, false);
            }}
          >
            Run Checks
          </Button>
          <DropdownToggle
            caret
            color="default drop-icon"
            disabled={!this.props.isRunCheckDisabled}
            onClick={() => this.clearAllCheckIfNotInLocalStorage()}
          />
          <DropdownMenu>
            <RunCheck
              isDropdown={true}
              runCheckListCallback={this.getRunCheckList.bind(this)}
              rcExpMap={this.state.runCheckExceptionMap}
              runcheckFromEV={this.state.runchecksList}
            />
          </DropdownMenu>
        </ButtonDropdown>
        {this.state.displayRunCheckModal ? (
          <PopupModal
            title={this.state.modalTitle}
            message={this.state.modalBodyMessage}
            yesClickCallback={this.closePopup.bind(this)}
            noClickCallback={this.closePopup.bind(this)}
            cancelClickCallback={this.cancelPopup.bind(this)}
            buttonPositive="OK"
            modalSize="md"
          />
        ) : null}
      </div>
    );
  }
}

RuncheckButton.propsType = {
  callback: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    isAnnualMode: state.auth.isAnnualMode,
    runCheckExecuting: state.auth.runCheckExecuting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUserDetails: () => {
      dispatch(actions.callUserDetails());
    },
    onRunCheck: (runCheckExecuting) => {
      dispatch(actions.runCheckExecuting(runCheckExecuting));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RuncheckButton);
