const customStylesSmall = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px dotted #eee",
    color: state.isSelected ? "rgba(0, 0, 0, 0.6)" : "#495057",
    backgroundColor: state.isSelected ? "#fff" : "#fff",
    backgroundColor: state.isFocused ? "#fff" : "#fff",
    color: state.isFocused ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.6)",

    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 20,
    paddingRight: 10,
    marginLeft: 6,
    textIndent: -17,
    fontSize: 13,
    fontWeight: "normal",
    width: "auto",
  }),
  control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 180,
    display: "flex",
    height: 30,
      fontSize:13,
    color: "rgba(0, 0, 0, 0.6)",

    border: "1px solid #cac7c7",
    boxShadow: "none",
    "&:focus-within": {
      border: "1px solid #233d63",
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.6 : 1;
    const transition = "opacity 100ms";

    return { ...provided, opacity, transition };
  },
};

export default customStylesSmall;
