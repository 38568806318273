import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button } from "reactstrap";
import blue from "@material-ui/core/colors/blue";
import { FormErrors } from "../commonUtility/FormErrors";
import { authHeader } from "../commonUtility/auth-header";
import instance from "../../instance";
import { connect } from "react-redux";
import { callUserDetails } from "../../store/actions/index";
import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
} from "../commonUtility/string-const";
import { ToastContainer } from "react-toastify";
import Loader from "react-loader-spinner";
import showAlert from "../commonUtility/confirmAlert";
import PopupModal from "../commonUtility/PopupModal";

const styles = (theme) => ({
  colorSwitchBase: {
    color: blue[400],
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[700],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class ChangePasswordTextFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
      formValid: false,
      formErrors: {
        oldPassword: "",
        newpassword: "",
        confirmPassword: "",
        error: "",
      },
      oldPasswordValid: false,
      oldPasswordNotBlank: false,
      newpasswordValid: false,
      newpasswordNotBlank: false,
      newpasswordRegexValid: false,
      confirmpasswordValid: false,
      confirmpasswordNotBlank: false,
      visible: false,
      message: "",
      alertColor: "info",
      showLoader: false,
      displayPasswordChangedModal: false,
      changePasswordModalMsg: null,
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.validateField(name, value);
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };

  validateField(fieldName, value) {
    console.log(this.state.newpasswordValid);
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.error = "";
    let oldPasswordValid = this.state.oldPasswordNotBlank;
    let newpasswordValid = this.state.newpasswordNotBlank;
    let confirmpasswordValid = this.state.confirmpasswordNotBlank;
    switch (fieldName) {
      case "oldpassword":
        fieldValidationErrors.oldPassword = "";
        if (value.trim().length == 0) {
          oldPasswordValid = false;
        }
        if (value.trim().length > 0) {
          oldPasswordValid = true;
        }
        break;
      case "newpassword":
        fieldValidationErrors.newpassword = "";
        if (value.trim().length == 0) {
          newpasswordValid = false;
        }
        if (value.trim().length > 0) {
          newpasswordValid = true;
        }
        break;
      case "confirmpassword":
        fieldValidationErrors.confirmPassword = "";
        if (value.trim().length == 0) {
          confirmpasswordValid = false;
        }
        if (value.trim().length > 0) {
          confirmpasswordValid = true;
        }
        break;
    }

    console.log("123 ", this.props.isFromFirstLogin);

    this.setState(
      {
        formErrors: fieldValidationErrors,
        oldPasswordNotBlank: this.props.isFromFirstLogin
          ? true
          : oldPasswordValid,
        newpasswordNotBlank: newpasswordValid,
        confirmpasswordNotBlank: confirmpasswordValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    console.log(this.state.oldPasswordNotBlank);
    this.setState({
      formValid:
        this.state.oldPasswordNotBlank &&
        this.state.newpasswordNotBlank &&
        this.state.confirmpasswordNotBlank,
    });
  }

  validateAndSubmit = (event) => {
    document.activeElement.blur();
    event.preventDefault();
    const regexName =
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#?@$%&*-])[a-zA-Z0-9#?@$%&*-]{8,20}$";
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.error = "";
    fieldValidationErrors.confirmPassword = "";
    fieldValidationErrors.newpassword = "";
    fieldValidationErrors.oldPassword = "";
    let newpasswordValid = this.state.newpasswordValid;
    let confirmpasswordValid = false;
    let errorMessage = "";
    let errorField = "";
    if (this.state.newpassword.length > 0) {
      newpasswordValid =
        this.state.newpassword.length >= 8 &&
        this.state.newpassword.length <= 20 &&
        this.state.newpassword.match(regexName) != null;
      errorMessage = newpasswordValid
        ? ""
        : "New password does not match the criteria";
      errorField = "newpassword";
    }

    if (newpasswordValid) {
      if (this.state.confirmpassword.length > 0) {
        let confirmpasswordValidMatchWithNewpassword =
          this.state.confirmpassword === this.state.newpassword;
        confirmpasswordValid = confirmpasswordValidMatchWithNewpassword;

        errorMessage = confirmpasswordValidMatchWithNewpassword
          ? ""
          : "Passwords do not match";
        errorField = "confirmPassword";
      }
    }

    this.setState({
      formErrors: fieldValidationErrors,

      newpasswordValid: newpasswordValid,
      confirmpasswordValid: confirmpasswordValid,
    });
    if (!this.props.isFromFirstLogin) {
    }
    this.setState({ showLoader: true });
    const config = {
      headers: {
        ...authHeader(),
        isNewPasswordValid: newpasswordValid && confirmpasswordValid,
      },
    };
    let reset = false;
    let data = {
      defaultPassword: this.state.oldpassword,
      newPassword: this.state.newpassword,
    };
    instance
      .put("/api/changePassword/" + reset, data, config)
      .then((response) => {
        this.setState({ showLoader: false });

        if (
          response.data &&
          response.data.error &&
          response.data.error == "false"
        ) {
          if (this.props.isFromFirstLogin) {
            this.props.callUserDetails();

            this.confirmPwdChange(response.data.message);
          } else {
            showToast(response.data.message, TOAST_TYPE_SUCCESS);
          }
          this.setState({
            oldpassword: "",
            newpassword: "",
            confirmpassword: "",
          });
        } else {
          if (response.data && response.data.message)
            if (
              response.data.auth != undefined &&
              response.data.auth == "false"
            ) {
              fieldValidationErrors.oldPassword = response.data.message;
              this.setState({ formErrors: fieldValidationErrors });
            } else {
              if (response.data.message == "INVALID_NEW_PASSWORD") {
                fieldValidationErrors[errorField] = errorMessage;
                this.setState({ formErrors: fieldValidationErrors });
              } else {
                showToast(response.data.message, TOAST_TYPE_ERROR);
              }
            }
        }
      })
      .catch((error) => {
        this.setState({ showLoader: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.reload();
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  };

  confirmPwdChange(msg) {
    this.setState({
      displayPasswordChangedModal: true,
      changePasswordModalMsg: msg,
    });
  }

  onModalOkPress() {
    window.location.replace("/");
  }

  closePasswordChangeModal() {
    this.setState({ displayPasswordChangedModal: false });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    console.log("ISFromFirstLogin ", this.props.isFromFirstLogin);
    const { classes } = this.props;
    var oldPasswordModule = (
      <div className="col-12">
        <div
          className={`form-group ${this.errorClass(
            this.state.formErrors.oldPassword
          )}`}
        >
          <input
            id="oldpassword"
            name="oldpassword"
            label="Old Password"
            placeholder="Old Password"
            className={classes.textField + " w-100 form-control"}
            type="password"
            autoComplete="current-oldpassword"
            margin="normal"
            autoComplete="off"
            onChange={this.handleUserInput}
            value={this.state.oldpassword}
          />
          <span>
            <FormErrors formErrors={this.state.formErrors.oldPassword} />
          </span>
        </div>
      </div>
    );
    return (
      <Paper className={classes.root}>
        {this.state.displayPasswordChangedModal ? (
          <PopupModal
            title="Change Password"
            message={this.state.changePasswordModalMsg}
            yesClickCallback={this.onModalOkPress.bind(this)}
            noClickCallback={this.onModalOkPress.bind(this)}
            cancelClickCallback={this.onModalOkPress.bind(this)}
            buttonPositive="OK"
          />
        ) : null}
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={this.validateAndSubmit}
        >
          <div className="col-sm-4">
            <div className="row pt-4">
              {this.props.isFromFirstLogin ? null : oldPasswordModule}
              <div className="col-12">
                <div
                  className={`form-group ${this.errorClass(
                    this.state.formErrors.newpassword
                  )}`}
                >
                  <input
                    id="newpassword"
                    name="newpassword"
                    label="New Password"
                    className={classes.textField + " w-100 form-control"}
                    type="password"
                    placeholder="New Password"
                    autoComplete="current-newpassword"
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleUserInput}
                    value={this.state.newpassword}
                  />
                  <span>
                    <FormErrors
                      formErrors={this.state.formErrors.newpassword}
                    />
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div
                  className={`form-group ${this.errorClass(
                    this.state.formErrors.confirmPassword
                  )}`}
                >
                  <input
                    id="confirmpassword"
                    name="confirmpassword"
                    label="Confirm New Password"
                    placeholder="Confirm New Password"
                    className={classes.textField + " w-100 form-control"}
                    type="password"
                    autoComplete="current-confirmpassword"
                    margin="normal"
                    autoComplete="off"
                    onChange={this.handleUserInput}
                    value={this.state.confirmpassword}
                  />
                  <span>
                    <FormErrors
                      formErrors={this.state.formErrors.confirmPassword}
                    />
                  </span>
                </div>
              </div>

              <div className="col-12 d-flex align-items-center justify-content-left m-2">
                <Button
                  type="submit"
                  color="default"
                  disabled={!this.state.formValid || this.state.showLoader}
                >
                  Change Password
                </Button>
                {this.state.showLoader ? (
                  <Loader
                    className="loader-2"
                    type="Oval"
                    color="#00BFFF"
                    height={25}
                    width={25}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-sm-6  offset-sm-2">
            <div className="reset-password-warn">
              <h5>
                <i className="fa fa-info-circle" /> Password Requirement
              </h5>
              <ul>
                <li>Should be at least 8 characters long</li>
                <li>Contain at least one number from [0 - 9]</li>
                <li>Contain at least one lowercase letter [a - z]</li>
                <li>Contain at least one uppercase letter [A - Z]</li>
                <li>Contain at least one special character [#?@$%&*]</li>
              </ul>
            </div>
          </div>
        </form>
      </Paper>
    );
  }
}

ChangePasswordTextFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  console.log("Change pwd props received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, { callUserDetails })(ChangePasswordTextFields)
);
