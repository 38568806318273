import React from "react";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import MaterialTable, { MTableToolbar, MTableEditRow } from "material-table";
import {
  ExcelViewActionAdd,
  ExcelViewActionDelete,
  ExcelViewActionUpdate,
  REF_COUNTERPART,
  YEAR_MIN,
  YEAR_MAX,
} from "../commonUtility/string-const";
import PropTypes from "prop-types";
import {
  isColumnMandatory,
} from "../commonUtility/method-utils";
import PubSub from "pubsub-js";
import {
  getExcelRowIdColumnEditTable,
  getRowValiditycolumnEditTable,
  getIdColumnEditColumn,
  getDropdownEditColumn,
  getTextEditColumn,
  getYearEditColumn
} from "./CustomGridComponents/EditColumns";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import _ from "lodash";


export default class RefCounterpart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      RefCounterPartList: [],
      selectedRow: null,
      isLoading: false,
      columns: [],
      currentPageSize: 10,
      disableAddonEmptySheet: true,
      refreshTable: true,
      gridOptions: {
        search: false,
        actionsColumnIndex: -1,
        showTitle: false,
        addRowPosition: "first",
        columnsButton: true,
        pageSizeOptions: [10, 20, 25],
        emptyRowsWhenPaging: false,
        thirdSortClick: false,
        rowStyle: (rowData) => ({
          backgroundColor:
            !rowData.valid &&
            rowData.highCat !== undefined &&
            parseInt(rowData.highCat) > 3
              ? rowData.tableData.id === this.state.selectedRow
                ? "#F5A9A9"
                : "#F6CECE"
              : rowData.tableData.id === this.state.selectedRow
              ? "#a9f5a9"
              : "#FFF",
        }),
        detailPanelType: "single",
      },
    };

    this.setColumns();
    this.tableRef = React.createRef();
  }

  checkIfColumnIsBlank(dataRow) {
    PubSub.publish("TRIGGER_ROW_VALIDATION", true);
    let validFlag = true;
    Object.keys(dataRow).forEach((item) => {
      if (
        dataRow[item] === undefined ||
        dataRow[item] === null ||
        dataRow[item].toString().trim() === ""
      ) {
        if (isColumnMandatory(REF_COUNTERPART, item)) {
          validFlag = validFlag && false;
        }
        if (
          item === "cod_region_ctp" &&
          dataRow["cod_country_ctp"].toLowerCase() === "reg"
        ) {
          validFlag = validFlag && false;
        }
      } else {
        const year_min = YEAR_MIN;
        const year_max = YEAR_MAX;
        switch (item) {
          case "maturity_ctp":
            if (
              !(
                parseInt(dataRow[item]) >= year_min &&
                parseInt(dataRow[item]) <= year_max
              )
            ) {
              validFlag = validFlag && false;
            }
            break;
          default:
            break;
        }
      }
    });
    return validFlag;
  }

  setColumns() {
    this.setState({
      columns: [
        getExcelRowIdColumnEditTable(),
        getRowValiditycolumnEditTable(),
        getIdColumnEditColumn(this, "id_counterpart", REF_COUNTERPART),
        getDropdownEditColumn(this, "cod_category_ctp", REF_COUNTERPART, "categoryOptions"),
        getTextEditColumn(this, "cod_sector_ctp", REF_COUNTERPART),
        getDropdownEditColumn(this, "cod_sector_ref_ctp", REF_COUNTERPART, "sectorRefOptions"),
        getTextEditColumn(this, "cod_subsector_ctp", REF_COUNTERPART),
        getDropdownEditColumn(this, "cod_country_ctp", REF_COUNTERPART, "countriesOptions"),
        getDropdownEditColumn(this, "cod_region_ctp", REF_COUNTERPART, "regionOptions"),
        getYearEditColumn(this, "maturity_ctp", REF_COUNTERPART),
        getDropdownEditColumn(this, "cross_default_clause", REF_COUNTERPART, "crossDefaultClauseOptions"),
      ],
    });
  }

  componentDidUpdate(oldProps) {
    if (Object.keys(this.state.RefCounterPartList).length === 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }

    if (
      oldProps.triggerReload !== this.props.triggerReload &&
      this.props.triggerReload
    ) {
      this.componentDidMount();
    }

    if (!_.isEqual(oldProps.paramList, this.props.paramList)) {
      this.setColumns();
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[1];
    if (pageDiv !== undefined) {
      pageDiv.hidden = flag;
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    let sheetName = "REF_COUNTERPART";
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        "content-type": "text/plain",
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .post("/data/upload/uploadedcontent", sheetName, config)
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data != null && Object.keys(response.data).length > 0) {
          this.setState({
            RefCounterPartList: response.data,
            disableAddonEmptySheet: false,
          });
          console.log("Response ::", this.state.RefCounterPartList);
          this.props.sizeCallbackRefCprt(
            this.state.RefCounterPartList.length,
            REF_COUNTERPART
          );
        }
        if (Array.isArray(response.data)) {
          this.setState({
            disableAddonEmptySheet: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
    this.returnTableToDefaultState();
  }

  syncObjectWithBackEnd(rowData, action, allList, oldData, resolve, reject) {
    let sheetName = "REF_COUNTERPART";
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        "content-type": "application/json",
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .post(
        "/data/upload/uploadedcontent/edit/" + sheetName + "/" + action,
        rowData,
        config
      )
      .then((response) => {
        if (response != null) {
          this.setState({
            selectedRow: null,
          });
          switch (action) {
            case ExcelViewActionAdd:
              this.setState({
                RefCounterPartList: [],
              });
              allList.forEach((value) => {
                value.id = value.id + 1;
              });
              allList.splice(0, 0, response.data.row);

              this.tableRef.current.dataManager.columns.forEach((items) => {
                items.tableData.filterValue = undefined;
              });
              break;
            case ExcelViewActionUpdate:
              const index = allList.findIndex((x) => x.id === oldData.id);
              allList[index] = response.data.row;
              break;
            case ExcelViewActionDelete:
              const indexDel = allList.findIndex((x) => x.id === rowData.id);
              allList.splice(indexDel, 1);
              allList.forEach((value, index) => {
                if (index >= indexDel) {
                  value.id = value.id - 1;
                }
              });
              if (allList.length === 0) {
                this.setState({
                  refreshTable: false,
                });
              }
              break;
            default:
              break;
          }
          if (
            response.data.statusMap !== undefined &&
            response.data.statusMap !== null
          )
            sessionStorage.setItem(
              "excel_summary",
              JSON.stringify(response.data.statusMap)
            );
          this.setState(
            {
              RefCounterPartList: allList.map((row) => ({
                ...row,
                tableData: {},
              })),
              refreshTable: true,
            },
            () => resolve()
          );
          this.props.sizeCallbackRefCprt(
            this.state.RefCounterPartList.length,
            REF_COUNTERPART
          );
        }
      })
      .catch((error) => {
        reject();
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
  }

  clearAllFilters() {
    this.setState(
      {
        RefCounterPartList: [],
      },
      () => {
        this.tableRef.current.dataManager.columns.forEach((items) => {
          items.tableData.filterValue = undefined;
        });
        this.setState({
          RefCounterPartList: this.tableRef.current.state.originalData,
        });
      }
    );
  }

  showAllColumns() {
    const originalData = this.tableRef.current.state.originalData;
    this.setState(
      {
        RefCounterPartList: [],
      },
      () => {
        this.tableRef.current.dataManager.columns.forEach((items) => {
          items.hidden = false;
        });
        this.setState({
          RefCounterPartList: originalData,
        });
      }
    );
  }

  clearAllErrors() {
    this.tableRef.current.dataManager.data.forEach((items) => {
      if (items.tableData !== undefined && items.tableData !== null)
        delete items.tableData.showDetailPanel;
    });
  }

  clearEditState() {
    this.setState(
      {
        RefCounterPartList: [],
      },
      () => {
        this.tableRef.current.dataManager.lastEditingRow = undefined;
        this.setState({
          RefCounterPartList: this.tableRef.current.state.originalData,
        });
      }
    );
  }

  returnTableToDefaultState() {
    this.clearAllErrors();
    this.clearAllFilters();
    this.clearEditState();
    this.showAllColumns();
    this.setState({
      selectedRow: null,
    });
  }

  render() {
    if (this.state.refreshTable) {
      return (
        <MaterialTable
          tableRef={this.tableRef}
          columns={this.state.columns}
          isLoading={this.state.isLoading}
          data={this.state.RefCounterPartList}
          localization={{
            body: {
              addRemoveColumns: "Add",
            },
            toolbar: {
              addRemoveColumns: "Show/Hide Columns",
              showColumnsTitle: "Show/Hide Columns",
            },
          }}
          onChangeRowsPerPage={(pageSize) => {
            this.setState({ currentPageSize: pageSize });
            let timesRun = 0;
            let firstTimeExecuted = false;
            var hasVScroll = window.innerHeight <= document.body.offsetHeight;
            this.interval = setInterval(() => {
              if (timesRun === 5) {
                clearInterval(this.interval);
              }
              timesRun++;
              if (hasVScroll) {
                window.scrollTo(0, 0);
                clearInterval(this.interval);
              } else {
                if (firstTimeExecuted) {
                  clearInterval(this.interval);
                }
                firstTimeExecuted = true;
              }
            }, 1);
          }}
          actions={[
            {
              icon: "clear_all",
              tooltip: "Clear All Filters",
              hidden: this.state.RefCounterPartList.length === 0,
              isFreeAction: true,
              onClick: () => this.clearAllFilters(),
            },
          ]}
          options={{
            ...this.state.gridOptions,
            draggable: false,
            filtering: this.state.RefCounterPartList.length > 0,
            sorting: this.state.RefCounterPartList.length > 0,
            pageSize: this.state.currentPageSize,
          }}
          onRowClick={(evt, selectedRow, togglePanel) => {
            if (
              this.tableRef.current !== null &&
              this.tableRef.current.state.lastEditingRow === undefined &&
              !this.tableRef.current.state.showAddRow
            ) {
              if (!selectedRow.valid) togglePanel();
              this.setState({
                selectedRow: selectedRow.tableData.id,
              });
            }
          }}
          editable={
            !this.state.disableAddonEmptySheet
              ? {
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      const data = this.state.RefCounterPartList;
                      let newRow = {
                        id_counterpart: newData.id_counterpart,
                        cod_category_ctp: newData.cod_category_ctp,
                        cod_sector_ctp: newData.cod_sector_ctp,
                        cod_sector_ref_ctp: newData.cod_sector_ref_ctp,
                        cod_subsector_ctp: newData.cod_subsector_ctp,
                        cod_country_ctp: newData.cod_country_ctp,
                        cod_region_ctp: newData.cod_region_ctp,
                        maturity_ctp: newData.maturity_ctp,
                        cross_default_clause: newData.cross_default_clause,
                        valid: true,
                        id: 2,
                        info: null,
                      };

                      if (this.checkIfColumnIsBlank(newRow)) {
                        this.syncObjectWithBackEnd(
                          newRow,
                          ExcelViewActionAdd,
                          data.length === undefined ? [] : data,
                          [],
                          resolve,
                          reject
                        );
                      } else {
                        reject();
                      }
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      const data = this.state.RefCounterPartList;
                      if (this.checkIfColumnIsBlank(newData)) {
                        this.syncObjectWithBackEnd(
                          newData,
                          ExcelViewActionUpdate,
                          data,
                          oldData,
                          resolve,
                          reject
                        );
                      } else {
                        reject();
                      }
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      let data = this.state.RefCounterPartList;
                      this.syncObjectWithBackEnd(
                        oldData,
                        ExcelViewActionDelete,
                        data,
                        [],
                        resolve,
                        reject
                      );
                    }),
                }
              : null
          }
          components={{
            Toolbar: (props) => (
              <div className="row">
                <div className="col-auto mr-auto">
                  {this.tableRef.current !== null &&
                  (this.tableRef.current.state.lastEditingRow !== undefined ||
                    this.tableRef.current.state.showAddRow) ? (
                    <div className="alert alert-danger" role="alert">
                      <div className="d-flex flex-wrap">
                        <div className="error_list">
                          Note: Fields marked with * are mandatory
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-4 excel-buttons">
                  <MTableToolbar {...props} />
                </div>
              </div>
            ),

            EditRow: (props) => {
              this.clearAllErrors();
              if (
                this.tableRef.current.dataManager.columns.filter(
                  (item) => item.hidden === true
                ).length > 0
              )
                this.showAllColumns();
              if (this.state.selectedRow !== null) {
                this.setState({
                  selectedRow: null,
                });
              }
              return <MTableEditRow {...props} />;
            },
          }}
          detailPanel={[
            (rowData) => ({
              tooltip: "Show/Hide Error",
              disabled: rowData.valid,
              icon: () => (
                <ErrorOutlineIcon
                  color="error"
                  style={{ display: rowData.valid ? "none" : "" }}
                  onClick={() => {
                    if (
                      this.tableRef.current !== null &&
                      this.tableRef.current.state.lastEditingRow ===
                        undefined &&
                      !this.tableRef.current.state.showAddRow
                    ) {
                      this.setState({ selectedRow: rowData.tableData.id });
                    }
                  }}
                />
              ),
              openIcon: () => (
                <ErrorOutlineIcon
                  color="error"
                  style={{ display: rowData.valid ? "none" : "" }}
                  onClick={() => {
                    this.setState({ selectedRow: null });
                  }}
                />
              ),
              render: () => (
                <div className="error-container">
                  <div className="heading-error">Errors</div>
                  {rowData.info !== undefined && rowData.info !== null
                    ? rowData.info.map((items) => {
                        return (
                          <div className="line-break mt-1 mb-1">
                            <div
                              className={
                                "cat cat" +
                                items.category +
                                " align-self-center"
                              }
                            >
                              {items.category}
                            </div>
                            <div className="error-text">{items.error}</div>
                          </div>
                        );
                      })
                    : null}
                </div>
              ),
            }),
          ]}
        />
      );
    } else {
      return null;
    }
  }
}

RefCounterpart.propTypes = {
  sizeCallbackRefCprt: PropTypes.func,
};
