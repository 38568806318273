import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import EventForm from "./EventForm";
import { authHeader } from "../commonUtility/auth-header";
import { connect } from "react-redux";
import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
} from "../commonUtility/string-const";
import MaterialTable from "material-table";
import LoaderComponent from "../commonUtility/LoaderComponent";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { FormErrors } from "../commonUtility/FormErrors";
import Select from "react-select";
import moment from "moment";
import customStyles from "../commonUtility/ReactSelectCss";
/**
 * Patch for showing the select popup above other containers
 */

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class DashboardManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      showEventForm: false,
      eventInfo: [],
      isFormOpen: false,
      columns: [],
      isLoading: false,
      firstTymLoad: false,
      tempEventInfo: [],
      searchText: "",
      searchClass: "fa fa-search",
      clearClass: "fa fa-times",
      currentPageSize: 10,
      datasetYear: {
        value: moment().subtract(1, "years").year(),
        label: moment().subtract(1, "years").year(),
      },
      datasetYearError: "",
      validDatasetYears: [
        moment().subtract(1, "years").year(),
        moment().subtract(2, "years").year(),
        moment().subtract(3, "years").year(),
        moment().subtract(4, "years").year(),
        moment().subtract(5, "years").year(),
      ],
    };
    this.dashtoggle = this.dashtoggle.bind(this);
    this.showEventForm = this.showEventForm.bind(this);
    this.startSearching = this.startSearching.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.enterPressedTab = this.enterPressedTab.bind(this);
    this.setDataSetYear = this.setDataSetYear.bind(this);
  }

  dashtoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidUpdate() {
    if (Object.keys(this.state.eventInfo).length === 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv !== undefined) {
      pageDiv.hidden = flag;
    }
  }

  loadTableColumns() {
    let tempColumns = [
      {
        title: "Event Description",
        field: "description",
        type: "string",
        editable: "never",
        sorting: true,
        customSort: (a, b) =>
          a.description.toLowerCase() < b.description.toLowerCase() ? -1 : 1,
      },
      {
        title: "Start Date",
        field: "startDate",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => moment(rowData.startDate).format("DD-MM-YYYY"),
      },
      {
        title: "End Date",
        field: "endDate",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => moment(rowData.endDate).format("DD-MM-YYYY"),
      },
      {
        title: "Publish Status",
        field: "status",
        type: "string",
        editable: "never",
        sorting: true,
      },
      {
        title: "Last Updated Date",
        field: "updateOn",
        type: "string",
        editable: "never",

        sorting: true,
      },
    ];
    this.setState({ columns: tempColumns, firstTymLoad: false });
  }

  componentDidMount() {
    this.setState({ firstTymLoad: true });
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };

    instance
      .get("/dashboard/datasetYear/get", config)
      .then((response) => {
        this.setState({
          datasetYear: {
            value: response.data.datasetYear,
            label: response.data.datasetYear,
          },
        });
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });

    instance
      .get("/dashboard/getAll", config)
      .then((response) => {
        let eventsList = response.data.map((list, index) => {
          return {
            ...list,
            sid: index + 1,
          };
        });
        this.setState({
          eventInfo: eventsList,
          tempEventInfo: eventsList,
        });
        this.loadTableColumns();
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  showEventForm() {
    this.setState({ showEventForm: true, editInfo: null, isFormOpen: true });
  }

  addNewEvent(data, message, error, type) {
    this.setState({ searchText: "", firstTymLoad: true });
    this.startSearching(null);
    if (!error) {
      let value = sessionStorage.getItem("gems_bearer_token");
      const config = {
        headers: {
          b_token: value,
          ...authHeader(),
        },
      };

      instance
        .get("/dashboard/getAll", config)
        .then((response) => {
          let eventsList = response.data.map((list, index) => {
            return {
              ...list,
              sid: index + 1,
            };
          });
          this.setState({
            eventInfo: eventsList,
            tempEventInfo: eventsList,
          });
          this.loadTableColumns();
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response.status === 401) {
              console.log("Auth error");
              sessionStorage.clear();

              window.location.replace("/");
            } else {
              showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
            }
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        });
    }
    showToast(message, type);
    this.setState({ firstTymLoad: false });
  }

  enterEdit(dataItem) {
    console.log("Enter edit ", dataItem);
    this.setState({
      editInfo: dataItem,
      showEventForm: true,
      isFormOpen: true,
    });
    window.scrollTo(0, 0);
  }

  startSearching(e) {
    if (e != null) {
      e = e.target.value;
      this.setState({ searchText: e });
      if (e != null && e.toString().trim() !== "") {
        let userLst = this.state.tempEventInfo;
        let searchItems = [];
        for (var j = 0; j < userLst.length; j++) {
          for (var key in userLst[j]) {
            if (
              key !== "tableData" &&
              key !== "id" &&
              key !== "sid" &&
              key !== "endOfEvent" &&
              userLst[j][key] != null
            ) {
              if (
                userLst[j][key]
                  .toString()
                  .toLowerCase()
                  .includes(e.toLowerCase())
              ) {
                console.log(userLst[j]);
                searchItems.push(userLst[j]);
                break;
              }
            }
          }
        }
        console.log(searchItems);
        this.setState({ eventInfo: searchItems });
      } else {
        this.setState({ eventInfo: this.state.tempEventInfo });
      }
    } else {
      this.setState({ eventInfo: [] });
      this.setState({ eventInfo: this.state.tempEventInfo });
    }
  }

  closeEventForm() {
    this.setState({ showEventForm: false, editInfo: null, isFormOpen: false });
  }

  clearAll() {
    this.setState({ editInfo: null });
  }

  searchIconClick(clear) {
    if (clear) {
      this.setState({ searchText: "" });
      this.startSearching(null);
    }
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");
      this.searchIconClick(this.state.searchText.trim().length > 0);
    }
  };

  enterPressedTab = (event, num) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");
      this.dashtoggle(num);
    }
  };

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  setDataSetYear() {
    let input = {
      datasetYear: this.state.datasetYear.value,
    };
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .post("dashboard/datasetYear/save", input, config)
      .then((response) => {
        console.log(response);
        if (response.status === 200)
          showToast("Dataset Year saved successfully.", TOAST_TYPE_SUCCESS);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <Nav tabs className="tab-sec">
            <NavItem>
              <NavLink
                tabIndex="0"
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.dashtoggle("1");
                }}
                onKeyUp={(event) => this.enterPressedTab(event, "1")}
              >
                Event Management
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                tabIndex="0"
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.dashtoggle("2");
                }}
                onKeyUp={(event) => this.enterPressedTab(event, "2")}
              >
                Dataset Year
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="2">
              {this.state.firstTymLoad ? (
                <FullScreenLoaderComponent msg="" />
              ) : null}
              {this.state.firstTymLoad ? null : (
                <div className="row datasetyearmar">
                  <div className=" col-auto">
                    <div
                      className={`form-group ${this.errorClass(
                        this.state.datasetYearError
                      )}`}
                    >
                      <Select
                        required
                        name="datasetYear"
                        id="datasetYear"
                        value={this.state.datasetYear}
                        onChange={(value) => {
                          this.setState({ datasetYear: value });
                        }}
                        className="react-select"
                        placeholder="Select Dataset Year"
                        isSearchable={true}
                        noOptionsMessage={() => "No Results"}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        options={this.state.validDatasetYears.map((years) => ({
                          value: years,
                          label: years,
                        }))}
                        classNamePrefix="react-select-menulist datasetyear"
                      />

                      <div className="error-text">
                        <FormErrors formErrors={this.state.datasetYearError} />
                      </div>
                    </div>
                  </div>
                  <div className="col-auto pl-0">
                    <div className="">
                      <div className="form-group padding-save">
                        <button
                          type="submit"
                          className="btn btn-default site"
                          onClick={this.setDataSetYear.bind(this)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="1" className="minius-5">
              {this.state.firstTymLoad ? (
                <FullScreenLoaderComponent msg="" />
              ) : null}
              {this.state.showEventForm ? (
                <EventForm
                  addedEventCallback={this.addNewEvent.bind(this)}
                  editInfo={this.state.editInfo}
                  closeEventFormCallback={this.closeEventForm.bind(this)}
                  resetCallback={this.clearAll.bind(this)}
                  addEditEventLoaderCallback={this.startLoader.bind(this)}
                />
              ) : null}
              <div>
                <Paper className={classes.root + " event-list "}>
                  {this.state.isLoading ? <LoaderComponent /> : null}
                  {this.state.firstTymLoad ? (
                    <FullScreenLoaderComponent msg="" />
                  ) : null}
                  {this.state.firstTymLoad ? null : (
                    <div className="top-bar">
                      <div className="row">
                        <div className="col-auto align-self-center">
                          <div className="search-bar">
                            <div id="custom-search-input">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="search-query form-control"
                                  placeholder="Search"
                                  onChange={this.startSearching}
                                  value={this.state.searchText}
                                  tabIndex="0"
                                />
                                <span className="input-group-btn" tabIndex="-1">
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    tabIndex="-1"
                                  >
                                    <i
                                      className={
                                        this.state.searchText.trim().length > 0
                                          ? this.state.clearClass
                                          : this.state.searchClass
                                      }
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.searchIconClick(
                                          this.state.searchText.trim().length >
                                            0
                                        )
                                      }
                                      onKeyUp={(event) =>
                                        this.enterPressed(event)
                                      }
                                      tabIndex={
                                        this.state.searchText.trim().length > 0
                                          ? "0"
                                          : "-1"
                                      }
                                    />
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto ml-auto align-self-center">
                          {this.state.isFormOpen ? null : (
                            <div
                              aria-label="Add"
                              className="addbutton"
                              onClick={this.showEventForm}
                            >
                              <button className="btn btn-default">
                                Add Event
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.firstTymLoad ? null : (
                    <MaterialTable
                      columns={this.state.columns}
                      data={this.state.eventInfo}
                      onChangeRowsPerPage={(pageSize) => {
                        this.setState({ currentPageSize: pageSize });
                        let timesRun = 0;
                        let firstTimeExecuted = false;
                        var hasVScroll =
                          window.innerHeight <= document.body.offsetHeight;
                        this.interval = setInterval(() => {
                          if (timesRun == 5) {
                            clearInterval(this.interval);
                          }
                          timesRun++;
                          console.log("service ", hasVScroll);
                          if (hasVScroll) {
                            window.scrollTo(0, 0);
                            clearInterval(this.interval);
                          } else {
                            if (firstTimeExecuted) {
                              clearInterval(this.interval);
                            }
                            firstTimeExecuted = true;
                          }
                        }, 1);
                      }}
                      options={{
                        showTitle: false,
                        searchFieldAlignment: "left",
                        actionsColumnIndex: -1,
                        pageSize: this.state.currentPageSize,
                        pageSizeOptions: [10, 20, 25],
                        sorting: this.state.eventInfo.length > 0,
                        search: false,
                        emptyRowsWhenPaging: false,
                        draggable: false,
                        thirdSortClick: false,
                      }}
                      actions={[
                        (rowData) => {
                          return {
                            icon: "edit",
                            tooltip: "Edit",
                            hidden:
                              rowData.status === "Expired" ||
                              rowData.status === "Cancelled",
                            onClick: (event, rowData) =>
                              this.enterEdit(rowData),
                          };
                        },
                      ]}
                    />
                  )}
                </Paper>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {},
    errorActionToast: (message) => {},
  };
};

DashboardManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  passMessage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardManagement));
