import React, { Component } from "react";
import PropTypes from "prop-types";
import { ADD, REMOVE, TAG_TYPE_LIBRARY } from "../commonUtility/string-const";

import { getDayOfYear, getDaysInMonth } from "date-fns";
import { compose } from "@material-ui/system";
import ReactTags from "../commonUtility/react-tag-custom/ReactTags";

class InputTag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags,
      suggestions: this.props.suggestion,
      newSuggestionId: "new1",
      placeholder: "Tag",
    };
    this.suggestionsFilter = this.suggestionsFilter.bind(this);
  }

  handleDelete(i) {
    const tags = this.state.tags.slice(0);
    if (tags.length > 0) this.props.tagCallback(tags[i].name + ",", REMOVE);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  handleInputChange(input) {
    if (input.toString() == "(" || input.toString() == ")") {
      return;
    }
    let suggestionAlreadyFound = false;
    for (var j = 0; j < this.state.suggestions.length; j++) {
      if (
        this.state.suggestions[j].name.toString().toLowerCase() ==
          input.toLowerCase() ||
        this.state.suggestions[j].name.toString().toLowerCase() ==
          input + " (New Tag)".toLowerCase()
      ) {
        suggestionAlreadyFound = true;
        break;
      }
    }
    if (!suggestionAlreadyFound) {
      let found = false;
      let foundIndex = -1;
      for (var i = 0; i < this.state.suggestions.length; i++) {
        if (
          this.state.suggestions[i].id.toString().toLowerCase() ==
          this.state.newSuggestionId
        ) {
          found = true;
          foundIndex = i;
          break;
        }
      }
      if (found) {
        this.state.suggestions[foundIndex] = {
          name: input + " (New Tag)",
          id: this.state.newSuggestionId,
        };
        this.setState({ suggestions: this.state.suggestions });
      } else {
        this.state.suggestions.push({
          name: input + " (New Tag)",
          id: this.state.newSuggestionId,
        });
        this.setState({ suggestions: this.state.suggestions });
      }
      console.log(this.state.suggestions);
    } else {
      let suggestions = this.state.suggestions;
      for (var j = 0; j < suggestions.length; j++) {
        if (suggestions[j].name.toString().toLowerCase().includes("new tag")) {
          suggestions.splice(j, 1);
          break;
        }
      }
      this.setState({ suggestions });
    }
  }

  handleAddition(tag) {
    console.log(this.state.tags);
    let isNewSuggestion = false;
    if (tag.name.toString().trim() != "" && this.state.tags.length < 10) {
      if (tag["id"] == this.state.newSuggestionId) {
        isNewSuggestion = true;
      }
      tag["name"] = tag.name.replace(" (New Tag)", "");
      tag["id"] = tag.name;
      this.setState({ newSuggestionId: "new1" });
      let found = false;
      for (var i = 0; i < this.state.tags.length; i++) {
        if (
          this.state.tags[i].name.toString().toLowerCase().trim() ===
          tag.name.toString().toLowerCase().trim()
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.props.tagCallback(tag.name + ", ", ADD);
        const tags = [].concat(this.state.tags, tag);
        console.log(tag);
        this.setState({ tags });
      }
    }
    if (isNewSuggestion) {
      let suggestions = this.state.suggestions;
      for (var j = 0; j < suggestions.length; j++) {
        if (
          suggestions[j].name.toString().toLowerCase().trim() ===
          tag.name.toLowerCase()
        ) {
          suggestions.splice(j, 1);
          break;
        }
      }
      this.setState({ suggestions });
    }
  }

  suggestionsFilter(listSuggestion, query) {
    return listSuggestion.name
      .toString()
      .toLowerCase()
      .startsWith(query.toLowerCase());
  }

  render() {
    return (
      <ReactTags
        className="test"
        placeholder={this.state.tags.length > 0 ? "" : this.state.placeholder}
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)}
        handleInputChange={this.handleInputChange.bind(this)}
        allowNew={true}
        delimiterChars={[",", " "]}
        inputAttributes={{ maxLength: 25 }}
        addOnBlur={false}
        tagType={TAG_TYPE_LIBRARY}
        autofocus={false}
      />
    );
  }
}

InputTag.propTypes = {
  tagCallback: PropTypes.func,
};

export default InputTag;
