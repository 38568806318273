import React from "react";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  CustomInput,
  Alert,
} from "reactstrap";

import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  UNKNOWN_ERROR,
  ADD,
  REMOVE,
  FIFTY_MB,
  FILE_SIZE_LIMIT_EXCEED,
  FILE__NAME_SIZE_LIMIT_EXCEED,
  FILE_NAME_SIZE_LIMIT_EXCEED,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
  SERVER_TIME_ZONE,
} from "../commonUtility/string-const";
import { ToastContainer } from "react-toastify";
import { callUserDetails } from "../../store/actions/index";
import { connect } from "react-redux";
import InputTag from "./TagAuto";
import LoaderComponent from "../commonUtility/LoaderComponent";
import {
  changeTableSize,
  isPresentInListIgnoreCase,
} from "../commonUtility/method-utils";
import showAlert from "../commonUtility/confirmAlert";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import PopupModal from "../commonUtility/PopupModal";
var moment = require("moment-timezone");

class LibList extends React.Component {
  constructor() {
    super();
    this.state = {
      fileList: [],
      tagList: [],
      DocLibraryList: [],
      isLoading: false,
      firstTymLoad: false,
      modal: false,
      nestedModal: false,
      closeAll: false,
      message: "",
      file: null,
      tags: [],
      searchText: "",
      tempDocLibraryList: [],
      searchClass: "fa fa-search",
      clearClass: "fa fa-times",
      editClicked: false,
      editRowData: null,
      editTagList: [],
      displayDeleteModal: false,
      deleteRow: null,
      currentPageSize: 10,
    };
    this.pushUploadedFile = this.pushUploadedFile.bind(this);
    this.toggle = this.toggle.bind(this);
    this.cancelToggle = this.cancelToggle.bind(this);
    this.toggleNested = this.toggleNested.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.addUploadedFileToList = this.addUploadedFileToList.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.addTags = this.addTags.bind(this);
    this.startSearching = this.startSearching.bind(this);
    this.pushUpdatedTags = this.pushUpdatedTags.bind(this);
    this.addUpdatedTagsToList = this.addUpdatedTagsToList.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  downloadFile(rowData) {
    let fileName = rowData.doc_name;
    if (rowData.doc_format != null)
      fileName = fileName + "." + rowData.doc_format;

    this.setState({ isLoading: true });
    instance({
      url: "/library/download?fileName=" + encodeURIComponent(fileName),
      method: "GET",
      responseType: "blob",
      headers: {
        ...authHeader(),
      },
    })
      .then((response) => {
        this.setState({ isLoading: false });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
  }

  deleteFile() {
    let rowData = this.state.deleteRow;
    let fullfileName = rowData.doc_name + "." + rowData.doc_format;
    this.setState({ firstTymLoad: true });
    const config = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    instance
      .post("/library/deleteDoc", fullfileName, config)
      .then((response) => {
        this.setState({ searchText: "" });
        this.startSearching(null);
        if (response && response.data.error && response.data.error == "false") {
          this.setState({
            isLoading: false,
            displayDeleteModal: false,
            deleteRow: null,
            firstTymLoad: false,
          });
          console.log("reponse ", response.data);
          this.removeFileFromList(response.data.data);

          let index = this.state.fileList.indexOf(fullfileName);
          console.log(this.state.fileList);
          if (index != -1) {
            this.state.fileList.splice(index, 1);
          }
          console.log(this.state.fileList);
          this.setState({ fileList: this.state.fileList });
          this.setState({ tagList: response.data.tagList });
          this.setState({ file: null });
          showToast(response.data.message, TOAST_TYPE_SUCCESS);
        } else {
          if (
            response &&
            response.data.error &&
            response.data.error == "true"
          ) {
            showToast(response.data.message, TOAST_TYPE_ERROR);
            this.setState({
              file: null,
              isLoading: false,
              firstTymLoad: false,
            });
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
            this.setState({
              file: null,
              isLoading: false,
              firstTymLoad: false,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          displayDeleteModal: false,
          deleteRow: null,
          firstTymLoad: false,
        });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            this.setState({ file: null });
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          this.setState({ file: null });
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      });
  }

  removeFileFromList(data) {
    let foundIndex = -1;
    for (var i = 0; i < this.state.DocLibraryList.length; i++) {
      if (
        this.state.DocLibraryList[i].doc_name +
          "." +
          this.state.DocLibraryList[i].doc_format ===
        data
      ) {
        foundIndex = i;
        break;
      }
    }
    if (foundIndex != -1) {
      this.state.DocLibraryList.splice(foundIndex, 1);

      this.state.DocLibraryList.map((val, index) => {
        if (index >= foundIndex) {
          val.id = val.id - 1;
        }
      });

      foundIndex = -1;
      for (var i = 0; i < this.state.tempDocLibraryList.length; i++) {
        if (
          this.state.tempDocLibraryList[i].doc_name +
            "." +
            this.state.tempDocLibraryList[i].doc_format ===
          data
        ) {
          foundIndex = i;
          break;
        }
      }
      if (foundIndex != -1) {
        this.state.tempDocLibraryList.splice(foundIndex, 1);

        this.state.tempDocLibraryList.map((val, index) => {
          if (index >= foundIndex) {
            val.id = val.id - 1;
          }
        });

        this.setState({
          DocLibraryList: this.state.DocLibraryList,
        });
      }
    }
  }

  componentDidMount() {
    this.getDataFromBE();
  }

  componentDidUpdate() {
    if (Object.keys(this.state.DocLibraryList).length == 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv != undefined) {
      pageDiv.hidden = flag;
    }
  }

  getDataFromBE() {
    let value = sessionStorage.getItem("gems_bearer_token");
    this.setState({ firstTymLoad: true });
    const config = {
      headers: {
        "content-type": "application/json",
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .get("/library/displayDoc", config)
      .then((response) => {
        if (response.data != null) {
          this.setState({ firstTymLoad: false });

          let tempDocLst = response.data.displayList.map((list, index) => {
            return {
              ...list,
              id: index + 1,
            };
          });
          this.setState({ DocLibraryList: tempDocLst });
          this.setState({ tempDocLibraryList: tempDocLst });
          this.setState({ fileList: response.data.fileList });
          this.setState({ tagList: response.data.tagList });
          var hasVScroll =
            window.innerHeight + window.scrollY <= document.body.offsetHeight;
          console.log(
            "data ",
            hasVScroll,
            document.body.offsetHeight,
            window.innerHeight + window.scrollY
          );
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            this.setState({ file: null });
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          this.setState({ file: null });
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      });
  }

  uploadFile(e) {
    let f = e.target.files[0];
    if (f != null) {
      let fileName = f.name.substring(0, f.name.lastIndexOf("."));
      let fileExtension = f.name.substring(
        f.name.lastIndexOf(".") + 1,
        f.name.length
      );
      if (fileName.length <= 100 && fileExtension.length <= 20) {
        if (f.size <= FIFTY_MB) {
          this.setState({ file: f });

          let isPresent = isPresentInListIgnoreCase(
            this.state.fileList,
            f.name
          );
          if (!isPresent) {
            console.log("File does npt exists");
            this.setState({ message: "" });
          } else {
            this.setState({
              message:
                "File with same name already exists. Uploading this file will replace the existing one.",
            });
            console.log("File already exists");
          }
        } else {
          e.target.value = null;
          this.setState({ file: null, message: "" });
          this.setState({ message: FILE_SIZE_LIMIT_EXCEED });
        }
      } else {
        e.target.value = null;
        this.setState({ file: null, message: "" });
        this.setState({ message: FILE_NAME_SIZE_LIMIT_EXCEED });
      }
    } else {
      this.setState({ file: null, message: "" });
    }
  }

  hasUserSelectedSorting() {
    let isActiveIndex = -1;
    var p = document.getElementsByClassName("MuiTableSortLabel-root");
    for (var i = 0; i < p.length; i++) {
      if (p[i].getAttribute("class").indexOf("active") > 0) {
        isActiveIndex = i;
        break;
      }
    }
    return isActiveIndex;
  }

  pushUpdatedTags(tags, fullfileName) {
    this.toggle();
    this.setState({ firstTymLoad: true });
    const config = {
      headers: {
        ...authHeader(),
        "content-type": "multipart/form-data",
        tags: tags,
      },
    };
    instance
      .post("/library/edit", fullfileName, config)
      .then((response) => {
        this.setState({ searchText: "" });
        this.startSearching(null);
        console.log(response.data);
        if (response && response.data.error && response.data.error == "false") {
          this.setState({
            isLoading: false,
            firstTymLoad: false,
            tagList: response.data.tagList,
            editClicked: false,
            editRowData: null,
            editTagList: [],
          });
          showToast(response.data.message, TOAST_TYPE_SUCCESS);

          this.addUpdatedTagsToList(tags, fullfileName);
          console.log("reponse ", response.data);
        } else {
          if (
            response &&
            response.data.error &&
            response.data.error == "true"
          ) {
            this.setState({
              isLoading: false,
              firstTymLoad: false,
              editClicked: false,
              editRowData: null,
              editTagList: [],
            });
            showToast(response.data.message, TOAST_TYPE_ERROR);
          } else {
            this.setState({
              isLoading: false,
              firstTymLoad: false,
              tagList: response.data.tagList,
              editClicked: false,
              editRowData: null,
              editTagList: [],
            });
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, firstTymLoad: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            this.setState({ file: null });
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          this.setState({ file: null });
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      });
  }

  pushUploadedFile() {
    console.log(this.state.DocLibraryList);
    this.setState({ firstTymLoad: true });
    this.toggle();
    let formData = new FormData();
    formData.append("file", this.state.file);
    console.log("after upoad ", this.state.file);
    console.log("File does not exists");

    const config = {
      headers: {
        ...authHeader(),
        "content-type": "multipart/form-data",
        tags: this.state.tags,
      },
    };

    console.log("While upload tag ", this.state.file);

    instance
      .post("/library/upload", formData, config)
      .then((response) => {
        this.setState({ searchText: "" });
        this.startSearching(null);
        if (response && response.data.error && response.data.error == "false") {
          this.addUploadedFileToList(response.data.data);
          if (this.state.fileList.indexOf(this.state.file.name) == -1) {
            this.state.fileList.unshift(this.state.file.name);
            this.setState({ fileList: this.state.fileList });
            this.setState({ tagList: this.state.tagList });
          }
          this.setState({ file: null });
          console.log("After add ", this.state.fileList);
          showToast(response.data.message, TOAST_TYPE_SUCCESS);
        } else {
          if (
            response &&
            response.data.error &&
            response.data.error == "true"
          ) {
            showToast(response.data.message, TOAST_TYPE_ERROR);
            this.setState({ file: null });
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
            this.setState({ file: null });
          }
        }
        this.setState({ firstTymLoad: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false, firstTymLoad: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            this.setState({ file: null });
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          this.setState({ file: null });
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      });
  }

  toggle(editClicked = false) {
    editClicked = editClicked === true ? editClicked : false;
    this.setState((prevState) => ({
      modal: !prevState.modal,
      message: "",
      tags: editClicked
        ? prevState.editRowData.tags === null
          ? ""
          : prevState.editRowData.tags + ","
        : "",
      editClicked: editClicked,
    }));
  }

  cancelToggle() {
    console.log("cacel roggle called");
    this.setState((prevState) => ({
      modal: !prevState.modal,
      message: "",
      tags: "",
      file: null,
      editClicked: false,
      editRowData: null,
    }));
  }

  toggleNested() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false,
    });
  }

  toggleAll() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: true,
    });
  }

  addUpdatedTagsToList(tags, fullfileName) {
    if (tags == "") {
      tags = null;
    }
    for (var i = 0; i < this.state.DocLibraryList.length; i++) {
      if (
        this.state.DocLibraryList[i].doc_name +
          "." +
          this.state.DocLibraryList[i].doc_format ==
        fullfileName
      ) {
        this.state.DocLibraryList[i].tags =
          tags != null
            ? tags
                .toString()
                .substring(0, tags.length - 1) /*.replace(/,/g, ', ')*/
            : null;
        break;
      }
    }
    this.setState({ DocLibraryList: this.state.DocLibraryList });
  }

  addUploadedFileToList(data) {
    let foundIdex = -1;
    let newRow = {
      upload_time: data.upload_date,
      doc_name: data.fileName,
      tags: data.tag,
      doc_format: data.format,
    };
    let tempNewRow = [];
    for (var i = 0; i < this.state.tempDocLibraryList.length; i++) {
      if (
        this.state.DocLibraryList[i].doc_name.toLowerCase() +
          "." +
          this.state.DocLibraryList[i].doc_format.toLowerCase() ===
        newRow.doc_name.toLowerCase() + "." + newRow.doc_format.toLowerCase()
      ) {
        foundIdex = i;
        break;
      }
    }
    if (foundIdex != -1) {
      this.state.tempDocLibraryList.splice(foundIdex, 1);
      this.state.tempDocLibraryList.map((val, index) => {
        if (index >= foundIdex) {
          val.id = val.id - 1;
        }
      });
    }
    newRow["id"] = 1;

    this.state.tempDocLibraryList.map((lib) => {
      lib.id = lib.id + 1;
    });
    this.state.tempDocLibraryList.unshift(newRow);

    let unupdatedTags = this.state.tagList;
    let newTags = newRow.tags.split(",");

    for (var i = 0; i < newTags.length; i++) {
      let foundElmnt = unupdatedTags.filter(
        (ele, index) =>
          index ===
          unupdatedTags.findIndex((element) => element.name === newTags[i])
      );
      if (foundElmnt.length == 0) {
        this.state.tagList.push({ id: newTags[i], name: newTags[i] });
      }
    }

    this.setState({
      DocLibraryList: this.state.tempDocLibraryList,
      tempDocLibraryList: this.state.tempDocLibraryList,
      tagList: this.state.tagList,
    });
  }

  addTags(tags, addOrRemove) {
    if (addOrRemove === ADD) {
      this.state.tags += tags.trim();
      this.setState({ tags: this.state.tags });
      console.log("liblist ", this.state.tags);
    } else if (addOrRemove === REMOVE) {
      let localTag = this.state.tags;
      localTag = localTag.replace(tags.trim(), "");

      this.setState({ tags: localTag.trim() });
      console.log("liblist ", this.state.tags);
    } else {
    }
  }

  startSearching(e) {
    if (e != null) {
      e = e.target.value;
      this.setState({ searchText: e });
      if (e != null && e.toString().trim() != "") {
        let docList = this.state.tempDocLibraryList;
        let searchItems = [];
        for (var j = 0; j < docList.length; j++) {
          for (var key in docList[j]) {
            if (key != "tableData" && key != "id" && docList[j][key] != null) {
              if (
                docList[j][key]
                  .toString()
                  .toLowerCase()
                  .includes(e.toLowerCase())
              ) {
                searchItems.push(docList[j]);
                break;
              }
            }
          }
        }
        console.log(searchItems);
        this.setState({ DocLibraryList: searchItems });
      } else {
        this.setState({ DocLibraryList: this.state.tempDocLibraryList });
      }
    } else {
      this.setState({ DocLibraryList: [] });
      this.setState({ DocLibraryList: this.state.tempDocLibraryList });
    }
  }

  confirmDeleteDoc(row) {
    this.setState({ displayDeleteModal: true, deleteRow: row });
  }

  searchIconClick(clear) {
    if (clear) {
      this.setState({ searchText: "" });
      this.startSearching(null);
    }
  }

  callEdit(rowData) {
    if (rowData.tags == "") {
      rowData.tags = null;
    }
    let _tags = [];
    if (rowData.tags != null && rowData.tags.split(",").length > 0) {
      let tagsArray = rowData.tags.split(",");
      for (var i = 0; i < tagsArray.length; i++) {
        _tags.push({ name: tagsArray[i], id: tagsArray[i] });
      }
    }
    this.setState({
      editClicked: true,
      editRowData: rowData,
      editTagList: _tags,
      tags: rowData.tags,
    });
    console.log(rowData.tags);
    this.toggle(true);
  }

  closeDeleteModal() {
    this.setState({ displayDeleteModal: false, deleteRow: null });
  }

  cancelPopup() {
    this.setState({ displayDeleteModal: false, deleteRow: null });
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");
      this.searchIconClick(this.state.searchText.trim().length > 0);
    }
  };

  render() {
    console.log("hy ", this.state.tags);
    console.log("liblist ", this.state.DocLibraryList);
    return (
      <div className="liblist">
        {this.state.displayDeleteModal ? (
          <PopupModal
            title="Delete File"
            message="Do you want to delete this file?"
            yesClickCallback={this.deleteFile.bind(this)}
            noClickCallback={this.closeDeleteModal}
            cancelClickCallback={this.cancelPopup.bind(this)}
            buttonPositive="Yes"
            buttonNegative="No"
          />
        ) : null}
        <div className="model-upload">
          <div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              backdrop={false}
            >
              <ModalHeader toggle={this.state.modal ? this.cancelToggle : null}>
                {this.state.editClicked ? "Update Tag" : "Upload File"}
              </ModalHeader>
              <ModalBody>
                {this.state.message != "" ? (
                  <Alert color="danger">{this.state.message}</Alert>
                ) : null}
                <div className="upload-btn">
                  <Form className="lib-model">
                    <FormGroup className="p-2 upload-parent">
                      <div className="d-flex  ">
                        <div className="align-self-center">
                          {!this.state.editClicked ? (
                            <label
                              className="custom-file-upload-lib"
                              tabIndex="-1"
                            >
                              <input
                                type="file"
                                onChange={this.uploadFile}
                                tabIndex="0"
                              />
                              Choose File
                            </label>
                          ) : null}
                        </div>
                        {!this.state.editClicked ? (
                          <div className="align-self-center">
                            <span className="filename">
                              {this.state.file != null
                                ? this.state.file.name
                                : ""}
                            </span>
                          </div>
                        ) : (
                          <div className="align-self-center">
                            <span className="filename">
                              {this.state.editRowData != null &&
                              this.state.editRowData.doc_name != null
                                ? this.state.editRowData.doc_name +
                                  "." +
                                  this.state.editRowData.doc_format
                                : ""}
                            </span>
                          </div>
                        )}
                      </div>
                    </FormGroup>
                  </Form>
                </div>
                <div className="data-form input-tag-library">
                  {this.state.editClicked ? (
                    <InputTag
                      tagCallback={this.addTags.bind(this)}
                      tags={this.state.editTagList}
                      suggestion={this.state.tagList}
                    />
                  ) : (
                    <InputTag
                      tagCallback={this.addTags.bind(this)}
                      tags={[]}
                      suggestion={this.state.tagList}
                    />
                  )}
                </div>
                <div className="mt-3 lib-model-foot">
                  {!this.state.editClicked ? (
                    this.state.file != null ? (
                      <Button
                        className="btn btn-default"
                        onClick={this.pushUploadedFile}
                      >
                        Upload
                      </Button>
                    ) : null
                  ) : (
                    <Button
                      className="btn btn-default"
                      onClick={(e) =>
                        this.pushUpdatedTags(
                          this.state.tags,
                          this.state.editRowData.doc_name +
                            "." +
                            this.state.editRowData.doc_format
                        )
                      }
                    >
                      Update
                    </Button>
                  )}
                  <Button
                    className="btn btn-cancel"
                    onClick={this.cancelToggle}
                    disabled={!this.state.modal}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
        {this.state.isLoading ? <LoaderComponent /> : null}
        {this.state.firstTymLoad ? <FullScreenLoaderComponent msg="" /> : null}

        <div className="top-bar">
          {this.state.firstTymLoad ? null : (
            <div className="row">
              <div className="col-auto align-self-center">
                <div className="search-bar">
                  <div id="custom-search-input">
                    <div className="input-group">
                      <input
                        type="text"
                        className="search-query form-control"
                        placeholder="Search"
                        onChange={this.startSearching}
                        value={this.state.searchText}
                        tabIndex="0"
                      />
                      <span className="input-group-btn" tabIndex="-1">
                        <button
                          className="btn btn-danger"
                          type="button"
                          tabIndex="-1"
                        >
                          <i
                            className={
                              this.state.searchText.trim().length > 0
                                ? this.state.clearClass
                                : this.state.searchClass
                            }
                            aria-hidden="true"
                            onClick={() =>
                              this.searchIconClick(
                                this.state.searchText.trim().length > 0
                              )
                            }
                            onKeyUp={(event) => this.enterPressed(event)}
                            tabIndex={
                              this.state.searchText.trim().length > 0
                                ? "0"
                                : "-1"
                            }
                          ></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto ml-auto align-self-center">
                {this.props.userData ? (
                  this.props.userData.userRole != 1 ? null : (
                    <Tooltip title="Upload file" disableFocusListener={true}>
                      <button className="btn btn-default" onClick={this.toggle}>
                        Add
                      </button>
                    </Tooltip>
                  )
                ) : (
                  this.props.callUserDetails()
                )}
              </div>
            </div>
          )}
        </div>

        {this.state.firstTymLoad ? null : (
          <MaterialTable
            title="Library document"
            columns={[
              { title: "Sr. No.", field: "id", hidden: true },
              {
                title: "Name",
                field: "doc_name",
                customSort: (a, b) =>
                  a.doc_name.toLowerCase() < b.doc_name.toLowerCase() ? -1 : 1,
              },
              {
                title: "Format",
                field: "doc_format",
                customSort: (a, b) =>
                  a.doc_format.toLowerCase() < b.doc_format.toLowerCase()
                    ? -1
                    : 1,
                render: (rowData) => {
                  if (rowData == null) {
                    return rowData;
                  } else {
                    return rowData.doc_format.toLowerCase();
                  }
                },
              },
              {
                title: "Tags",
                field: "tags",
                customSort: (a, b) => {
                  if (a.tags == b.tags) {
                    return 0;
                  } else if (a.tags == null || a.tags == "") {
                    return 1;
                  } else if (b.tags == null || b.tags == "") {
                    return -1;
                  } else if (a.tags.toLowerCase() < b.tags.toLowerCase()) {
                    return -1;
                  } else {
                    return 1;
                  }
                },
                render: (rowData) => {
                  let arrWithSpan = [];
                  if (rowData.tags != null) {
                    let arrRow = rowData.tags.split(",");
                    for (var i = 0; i < arrRow.length; i++) {
                      if (i == arrRow.length - 1) {
                        arrWithSpan.push(
                          <span className="no-wrap" key={i}>
                            {arrRow[i]}
                          </span>
                        );
                      } else {
                        arrWithSpan.push(
                          <span className="no-wrap" key={i}>
                            {arrRow[i] + ", "}
                          </span>
                        );
                      }
                    }
                  }

                  return arrWithSpan;
                },
              },
              {
                title: "Date",
                field: "upload_time",
                type: "date",
                customSort: (a, b) =>
                  new Date(a.upload_time) < new Date(b.upload_time) ? -1 : 1,
                render: (rowData) => {
                  let dateTime = rowData.upload_time;
                  if (dateTime != null) {
                    let dateTimeArr = dateTime.split(" ");
                    if (dateTimeArr.length == 2) {
                      let date = dateTimeArr[0];
                      let time = dateTimeArr[1];
                      let dateArr = date.split("/");
                      if (dateArr.length == 3) {
                        let year = dateArr[0];
                        let month = dateArr[1];
                        let dt = dateArr[2];
                        dateTime = dt + "-" + month + "-" + year + " " + time;
                      }
                    }
                  }
                  return dateTime;
                },
              },
            ]}
            onChangeRowsPerPage={(pageSize) => {
              this.setState({ currentPageSize: pageSize });
              let timesRun = 0;
              let firstTimeExecuted = false;
              var hasVScroll =
                window.innerHeight /* + window.scrollY*/ <=
                document.body.offsetHeight;
              this.interval = setInterval(() => {
                if (timesRun == 5) {
                  clearInterval(this.interval);
                }
                timesRun++;
                console.log("service ", hasVScroll);
                if (hasVScroll) {
                  window.scrollTo(0, 0);
                  clearInterval(this.interval);
                } else {
                  if (firstTimeExecuted) {
                    clearInterval(this.interval);
                  }
                  firstTimeExecuted = true;
                }
              }, 1);
            }}
            data={this.state.DocLibraryList}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Download",
                onClick: (event, rowData) => {
                  this.downloadFile(rowData);
                },
              },
              (rowData) => ({
                icon: "delete_outline",
                tooltip: "Delete",
                hidden: this.props.userData
                  ? this.props.userData.userRole === 1
                    ? false
                    : true
                  : true,
                onClick: (event, rowData) => {
                  this.confirmDeleteDoc(rowData);
                },
              }),
              {
                icon: "edit",
                tooltip: "Edit Tag",
                disableFocusListener: true,
                hidden: this.props.userData
                  ? this.props.userData.userRole === 1
                    ? false
                    : true
                  : true,
                onClick: (event, rowData) => {
                  this.callEdit(rowData);
                },
              },
            ]}
            options={{
              hideSortIcon: true,
              actionsColumnIndex: -1,
              showTitle: false,
              searchFieldAlignment: "left",
              pageSize: this.state.currentPageSize,
              pageSizeOptions: [10, 20, 25],
              search: false,
              emptyRowsWhenPaging: false,
              draggable: false,
              sorting: this.state.DocLibraryList.length > 0,
              thirdSortClick: false,
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Navigation Props received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
  };
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default connect(mapStateToProps, { callUserDetails })(LibList);
