import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import parse from "html-react-parser";
class PopupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      message: "",
      buttonPositive: this.props.buttonPositive,
      buttonNegative: this.props.buttonNegative,
    };

    this.toggle = this.toggle.bind(this);
    this.cancelToggle = this.cancelToggle.bind(this);
    this.yesToggle = this.yesToggle.bind(this);
    this.noToggle = this.noToggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      message: "",
    }));
  }

  cancelToggle() {
    console.log("cacel roggle called");
    this.setState((prevState) => ({
      modal: !prevState.modal,
      message: "",
    }));
    this.props.cancelClickCallback();
  }

  yesToggle() {
    console.log("cacel roggle called");
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.props.yesClickCallback();
  }

  noToggle() {
    console.log("cacel roggle called");
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    this.props.noClickCallback();
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          backdrop={false}
          size={this.props.modalSize}
        >
          <ModalHeader toggle={this.cancelToggle}>
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.props.hasHtmlElements
              ? parse(this.props.message)
              : this.props.message}
            <div className="mt-3 lib-model-foot">
              <Button
                className="btn btn-default"
                onClick={this.yesToggle}
                hidden={this.state.buttonPositive == null}
              >
                {this.state.buttonPositive}
              </Button>
              <Button
                className="btn btn-cancel"
                onClick={this.noToggle}
                hidden={this.state.buttonNegative == null}
              >
                {this.state.buttonNegative}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

PopupModal.propTypes = {
  yesClickCallback: PropTypes.func,
  noClickCallback: PropTypes.func,
  cancelClickCallback: PropTypes.func,
};

PopupModal.defaultProps = {
  modalSize: "sm",
};

export default PopupModal;
