import React from "react";
import logo from "./../../images/logo.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SwipeableTemporaryDrawer from "../commonUtility/NotificationDrawer";
import { Redirect, withRouter } from "react-router-dom";
import { callUserDetails } from "../../store/actions/index";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as actions from "../../store/actions/index";
import PopupModal from "../commonUtility/PopupModal";
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeLogoutModal = this.closeLogoutModal.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.confirmLogout = this.confirmLogout.bind(this);
    this.state = {
      isOpen: false,
      userInfo: {},
      displayLogoutModal: false,
    };
  }

  componentDidMount() {}

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  confirmLogout() {
    this.setState({ displayLogoutModal: true });
  }

  doLogout() {
    this.setState({ displayLogoutModal: false });
    window.location.href = "/logout";
  }

  closeLogoutModal() {
    this.setState({ displayLogoutModal: false });
  }

  cancelPopup() {
    this.setState({ displayLogoutModal: false });
  }

  render() {
    console.log(this.props);
    var navigationBlank = (
      <div className="menubar">
        <Navbar expand="md" className="gems-menu">
          <div className="container">
            <Link to="/" tabIndex="-1" disabled={false}>
              <img src={logo} alt="logo" className="logo" />
            </Link>

            <NavbarToggler onClick={this.toggle}>
              <i className="fa fa-bars" />
            </NavbarToggler>
          </div>
        </Navbar>
      </div>
    );
    if (!this.props.userData) {
      this.props.callUserDetails();
    }
    if (this.props.isLogout) {
      sessionStorage.clear();
      return <Redirect push to="/" />;
    }

    var fullNavBar = (
      <Navbar expand="md" className="gems-menu">
        <div className="container">
          <Link to="/" tabIndex="-1" disabled={false}>
            <img src={logo} alt="logo" className="logo" />
          </Link>

          <NavbarToggler onClick={this.toggle}>
            <i className="fa fa-bars" />
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/dashboard"
                  disabled={false}
                  className={
                    this.props.location.pathname === "/dashboard" ||
                    this.props.location.pathname === "/"
                      ? "active"
                      : ""
                  }
                >
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/submit"
                  disabled={false}
                  className={
                    this.props.location.pathname === "/submit" ? "active" : ""
                  }
                >
                  Submit
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/edit"
                  disabled={false}
                  className={
                    this.props.location.pathname === "/edit" ? "active" : ""
                  }
                >
                  Edit
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/query"
                  disabled={false}
                  className={
                    this.props.location.pathname === "/query" ? "active" : ""
                  }
                >
                  Query
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink disabled={false}>|</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/info"
                  disabled={false}
                  className={
                    this.props.location.pathname === "/info" ? "active" : ""
                  }
                >
                  Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/library"
                  className={
                    this.props.location.pathname === "/library" ? "active" : ""
                  }
                >
                  Library{" "}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/help"
                  className={
                    this.props.location.pathname === "/help" ? "active" : ""
                  }
                >
                  Help
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink>
                  <SwipeableTemporaryDrawer />
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="mt-1">
                  {this.props.userData ? this.props.userData.firstName : ""}
                </DropdownToggle>
                <DropdownMenu right>
                  {this.props.userData ? (
                    this.props.userData.userRole == 3 ? null : (
                      <DropdownItem tag={Link} to="/AdminPage" disabled={false}>
                        Admin
                      </DropdownItem>
                    )
                  ) : null}
                  {this.props.userData ? (
                    this.props.userData.userRole == 3 ? null : (
                      <DropdownItem divider />
                    )
                  ) : null}

                  <DropdownItem tag={Link} to="/changePassword">
                    {" "}
                    Change Password
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.confirmLogout}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    );
    return (
      <div className="menubar">
        {this.state.displayLogoutModal ? (
          <PopupModal
            title="Logout Confirmation"
            message="Are you sure you want to logout?"
            yesClickCallback={this.doLogout.bind(this)}
            noClickCallback={this.closeLogoutModal}
            cancelClickCallback={this.cancelPopup.bind(this)}
            buttonPositive="Yes"
            buttonNegative="No"
          />
        ) : null}
        {this.props.location != null &&
        this.props.location.pathname != undefined &&
        this.props.location.pathname != "/changePassword"
          ? fullNavBar
          : this.props.userData == undefined
          ? navigationBlank
          : this.props.userData.hasTempPassword
          ? navigationBlank
          : fullNavBar}
        {this.props.userData && this.props.userData.hasTempPassword ? (
          <Redirect
            to={{
              pathname: "/changePassword",
              state: { isFromFirstLogin: this.props.userData.hasTempPassword },
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNavLoad: () => {
      dispatch(actions.checkAuth());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, { callUserDetails })(Navigation)
);
