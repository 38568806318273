import React, { Component } from "react";
import classnames from "classnames";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import CounterpartCount from "../commonUtility/CounterpartCount";
import RefCounterpart from "./RefCounterpart";
import ActCounterpart from "./ActCounterpart";
import RefContract from "./RefContract";
import DefaultCounterpart from "./DefaultCounterpart";
import DefaultContract from "./DefaultContract";
import GemImport from "./GemImport";
import RefCollaterals from "./RefCollaterals";
import RefGuarantor from "./RefGuarantor";
import RefGuarantee from "./RefGuarantee";


import {
  ACT_COUNTERPART,
  REF_COUNTERPART,
  DEF_CONTRACT,
  DEF_COUNTERPART,
  REF_CONTRACT,
  GEM_IMPORT,
  EMPTY_COLUMN,
  MISSING_COLUMN,
  DUPLICATE_COLUMN,
  EMPTY_COLUMN_MESSAGE,
  MISSING_COLUMN_MESSAGE,
  DUPLICATE_COLUMN_MESSAGE,
  REF_GUARANTOR,
  REF_GUARANTEE,
  REF_COLLATERAL,
} from "../commonUtility/string-const";
import PropTypes from "prop-types";
import Accordion from "../homePage/accordion";
import { connect } from "react-redux";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";

const sortedBy = {
  ACT_COUNTERPART: 0,
  REF_COUNTERPART: 1,
  REF_CONTRACT: 2,
  DEFAULT_COUNTERPART: 3,
  DEFAULT_CONTRACT: 4,
  GEM_IMPORT: 5,
  REF_COLLATERAL: 6,
  REF_GUARANTOR: 7,
  REF_GUARANTEE: 8,
};

class Tab extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      actCounterpartSize: 0,
      defaultContractSize: 0,
      defaultCounterpartSize: 0,
      gemImportSize: 0,
      refCollateralsSize: 0,
      refGuarantorSize: 0,
      refGuaranteeSize: 0,
      refContractSize: 0,
      refCounterpartSize: 0,
      paramList: null,
      validExcelObj: null,
      hideExcelTabs: false,
      excelErrorModal: false,
      excelSheetErrors: [],
    };

    this.listSize = this.listSize.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  listSize(size, sheetName) {
    switch (sheetName) {
      case ACT_COUNTERPART:
        this.setState({ actCounterpartSize: size });
        break;
      case REF_COUNTERPART:
        this.setState({ refCounterpartSize: size });
        break;
      case DEF_CONTRACT:
        this.setState({ defaultContractSize: size });
        break;
      case DEF_COUNTERPART:
        this.setState({ defaultCounterpartSize: size });
        break;
      case REF_CONTRACT:
        this.setState({ refContractSize: size });
        break;
      case GEM_IMPORT:
        this.setState({ gemImportSize: size });
        break;
        case REF_COLLATERAL:
          this.setState({ refCollateralsSize: size });
          break;
        case REF_GUARANTOR:
          this.setState({ refGuarantorSize: size });
          break;
        case REF_GUARANTEE:
          this.setState({ refGuaranteeSize: size });
          break;
        default:
        break;
    }

    this.props.sizeCallbackExcelView(
      this.state.actCounterpartSize +
        this.state.refCounterpartSize +
        this.state.defaultContractSize +
        this.state.defaultCounterpartSize +
        this.state.refContractSize +
        this.state.gemImportSize +
        this.state.refCollateralsSize +
        this.state.refGuarantorSize +
        this.state.refGuaranteeSize        
    );
  }

  componentDidMount() {
    var config = {
      headers: { ...authHeader() },
    };
    instance
      .get("/data/upload/getValidExcelUpload", config)
      .then((response) => {
        if (response.data !== undefined && !_.isEmpty(response.data)) {
          console.log(response.data);
          const excelSheetErrors = response.data;
          let errorMessages = [];

          let unOrderedArrayOfErrors = [];
          for (var prop in excelSheetErrors) {
            unOrderedArrayOfErrors.push({
              sheet: prop,
              errors: excelSheetErrors[prop],
            });
          }

          const orderedArrayOfErrors = unOrderedArrayOfErrors.sort(
            (a, b) => sortedBy[a.sheet] - sortedBy[b.sheet]
          );

          orderedArrayOfErrors.forEach((item) => {
            errorMessages.push(
              <div>
                <span>
                  For sheet {item.sheet}, following error(s) were encountered in
                  the uploaded excel:
                </span>
                <ul>
                  {item.errors.map((items) => {
                    switch (items) {
                      case EMPTY_COLUMN:
                        return <li>{EMPTY_COLUMN_MESSAGE}</li>;
                      case MISSING_COLUMN:
                        return <li>{MISSING_COLUMN_MESSAGE}</li>;
                      case DUPLICATE_COLUMN:
                        return <li>{DUPLICATE_COLUMN_MESSAGE}</li>;
                      default:
                        return null;
                    }
                  })}
                </ul>
              </div>
            );
          });

          this.setState({
            excelErrorModal: true,
            excelSheetErrors: errorMessages,
            hideExcelTabs: true,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          }
        }
      });
    this.getParameterList();
  }

  enterPressed = (event, num) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed excelview");

      this.toggle(num);
    }
  };

  capitalizeFLetter(string) {
    return string.charAt(0) + string.toLowerCase().slice(1);
  }

  getParameterList() {
    var config = {
      headers: { ...authHeader() },
    };
    instance
      .get("/compute/getParamList", config)
      .then((response) => {
        if (response.data !== undefined) {
          const categoryOptions = [];
          for (let prop in response.data.category) {
            categoryOptions.push({
              value: this.capitalizeFLetter(prop),
              label: this.capitalizeFLetter(prop),
            });
          }

          // TODO: Change to using values from Backend when it gives them back under getParamList
          const ratingOptions = [];
          ratingOptions.push({
              label: "GI1",
              value: "GI1",
            });
            ratingOptions.push({
              label: "GI2",
              value: "GI2",
            });
            ratingOptions.push({
              label: "GI3",
              value: "GI3",
            });
            ratingOptions.push({
              label: "GI4",
              value: "GI4",
            });
            ratingOptions.push({
              label: "GI5",
              value: "GI5",
            });
            ratingOptions.push({
              label: "GI6",
              value: "GI6",
            });
            ratingOptions.push({
              label: "GI7",
              value: "GI7",
            });
            ratingOptions.push({
              label: "GI8",
              value: "GI8",
            });
            ratingOptions.push({
              label: "GI9",
              value: "GI9",
            });
            ratingOptions.push({
              label: "GI10",
              value: "GI10",
            });
            ratingOptions.push({
              label: "Gs1",
              value: "Gs1",
            });
            ratingOptions.push({
              label: "Gs2",
              value: "Gs2",
            });
            ratingOptions.push({
              label: "Gs3",
              value: "Gs3",
            });
            ratingOptions.push({
              label: "Gs4",
              value: "Gs4",
            });
            ratingOptions.push({
              label: "Gs5",
              value: "Gs5",
            });
            ratingOptions.push({
              label: "Gs6",
              value: "Gs6",
            });
            ratingOptions.push({
              label: "Gs7",
              value: "Gs7",
            });
            ratingOptions.push({
              label: "Gs8",
              value: "Gs8",
            });
            ratingOptions.push({
              label: "Gs9",
              value: "Gs9",
            });
            ratingOptions.push({
              label: "Gs10",
              value: "Gs10",
            });

          const reflowOriginOptions = [];
          for (let prop in response.data.reflowOrigin) {
            reflowOriginOptions.push({
              value: response.data.reflowOrigin[prop],
              label: prop,
            });
          }

          const collateralTypeOptions = [];
          for (let prop in response.data.collateralType) {
            collateralTypeOptions.push({
              value: response.data.collateralType[prop],
              label: prop,
            });
          }

          const collateralCoverageOptions = [];
          for (let prop in response.data.collateralCoverage) {
            collateralCoverageOptions.push({
              value: response.data.collateralCoverage[prop],
              label: prop,
            });
          }

          const guarantorCoverageOptions = [];
          for (let prop in response.data.guarantorCoverage) {
            guarantorCoverageOptions.push({
              value: response.data.guarantorCoverage[prop],
              label: prop,
            });
          }


          const guaranteeTypeOptions = [];
          for (let prop in response.data.guaranteeType) {
            guaranteeTypeOptions.push({
              value: response.data.guaranteeType[prop],
              label: prop,
            });
          }


          const guaranteePartialTypeOptions = [];
          for (let prop in response.data.guaranteePartialType) {
            guaranteePartialTypeOptions.push({
              value: response.data.guaranteePartialType[prop],
              label: prop,
            });
          }


          const contagionOptions = [];
          for (let prop in response.data.contagion) {
            contagionOptions.push({
              value: response.data.contagion[prop],
              label: prop,
            });
          }

          // TODO: Change to using values from Backend when it gives them back under getParamList
          const collateralFlagOptions = [];
          collateralFlagOptions.push({
            value: "Y",
            label: "Y",
          });
          collateralFlagOptions.push({
            value: "N",
            label: "N",
          });
          collateralFlagOptions.push({
            value: "NA",
            label: "NA",
          });

          const countriesOptions = [];
          const regionOptions = [];
          response.data.countryRegionList.forEach((items) => {
            regionOptions.push({
              label: items.name,
              value: items.name.toUpperCase(),
            });
            items.countries.forEach((countryItems) => {
              countriesOptions.push({
                label:
                  countryItems.code === "OT"
                    ? "Other (OT)"
                    : countryItems.name + " (" + countryItems.code + ")",
                value: countryItems.code,
              });
            });
          });

          const crossDefaultClauseOptions = [];
          for (let prop in response.data.crossDefaultClause) {
            crossDefaultClauseOptions.push({
              value: prop,
              label: prop,
            });
          }

          const currencyTypeOptions = [];
          for (let prop in response.data.currencyType) {
            currencyTypeOptions.push({
              value: response.data.currencyType[prop],
              label: prop,
            });
          }

          const loanAmountOptions = [];
          for (let prop in response.data.loanAmount) {
            loanAmountOptions.push({
              value: response.data.loanAmount[prop],
              label: prop,
            });
          }

          const loanTypeOptions = [];
          for (let prop in response.data.loanType) {
            loanTypeOptions.push({
              value: this.capitalizeFLetter(prop),
              label: this.capitalizeFLetter(prop),
            });
          }

          const sectorRefOptions = [];
          for (let prop in response.data.sectorClassification) {
            sectorRefOptions.push({
              value: prop,
              label: prop,
            });
          }

          this.setState({
            paramList: {
              categoryOptions: categoryOptions,
              collateralCoverageOptions: collateralCoverageOptions,
              collateralFlagOptions: collateralFlagOptions,
              collateralTypeOptions: collateralTypeOptions,
              contagionOptions: contagionOptions,
              countriesOptions: _.sortBy(countriesOptions, [
                function (o) {
                  return o.label;
                },
              ]),
              regionOptions: _.sortBy(regionOptions, [
                function (o) {
                  return o.label;
                },
              ]),
              crossDefaultClauseOptions: crossDefaultClauseOptions,
              currencyTypeOptions: currencyTypeOptions,
              guaranteeTypeOptions: guaranteeTypeOptions,
              guaranteePartialTypeOptions: guaranteePartialTypeOptions,
              guarantorCoverageOptions: guarantorCoverageOptions,
              loanAmountOptions: loanAmountOptions,
              loanTypeOptions: loanTypeOptions,
              ratingOptions: ratingOptions,
              reflowOriginOptions: reflowOriginOptions,
              sectorRefOptions: sectorRefOptions
            },
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          }
        }
      });
  }

  toggleExcelModal() {
    this.setState((prevState) => ({
      excelErrorModal: !prevState.excelErrorModal,
    }));
  }

  cancelExcelModalToggle() {
    this.setState((prevState) => ({
      excelErrorModal: false,
    }));
  }

  getSheetTab(idTab, displayName){
    return (
      <NavItem
      tabIndex="0"
      onKeyUp={(event) => this.enterPressed(event, idTab)}
      className={this.state.hideExcelTabs ? "disable-section" : ""}
    >
      <NavLink
        className={classnames({
          active: this.state.activeTab === idTab,
        })}
        onClick={() => {
          this.toggle(idTab);
        }}
      >
        {displayName}
      </NavLink>
    </NavItem>
    );
    
  }

  render() {
    let summary = sessionStorage.getItem("excel_summary");
    if (summary != null) {
      summary = JSON.parse(summary);
    } else {
      summary = null;
    }
    return (
      <div className="mt-3">
        <div className="tab">
          <div>
            <Nav tabs className="tab-sec">
              <NavItem
                tabIndex="0"
                onKeyUp={(event) => this.enterPressed(event, "1")}
              >
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Summary
                </NavLink>
              </NavItem>
              {this.getSheetTab("2", "Act Counterpart")}
              {this.getSheetTab("3", "Ref Counterpart")}
              {this.getSheetTab("4", "Ref Contract")}
              {this.getSheetTab("5", "Default Counterpart")}
              {this.getSheetTab("6", "Default Contract")}
              {this.getSheetTab("7", "Gem Import")}
              {this.getSheetTab("8", "Ref Collateral")}
              {this.getSheetTab("9", "Ref Guarantor")}
              {this.getSheetTab("10", "Ref Guarantee")}
              {this.props.expMap !== null ? (
                <NavItem
                  tabIndex="0"
                  onKeyUp={(event) => this.enterPressed(event, "11")}
                >
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "11",
                    })}
                    onClick={() => {
                      this.toggle("11");
                    }}
                  >
                    Errors
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <CounterpartCount
                  data={summary}
                  isUploading={this.props.isUploading}
                />
              </TabPane>
              <TabPane tabId="2">
                <ActCounterpart
                  sizeCallback={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  paramList={this.state.paramList}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="3">
                <RefCounterpart
                  sizeCallbackRefCprt={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  paramList={this.state.paramList}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="4">
                <RefContract
                  sizeCallbackRefCon={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  paramList={this.state.paramList}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="5">
                <DefaultCounterpart
                  sizeCallbackDefCprt={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="6">
                <DefaultContract
                  sizeCallbackDefCon={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="7">
                <GemImport
                  sizeCallbackGI={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  paramList={this.state.paramList}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="8">
                <RefCollaterals
                  sizeCallbackRefCollaterals={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  paramList={this.state.paramList}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="9">
                <RefGuarantor
                  sizeCallbackRefGtor={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  paramList={this.state.paramList}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              <TabPane tabId="10">
                <RefGuarantee
                  sizeCallbackRefGtee={this.listSize.bind(this)}
                  isUploading={this.props.isUploading}
                  paramList={this.state.paramList}
                  triggerReload={this.props.triggerReload}
                />
              </TabPane>
              {this.props.triggerReload ? (
                <TabPane tabId="11">
                  <Accordion expMap={this.props.expMap} />
                </TabPane>
              ) : null}
            </TabContent>
          </div>
        </div>

        {this.state.excelErrorModal ? (
          <Modal
            isOpen={this.state.excelErrorModal}
            toggle={this.toggleExcelModal.bind(this)}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelExcelModalToggle.bind(this)}>
              Errors in Uploaded Excel
            </ModalHeader>
            <ModalBody>
              <div className="excel-error-content">
                <Form
                  className="lib-model"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup className="pt-1">
                    <div className="row">
                      <div className="col-12">
                        {this.state.excelSheetErrors}
                      </div>
                      <br />
                      <div className="col-12">
                        <span>Please check the data and upload again.</span>
                      </div>
                    </div>
                  </FormGroup>
                </Form>
              </div>
              <div className="mt-2 lib-model-foot">
                <Button
                  className="btn btn-default"
                  onClick={this.cancelExcelModalToggle.bind(this)}
                >
                  OK
                </Button>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    );
  }
}

Tab.propTypes = {
  sizeCallbackExcelView: PropTypes.func,
};

const mapStateToProps = (state) => {
  console.log("Tab received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
    isUploading: state.auth.uploading,
  };
};

export default connect(mapStateToProps, null)(Tab);
