import React, { Component } from "react";

export default class LoaderInline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    let data;
    data = (
      <div className="Load-relative-inline">
        <div className="loading">
          <div />
          <div />
          <div />
        </div>
      </div>
    );

    return (
      <div className="d-flex justify-content-center  align-items-center">
        {data}
      </div>
    );
  }
}
