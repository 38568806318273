import React, { Component } from "react";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import AdminUserList from "./userList";
import AdminInstituteList from "./instituteList";
import NotificationAdmin from "./NotificationFeedback";

import { ToastContainer } from "react-toastify";
import { showToast } from "../commonUtility/NewToast";
import { connect } from "react-redux";
import {
  callUserDetails,
  getUserNotificationsAndEvents,
} from "../../store/actions/index";
import DashboadManagement from "./DashboardManagment";
import AnnualMode from "./AnnualMode";

class AdminTab extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.props.getUserNotificationsAndEvents();
      this.setState({
        activeTab: tab,
      });
    }
  }

  showToast(message, type) {
    showToast(message, type);
  }

  enterPressed = (event, num) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");

      this.toggle(num);
    }
  };

  render() {
    return (
      <div className="mt-3">
        <div className="tab">
          <div>
            <Nav tabs className="tab-sec admin-section">
              <NavItem>
                <NavLink
                  tabIndex="0"
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                  onKeyUp={(event) => this.enterPressed(event, "1")}
                >
                  User Management
                </NavLink>
              </NavItem>
              {this.props.userData ? (
                this.props.userData.userRole !== 1 ? null : (
                  <NavItem>
                    <NavLink
                      tabIndex="0"
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                      onKeyUp={(event) => this.enterPressed(event, "2")}
                    >
                      Dashboard Management
                    </NavLink>
                  </NavItem>
                )
              ) : (
                this.props.callUserDetails()
              )}
              {this.props.userData ? (
                this.props.userData.userRole !== 1 ? null : (
                  <NavItem>
                    <NavLink
                      tabIndex="0"
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                      onKeyUp={(event) => this.enterPressed(event, "3")}
                    >
                      Annual Mode
                    </NavLink>
                  </NavItem>
                )
              ) : (
                this.props.callUserDetails()
              )}
              {this.props.userData ? (
                this.props.userData.userRole !== 1 ? null : (
                  <NavItem>
                    <NavLink
                      tabIndex="0"
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggle("4");
                      }}
                      onKeyUp={(event) => this.enterPressed(event, "4")}
                    >
                      Institution List
                    </NavLink>
                  </NavItem>
                )
              ) : (
                this.props.callUserDetails()
              )}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {this.state.activeTab == "1" ? (
                  <AdminUserList passMessage={this.showToast.bind(this)} />
                ) : null}
              </TabPane>
              <TabPane tabId="2">
                {this.state.activeTab == "2" ? (
                  this.props.userData ? (
                    this.props.userData.userRole === 1 ? (
                      <DashboadManagement
                        passMessage={this.showToast.bind(this)}
                      />
                    ) : null
                  ) : (
                    this.props.callUserDetails()
                  )
                ) : null}
              </TabPane>
              {this.props.userData ? (
                this.props.userData.userRole !== 1 ? null : (
                  <TabPane tabId="3">
                    {this.state.activeTab == "3" ? (
                      <AnnualMode passMessage={this.showToast.bind(this)} />
                    ) : null}
                  </TabPane>
                )
              ) : (
                this.props.callUserDetails()
              )}
              {this.props.userData ? (
                this.props.userData.userRole !== 1 ? null : (
                  <TabPane tabId="4">
                    {this.state.activeTab == "4" ? (
                      <AdminInstituteList
                        passMessage={this.showToast.bind(this)}
                      />
                    ) : null}
                  </TabPane>
                )
              ) : (
                this.props.callUserDetails()
              )}
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Navigation Props received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, {
  callUserDetails,
  getUserNotificationsAndEvents,
})(AdminTab);
