import blue from "@material-ui/core/colors/blue";

const customStyles = {
  option: (provided, state) => ({
    ...provided,

    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 0,

    fontSize: 13,
    fontWeight: "normal",
    width: "auto",
  }),
  control: (state) => ({
    width: 200,
    display: "flex",
    height: 30,
    fontSize: 13,
    border: "1px solid #cac7c7",
    boxShadow: "none",
    "&:focus-within": {
      border: "1px solid #233d63",
    },
    color: "rgba(0, 0, 0, 0.6)",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.6 : 1;
    const transition = "opacity 100ms";

    return { ...provided, opacity, transition };
  },
};

export default customStyles;
