import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import Tab from "./Tab";
import { Container, Row, Col } from "reactstrap";
import RuncheckButton from "./RuncheckButton";
import Footer from "../commonUtility/Footer";
import ScrollUpButton from "react-scroll-up-button";

import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
class ExcelView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelSize: 0,
      rucChkExp: null,
      isLoading: false,
      triggerReload: false,
    };
  }
  getSheetSize(size) {
    this.setState({ excelSize: size });
    console.log("Excel sheet size = ", this.state.excelSize);
  }

  runCheckCallback(expMap) {
    this.setState({ rucChkExp: expMap });
  }

  setUnsetLoadingScreen(flag) {
    this.setState({ isLoading: flag });
  }

  triggerReload(flag) {
    this.setState({ triggerReload: flag });
  }

  render() {
    return (
      <div className="excelView">
        <Navigation />

        <MainHeading />

        <Container>
          <RuncheckButton
            isRunCheckDisabled={this.state.excelSize > 0}
            callback={this.runCheckCallback.bind(this)}
            setUnsetLoadingScreen={this.setUnsetLoadingScreen.bind(this)}
            triggerReload={this.triggerReload.bind(this)}
          />
          {this.state.isLoading ? <FullScreenLoaderComponent /> : null}
          <Row>
            <Col sm="12">
              <Tab
                sizeCallbackExcelView={this.getSheetSize.bind(this)}
                expMap={this.state.rucChkExp}
                triggerReload={this.state.triggerReload}
              />
            </Col>
          </Row>
        </Container>
        <ScrollUpButton  ShowAtPosition={20} className="scrolltopbutton"/>
        <Footer />
      </div>
    );
  }
}

export default ExcelView;
