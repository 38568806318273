import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import MaterialTable from "material-table";

import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  UNKNOWN_ERROR,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
  SAVED_QUERY_LIMIT_EXCEED_MSG,
  ACTION_QUERY_DELETE,
  DELETE_QUERY_SUCCESS,
  DELETE_QUERY_FAIL,
} from "../commonUtility/string-const";
import LoaderComponent from "../commonUtility/LoaderComponent";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Redirect } from "react-router-dom";
import PopupModal from "../commonUtility/PopupModal";
import { getComputeNameByKey } from "../commonUtility/method-utils";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#0075ff",
      light: "#659dfd",
      dark: "#37b159",
    },
  },
});

class SavedQueries extends React.Component {
  constructor() {
    super();
    this.state = {
      queryList: [],
      selectedQuery: [],
      showDeletePopup: false,
      tempQueryList: [],
      isLoading: false,
      firstTymLoad: false,
      searchText: "",
      searchClass: "fa fa-search",
      clearClass: "fa fa-times",
      currentPageSize: 10,
    };
    this.startSearching = this.startSearching.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.deleteSavedQuery = this.deleteSavedQuery.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.cancelPopup = this.cancelPopup.bind(this);
  }

  componentDidMount() {
    this.getDataFromBE();
  }

  componentDidUpdate() {
    if (Object.keys(this.state.queryList).length == 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv != undefined) {
      pageDiv.hidden = flag;
    }
  }

  getDataFromBE() {
    this.setState({ firstTymLoad: true });
    const config = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    instance
      .get("infopage/savedquery/fetch", config)
      .then((response) => {
        if (response.data != null) {
          this.setState({
            firstTymLoad: false,
            queryList: response.data.list,
            tempQueryList: response.data.list,
            selectedQuery: [],
          });
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
        }
      });
  }

  startSearching(e) {
    if (e != null) {
      e = e.target.value;
      this.setState({ searchText: e });
      if (e != null && e.toString().trim() != "") {
        let qList = this.state.tempQueryList;
        let searchItems = [];
        for (var j = 0; j < qList.length; j++) {
          for (var key in qList[j]) {
            if (
              key != "tableData" &&
              key != "cid" &&
              key != "param" &&
              qList[j][key] != null
            ) {
              if (
                qList[j][key].toString().toLowerCase().includes(e.toLowerCase())
              ) {
                searchItems.push(qList[j]);
                break;
              }
            }
          }
        }
        this.setState({ queryList: searchItems });
      } else {
        this.setState({ queryList: this.state.tempQueryList });
      }
    } else {
      this.setState({ queryList: [] });
      this.setState({ queryList: this.state.tempQueryList });
    }
  }

  deleteSavedQuery(ids) {
    this.setState({ isLoading: true });
    let cidArr = [];
    for (var i = 0; i < ids.length; i++) {
      cidArr.push(ids[i].cid);
    }
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    instance
      .post("compute/savedquery/" + ACTION_QUERY_DELETE, cidArr, config)
      .then((response) => {
        this.searchIconClick(this.state.searchText.trim().length > 0);
        this.setState({ showDeletePopup: false, isLoading: false });
        if (response != null && response.data.actionDone === true) {
          this.getDataFromBE();
          showToast(DELETE_QUERY_SUCCESS, TOAST_TYPE_SUCCESS);
        } else {
          showToast(DELETE_QUERY_FAIL, TOAST_TYPE_SUCCESS);
        }
      })
      .catch((error) => {
        this.searchIconClick(this.state.searchText.trim().length > 0);
        this.setState({ showDeletePopup: false, isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast("An error occured.", TOAST_TYPE_SUCCESS);
          }
        } else {
          showToast("An error occured.", TOAST_TYPE_SUCCESS);
        }
      });
  }

  confirmDeleteDoc() {
    this.setState({ showDeletePopup: true });
  }

  searchIconClick(clear) {
    if (clear) {
      this.setState({ searchText: "" });
      this.startSearching(null);
    }
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");
      this.searchIconClick(this.state.searchText.trim().length > 0);
    }
  };

  computeAll(paramList) {
    if (paramList != null && paramList.length > 0 && paramList.length <= 10) {
      this.props.setSavedQueryPlaylist(paramList);
    } else {
      if (paramList.length > 10) {
        showToast(SAVED_QUERY_LIMIT_EXCEED_MSG, TOAST_TYPE_SUCCESS);
      }
    }
  }

  closeDeleteModal() {
    this.setState({ showDeletePopup: false });
  }

  cancelPopup() {
    this.setState({ showDeletePopup: false });
  }

  render() {
    if (
      this.props.savedQueryPlaylist != null &&
      this.props.savedQueryPlaylist.length > 0
    ) {
      return <Redirect push to="/query" />;
    }
    return (
      <MuiThemeProvider theme={theme}>
        {this.state.showDeletePopup ? (
          <PopupModal
            title="Delete Query"
            message="Do you want to delete the selected query?"
            yesClickCallback={(e) =>
              this.deleteSavedQuery(this.state.selectedQuery)
            }
            noClickCallback={this.closeDeleteModal}
            cancelClickCallback={this.cancelPopup.bind(this)}
            buttonPositive="Yes"
            buttonNegative="No"
          />
        ) : null}
        {this.state.isLoading ? <LoaderComponent /> : null}
        {this.state.firstTymLoad ? <FullScreenLoaderComponent msg="" /> : null}

        <div style={{ maxWidth: "100%" }}>
          <div className="top-bar mt-3">
            {this.state.firstTymLoad ? null : (
              <div className="row">
                <div className="col-auto align-self-center">
                  <div className="search-bar">
                    <div id="custom-search-input">
                      <div className="input-group">
                        <input
                          type="text"
                          className="search-query form-control"
                          placeholder="Search"
                          onChange={this.startSearching}
                          value={this.state.searchText}
                          tabIndex="0"
                        />
                        <span className="input-group-btn" tabIndex="-1">
                          <button
                            className="btn btn-danger"
                            type="button"
                            tabIndex="-1"
                          >
                            <i
                              className={
                                this.state.searchText.trim().length > 0
                                  ? this.state.clearClass
                                  : this.state.searchClass
                              }
                              aria-hidden="true"
                              onClick={() =>
                                this.searchIconClick(
                                  this.state.searchText.trim().length > 0
                                )
                              }
                              onKeyUp={(event) => this.enterPressed(event)}
                              tabIndex={
                                this.state.searchText.trim().length > 0
                                  ? "0"
                                  : "-1"
                              }
                            ></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto ml-auto align-self-center">
                  <div className="row">
                    <div className="auto">
                      <Tooltip
                        title="Compute query"
                        disableFocusListener={true}
                      >
                        <button
                          className="btn btn-default"
                          onClick={(e) =>
                            this.computeAll(this.state.selectedQuery)
                          }
                          disabled={
                            this.state.selectedQuery == null ||
                            this.state.selectedQuery.length === 0
                          }
                        >
                          Compute
                        </button>
                      </Tooltip>
                    </div>
                    <div className="col-auto">
                      <Tooltip title="Delete query" disableFocusListener={true}>
                        <button
                          className="btn btn-default"
                          onClick={(e) => this.confirmDeleteDoc()}
                          disabled={
                            this.state.selectedQuery == null ||
                            this.state.selectedQuery.length === 0
                          }
                        >
                          Delete
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {this.state.firstTymLoad ? null : (
            <div className="savedquery">
              <MaterialTable
                title="Saved Queries"
                columns={[
                  {
                    title: "Query Name",
                    field: "name",
                    sorting: true,
                    customSort: (a, b) =>
                      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                  },
                  {
                    title: "Computation Type",
                    sorting: true,
                    field: "type",
                    customSort: (a, b) =>
                      a.type.toLowerCase() < b.type.toLowerCase() ? -1 : 1,
                    render: (e) => {
                      return getComputeNameByKey(e.type);
                    },
                  },
                  {
                    title: "Saved Date & Time",
                    sorting: true,
                    field: "dateTime",
                  },
                ]}
                onChangeRowsPerPage={(pageSize) => {
                  this.setState({ currentPageSize: pageSize });
                  let timesRun = 0;
                  let firstTimeExecuted = false;
                  var hasVScroll =
                    window.innerHeight /* + window.scrollY*/ <=
                    document.body.offsetHeight;
                  this.interval = setInterval(() => {
                    if (timesRun == 5) {
                      clearInterval(this.interval);
                    }
                    timesRun++;
                    console.log("service ", hasVScroll);
                    if (hasVScroll) {
                      window.scrollTo(0, 0);
                      clearInterval(this.interval);
                    } else {
                      if (firstTimeExecuted) {
                        clearInterval(this.interval);
                      }
                      firstTimeExecuted = true;
                    }
                  }, 1);
                }}
                data={this.state.queryList}
                onSelectionChange={(data, rowData) => {
                  this.setState({ selectedQuery: data });
                }}
                options={{
                  selection: true,
                  showSelectAllCheckbox:
                    this.state.queryList != null &&
                    this.state.queryList.length > 0
                      ? true
                      : false,
                  hideSortIcon: true,
                  actionsColumnIndex: -1,
                  showTitle: false,
                  searchFieldAlignment: "left",
                  pageSize: this.state.currentPageSize,
                  pageSizeOptions: [10, 20, 25],
                  search: false,
                  emptyRowsWhenPaging: false,
                  draggable: false,
                  sorting: this.state.queryList.length > 0,
                  thirdSortClick: false,
                  showTextRowsSelected: false,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.tableData.checked ? "#eee" : "",
                  }),
                }}
              />
            </div>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    savedQueryPlaylist: state.auth.savedQueryPlaylist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSavedQueryPlaylist: (savedQueryPlaylist) => {
      dispatch(actions.setSavedQueryPlaylist(savedQueryPlaylist));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedQueries);
