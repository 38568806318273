import React from "react";
import PubSub from "pubsub-js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";
import moment from "moment";
import _ from "lodash";

class CustomInput extends React.Component {
  render() {
    return (
      <div className="custom-datepicker">
        <input
          className="form-control date-contol"
          value={
            this.props.value === undefined ||
            this.props.value === null ||
            this.props.value === ""
              ? ""
              : moment(this.props.value).format("DD-MMM-YY")
          }
          type="text"
          disabled={this.props.disabled}
          placeholder={
            this.props.required
              ? this.props.placeholder + "*"
              : this.props.placeholder
          }
          onKeyDown={() => {
            return false;
          }}
          onChange={() => {
            return false;
          }}
        />
        <i
          onClick={this.props.onClick}
          aria-hidden="true"
          className="fa fa-calendar"
        />
        <i
          onClick={this.props.clearCalender}
          aria-hidden="true"
          className="fa fa-times"
          hidden={
            this.props.value === undefined ||
            this.props.value === null ||
            this.props.value === ""
          }
        />
      </div>
    );
  }
}

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

export default class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      required: false,
    };
  }

  componentDidMount() {
    PubSub.subscribe("TRIGGER_ROW_VALIDATION", this.runValidation);
    this.setState({
      required: this.props.required,
    });
    this.checkForRequiredConditions();
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.runValidation);
  }

  runValidation = () => {
    if (
      this.state.required &&
      (this.props.value === "" ||
        this.props.value === null ||
        this.props.value === undefined)
    ) {
      this.setState({
        error: true,
        errorMessage: "Please enter " + this.props.name,
      });
    }
  };

  handleChange(value) {
    this.setState({ error: false, errorMessage: "" });
    this.props.onChange(value);
  }

  checkForRequiredConditions() {
    if (
      this.props.name === "dat_resolution" &&
      this.props.rowProps !== undefined &&
      this.props.rowProps.rowData.resolution_type === "URSVD"
    ) {
      this.setState({
        required: false,
      });
    } else {
      this.setState({
        required: true,
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps, this.props)) {
      this.checkForRequiredConditions();
    }
  }

  clearCalender() {
    this.setState({ error: false, errorMessage: "" });
    this.props.onChange(null);
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <DatePicker
              selected={Date.parse(this.props.value)}
              onChange={this.handleChange.bind(this)}
              value={this.props.value}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={
                <CustomInput clearCalender={this.clearCalender.bind(this)} />
              }
              popperContainer={CalendarContainer}
              placeholderText={this.props.name}
              required={this.state.required}
              autoFocus={this.props.autoFocus === undefined ? false : true}
            />
          </div>
          <span
            className={this.state.error ? "errordisplay" : "errornotdisplay"}
          >
            <div className="formErrors">
              <div className="alert-warning">
                {this.state.error && this.state.required
                  ? this.state.errorMessage
                  : ""}
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }
}
