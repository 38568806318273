import React, { Component } from "react";
import Highcharts from "highcharts";
import _ from "lodash";

class MMResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charts: {},
    };
  }

  getMMChartSVG() {
    return this.state.charts;
  }

  componentDidMount() {
    this.renderRatingChart();
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps.mmData, this.props.mmData)) {
      this.renderRatingChart();
    }
  }

  renderRatingChart() {
    let upRatioDataToPlot = [];
    const mmDataToPlot = JSON.parse(
      JSON.stringify(this.props.mmData.upRatioList)
    );
    if (this.props.startYear < 1995) {
      for (let i = 1995 - this.props.startYear; i < mmDataToPlot.length; i++) {
        upRatioDataToPlot.push(mmDataToPlot[i]);
      }
    } else {
      upRatioDataToPlot = mmDataToPlot;
    }
    let breakEvenData = [];
    let averageData = [];

    upRatioDataToPlot.forEach(() => {
      breakEvenData.push(1);
      averageData.push(parseFloat(this.props.mmData.mean));
    });
    console.log(averageData);

    let chartId = "annualRatingChart";
    if (this.props.renderHiddenMMChart) {
      chartId = "annualRatingChartHidden";
    }
    let mmChart = Highcharts.chart(chartId, {
      title: {
        text:
          "Annual Rating Upgrade vs. Downgrade Ratio (" +
          (this.props.startYear > 1995 ? this.props.startYear - 1 : 1994) +
          "-" +
          this.props.endYear +
          ")",
      },

      yAxis: {
        title: {
          text: "",
        },
        min: 0,
        tickInterval: 0.25,
        labels: {
          formatter: function () {
            var label = this.axis.defaultLabelFormatter.call(this);
            return Number(label).toFixed(2);
          },
        },
      },

      xAxis: {
        tickInterval: 1,
        labels: {
          step: 1,
          rotation: 300,
        },
      },

      tooltip: {
        enabled: true,
        headerFormat: "<b>{series.name}</b><br>",
        formatter: function () {
          return (
            "<b>" +
            this.series.name +
            "</b><br>" +
            this.x +
            " : " +
            Number(this.y).toFixed(2)
          );
        },
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      exporting: {
        enabled: false,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: this.props.startYear > 1995 ? this.props.startYear : 1995,
          marker: {
            radius: 3,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },

      series: [
        {
          name: "Up/Down Ratio",
          color: "#274368",
          data: upRatioDataToPlot.map((ratio) => parseFloat(ratio)),
        },
        {
          name: "Breakeven",
          color: "#008b00",
          marker: {
            enabled: false,
          },
          enableMouseTracking: false,
          data: breakEvenData,
        },
        {
          name: "Average Ratio",
          color: "#f00",
          dashStyle: "DashDot",
          marker: {
            enabled: false,
          },
          enableMouseTracking: false,
          data: averageData,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (
                this.point === this.series.data[this.series.data.length - 1]
              ) {
                return "Average: " + Number(this.y).toFixed(2);
              }
            },
            y:
              parseFloat(this.props.mmData.mean) > 0.85 &&
              parseFloat(this.props.mmData.mean) < 0.96
                ? -15
                : 0,
          },
        },
      ],
      credits: {
        enabled: false,
      },
    });
    this.setState({ charts: mmChart });
  }

  render() {
    return (
      <div>
        <div id="annualRatingChart" />
        <div id="annualRatingChartHidden" className="display-none" />
      </div>
    );
  }
}

export default MMResult;
