import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import Footer from "../commonUtility/Footer";
import ChangePasswordTextFields from "./ChangePassword";
import { callUserDetails } from "../../store/actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    console.log("Change password=== ", this.props);
  }
  render() {
    if (this.props.userData && this.props.userData.isLogout) {
      sessionStorage.clear();
      return <Redirect push to="/" />;
    }
    
    return (
      <div className="reset-password">
        <Navigation location={this.props.location}/>
        <MainHeading />
        {console.log("container is redering")}
        <div className="container">
          <div>
            <ChangePasswordTextFields
              isFromFirstLogin={
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.isFromFirstLogin
                  ? this.props.location.state.isFromFirstLogin
                  : false
              }
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log("Change password Props received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData
  };
};

export default connect(
  mapStateToProps,
  { callUserDetails }
)(ChangePasswordForm);
