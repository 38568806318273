import { takeEvery } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import {
  logoutSaga,
  authUserSaga,
  authCheckOTP,
  authResendOTP,
  authCheckUserSession
} from "./auth";

//Adding watch to actions so that whenever this actionType will called
//action method will execute
export function* watchAuth() {
  yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga);
  yield takeEvery(actionTypes.AUTH_USER, authUserSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_OTP, authCheckOTP);
  yield takeEvery(actionTypes.RESEND_OTP, authResendOTP);
  yield takeEvery(actionTypes.CHECK_AUTH, authCheckUserSession);
}
