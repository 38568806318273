import React, { Component } from "react";
import { callUserDetails } from "../../store/actions/index";
import instance, { BASE_URL } from "../../instance";
import { showToast } from "../commonUtility/NewToast";
import {
  ACTION_UPLOAD,
  ACTION_MOD,
  ACTION_DB,
  TOAST_TYPE_ERROR,
  ERROR_OCCURED,
} from "../commonUtility/string-const";
import { authHeader } from "../commonUtility/auth-header";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { filter } from "../commonUtility/method-utils";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import Select from "react-select";
import customStyles from "../commonUtility/ReactSelectCss";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";

class InfoTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      columns: [],
      fileList: [],
      instColumns: [],
      instList: {},
      firstTymLoad: false,
      instituteFilter: [],
      selectedInstitute: "",
      filteredInsitute: -1,
    };
    this.dashtoggle = this.dashtoggle.bind(this);
    this.loadFileList = this.loadFileList.bind(this);
    this.handleInstituteSelect = this.handleInstituteSelect.bind(this);
  }

  dashtoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    if (this.props.userData == null) {
      this.props.callUserDetails();
      this.loadFileList();
    } else {
      this.loadFileList();
    }
  }

  loadTableColumns() {
    let instColumns = [
      {
        title: "Institution",
        type: "string",
        field: "name",
        editable: "never",
        sorting: true,
        hidden: false,
      },
    ];

    let tempColumns = [
      {
        title: "Reporting Period",
        type: "numeric",
        field: "dataset_year",
        editable: "never",
        sorting: true,
        hidden: false,
        render: (rowData) =>
          rowData.dataset_year == new Date().getFullYear() - 1 ? "New" : "Old",
      },
      {
        title: "Default Period",
        field: "defaultPeriod180",
        type: "boolean",
        editable: "never",
        sorting: true,
        render: (rowData) =>
          rowData.defaultPeriod180 ? "180 Days" : "90 Days",
      },
      {
        title: "File Uploaded",
        field: "last_excel_upload_filename",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={
              rowData.last_excel_upload_filename != "-"
                ? "download-link"
                : "text-center"
            }
            onClick={(e) =>
              this.downloadFile(
                rowData.Abbr,
                false,
                rowData.defaultPeriod180,
                rowData.dataset_year,
                rowData.modified == 0 ? ACTION_UPLOAD : ACTION_MOD,
                rowData.last_excel_upload_filename
              )
            }
          >
            {rowData.last_excel_upload_filename}
          </div>
        ),
      },
      {
        title: "Last Uploaded",
        field: "last_excel_upload_date",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={
              rowData.last_excel_upload_date != "-" ? "" : "text-center"
            }
          >
            {rowData.last_excel_upload_date}
          </div>
        ),
      },
      {
        title: "File Updated",
        field: "last_version_file_name",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={
              rowData.last_version_file_name != "-"
                ? "download-link"
                : "text-center"
            }
            onClick={(e) =>
              this.downloadFile(
                rowData.Abbr,
                false,
                rowData.defaultPeriod180,
                rowData.dataset_year,
                rowData.modified == 0 ? ACTION_UPLOAD : ACTION_MOD,
                rowData.last_version_file_name
              )
            }
          >
            {rowData.last_version_file_name}
          </div>
        ),
      },
      {
        title: "Last Updated",
        field: "last_version_date",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={rowData.last_version_date != "-" ? "" : "text-center"}
          >
            {rowData.last_version_date}
          </div>
        ),
      },
      {
        title: "File Inserted",
        field: "last_persist_filename",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={
              rowData.last_persist_filename != "-"
                ? "download-link"
                : "text-center"
            }
            onClick={(e) =>
              this.downloadFile(
                rowData.Abbr,
                false,
                rowData.defaultPeriod180,
                rowData.dataset_year,
                ACTION_DB,
                rowData.last_persist_filename
              )
            }
          >
            {rowData.last_persist_filename}
          </div>
        ),
      },
      {
        title: "Last Inserted",
        field: "last_persist_date",
        type: "string",
        editable: "never",
        sorting: true,
        render: (rowData) => (
          <div
            className={rowData.last_persist_date != "-" ? "" : "text-center"}
          >
            {rowData.last_persist_date}
          </div>
        ),
      },
    ];
    this.setState({
      columns: tempColumns,
      firstTymLoad: true,
      instColumns: instColumns,
    });
  }

  createBlankFileList() {
    let fileList = [];
    let currentYear = new Date().getFullYear();
    let newPeriod = currentYear - 1;
    let oldPeriod = currentYear - 2;
    let yearList = [newPeriod, oldPeriod];
    let defPeriod180 = [false, true];
    Object.keys(yearList).forEach((y) => {
      Object.keys(defPeriod180).forEach((d) => {
        let obj = {
          dataset_year: yearList[y],
          defaultPeriod180: defPeriod180[d],
          last_excel_upload_filename: "-",
          last_excel_upload_date: "-",
          last_version_file_name: "-",
          last_version_date: "-",
          last_persist_filename: "-",
          last_persist_date: "-",
          Abbr: "-",
          modified: "-",
        };
        fileList.push(obj);
      });
    });
    return fileList;
  }

  handleInstituteSelect(e) {
    let instSel = e.value;
    let fileList = this.state.instList[instSel];
    if (fileList != null) {
      this.setState({
        fileList: fileList,
        selectedInstitute: instSel,
        filteredInsitute: e,
      });
    }
  }

  loadFileList() {
    this.setState({ firstTymLoad: true });
    let currentYear = new Date().getFullYear();
    let newPeriod = currentYear - 1;
    let oldPeriod = currentYear - 2;

    let fileList = this.createBlankFileList();
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    instance
      .post("/infopage/getWorkModeFileList", null, config)
      .then((response) => {
        this.setState({ firstTymLoad: false });
        if (response.data != null && response.data.error == false) {
          if (response.data.SA) {
            let instList = {};
            let instituteFilter = [];
            Object.keys(response.data.data).forEach((l) => {
              fileList = this.createBlankFileList();
              let list = response.data.data[l];
              Object.keys(list).forEach((j) => {
                if (list[j].dataset_year == newPeriod) {
                  if (!list[j].defaultPeriod180) {
                    fileList[0] = {
                      ...list[j],
                    };
                  } else if (list[j].defaultPeriod180) {
                    fileList[1] = {
                      ...list[j],
                    };
                  }
                }
                if (list[j].dataset_year == oldPeriod) {
                  if (!list[j].defaultPeriod180) {
                    fileList[2] = {
                      ...list[j],
                    };
                  } else if (list[j].defaultPeriod180) {
                    fileList[3] = {
                      ...list[j],
                    };
                  }
                }
              });

              instList[l] = fileList;
            });
            Object.keys(response.data.instList).forEach((ins) => {
              instituteFilter.push({
                label: ins,
                value: response.data.instList[ins],
              });
            });
            let filteredInsitute = instituteFilter[0];
            this.setState({
              instList: instList,
              instituteFilter: instituteFilter,
              fileList: instList[filteredInsitute.value],
              selectedInstitute: filteredInsitute.value,
              filteredInsitute: filteredInsitute,
            });
          } else {
            Object.keys(response.data.data).forEach((i) => {
              if (response.data.data[i].dataset_year == newPeriod) {
                if (!response.data.data[i].defaultPeriod180) {
                  fileList[0] = {
                    ...response.data.data[i],
                  };
                } else if (response.data.data[i].defaultPeriod180) {
                  fileList[1] = {
                    ...response.data.data[i],
                  };
                }
              }
              if (response.data.data[i].dataset_year == oldPeriod) {
                if (!response.data.data[i].defaultPeriod180) {
                  fileList[2] = {
                    ...response.data.data[i],
                  };
                } else if (response.data.data[i].defaultPeriod180) {
                  fileList[3] = {
                    ...response.data.data[i],
                  };
                }
              }
            });
            this.setState({ fileList: fileList, firstTymLoad: false });
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          this.setState({ firstTymLoad: false });
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  filterOption = (option, inputValue) => {
    const { label, value } = option;

    if (value === -1) {
      return false;
    }
    return label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  downloadFile(abbr, eoy, defaultPeriod180, dataset_year, type, fileName) {
    if (fileName == "-") {
      return;
    }
    this.setState({ isLoading: true });
    let uploadedFileRes = {
      abbr: abbr,
      eoy: eoy,
      defaultPeriod180: defaultPeriod180,
      dataset_year: dataset_year,
      type: type,
      fileName: fileName,
    };
    let b_token = sessionStorage.getItem("gems_bearer_token");
    let xhr = new XMLHttpRequest();
    xhr.open("POST", BASE_URL + "/infopage/download/uploadedfile");
    xhr.responseType = "blob";
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + b_token);
    xhr.send(JSON.stringify(uploadedFileRes));

    xhr.onload = function (e) {
      if (this.status == 200) {
        if (this.response.size > 0) {
          if (type != ACTION_UPLOAD) {
            let fileNameWithExt = fileName;
            let index = fileNameWithExt.lastIndexOf(".");
            let name = fileNameWithExt.substring(0, index);
            let fileType = fileNameWithExt.substring(index + 1);
            if (fileType == "xlsm") {
              fileName = name + "." + "xlsx";
            }
          }
          var blob = new Blob([this.response], {
            type: "application/vnd.ms-excel",
          });
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      } else {
        showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
      }
    };
  }

  render() {
    let fileTableListNew90 = [];
    let fileTableListNew180 = [];
    let fileTableListOld90 = [];
    let fileTableListOld180 = [];
    let fileList = this.state.fileList;
    Object.keys(fileList).forEach((i) => {
      if (fileList[i].dataset_year == new Date().getFullYear() - 1) {
        if (fileList[i].defaultPeriod180) {
          let tbRow = [];
          tbRow.push(<td>File Uploaded</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_UPLOAD,
                  fileList[i].last_excel_upload_filename
                )
              }
            >
              {fileList[i].last_excel_upload_filename}
            </td>
          );

          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_excel_upload_date}
            </td>
          );
          fileTableListNew180.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Updated</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_version_file_name != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  fileList[i].modified == 0 ? ACTION_UPLOAD : ACTION_MOD,
                  fileList[i].last_version_file_name
                )
              }
            >
              {fileList[i].last_version_file_name}
            </td>
          );

          tbRow.push(
            <td
              className={
                fileList[i].last_version_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_version_date}
            </td>
          );
          fileTableListNew180.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Inserted</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_DB,
                  fileList[i].last_persist_filename
                )
              }
            >
              {fileList[i].last_persist_filename}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_persist_date}
            </td>
          );
          fileTableListNew180.push(<tr>{tbRow}</tr>);
        } else if (!fileList[i].defaultPeriod180) {
          let tbRow = [];

          tbRow.push(<td>File Uploaded</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_UPLOAD,
                  fileList[i].last_excel_upload_filename
                )
              }
            >
              {fileList[i].last_excel_upload_filename}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_excel_upload_date}
            </td>
          );
          fileTableListNew90.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Updated</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_version_file_name != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  fileList[i].modified == 0 ? ACTION_UPLOAD : ACTION_MOD,
                  fileList[i].last_version_file_name
                )
              }
            >
              {fileList[i].last_version_file_name}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_version_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_version_date}
            </td>
          );
          fileTableListNew90.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Inserted</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_DB,
                  fileList[i].last_persist_filename
                )
              }
            >
              {fileList[i].last_persist_filename}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_persist_date}
            </td>
          );
          fileTableListNew90.push(<tr>{tbRow}</tr>);
        }
      } else if (fileList[i].dataset_year == new Date().getFullYear() - 2) {
        if (fileList[i].defaultPeriod180) {
          let tbRow = [];

          tbRow.push(<td>File Uploaded</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_UPLOAD,
                  fileList[i].last_excel_upload_filename
                )
              }
            >
              {fileList[i].last_excel_upload_filename}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_excel_upload_date}
            </td>
          );
          fileTableListOld180.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Updated</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_version_file_name != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  fileList[i].modified == 0 ? ACTION_UPLOAD : ACTION_MOD,
                  fileList[i].last_version_file_name
                )
              }
            >
              {fileList[i].last_version_file_name}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_version_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_version_date}
            </td>
          );
          fileTableListOld180.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Inserted</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_DB,
                  fileList[i].last_persist_filename
                )
              }
            >
              {fileList[i].last_persist_filename}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_persist_date}
            </td>
          );
          fileTableListOld180.push(<tr>{tbRow}</tr>);
        } else if (!fileList[i].defaultPeriod180) {
          let tbRow = [];
          tbRow.push(<td>File Uploaded</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_UPLOAD,
                  fileList[i].last_excel_upload_filename
                )
              }
            >
              {fileList[i].last_excel_upload_filename}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_excel_upload_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_excel_upload_date}
            </td>
          );
          fileTableListOld90.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Updated</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_version_file_name != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  fileList[i].modified == 0 ? ACTION_UPLOAD : ACTION_MOD,
                  fileList[i].last_version_file_name
                )
              }
            >
              {fileList[i].last_version_file_name}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_version_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_version_date}
            </td>
          );
          fileTableListOld90.push(<tr>{tbRow}</tr>);
          tbRow = [];

          tbRow.push(<td>File Inserted</td>);
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_filename != "-"
                  ? "download-link"
                  : "text-center"
              }
              onClick={(e) =>
                this.downloadFile(
                  fileList[i].Abbr,
                  false,
                  fileList[i].defaultPeriod180,
                  fileList[i].dataset_year,
                  ACTION_DB,
                  fileList[i].last_persist_filename
                )
              }
            >
              {fileList[i].last_persist_filename}
            </td>
          );
          tbRow.push(
            <td
              className={
                fileList[i].last_persist_date != "-" ? "" : "text-center"
              }
            >
              {fileList[i].last_persist_date}
            </td>
          );
          fileTableListOld90.push(<tr>{tbRow}</tr>);
        }
      }
    });

    let selectedInstitute = "";
    selectedInstitute = this.props.userData
      ? this.props.userData.userRole == 1
        ? this.state.selectedInstitute
        : this.props.userData.institutionName
      : null;

    return (
      <div className="info-page ">
        <div className="institute-table">
          {this.state.firstTymLoad ? (
            <FullScreenLoaderComponent msg="" />
          ) : null}

          {/* <div className="institute-table"> */}
          <div className="row border-1">
            <div className="col-auto mr-auto">
              <h6 className="mt-3">{selectedInstitute}</h6>
            </div>
            <div
              className="col-auto"
              hidden={
                this.props.userData
                  ? this.props.userData.userRole != 1
                    ? true
                    : false
                  : true
              }
            >
              <Select
                required
                options={this.state.instituteFilter}
                filterOption={this.filterOption}
                name="institution"
                id="institution-info"
                value={this.state.filteredInsitute}
                onChange={(e) => this.handleInstituteSelect(e)}
                className="react-select"
                placeholder="Select Institution"
                isSearchable={true}
                noOptionsMessage={() => "No Results"}
                classNamePrefix="react-select-menulist"
                styles={customStyles}
              />
            </div>
          </div>
          <div>
            <Nav tabs className="tab-sec tab-sec-child">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.dashtoggle("1");
                  }}
                  onKeyUp={(event) => this.enterPressed(event, "1")}
                >
                  New Report Period
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.dashtoggle("2");
                  }}
                  onKeyUp={(event) => this.enterPressed(event, "2")}
                >
                  Old Report Period
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="period-sec">
                  <p>90 Days Default Period</p>
                  <div className="info-table">
                    <table className="table table-bordered">
                      {fileTableListNew90}
                      {/* <tr>
                        <td>Last Excel</td>
                        <td>fienamefienamefienamefienamefienamefiename.xlx</td>
                        <td>08/07/2020 22:40</td>
                      </tr>
                      <tr>
                        <td>Last Version</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>In Database</td>
                        <td>-</td>
                        <td>-</td>
                      </tr> */}
                    </table>
                  </div>
                </div>

                <div className="period-sec">
                  <p>180 Days Default Period</p>
                  <div className="info-table">
                    <table className="table table-bordered">
                      {fileTableListNew180}
                      {/* <tr>
                        <td>Last Excel</td>
                        <td>fienamefienamefienamefienamefienamefiename.xlx</td>
                        <td>08/07/2020 22:40</td>
                      </tr>
                      <tr>
                        <td>Last Version</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>In Database</td>
                        <td>-</td>
                        <td>-</td>
                      </tr> */}
                    </table>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="period-sec">
                  <p>90 Days Default Period</p>
                  <div className="info-table">
                    <table className="table table-bordered">
                      {fileTableListOld90}
                    </table>
                  </div>
                </div>

                <div className="period-sec">
                  <p>180 Days Default Period</p>
                  <div className="info-table">
                    <table className="table table-bordered">
                      {fileTableListOld180}
                    </table>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>

          {/* {this.props.userData?(this.props.userData.userRole==1?instTable:fileTable):this.props.callUserDetails()} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, { callUserDetails })(InfoTable);
