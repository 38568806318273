import React, { Component } from "react";
import { Card, CardHeader } from "reactstrap";

class MainHeading extends Component {
  render() {
    return (
      <div className="container">
      
          <Card className="head-parent">
            <CardHeader className="heading">Contact</CardHeader>
          </Card>
 
      </div>
    );
  }
}

export default MainHeading;
