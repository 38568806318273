import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import blue from "@material-ui/core/colors/blue";
import { authHeader } from "../commonUtility/auth-header";
import { FormErrors } from "../commonUtility/FormErrors";
import { connect } from "react-redux";
import {
  ACTION_EVENT_ADD,
  ACTION_EVENT_UPDATE,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
} from "../commonUtility/string-const";
import moment from "moment";
import _ from "lodash";
import * as actions from "../../store/actions/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";

const styles = (theme) => ({
  colorSwitchBase: {
    color: blue[400],
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[700],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class CustomInput extends React.Component {
  render() {
    return (
      <div className="custom-datepicker">
        <input
          ref={(input) =>
            this.props.focusOnOpen ? input && input.focus() : null
          }
          className="w-100 form-control date-contol"
          value={this.props.value}
          type="text"
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          onKeyDown={() => {
            return false;
          }}
          onChange={() => {
            return false;
          }}
        />
        <i
          onClick={this.props.onClick}
          aria-hidden="true"
          className="fa fa-calendar"
        />
      </div>
    );
  }
}

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDescription: "",
      startDate: "",
      endDate: "",
      eventDescriptionValid: true,
      startDateValid: true,
      endDateValid: true,
      formValid: true,
      showLoader: false,
      formErrors: {
        eventDescription: "",
        startDate: "",
        endDate: "",
      },
      action: ACTION_EVENT_ADD,
      isEdit: false,
      endEvent: false,
      editInfo: {},
      startFocusOnOpen: true,
      endFocusOnOpen: false,
    };

    this.addEditEvent = this.addEditEvent.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  handleChangeSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  handleChange(field, event) {
    this.setState({
      [field]: event.target.value,
      formErrors: {
        eventDescription: "",
        startDate: "",
        endDate: "",
      },
    });
  }
  handleChangeDate(field, value) {
    this.setState({
      [field]: value,
      formErrors: {
        eventDescription: "",
        startDate: "",
        endDate: "",
      },
    });
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.eventDescriptionValid &&
        this.state.startDateValid &&
        this.state.endDateValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  validateAndSubmit = (event) => {
    event.preventDefault();
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.error = "";
    fieldValidationErrors.eventDescription = "";
    fieldValidationErrors.startDate = "";
    fieldValidationErrors.endDate = "";
    let eventDescriptionValid = this.state.eventDescriptionValid;
    let startDateValid = this.state.startDateValid;
    let endDateValid = this.state.endDateValid;

    if (
      this.state.eventDescription === undefined ||
      this.state.eventDescription === null ||
      this.state.eventDescription === ""
    ) {
      fieldValidationErrors.eventDescription = "Please enter Event Description";
      eventDescriptionValid = false;
    } else {
      eventDescriptionValid = true;
    }

    if (
      this.state.startDate === undefined ||
      this.state.startDate === null ||
      this.state.startDate === ""
    ) {
      fieldValidationErrors.startDate = "Please enter Start Date";
      startDateValid = false;
    } else {
      startDateValid = true;
    }

    if (
      this.state.endDate === undefined ||
      this.state.endDate === null ||
      this.state.endDate === ""
    ) {
      fieldValidationErrors.endDate = "Please enter End Date";
      endDateValid = false;
    } else {
      endDateValid = true;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      eventDescriptionValid: eventDescriptionValid,
      startDateValid: startDateValid,
      endDateValid: endDateValid,
    });
    console.log(eventDescriptionValid);
    console.log(startDateValid);
    console.log(endDateValid);

    if (eventDescriptionValid && startDateValid && endDateValid) {
      this.addEditEvent();
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.editInfo && !_.isEqual(props.editInfo, state.editInfo)) {
      let edit_info = props.editInfo;
      return {
        eventDescription: edit_info.description,
        startDate: new Date(edit_info.startDate),
        endDate: new Date(edit_info.endDate),
        action: ACTION_EVENT_UPDATE,
        isEdit: true,
        endEvent: edit_info.endOfEvent === 1 ? true : false,
        formErrors: {
          eventDescription: "",
          startDate: "",
          endDate: "",
          error: "",
        },
        editInfo: edit_info,
        startFocusOnOpen: edit_info.status === "Ongoing" ? false : true,
        endFocusOnOpen: edit_info.status === "Ongoing" ? true : false,
      };
    }
    return {
      startFocusOnOpen: false,
      endFocusOnOpen: false,
    };
  }

  closeEventAddEditForm() {
    this.setState({ isEdit: false });
    this.props.closeEventFormCallback();
  }

  addEditEvent() {
    let input = {
      id: this.state.isEdit ? this.state.editInfo.id : null,
      description: this.state.eventDescription,
      startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
      endOfEvent: this.state.endEvent ? 1 : 0,
      updateOn: moment().format("DD-MM-YYYY HH:mm:ss"),

      status: this.state.isEdit ? this.state.editInfo.status : null,
    };
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    this.props.addEditEventLoaderCallback(true);
    console.log("In save event");
    console.log("Input ", input);
    instance
      .post("dashboard/save", input, config)
      .then((response) => {
        this.props.addEditEventLoaderCallback(false);
        if (response) {
          if (response.status === 200) {
            this.props.getUserNotificationsAndEvents();

            this.props.addedEventCallback(
              null,
              this.state.isEdit
                ? "Event has been updated successfully."
                : "Event has been created successfully.",
              false,
              TOAST_TYPE_SUCCESS
            );

            this.setState({
              eventDescription: "",
              startDate: "",
              endDate: "",
              endEvent: false,
              eventDescriptionValid: true,
              startDateValid: true,
              endDateValid: true,
              formValid: true,
              showLoader: false,
              formErrors: {
                eventDescription: "",
                startDate: "",
                endDate: "",
                error: "",
              },
              action: ACTION_EVENT_ADD,
              isEdit: false,
            });
          } else {
            this.props.addedEventCallback(
              null,
              response.data.message,
              true,
              TOAST_TYPE_ERROR
            );
          }
        } else {
          this.props.addedEventCallback(
            null,
            "An error occured.",
            true,
            TOAST_TYPE_ERROR
          );
        }
      })
      .catch((error) => {
        this.props.addEditEventLoaderCallback(false);
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            this.props.addedEventCallback(
              null,
              "An error occured.",
              true,
              TOAST_TYPE_ERROR
            );
          }
        } else {
          this.props.addedEventCallback(
            null,
            "An error occured.",
            true,
            TOAST_TYPE_ERROR
          );
        }
      });
  }

  clearAll() {
    if (this.state.isEdit) {
      let edit_info = this.props.editInfo;
      this.setState({
        eventDescription: edit_info.description,
        startDate: new Date(edit_info.startDate),
        endDate: new Date(edit_info.endDate),
        action: ACTION_EVENT_UPDATE,
        isEdit: true,
        endEvent: edit_info.endOfEvent === 1 ? true : false,
        formErrors: {
          eventDescription: "",
          startDate: "",
          endDate: "",
          error: "",
        },
        editInfo: edit_info,
      });
    } else {
      this.setState({
        eventDescription: "",
        startDate: "",
        endDate: "",
        endEvent: false,
        eventDescriptionValid: true,
        startDateValid: true,
        endDateValid: true,
        formValid: true,
        showLoader: false,
        formErrors: {
          eventDescription: "",
          startDate: "",
          endDate: "",
          error: "",
        },
        action: ACTION_EVENT_ADD,
        isEdit: false,
        editInfo: {},
      });

      this.props.resetCallback();
    }
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.closeEventAddEditForm();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className="edit-form-border">
        <div className="form-padd event-management">
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={this.validateAndSubmit}
          >
            <div className="w-100">
              <div className="d-flex">
                <div className="first">
                  <div className="">
                    <div
                      className={`form-group ${this.errorClass(
                        this.state.formErrors.startDate
                      )}`}
                    >
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChangeDate.bind(this, "startDate")}
                        value={this.state.startDate}
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={
                          <CustomInput
                            focusOnOpen={this.state.startFocusOnOpen}
                          />
                        }
                        disabled={
                          this.state.isEdit
                            ? this.props.editInfo.status === "Ongoing"
                              ? true
                              : false
                            : false
                        }
                        minDate={new Date()}
                        maxDate={this.state.endDate}
                        popperContainer={CalendarContainer}
                        autoFocus={
                          this.state.isEdit
                            ? this.props.editInfo.status === "Ongoing"
                              ? false
                              : true
                            : true
                        }
                        placeholderText="Start Date"
                      />
                      <div className="error-text">
                        <FormErrors
                          formErrors={this.state.formErrors.startDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div
                      className={`form-group ${this.errorClass(
                        this.state.formErrors.endDate
                      )}`}
                    >
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleChangeDate.bind(this, "endDate")}
                        value={this.state.endDate}
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={
                          <CustomInput
                            focusOnOpen={this.state.endFocusOnOpen}
                          />
                        }
                        minDate={
                          this.state.startDate === null ||
                          this.state.startDate === "" ||
                          moment(this.state.startDate).isBefore(moment(), "day")
                            ? new Date()
                            : this.state.startDate
                        }
                        popperContainer={CalendarContainer}
                        autoFocus={
                          this.state.isEdit
                            ? this.props.editInfo.status === "Ongoing"
                              ? true
                              : false
                            : false
                        }
                        placeholderText="End Date"
                      />
                      <div className="error-text">
                        <FormErrors
                          formErrors={this.state.formErrors.endDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {this.state.isEdit ? (
                      <div className="form-group">
                        <div className="">
                          <input
                            id="endtheevent"
                            type="checkbox"
                            checked={this.state.endEvent}
                            onChange={this.handleChangeSwitch("endEvent")}
                            value="endEvent"
                            label="Primary"
                            style={{ marginTop: "-0.2em" }}
                            disabled={
                              this.state.editInfo.status === "Expired" ||
                              this.state.editInfo.status === "Cancelled"
                            }
                          />
                          &nbsp;
                          <label htmlFor="endtheevent">End the Event</label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="second-column flex-grow-1 pl-4">
                  <div className="">
                    <div
                      className={`form-group ${this.errorClass(
                        this.state.formErrors.eventDescription
                      )}`}
                    >
                      <textarea
                        id="eventDescription"
                        name="eventDescription"
                        label="Event Description"
                        className={
                          classes.textField + " w-100 form-control event-desc"
                        }
                        value={this.state.eventDescription}
                        onChange={this.handleChange.bind(
                          this,
                          "eventDescription"
                        )}
                        margin="normal"
                        placeholder="Event Description"
                        autoComplete="off"
                        rows="3"
                        maxLength="150"
                      />
                      <div className="error-text">
                        <FormErrors
                          formErrors={this.state.formErrors.eventDescription}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-auto mr-auto text-right">
                  <div className="">
                    <div className="form-group">
                      <button type="submit" className="btn btn-default site">
                        {this.state.isEdit ? "Update" : "Add Event"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={() => this.clearAll()}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                onClick={() => this.closeEventAddEditForm()}
                tabIndex="0"
                className="cross-icon"
                onKeyPress={(event) => this.enterPressed(event)}
              >
                <span>
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
              </div>
            </div>
          </form>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {},
    errorActionToast: (message) => {},
    getUserNotificationsAndEvents: () => {
      dispatch(actions.getUserNotificationsAndEvents());
    },
  };
};

EventForm.propTypes = {
  classes: PropTypes.object.isRequired,
  addedEventCallback: PropTypes.func,
  closeEventFormCallback: PropTypes.func,
  addEditEventLoaderCallback: PropTypes.func,
  resetCallback: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventForm));
