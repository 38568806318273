import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import instance from "../../instance";
import AdminUserForm from "./userForm";
import { authHeader } from "../commonUtility/auth-header";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import showAlert from "../commonUtility/confirmAlert";
import { ToastContainer } from "react-toastify";
import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  PRIVILEGE_ALL,
} from "../commonUtility/string-const";
import MaterialTable, { MTableToolbar } from "material-table";
import LoaderComponent from "../commonUtility/LoaderComponent";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import PopupModal from "../commonUtility/PopupModal";

const AdminUserList = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#111",
    fontSize: 15,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserForm: false,
      userInfo: [],
      institutionList: [],
      roleList: [],
      allRolelist: [],
      institutionListSpecific: [],
      roleListSpecific: [],
      userPrivilege: [],
      message: "",
      isFormOpen: false,
      adminRole: null,
      columns: [],
      isLoading: false,
      firstTymLoad: false,
      instituteCodeWithMultipleAdmin: [],
      noOfAdminByInst: [],
      memberRoleOnly: [],
      tempUserInfo: [],
      searchText: "",
      multiple_admin_allowed: false,
      searchClass: "fa fa-search",
      clearClass: "fa fa-times",
      displayResetPasswordModal: false,
      resetPasswordUser: null,
      currentPageSize: 10,
      superAdminPriv: [],
    };
    this.showAdmin = this.showAdmin.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.startSearching = this.startSearching.bind(this);
    this.isAdminAllowed = this.isAdminAllowed.bind(this);
    this.closeResetPasswordModal = this.closeResetPasswordModal.bind(this);
    this.resetPwd = this.resetPwd.bind(this);
    this.confirmResetPwd = this.confirmResetPwd.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  componentDidUpdate() {
    if (Object.keys(this.state.userInfo).length == 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv != undefined) {
      pageDiv.hidden = flag;
    }
  }

  loadInstituteAndRole() {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .get("/api/getUsersRoleInstitute", config)
      .then((response) => {
        let data = null;
        let roledata = null;
        let allRoleData = null;
        let privilegeData = null;
        let instituteCodeWithMultipleAdmin = null;
        response.data.map((dt) =>
          Object.keys(dt).map((k) => {
            switch (k) {
              case "institution":
                data = dt[k];
                break;
              case "role":
                roledata = dt[k];
                allRoleData = dt[k];
                break;
              case "privilege":
                privilegeData = dt[k];
                break;
            }
          })
        );
        let adminRole = null;
        for (var i = 0; i < roledata.length; i++) {
          if (roledata[i].code === 1) {
            adminRole = {
              value: roledata[i].code,
              display: roledata[i].role_type,
            };
            break;
          }
        }
        let memberRole = null;
        for (var i = 0; i < roledata.length; i++) {
          if (roledata[i].code === 3) {
            memberRole = {
              value: roledata[i].code,
              display: roledata[i].role_type,
            };
            break;
          }
        }

        let superAdminPriv = null;
        for (var i = 0; i < privilegeData.length; i++) {
          if (privilegeData[i].id == PRIVILEGE_ALL) {
            superAdminPriv = {
              value: privilegeData[i].id,
              display: privilegeData[i].name,
            };
            break;
          }
        }
        this.setState({
          adminRole: adminRole,
          memberRoleOnly: memberRole,
          superAdminPriv: superAdminPriv,
        });
        console.log("RoleData ", roledata);

        let institutionlistSpecific = data.map((instituion) => {
          let codeValue = instituion.code;
          let title = instituion.institution_name;
          return {
            value: codeValue,
            display: title,
          };
        });

        instituteCodeWithMultipleAdmin = data.map((instituion) => {
          let codeValue = instituion.code;
          let multiple_admin_allowed = instituion.multiple_admin_allowed;
          return {
            code: codeValue,
            multiple_admin_allowed: multiple_admin_allowed,
          };
        });

        let institutionlist = data.map((instituion) => {
          let codeValue = instituion.code;
          let title = instituion.institution_name;
          return {
            [codeValue]: title,
          };
        });
        console.log("institutes ::", institutionlist);
        let instituteLookUp = {};
        institutionlist.map((list) => {
          let ids = Object.keys(list);
          ids.forEach((innerId) => {
            instituteLookUp[innerId] = list[innerId];
          });
        });
        console.log(instituteLookUp);

        let rolelistSpecific = allRoleData.map((role) => {
          let rolValue = role.code;
          let rolTitle = role.role_type;
          return { value: rolValue, display: rolTitle };
        });

        for (var i = 0; i < rolelistSpecific.length; i++) {
          console.log(rolelistSpecific[i].value);
          if (rolelistSpecific[i].value === 1) {
            rolelistSpecific.splice(i, 1);
          }
        }

        let rolelist = allRoleData.map((role) => {
          let rolValue = role.code;
          let rolTitle = role.role_type;
          return { [rolValue]: rolTitle };
        });

        let allRolelist = allRoleData.map((role) => {
          let rolValue = role.code;
          let rolTitle = role.role_type;
          return { value: rolValue, display: rolTitle };
        });

        let roleLookUp = {};
        rolelist.map((list) => {
          let ids = Object.keys(list);
          ids.forEach((innerId) => {
            roleLookUp[innerId] = list[innerId];
          });
        });
        console.log(roleLookUp);
        console.log("rolelist ::", rolelist);
        this.setState({
          instituteCodeWithMultipleAdmin: instituteCodeWithMultipleAdmin,
        });
        this.setState({
          institutionListSpecific: [
            { value: -1, display: "Select Institution" },
          ].concat(institutionlistSpecific),
          institutionList: [
            { value: -1, display: "Select Institution" },
          ].concat(institutionlistSpecific),
        });

        this.setState({
          roleListSpecific: [{ value: -1, display: "Select User Type" }].concat(
            rolelistSpecific
          ),
          roleList: [{ value: -1, display: "Select User Type" }].concat(
            rolelist
          ),
          allRolelist: allRolelist,
        });

        let privilegelistSpecific = privilegeData.map((privilege) => {
          let privValue = privilege.id;
          let privTitle = privilege.name;
          return {
            value: privValue,
            display: privTitle,
          };
        });

        let privilegelist = privilegeData.map((privilege) => {
          let privValue = privilege.id;
          let privTitle = privilege.name;
          return {
            [privValue]: privTitle,
          };
        });

        let privLookUp = {};
        privilegelist.map((list) => {
          let ids = Object.keys(list);
          ids.forEach((innerId) => {
            privLookUp[innerId] = list[innerId];
          });
        });
        console.log(privLookUp);

        this.setState({
          userPrivilege: [{ value: -1, display: "Select Privilege" }].concat(
            privilegelistSpecific
          ),
        });

        console.log("User privilege ", this.state.userPrivilege);

        if (institutionlist.length == 1) {
          this.setState({ institution: institutionlist[0].value });
          console.log("***** ", this.state.institution);
        }

        let tempColumns = [
          {
            title: "Sr. No.",
            type: "numeric",
            field: "id",
            editable: "never",
            sorting: true,
            hidden: true,
          },
          {
            title: "Name",
            field: "firstName",
            type: "string",
            editable: "never",
            sorting: true,
            customSort: (a, b) =>
              a.firstName.toLowerCase() < b.firstName.toLowerCase() ? -1 : 1,
          },
          {
            title: "Email",
            field: "username",
            type: "string",
            editable: "never",
            sorting: true,
            customSort: (a, b) =>
              a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1,
          },
          {
            title: "Institution",
            field: "institution_code",
            type: "string",
            editable: "never",
            lookup: instituteLookUp,
            sorting: true,
          },
          {
            title: "User Type",
            field: "role_id",
            type: "string",
            editable: "never",
            lookup: roleLookUp,
            sorting: true,
          },
          {
            title: "Privileges",
            field: "privilege_id",
            type: "string",
            editable: "never",
            lookup: privLookUp,
            sorting: true,
          },
          {
            title: "Status",
            field: "isActive",
            type: "string",
            editable: "never",
            sorting: true,
          },
        ];
        this.setState({ columns: tempColumns, firstTymLoad: false });
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();

            window.location.replace("/");
          } else {
          }
        } else {
        }
      });
  }

  componentDidMount() {
    this.setState({ firstTymLoad: true });
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };

    instance
      .get("/api/getIntituteUsers", config)
      .then((response) => {
        let userList = response.data.map((list, index) => {
          return {
            ...list,
            id: index + 1,
          };
        });
        console.log("new list : ", userList);
        const noOfAdminByInst = userList.reduce(
          (code, { institution_code }) => {
            if (!code[institution_code]) code[institution_code] = [];
            return code;
          },
          {}
        );

        Object.keys(noOfAdminByInst).forEach(function (code) {
          let count = 0;
          for (var i = 0; i < userList.length; i++) {
            if (
              userList[i].institution_code == code &&
              userList[i].role_id == 2
            ) {
              count++;
            }
          }
          noOfAdminByInst[code].push(count);
        });

        console.log("noOfAdminByInst", noOfAdminByInst);

        this.setState({
          userInfo: userList,
          noOfAdminByInst: noOfAdminByInst,
          tempUserInfo: userList,
        });
        this.loadInstituteAndRole();
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  showAdmin() {
    this.setState({ showUserForm: true, editInfo: null, isFormOpen: true });
  }

  hasUserSelectedSorting() {
    let isActiveIndex = -1;
    var p = document.getElementsByClassName("MuiTableSortLabel-root");
    for (var i = 0; i < p.length; i++) {
      if (p[i].getAttribute("class").indexOf("active") > 0) {
        isActiveIndex = i;
        break;
      }
    }
    return isActiveIndex;
  }

  userAdd(data, message, error, type) {
    this.setState({ searchText: "", firstTymLoad: true });
    this.startSearching(null);

    let foundIdex = -1;
    let tempUserData = [];
    if (!error) {
      let userData = {
        firstName: data.firstName,

        institution_name: data.institution_name,
        institution_code: data.institution_code,
        username: data.username,
        role_type: data.role_type,
        role_id: data.role_id,
        isActive: data.isActive ? "Active" : "Inactive",
        privilege_id: data.privilege_id,
        privilege_name: data.privilege_name,
      };
      console.log("User add ", userData, " ", data);
      for (var i = 0; i < this.state.userInfo.length; i++) {
        if (this.state.userInfo[i].username === userData.username) {
          foundIdex = i;
          break;
        }
      }
      if (foundIdex != -1) {
        userData["id"] = foundIdex + 1;
        tempUserData = this.state.userInfo;
        tempUserData.splice(foundIdex, 1, userData);
        this.setState({
          userInfo: tempUserData,
          editInfo: null,
          tempUserInfo: tempUserData,
        });
      } else {
        userData["id"] = 1;

        this.state.userInfo.map((user) => {
          user.id = user.id + 1;
        });
        this.state.userInfo.unshift(userData);
        this.setState({
          userInfo: this.state.userInfo,
          editInfo: null,
          tempUserInfo: this.state.userInfo,
        });
      }

      const noOfAdminByInst = this.state.userInfo.reduce(
        (code, { institution_code }) => {
          if (!code[institution_code]) code[institution_code] = [];
          return code;
        },
        {}
      );

      const tempList = this.state.userInfo;
      Object.keys(noOfAdminByInst).forEach(function (code) {
        let count = 0;
        for (var i = 0; i < tempList.length; i++) {
          if (
            tempList[i].institution_code == code &&
            tempList[i].role_id == 2
          ) {
            count++;
          }
        }
        noOfAdminByInst[code].push(count);
      });

      this.setState({ noOfAdminByInst: noOfAdminByInst });
      console.log("noOfAdminByInst ", noOfAdminByInst);
    }
    showToast(message, type);
    this.setState({ firstTymLoad: false });
  }

  enterEdit(dataItem) {
    console.log("Enter edit ", dataItem);
    this.setState({
      editInfo: dataItem,
      showUserForm: true,
      isFormOpen: true,
      multiple_admin_allowed: this.isAdminAllowed(dataItem.institution_code),
    });
    window.scrollTo(0, 0);
  }

  startSearching(e) {
    if (e != null) {
      e = e.target.value;
      this.setState({ searchText: e });
      if (e != null && e.toString().trim() != "") {
        let userLst = this.state.tempUserInfo;
        let searchItems = [];
        for (var j = 0; j < userLst.length; j++) {
          for (var key in userLst[j]) {
            if (
              key != "tableData" &&
              key != "id" &&
              key != "institution_code" &&
              key != "privilege_id" &&
              key != "role_id" &&
              userLst[j][key] != null
            ) {
              if (
                userLst[j][key]
                  .toString()
                  .toLowerCase()
                  .includes(e.toLowerCase())
              ) {
                searchItems.push(userLst[j]);
                break;
              }
            }
          }
        }
        console.log(searchItems);
        this.setState({ userInfo: searchItems });
      } else {
        this.setState({ userInfo: this.state.tempUserInfo });
      }
    } else {
      this.setState({ userInfo: [] });
      this.setState({ userInfo: this.state.tempUserInfo });
    }
  }

  confirmResetPwd(row) {
    this.setState({ displayResetPasswordModal: true, resetPasswordUser: row });
  }

  closeUserForm() {
    this.setState({ showUserForm: false, editInfo: null, isFormOpen: false });
  }

  clearAll() {
    this.setState({ editInfo: null });
  }

  searchIconClick(clear) {
    if (clear) {
      this.setState({ searchText: "" });
      this.startSearching(null);
    }
  }

  resetPwd() {
    let row = this.state.resetPasswordUser;
    this.setState({ isLoading: true });
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };
    let reset = true;
    let data = {
      username: row.username,
    };
    instance
      .put("/api/changePassword/" + reset, data, config)
      .then((response) => {
        this.setState({
          isLoading: false,
          displayResetPasswordModal: false,
          resetPasswordUser: null,
        });
        console.log("password change ", response);
        if (
          response.data &&
          response.data.error &&
          response.data.error == "false"
        ) {
          this.props.passMessage(response.data.message, TOAST_TYPE_SUCCESS);
        } else {
          if (response.data && response.data.message)
            this.props.passMessage(response.data.message, TOAST_TYPE_INFO);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          displayResetPasswordModal: false,
          resetPasswordUser: null,
        });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            this.props.passMessage(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          this.props.passMessage(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  isAdminAllowed(code) {
    let hasNoAdmin = false;
    let isMultipleAdminAllowed = false;
    for (var i = 0; i < this.state.instituteCodeWithMultipleAdmin.length; i++) {
      if (this.state.instituteCodeWithMultipleAdmin[i].code == code) {
        if (
          this.state.instituteCodeWithMultipleAdmin[i].multiple_admin_allowed ==
          true
        ) {
          isMultipleAdminAllowed = true;
        }
      }
    }
    if (isMultipleAdminAllowed) {
      return true;
    } else {
      let noOfAdminByInst = this.state.noOfAdminByInst;
      Object.keys(noOfAdminByInst).forEach(function (cd) {
        if (cd == code) {
          if (noOfAdminByInst[cd][0] === 0) {
            hasNoAdmin = true;
          }
        }
      });
      if (hasNoAdmin) {
        return true;
      } else {
        return false;
      }
    }
  }

  closeResetPasswordModal() {
    this.setState({
      displayResetPasswordModal: false,
      resetPasswordUser: null,
    });
  }

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");

      this.searchIconClick(this.state.searchText.trim().length > 0);
    }
  };

  cancelPopup() {
    this.setState({
      displayResetPasswordModal: false,
      resetPasswordUser: null,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.displayResetPasswordModal ? (
          <PopupModal
            title="Reset Password"
            message={
              "Are you sure you want to reset password for " +
              this.state.resetPasswordUser.username +
              "?"
            }
            yesClickCallback={this.resetPwd.bind(this)}
            noClickCallback={this.closeResetPasswordModal}
            cancelClickCallback={this.cancelPopup.bind(this)}
            buttonPositive="Yes"
            buttonNegative="No"
          />
        ) : null}
        {this.state.showUserForm ? (
          <AdminUserForm
            addedUserCallback={this.userAdd.bind(this)}
            editInfo={this.state.editInfo}
            institution={this.state.institutionListSpecific}
            roleSAdmin={this.state.adminRole}
            roleNonSAdmin={this.state.roleListSpecific}
            userPrivilege={this.state.userPrivilege}
            closeUserFormCallback={this.closeUserForm.bind(this)}
            resetCallback={this.clearAll.bind(this)}
            addEditUserLoaderCallback={this.startLoader.bind(this)}
            instituteCodeWithAdminAllowed={
              this.state.instituteCodeWithMultipleAdmin
            }
            noOfAdminByInst={this.state.noOfAdminByInst}
            memberRole={this.state.memberRoleOnly}
            isMultipleAdminAllowed={this.state.multiple_admin_allowed}
            allRolelist={this.state.allRolelist}
            superAdminPriv={this.state.superAdminPriv}
          />
        ) : null}
        <div>
          <Paper className={classes.root + " admin-user-list "}>
            {this.state.isLoading ? <LoaderComponent /> : null}
            {this.state.firstTymLoad ? (
              <FullScreenLoaderComponent msg="" />
            ) : null}
            {this.state.firstTymLoad ? null : (
              <div className="top-bar">
                <div className="row">
                  <div className="col-auto align-self-center">
                    <div className="search-bar">
                      <div id="custom-search-input">
                        <div className="input-group">
                          <input
                            type="text"
                            className="search-query form-control"
                            placeholder="Search"
                            onChange={this.startSearching}
                            value={this.state.searchText}
                            tabIndex="0"
                          />
                          <span className="input-group-btn" tabIndex="-1">
                            <button
                              className="btn btn-danger"
                              type="button"
                              tabIndex="-1"
                            >
                              <i
                                className={
                                  this.state.searchText.trim().length > 0
                                    ? this.state.clearClass
                                    : this.state.searchClass
                                }
                                aria-hidden="true"
                                onClick={() =>
                                  this.searchIconClick(
                                    this.state.searchText.trim().length > 0
                                  )
                                }
                                onKeyUp={(event) => this.enterPressed(event)}
                                tabIndex={
                                  this.state.searchText.trim().length > 0
                                    ? "0"
                                    : "-1"
                                }
                              ></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto ml-auto align-self-center">
                    {this.state.isFormOpen ? null : (
                      <div
                        aria-label="Add"
                        className="addbutton"
                        onClick={this.showAdmin}
                      >
                        <button className="btn btn-default">Add User</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {this.state.firstTymLoad ? null : (
              <MaterialTable
                columns={this.state.columns}
                data={this.state.userInfo}
                onChangeRowsPerPage={(pageSize) => {
                  this.setState({ currentPageSize: pageSize });
                  let timesRun = 0;
                  let firstTimeExecuted = false;
                  var hasVScroll =
                    window.innerHeight /* + window.scrollY*/ <=
                    document.body.offsetHeight;
                  this.interval = setInterval(() => {
                    if (timesRun == 5) {
                      clearInterval(this.interval);
                    }
                    timesRun++;
                    console.log("service ", hasVScroll);
                    if (hasVScroll) {
                      window.scrollTo(0, 0);
                      clearInterval(this.interval);
                    } else {
                      if (firstTimeExecuted) {
                        clearInterval(this.interval);
                      }
                      firstTimeExecuted = true;
                    }
                  }, 1);
                }}
                options={{
                  showTitle: false,
                  searchFieldAlignment: "left",
                  actionsColumnIndex: -1,
                  pageSize: this.state.currentPageSize,
                  pageSizeOptions: [10, 20, 25],
                  sorting: this.state.userInfo.length > 0,
                  search: false,
                  emptyRowsWhenPaging: false,
                  draggable: false,
                  thirdSortClick: false,
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => this.enterEdit(rowData),
                  },
                  {
                    icon: "refresh",
                    tooltip: "Reset Password",
                    onClick: (event, rowData) => this.confirmResetPwd(rowData),
                  },
                ]}
              />
            )}
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.value,
});

const mapDispatchToProps = (dispatch) => {
  return {
    successActionToast: (message) => {},
    errorActionToast: (message) => {},
  };
};

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  passMessage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomizedTable));
