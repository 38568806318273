import React, { Component } from "react";
import { FormErrors } from "./FormErrors";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Redirect } from "react-router-dom";
import Otp from "./Otp";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import lock from "./../../images/lock.png";
import { LoginInvalidEmailPassword } from "../commonUtility/string-const";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ModalError } from "./ModalError";
import Loader from "react-loader-spinner";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      formErrors: {
        email: "",
        password: "",
        error: "",
        fpEmail: "",
        modalError: "",
      },
      modalErrors: { error: "" },
      emailValid: false,
      fpEmailValid: false,
      passwordValid: false,
      formValid: false,
      disableSignin: false,
      isSignIn: false,
      showComponent: false,
      displayPopup: false,
      modal: false,
      forgotPwdEmailDisplay: true,
      isModelLoading: false,
      fpEmail: "",
      hasError: true,
    };
    this.forgotPwd = this.forgotPwd.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    if (this.state.hasError === false && this.state.modal === true) {
      window.location.replace("/login");
    }
    this.setState((prevState) => ({
      modal: !prevState.modal,
      modalErrors: { error: "" },
      forgotPwdEmailDisplay: true,
      fpEmail: "",
      fpEmailValid: false,
      hasError: true,
    }));
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.validateField(name, value);
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  hideOTPComponent() {
    this.setState({ showComponent: false, disableSignin: false });
    console.log("hide ", this.state.showComponent);
  }

  forgotPwd = (event) => {
    event.preventDefault();
    let fieldValidationErrors = this.state.modalErrors;
    if (this.state.fpEmailValid) {
      if (!this.state.fpEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        fieldValidationErrors.error = "This email is invalid.";
        this.setState({
          modalErrors: fieldValidationErrors,
        });
      } else {
        let value = sessionStorage.getItem("gems_bearer_token");
        const config = {
          headers: {
            "content-type": "text/plain",
            b_token: value,
            ...authHeader(),
          },
        };
        this.setState({ isModelLoading: true });
        instance
          .post("/api/forgotPass", this.state.fpEmail.toLowerCase(), config)
          .then((response) => {
            this.setState({ isModelLoading: false, modalError: "" });
            if (response.data != null) {
              fieldValidationErrors.error = response.data.message;
              if (response.data.error == "false") {
                this.setState({
                  forgotPwdEmailDisplay: false,
                  hasError: false,
                  fpEmail: "",
                });
              } else {
                this.setState({ hasError: true });
              }
              this.setState({
                modalErrors: fieldValidationErrors,
              });
            }
          })
          .catch((error) => {
            this.setState({ isModelLoading: false });
            console.log(error);
          });
      }
    } else {
      if (this.state.fpEmail.trim() == "") {
        fieldValidationErrors.error = "Invalid Email address.";
        this.setState({
          modalErrors: fieldValidationErrors,
        });
      }
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.error = "";
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let fpEmailValid = false;

    switch (fieldName) {
      case "email":
        fieldValidationErrors.email = "";
        if (value.trim().length == 0) {
          emailValid = false;
        }
        if (value.trim().length > 0) {
          emailValid = true;
        }
        break;
      case "fpEmail":
        fieldValidationErrors.fpEmail = "";
        if (value.trim().length == 0) {
          fpEmailValid = false;
        }
        if (value.trim().length > 0) {
          fpEmailValid = true;
        }
        break;
      case "password":
        if (value.trim().length == 0) {
          fieldValidationErrors.password = "";
        }
        passwordValid = value.trim().length != 0;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        fpEmailValid: fpEmailValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.props.onAuth(this.state.email, this.state.password);
      this.setState({
        disableSignin: true,
        showComponent: false,
      });
    } else {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors.error = LoginInvalidEmailPassword;
      this.setState({
        formErrors: fieldValidationErrors,
      });
    }
  };

  resendOTP() {
    let session = sessionStorage.getItem("gems_session");
    this.props.onResend(session, this.state.email, this.state.password);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ displayPopup: false });
    let errRes = this.state.formErrors;
    errRes.error = "";
    if (nextProps.loading == false) {
      if (!nextProps.error) {
        errRes.error = nextProps.otpAck;
        this.setState({ displayPopup: true });
      }
      if (nextProps.error) {
        errRes.error = "";
        errRes.error = nextProps.error;
        if (!nextProps.showComponent) this.setState({ disableSignin: false });
      }
      this.setState({ showComponent: nextProps.showComponent });
    }
  }

  render() {
    const token = sessionStorage.getItem("gems_bearer_token");
    if (token) {
      return <Redirect push to="/" />;
    }
    return (
      <div className="loginBox">
        <div className="demoForm">
          <form onSubmit={this.submitHandler}>
            <div>
              <img className="img-fluid login-lock" alt="logo" src={lock} />

              <div>
                <FormErrors formErrors={this.state.formErrors} />
              </div>
              <div
                className={`form-group ${this.errorClass(
                  this.state.formErrors.email
                )}`}
              >
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  onChange={this.handleUserInput}
                  disabled={this.state.disableSignin}
                  autoComplete="off"
                />
              </div>
              <div
                className={`form-group ${this.errorClass(
                  this.state.formErrors.password
                )}`}
              >
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  autoComplete="off"
                  onChange={this.handleUserInput}
                  disabled={this.state.disableSignin}
                />
              </div>

              <div className="row">
                <div className="col-7">
                  <span className="login-mail" onClick={this.toggle}>
                    Forgot Password?
                  </span>
                </div>
                <div className="col-5">
                  {" "}
                  <button
                    type="submit"
                    name="login_button"
                    className="btn btn-login"
                    disabled={!this.state.formValid || this.state.disableSignin}
                  >
                    LOGIN
                  </button>
                </div>
              </div>
            </div>
          </form>

          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            backdrop={false}
          >
            <ModalHeader toggle={this.toggle}>Forgot Password</ModalHeader>
            <ModalBody>
              {this.state.modalErrors.error.length > 0 ? (
                <div
                  className={this.state.hasError ? "alert alert-danger" : ""}
                  role="alert"
                >
                  <ModalError fieldName={this.state.modalErrors.error} />
                </div>
              ) : null}

              <form autoComplete="off" onSubmit={this.forgotPwd}>
                {this.state.forgotPwdEmailDisplay ? (
                  <div
                    className={`form-group ${this.errorClass(
                      this.state.modalErrors.error
                    )}`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      name="fpEmail"
                      id="fpEmail"
                      placeholder="Please enter your email"
                      onChange={this.handleUserInput}
                      autoComplete="off"
                    />
                  </div>
                ) : null}

                <div className="row text-right">
                  <div className="col-auto ml-auto mt-3">
                    {" "}
                    {this.state.forgotPwdEmailDisplay ? (
                      <button
                        type="submit"
                        name="fpButton"
                        className="btn btn-default"
                        disabled={!this.state.fpEmailValid}
                        hidden={this.state.isModelLoading}
                      >
                        OK
                      </button>
                    ) : null}{" "}
                    <button
                      type="button"
                      name="fpSuccessButton"
                      className="btn btn-default"
                      onClick={
                        (e) => window.location.replace("/login") /*this.toggle*/
                      }
                      hidden={this.state.forgotPwdEmailDisplay}
                    >
                      OK
                    </button>
                    <Loader
                      className="loader-2"
                      type="Oval"
                      color="#00BFFF"
                      height={50}
                      width={50}
                      visible={this.state.isModelLoading}
                    />
                  </div>
                </div>
              </form>
              <div></div>
            </ModalBody>
          </Modal>
          {/* <div className="row mt-2">
          <div className="col-auto mr-auto">
            <a
              className="login-mail"
              href="mailto:gems-support@d-fine.de?subject=GEMs%20System%20Problem"
            >
              Facing Trouble?
              </a>
          </div>
          <div className="col-auto">
          
           
          </div>
        </div> */}
          {this.state.showComponent ? (
            <Otp
              email={(this.state.email, this.state.token)}
              hideOTPComponent={this.hideOTPComponent.bind(this)}
              resendOTPClicked={this.resendOTP.bind(this)}
              dsplyModel={this.state.displayPopup}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Props received ", state.auth);
  console.log(state.auth.message);
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    showComponent: state.auth.showComponent,
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    otpAck: state.auth.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => {
      dispatch(actions.auth(email, password));
    },
    onResend: (session, email, password) => {
      dispatch(actions.resendOTP(session, email, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
