import React, { Component } from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import {
  TOAST_TYPE_INFO,
  TOAST_TYPE_SUCCESS,
  TOAST_TYPE_ERROR,
} from "./string-const";
import { useTheme, withTheme } from "../../../node_modules/@material-ui/core";

const CustomNotificationInfo = ({ title, onClose }) => {
  const theme = useTheme();
  return (
    <div className="new-toast info-msg">
      <div className="d-flex">
        <div className="p-2 w-100">{title}</div>
        <div className="p-2 flex-shrink-1">
          <span className="toast-cross">
            <i className="fa fa-times" aria-hidden="true" onClick={onClose}></i>
          </span>
        </div>
      </div>
    </div>
  );
};

const CustomNotificationSuccess = ({ title, onClose }) => {
  const theme = useTheme();
  return (
    <div className="new-toast success-msg">
      <div className="d-flex">
        <div className="p-2 w-100">{title}</div>
        <div className="p-2 flex-shrink-1">
          <span className="toast-cross">
            <i className="fa fa-times" aria-hidden="true" onClick={onClose}></i>
          </span>
        </div>
      </div>
    </div>
  );
};

const CustomNotificationError = ({ title, onClose }) => {
  const theme = useTheme();
  return (
    <div className="new-toast error-msg">
      <div className="d-flex">
        <div className="p-2 w-100">{title}</div>
        <div className="p-2 flex-shrink-1">
          <span className="toast-cross">
            <i className="fa fa-times" aria-hidden="true" onClick={onClose}></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export function showToast(message, type = TOAST_TYPE_INFO) {
  switch (type) {
    case TOAST_TYPE_INFO:
      let CustomNotificationWithTheme1 = withTheme(CustomNotificationInfo);
      toaster.notify(({ onClose }) => (
        <CustomNotificationWithTheme1 title={message} onClose={onClose} />
      ));
      break;
    case TOAST_TYPE_SUCCESS:
      let CustomNotificationWithTheme2 = withTheme(CustomNotificationSuccess);
      toaster.notify(({ onClose }) => (
        <CustomNotificationWithTheme2 title={message} onClose={onClose} />
      ));
      break;
    case TOAST_TYPE_ERROR:
      let CustomNotificationWithTheme3 = withTheme(CustomNotificationError);
      toaster.notify(({ onClose }) => (
        <CustomNotificationWithTheme3 title={message} onClose={onClose} />
      ));
      break;
    default:
      let CustomNotificationWithTheme4 = withTheme(CustomNotificationInfo);
      toaster.notify(({ onClose }) => (
        <CustomNotificationWithTheme4 title={message} onClose={onClose} />
      ));
  }
}
