import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import { connect } from "react-redux";
import Footer from "../commonUtility/Footer";
import * as actions from "../../store/actions/index";
import ProfilePic from "./../../images/profile.jpg";
class Help extends Component {
  // state = {
  //   persons: []
  // };

  // componentDidMount() {
  //   axios.get(`https://jsonplaceholder.typicode.com/users`).then(res => {
  //     const res_persons = res.data;
  //     this.setState({ persons: res_persons });
  //     console.log(this.state.persons[0].name);
  //   });
  // }

  render() {
    console.log(this.props);
    return (
      <div className="help">
        <Navigation />
        <MainHeading />
        <div className="container">
          <div className="row justify-content-md-center">
<div className="col-4">
<h1 className="heading"><i className="fa fa-question-circle" aria-hidden="true"></i><br/> BUSINESS QUERY</h1>
<p> For any business query, please contact </p>
  <p className="email"> <a href="mailto: gems@eib.org">gems@eib.org</a></p>
</div>
<div className="col-4">
  <h1 className="heading"><i className="fa fa-cogs" aria-hidden="true"></i><br/>APPLICATION PROBLEMS</h1>
  <p> For any technical assistance, please contact </p>
  <p className="email"> <a href="mailto:gems-support@d-fine.de">gems-support@d-fine.de</a></p>

</div>
          </div>


        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log("User Props received", state.auth.userData);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNavLoad: () => {
      dispatch(actions.checkAuth());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Help);
