import * as actionTypes from "./actionTypes";
import instance from "../../instance";
import { authHeader } from "../../components/commonUtility/auth-header";

//Assigning ActionType for Auth start
//This will Change the State of Type to Auth_start
//So that in case some execution need to be done on Auth Start based on State it can be done
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};
export const changeRadioProperty = () => {
  return {
    type: actionTypes.RADIO_BUTTON_CLICKED,
    period: "90",
  };
};

//Assigning props and actionType for auth Success
//This will change the type to Auth Success post 2nd Factor Authentication
//Setting up Type to Auth_SUCCESS
//Setting up session token from action
//Setting up userId as email Id
//Setting up is Authenticated to true from action
//Is authenticated is a flag used for to check whether user is authenticated or not
export const authSuccess = (idToken, userId, isAuthenticated, userData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: idToken,
    userId: userId,
    isAuthenticated: isAuthenticated,
    isLogout: false,
    userData: userData,
  };
};

//Assigning props and actionType for authFail
//This will be executed in case of authentication fails
//Type :  This will set type as auth_fail
//error : This will set error in error state getting from response
export const authFail = (error, showComponent) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
    showComponent: showComponent,
  };
};

//Assigning  actionType for logout need to Implemented on UI
//This will executed when user click on logout
//Removing user email Id from local storage
//Setting type as AUTH_INITIATE_LOGOUT
export const logout = (b_token) => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT,
    b_token: b_token,
  };
};

//Assigning  actionType for logoutSucceed
//This will execute once user logged out successfully
//Setting type state to AUTH_LOGOUT
export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
    isLogout: true,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime,
  };
};

//Assigning  actionType and states for checkAuthOTP
//This will
export const checkAuthOTP = (email, otp, isAuthenticated) => {
  return {
    type: actionTypes.AUTH_CHECK_OTP,
    otp: otp,
    email: email,
    isAuthenticated: isAuthenticated,
  };
};

//Assigning  actionType and states for firstLevelAuthDone
//Setting error to null and loading to true when Application Authentication starts
//Once Authentication success then setting authentication token from BE
//Setting up UserId with email
//Setting authentication to true
//error to null and loading to false
export const firstLevelAuthDone = (email, token, message, showComponent) => {
  return {
    type: actionTypes.FIRST_LEVEL_AUTH_VALIDATED,
    showComponent: showComponent,
    email: email,
    token: token,
    isLogout: false,
    message: message,
  };
};

export const auth = (email, password) => {
  return {
    type: actionTypes.AUTH_USER,
    email: email,
    password: password,
  };
};

export const resendOTP = (session, email, password) => {
  return {
    type: actionTypes.RESEND_OTP,
    session: session,
    email: email,
    password: password,
  };
};

//Assigning  actionType and states for setAuthRedirectPath
//Changing state for redirecting URL
//This will maintain the URL post authentication by updating the URL path in state
//Setting up authRedirectPath on the basis of URL from Action
export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE,
  };
};

export const successActionToast = (message) => {
  return {
    type: actionTypes.SUCCESS_TOAST,
    message: message,
  };
};

export const errorActionToast = (message) => {
  return {
    type: actionTypes.ERROR_TOAST,
    message: message,
  };
};

export const checkAuth = (userId, isAuthenticated, userData) => {
  return {
    type: actionTypes.CHECK_AUTH,
    userId: userId,
    isAuthenticated: isAuthenticated,
    isLogout: false,
    userData: userData,
  };
};

export const isFileUploading = (uploading) => {
  return {
    type: actionTypes.FILE_UPLOADING,
    uploading: uploading,
  };
};

export const updateProgress = (progress) => {
  return {
    type: actionTypes.PROGRESSING,
    progress: progress,
  };
};

export const annualModeChange = (isAnnualMode, userData) => {
  return {
    type: actionTypes.ANNUAL_MODE_CHANGE,
    isAnnualMode: isAnnualMode,
    userData: { ...userData, isAnnualMode: isAnnualMode },
  };
};

export const showToast = (message, toastType) => {
  return {
    type: actionTypes.SHOW_TOAST,
    message: message,
    toastType: toastType,
  };
};

export const broadcastInsertSummary = (
  showModal,
  uploadSuccessMessage,
  uploadSummary
) => {
  return {
    type: actionTypes.SHOW_INSERT_SUMMARY,
    uploadSuccessMessage: uploadSuccessMessage,
    uploadSummary: uploadSummary,
    showModal: showModal,
  };
};

export function callUserDetails() {
  return (dispatch) => {
    const url = "/api/checkAuth";
    const b_token = sessionStorage.getItem("gems_bearer_token");
    let headerConfig = {
      headers: {
        "Content-Type": "text/plain",
        ...authHeader(),
        // tokenGenerationDate: sessionStorage.getItem("tokenGenerationDate")
      },
    };
    dispatch({ type: actionTypes.CHECK_AUTH_ASYNC });
    instance
      .post(url, b_token, headerConfig)
      .then((res) => {
        dispatch({
          type: actionTypes.CHECK_AUTH_ASYNC_SUCCESS,
          userData: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CHECK_AUTH_ASYNC_FAILURE,
          userData: error,
        });
      });
  };
}

export function getUserNotificationsAndEvents() {
  return (dispatch) => {
    const url = "dashboard/NaE/get";
    let headerConfig = {
      headers: {
        "Content-Type": "text/plain",
        ...authHeader(),
      },
    };
    instance
      .get(url, headerConfig)
      .then((res) => {
        dispatch({
          type: actionTypes.NOTIFICATIONS_EVENTS,
          notifications: res.data,
        });
      })
      .catch((error) => {
      });
  };
}

export function updateUserNotificationsAndEvents(userNotificationsEvents) {
  return (dispatch) => {
    if (
      userNotificationsEvents.eventUnreadCout > 0 ||
      userNotificationsEvents.notificationUnreadCount > 0
    ) {
      const url = "dashboard/NaE/update";
      let headerConfig = {
        headers: {
          ...authHeader(),
        },
      };
      instance
        .post(
          url,
          {
            datasetYear: userNotificationsEvents.datasetYear,
            eventIdList: userNotificationsEvents.eventList.map(
              (item) => item.id
            ),
            notifList: userNotificationsEvents.notifList,
          },
          headerConfig
        )
        .then((res) => {
          dispatch(getUserNotificationsAndEvents());
        })
        .catch((error) => {
        });
    }
  };
}

export const runCheckExecuting = (runCheckExecuting) => {
  return {
    type: actionTypes.RUN_CHECK_EXEC,
    runCheckExecuting: runCheckExecuting,
  };
};

export const setSavedQueryPlaylist = (savedQueryPlaylist) => {
  return {
    type: actionTypes.SAVED_QUERY_PLAYLIST,
    savedQueryPlaylist: savedQueryPlaylist,
  };
};

export function uploadDSFile(f,formData, config) {
  return (dispatch) => {
    const url = "/data/upload";
    instance
      .post(url, formData, config)
      .then((res) => {
        dispatch({
          type: actionTypes.FILE_UPLOADED,
          fileUploadRes: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FILE_UPLOADED,
          fileUploadRes: null,
        });
      });
  };
}

export const deleteUploadRes = () => {
  return {
    type: actionTypes.DELETE_UPLOAD_RES,
    fileUploadRes: null,
  };
};
