import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer">
          <div className="card text-center">
            <div className="card-body">
              <p className="card-text">
                © Copyright {(new Date().getFullYear())} European Investment Bank
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
