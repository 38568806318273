import React, { Component } from "react";
import classnames from "classnames";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { showToast } from "../commonUtility/NewToast";
import { connect } from "react-redux";
import { callUserDetails } from "../../store/actions/index";
import InfoTable from "./InfoTable";
import SavedQueries from "./SavedQueries";
import ExchangeRateInfo from "./ExchangeRateInfo";
import AuditTrail from "./AuditTrail";

class InfoTab extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  showToast(message, type) {
    showToast(message, type);
  }

  enterPressed = (event, num) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      console.log("Enter pressed");

      this.toggle(num);
    }
  };

  render() {
    return (
      <div className="mt-3">
        <div className="tab">
          <div>
            <Nav tabs className="tab-sec admin-section">
              <NavItem>
                <NavLink
                  tabIndex="0"
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                  onKeyUp={(event) => this.enterPressed(event, "1")}
                >
                  Saved Queries
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tabIndex="0"
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                  onKeyUp={(event) => this.enterPressed(event, "2")}
                >
                  Exchange Rate Information
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tabIndex="0"
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                  onKeyUp={(event) => this.enterPressed(event, "3")}
                >
                  Audit Trail
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  tabIndex="0"
                  className={classnames({
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                  onKeyUp={(event) => this.enterPressed(event, "4")}
                >
                  Uploaded & Inserted Data Status
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {this.state.activeTab == "1" ? <SavedQueries /> : null}
              </TabPane>
              <TabPane tabId="2">
                {this.state.activeTab == "2" ? <ExchangeRateInfo /> : null}
              </TabPane>
              <TabPane tabId="3">
                {this.state.activeTab == "3" ? <AuditTrail /> : null}
              </TabPane>
              <TabPane tabId="4">
                {this.state.activeTab == "4" ? <InfoTable /> : null}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Navigation Props received", state);
  return {
    isLogout: state.auth.isLogout,
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, { callUserDetails })(InfoTab);
