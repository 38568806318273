import {
  ACT_COUNTERPART,
  REF_COUNTERPART,
  REF_CONTRACT,
  DEF_COUNTERPART,
  DEF_CONTRACT,
  GEM_IMPORT,
  REF_GUARANTOR,
  REF_GUARANTEE,
  REF_COLLATERAL,
  MANDATORY_ACT_COUNTERPART,
  MANDATORY_REF_CONTRACT,
  MANDATORY_DEF_COUNTERPART,
  MANDATORY_DEF_CONTRACT,
  MANDATORY_REF_COUNTERPART,
  MANDATORY_GEM_IMPORT,
  MANDATORY_REF_GUARANTOR,
  MANDATORY_REF_GUARANTEE,
  MANDATORY_REF_COLLATERAL,
  TYPE_PD,
  TYPE_MM,
  TYPE_RR,
  TYPE_FC,
  PROBABILITY_OF_DEFAULT,
  MIGRATION_MATRIX,
  RECOVERY_RATE,
  FULL_COMPUTE,
} from "./string-const";
import DefaultCounterpart from "../excelView/DefaultCounterpart";
import instance from "../../instance";
import { authHeader } from "./auth-header";
import _ from "lodash";
import moment from "moment";

export function changeTableSize(noOfRowsInPage, totalRecords, currentPageNo) {
  if (totalRecords != 0) {
    let totalNoofPages = Math.ceil(totalRecords / noOfRowsInPage) - 1;
    if (totalRecords < noOfRowsInPage) {
      return totalRecords;
    } else {
      let defaultRowsPerPage = totalRecords % noOfRowsInPage;
      if (currentPageNo === totalNoofPages) {
        return defaultRowsPerPage;
      } else {
        return noOfRowsInPage;
      }
    }
  } else {
    return 1;
  }
}

export function isPresentInListIgnoreCase(list, searchString) {
  for (var i = 0; i < list.length; i++) {
    if (
      list[i].toString().toLowerCase() == searchString.toString().toLowerCase()
    ) {
      return true;
    }
  }
  return false;
}

export function isColumnMandatory(sheetName, column) {
  switch (sheetName) {
    case ACT_COUNTERPART:
      if (MANDATORY_ACT_COUNTERPART.indexOf(column) >= 0) {
        return true;
      }
      return false;
    case REF_COUNTERPART:
      if (MANDATORY_REF_COUNTERPART.indexOf(column) >= 0) {
        return true;
      }
      return false;
    case REF_CONTRACT:
      if (MANDATORY_REF_CONTRACT.indexOf(column) >= 0) {
        return true;
      }
      return false;
    case DEF_COUNTERPART:
      if (MANDATORY_DEF_COUNTERPART.indexOf(column) >= 0) {
        return true;
      }
      return false;
    case DEF_CONTRACT:
      if (MANDATORY_DEF_CONTRACT.indexOf(column) >= 0) {
        return true;
      }
      return false;
    case GEM_IMPORT:
      if (MANDATORY_GEM_IMPORT.indexOf(column) >= 0) {
        return true;
      }
      return false;
    case REF_GUARANTOR:
      if (MANDATORY_REF_GUARANTOR.indexOf(column) >= 0) {
        return true;
      }
      return false;  
    case REF_GUARANTEE:
      if (MANDATORY_REF_GUARANTEE.indexOf(column) >= 0) {
        return true;
      }
      return false;
    case REF_COLLATERAL:
      if (MANDATORY_REF_COLLATERAL.indexOf(column) >= 0) {
        return true;
      }
      return false;  
    default:
      return false;
  }
}

function isPresendInArray(element, array) {
  if (array.indexOf(element) >= 0) {
    return true;
  }
  return false;
}

export function arrayEqual(array1, arrray2) {
  let isEqual = false;
  if (array1.length !== arrray2.length) {
    return false;
  } else {
    for (var i = 0; i < array1.length; i++) {
      if (isPresendInArray(array1[i], arrray2)) {
        isEqual = true;
      } else {
        return false;
      }
    }
  }
  return isEqual;
}

export function getUploadSummary() {
  var config = {
    headers: { ...authHeader() },
  };
  instance
    .get("/data/upload/getUploadSummary", config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          sessionStorage.clear();
          window.location.replace("/");
        } else {
          console.log("Unknown error occured");
          return null;
        }
      } else {
        console.log("Unknown error occured");
        return null;
      }
    });
  return null;
}

export function capitalizWords(str) {
  str = str.toLowerCase();
  var capStr = "";
  for (var i = 1; i < str.length; i++) {
    if (str[i - 1] == " " || str[i - 1] == "-") {
      capStr = capStr + str[i].toUpperCase();
    } else {
      capStr = capStr + str[i];
    }
  }
  return str[0].toUpperCase() + capStr;
}

export function formatDate(dateTime) {
  if (dateTime != null || dateTime != undefined) {
    let dateTimeArr = dateTime.split(" ");
    let dateArr = dateTimeArr[0].split("-");
    let timeArr = dateTimeArr[1].split(":");

    let year = dateArr[0];
    let date = dateArr[1];
    if (date <= 9) {
      date = "0" + date;
    }
    let month = dateArr[2];
    if (month <= 9) {
      month = "0" + month;
    }

    let hour = timeArr[0];
    if (hour <= 9) {
      hour = "0" + hour;
    }
    let min = timeArr[1];
    if (min <= 9) {
      min = "0" + min;
    }
    let sec = timeArr[2];
    if (sec <= 9) {
      sec = "0" + sec;
    }
    return date + "-" + month + "-" + year + " " + hour + ":" + min + ":" + sec;
  }
  return dateTime;
}

export function convertTo2Decimal(number) {
  let formattedNo = null;
  if (number == 0 || number == null) {
    return number;
  } else {
    formattedNo = parseInt((number + 0.005) * 100.0) / 100.0;
  }
  return formattedNo;
}

export function firtCharToUpperCaseWithoutSpace(str) {
  if (str !== undefined || str !== "") {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  } else {
    return str;
  }
}

export function findInJson(data, search, attr) {
  for (var i = 0; i < data.length; i++) {
    if (data[i][attr] == search) {
      return i;
    }
  }
  return -1;
}

export function toLocaleStringWithDecimal(numberInString) {
  var numberArr = numberInString.split(".");
  if (numberArr.length == 2) {
    var num1 = parseInt(numberArr[0]).toLocaleString();
    return num1 + "." + numberArr[1];
  }
  return parseInt(numberInString).toLocaleString();
}

export function toFixed(num, fixed) {
  num = parseFloat(num);
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

export function toolTipToFixRoundOff(number) {
  if (
    (number !== null || number !== 0) &&
    number.toString().split(".").length > 1 &&
    number.toString().split(".")[1].length > 5
  ) {
    number = number + 0.0000005;
    number = toFixed(number, 6);
  }
  return number;
}

export function filter(list, search, attr) {
  let filteredList = [];
  Object.keys(list).forEach((i) => {
    if (list[i][attr] == search) {
      filteredList.push(list[i]);
    }
  });
  return filteredList;
}

export function randomComputeIDGenerator() {
  return _.random(100000, 999999, false);
}

export function getComputeNameByKey(k) {
  switch (k) {
    case TYPE_PD:
      return PROBABILITY_OF_DEFAULT;
    case TYPE_MM:
      return MIGRATION_MATRIX;
    case TYPE_RR:
      return RECOVERY_RATE;
    case TYPE_FC:
      return FULL_COMPUTE;
    default:
      return "";
  }
}

export function recordForAuditTrail(action) {
  const config = {
    headers: {
      ...authHeader(),
    },
  };
  let requestDTO = {
    action: action,
  };
  instance
    .post("infopage/auditTrail", requestDTO, config)
    .then((response) => {})
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          sessionStorage.clear();

          window.location.replace("/");
        } else {
        }
      } else {
      }
    });
}

export function returnLookupObject(options) {
  let lookupObj = {};
  options.forEach((items) => {
    lookupObj[items.value] = items.label;
  });
  return lookupObj;
}

export function customDropdownFilterAndSearch(term, rowData, field) {
  if (term.length === 0) {
    return rowData;
  } else {
    if (rowData[field] !== null)
      return term.some((v) => rowData[field].toString().indexOf(v) !== -1);
  }
}

export function customDropdownFilterAndSearchRating(term, rowData, field) {
  if (term.length === 0) {
    return rowData;
  } else {
    if (
      rowData[field] !== null &&
      rowData[field].toString().toLowerCase() === term.toString().toLowerCase()
    )
      return rowData;
  }
}
