import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { connect } from "react-redux";
import { callUserDetails } from "../../store/actions/index";

const options = [
  { value: "ALL", label: "All" },
  { value: "ONLYME", label: "Only Me" },
  { value: "EXCEPTME", label: "Except Me" },
];
const optionsDefaultPeriod = [
  { value: false, label: "90 Days" },
  { value: true, label: "180 Days" },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 0,
    fontSize: 13,
    fontWeight: state.data.value === "ALL" ? "bold" : "normal",
    width: "auto",
  }),
  control: (provided, state) => ({
    width: 115,
    display: "flex",
    height: 30,
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.6)",
    border: "1px solid #cac7c7",
    boxShadow: "none",
    "&:focus-within": {
      border: "1px solid #233d63",
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.6 : 1;
    const transition = "opacity 100ms";

    return { ...provided, opacity, transition };
  },
};

export const filterOption = (option, inputValue) => {
  if (
    option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};

class MainHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: { value: "ALL", label: "All" },
      isDefaultPeriod180: { value: false, label: "90 Days" },
      superAdminOptions: [],
      categoryOptions: [],
      filterOpen: false,
    };
  }

  filterToggle() {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  }

  componentDidMount() {
    this.setToggleButtonValues();
  }

  capitalizeFLetter(string) {
    return string.charAt(0) + string.toLowerCase().slice(1);
  }

  componentDidUpdate(oldProps) {
    if (
      !_.isEqual(oldProps.option, this.props.option) ||
      !_.isEqual(oldProps.isDefaultPeriod180, this.props.isDefaultPeriod180) ||
      !_.isEqual(oldProps.category, this.props.category)
    ) {
      this.setToggleButtonValues();
    }

    if (!_.isEqual(oldProps.institutionInfo, this.props.institutionInfo)) {
      let superAdminOptions = [];
      superAdminOptions.push({ value: "ALL", label: "All" });
      this.props.institutionInfo.sort().forEach((items) => {
        superAdminOptions.push({ value: items, label: items });
      });
      this.setState({ superAdminOptions: superAdminOptions }, () =>
        this.setToggleButtonValues()
      );
    }

    if (!_.isEqual(oldProps.paramList, this.props.paramList)) {
      let categoryOptions = [];
      for (let prop in this.props.paramList.category) {
        categoryOptions.push({
          value: this.props.paramList.category[prop],
          label: this.capitalizeFLetter(prop),
          selected: true,
        });
      }
      this.setState({ categoryOptions: categoryOptions }, () =>
        this.setToggleButtonValues()
      );
    }
  }

  setToggleButtonValues() {
    let updatedCategoryOptions = JSON.parse(
      JSON.stringify(this.state.categoryOptions)
    );
    updatedCategoryOptions.forEach((items) => {
      if (
        this.props.category !== null &&
        this.props.category.indexOf(items.value) === -1
      ) {
        items.selected = false;
      } else {
        items.selected = true;
      }
    });
    this.setState({
      option: this.props.userData
        ? this.props.userData.userRole === 1
          ? this.state.superAdminOptions.find(
              (item) => item.value === this.props.option
            )
          : options.find((item) => item.value === this.props.option)
        : this.props.callUserDetails(),
      isDefaultPeriod180: optionsDefaultPeriod.find(
        (item) => item.value === this.props.isDefaultPeriod180
      ),
      categoryOptions: updatedCategoryOptions,
    });
  }

  handleInput(field, event) {
    this.props.setMultiFilterValues(field, event.value);
  }

  handleMultiCheckedInput(field, value, event) {
    event.stopPropagation();
    this.setCategoryCheckValues(field, value, event.target.checked);
  }

  handleMultiCheckedInputLabel(field, label, value) {
    this.setCategoryCheckValues(field, label, !value);
  }

  setCategoryCheckValues(field, value, checkStatus) {
    let selectedValues = JSON.parse(JSON.stringify(this.props.category));
    if (value === "All") {
      selectedValues = [];
      if (checkStatus) {
        this.state.categoryOptions.forEach((items) => {
          selectedValues.push(items.value);
        });
      }
    } else {
      if (checkStatus) {
        selectedValues.push(value);
      } else {
        selectedValues = selectedValues.filter((item) => item !== value);
      }
    }
    this.props.setMultiFilterValues(field, _.sortBy(selectedValues));
  }

  enterPressed(event, clickType) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (clickType === "Apply") {
        this.filterToggle();
        this.props.callGlobalDropdownChange();
      } else {
        this.props.resetGlobalDropdownChange();
      }
    }
  }

  render() {
    let selectedCategoriesLabel = [];

    if (
      this.props.selectedDashboardFilters.category !== null &&
      this.props.selectedDashboardFilters.category.length === 0
    ) {
      selectedCategoriesLabel.push("No Restrictions");
    } else {
      this.state.categoryOptions.forEach((items) => {
        if (
          this.props.selectedDashboardFilters.category.indexOf(items.value) > -1
        ) {
          selectedCategoriesLabel.push(items.label);
        }
      });
    }

    let selectedOptionsLabel;
    if (this.props.userData && this.props.userData.userRole === 1) {
      this.state.superAdminOptions.forEach((items) => {
        if (items.value === this.props.selectedDashboardFilters.option) {
          selectedOptionsLabel = items.label;
        }
      });
    } else {
      options.forEach((items) => {
        if (items.value === this.props.selectedDashboardFilters.option) {
          selectedOptionsLabel = items.label;
        }
      });
    }

    return (
      <div className="container">
        <Card className="head-parent">
          <CardHeader className="heading">
            <div className="row">
              <div className="col-auto mr-auto"> Dashboard</div>
              <div className="col-auto ml-auto mt-2 filter-font">
                {" "}
                <b>Default Period:</b>{" "}
                {this.props.selectedDashboardFilters.isDefaultPeriod180
                  ? "180 Days"
                  : "90 Days"}
                ,<b> Institution:</b> {selectedOptionsLabel}, <b> Category:</b>{" "}
                {selectedCategoriesLabel.toString()}
              </div>
              <div className="col-auto  mb-1">
                <Dropdown
                  isOpen={this.state.filterOpen}
                  toggle={this.filterToggle.bind(this)}
                  direction={"left"}
                >
                  <DropdownToggle>
                    <i className="fa fa-filter" aria-hidden="true" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>
                      <label className="col-form-label font13">
                        <b>Default Period</b>
                      </label>
                      <Select
                        options={optionsDefaultPeriod}
                        styles={customStyles}
                        value={this.state.isDefaultPeriod180}
                        onChange={this.handleInput.bind(
                          this,
                          "isDefaultPeriod180"
                        )}
                        noOptionsMessage={() => "No Results"}
                        filterOption={filterOption}
                        className="react-select normal-font"
                        classNamePrefix="react-select-menulist"
                      />
                    </DropdownItem>

                    <DropdownItem header>
                      <label className="col-form-label font13">
                        <b>Institution</b>
                      </label>
                      <Select
                        options={
                          this.props.userData
                            ? this.props.userData.userRole === 1
                              ? this.state.superAdminOptions
                              : options
                            : this.props.callUserDetails()
                        }
                        styles={customStyles}
                        value={this.state.option}
                        onChange={this.handleInput.bind(this, "option")}
                        noOptionsMessage={() => "No Results"}
                        className="react-select normal-font"
                        maxMenuHeight={150}
                        classNamePrefix="react-select-menulist"
                      />
                    </DropdownItem>

                    <DropdownItem header>
                      <label className="col-form-label font13">
                        <b>Category</b>
                      </label>

                      {/* <div className="form-group">
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="pointer"
                              name={"allCategories"}
                              onClick={this.handleMultiCheckedInput.bind(
                                this,
                                "category",
                                "All"
                              )}
                              checked={
                                this.props.category === null
                                  ? false
                                  : this.props.category.length ===
                                    this.state.categoryOptions.length
                              }
                            />{" "}
                            <label
                              className="pointer"
                              onClick={this.handleMultiCheckedInputLabel.bind(
                                this,
                                "category",
                                "All",
                                this.props.category === null
                                  ? false
                                  : this.props.category.length ===
                                      this.state.categoryOptions.length
                              )}
                            >
                              All
                            </label>
                          </label>
                        </div>
                      </div> */}
                      {this.state.categoryOptions.map((items) => {
                        return (
                          <div key={items.value} className="form-group">
                            <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="pointer"
                                  name={items.label}
                                  key={items.label + items.value}
                                  onClick={this.handleMultiCheckedInput.bind(
                                    this,
                                    "category",
                                    items.value
                                  )}
                                  checked={items.selected}
                                  value={items.selected}
                                />{" "}
                                <label
                                  className="pointer"
                                  onClick={this.handleMultiCheckedInputLabel.bind(
                                    this,
                                    "category",
                                    items.value,
                                    items.selected
                                  )}
                                >
                                  {items.label}
                                </label>
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </DropdownItem>
                    <DropdownItem header>
                      <div className="mt-2 lib-model-foot">
                        <Button
                          className="btn btn-default"
                          type="submit"
                          onClick={() => {
                            this.filterToggle();
                            this.props.callGlobalDropdownChange();
                          }}
                          onKeyDown={(event) => {
                            this.enterPressed(event, "Apply");
                          }}
                        >
                          Apply
                        </Button>{" "}
                        <Button
                          className="btn btn-cancel"
                          onClick={() => {
                            this.props.resetGlobalDropdownChange();
                          }}
                          onKeyDown={(event) => {
                            this.enterPressed(event, "Reset");
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </CardHeader>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, { callUserDetails })(MainHeading);
