import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import customStylesSmall from "../commonUtility/ReactSelectSmallCss";

const MySelect = (props) => {
  if (props.allowSelectAll) {
    let selectRef = null;
    return (
      <ReactSelect
        ref={(ref) => {
          ref != null && props.shouldFocus ? ref.focus() : (selectRef = ref);
          selectRef = ref;
        }}
        styles={props.styles === undefined ? customStylesSmall : props.style}
        noOptionsMessage={() => "No Results"}
        {...props}
        options={[props.allOption, ...props.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            let result = [];
            if (event.action === "select-option") {
              if (
                selected[selected.length - 1].value === props.allOption.value ||
                selected.length === props.options.length
              ) {
                return props.onChange(
                  [props.allOption, ...props.options],
                  selectRef
                );
              }
              return props.onChange(selected, selectRef);
            } else if (
              event.action === "deselect-option" &&
              event.option.value !== "*"
            ) {
              result = selected.filter(
                (option) => option.value !== props.allOption.value
              );
            }
            return props.onChange(result, selectRef);
          }
          return props.onChange(selected, selectRef);
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

MySelect.defaultProps = {
  allOption: {
    label: "Select All",
    value: "*",
  },
};

export default MySelect;
