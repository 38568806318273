import React, { Component } from "react";
import ReactTags from "../commonUtility/react-tag-custom/ReactTags";
import { REMOVE, ADD, TAG_TYPE_EMAIL } from "../commonUtility/string-const";
import PropTypes from "prop-types";

class DomainTagInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      suggestions: [],
      placeholder: "Domain(s)",
    };
  }

  static getDerivedStateFromProps(props, state) {
    console.log(state, props);
    if (props.domainList) {
      return {
        ...state,
        tags: props.domainList.length == 0 ? [] : props.domainList,
      };
    }
    return { ...state };
  }

  handleDelete(i) {
    const tags = this.state.tags.slice(0);
    if (tags.length > 0)
      this.props.addDomainCallback(tags[i].name + ",", REMOVE, tags);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  handleAddition(tag) {
    var validCharEmailDomain =
      /^(?!\.)(?!.*\.$)(?!-)(?!.*-$)(?!.*?\-\-)(?!.*?\-\.)(?!.*?\.\-)+([a-zA-Z0-9-])+\.([a-zA-Z0-9-\.])+$/;
    console.log(this.state.tags);
    if (tag.name.toString().trim() != "" && this.state.tags.length < 10) {
      if (tag.name.match(validCharEmailDomain)) {
        tag["name"] = tag.name;
        tag["id"] = tag.name;
        let found = false;
        for (var i = 0; i < this.state.tags.length; i++) {
          if (
            this.state.tags[i].name.toString().toLowerCase().trim() ===
            tag.name.toString().toLowerCase().trim()
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          const tags = [].concat(this.state.tags, tag);
          this.props.addDomainCallback(tag.name + ", ", ADD, tags);
          console.log(tag);
          console.log(this.state.tags);
          this.setState({ tags });
        }
      } else {
        this.props.raiseErrorCallback("Invalid domain format");
      }
    }
  }

  render() {
    return (
      <ReactTags
        className="test"
        placeholder={this.state.tags.length > 0 ? "" : this.state.placeholder}
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)}
        allowNew={true}
        delimiterChars={[",", " "]}
        inputAttributes={{ maxLength: 30 }}
        addOnBlur={true}
        tagType={TAG_TYPE_EMAIL}
        minQueryLength={1}
        autofocus={false}
      />
    );
  }
}

DomainTagInput.propTypes = {
  addDomainCallback: PropTypes.func,
  raiseErrorCallback: PropTypes.func,
};

export default DomainTagInput;
