export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const AUTH_USER = "AUTH_USER";
export const AUTH_START = "AUTH_START";
export const FIRST_LEVEL_AUTH_VALIDATED = "FIRST_LEVEL_AUTH_VALIDATED";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CHECK_OTP = "AUTH_CHECK_OTP";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
//Generate Summary Action Types
export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_ERROR = "DOWNLOAD_FILE_ERROR";
export const RADIO_BUTTON_CLICKED = "RADIO_BUTTON_CLICKED";

//Resend OTP
export const RESEND_OTP = "RESEND_OTP";

// export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'
export const SUCCESS_TOAST = "SUCCESS";
export const ERROR_TOAST = "ERROR";
export const CHECK_AUTH = "CHECK_AUTH";

export const CHECK_AUTH_ASYNC = "CHECK_AUTH_ASYNC";
export const CHECK_AUTH_ASYNC_SUCCESS = "CHECK_AUTH_ASYNC_SUCCESS";
export const CHECK_AUTH_ASYNC_FAILURE = "CHECK_AUTH_ASYNC_FAILURE";

export const FILE_UPLOADING = "FILE_UPLOADING";
export const SHOW_TOAST = "SHOW_TOAST";

export const SHOW_MODAL = "SHOW_MODAL";

export const SHOW_INSERT_SUMMARY = "INSERT_SUMMARY";

export const ANNUAL_MODE_CHANGE = "annual_mode_chg";
export const NOTIFICATIONS_EVENTS = "NOTIFICATIONS_EVENTS";

export const RUN_CHECK_EXEC = "runcheckexecuting";

export const SAVED_QUERY_PLAYLIST = "setSavedQueryPlayList";

export const PROGRESSING = "progressing";

export const FILE_UPLOADED = "fileUploaded";

export const DELETE_UPLOAD_RES = "deleteUploadRes";
