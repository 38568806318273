import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const showAlert = (title, message, buttons) => {
  let options = {
    title: title,
    message: message,
    buttons: buttons,
    childrenElement: () => <div />,
    closeOnEscape: false,
    closeOnClickOutside: false,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {}
  };
  confirmAlert(options);
};

export default showAlert;
