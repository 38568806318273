import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {
  convertTo2Decimal,
  toolTipToFixRoundOff,
} from "../commonUtility/method-utils";
import { Table } from "reactstrap";
import RatingDetailDialog from "../commonUtility/RatingDetailDialog";
import AllCounterpartsRatings from "./AllCounterpartsRatings";
import _ from "lodash";

class RatingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayPopover: false,
      currentIds: null,
      currentPopupStatus: "",
      currentTargetId: "",
      displayAllCounterparts: false,
      selectedYearData: [],
      selectedYear: "",
      clickedIndex: "",
      ratingToShow: "",
    };
    this.showCounterparts = this.showCounterparts.bind(this);
  }

  showCounterparts(rowId, ids, status) {
    if (ids.length > 0)
      this.setState({
        displayPopover: true,
        currentIds: ids,
        currentPopupStatus: status,
        currentTargetId: rowId,
      });
  }

  showAllCounterparts(data, startYear, clickedYear, clickedIndex) {
    console.log(this);
    console.log(data);
    console.log(clickedIndex);
    if (!_.isEmpty(data[clickedYear]))
      this.setState({
        ratingToShow: this.props.rating,
        displayAllCounterparts: true,
        selectedYearData: data,
        selectedYear: startYear,
        clickedIndex: clickedIndex,
      });
  }

  togglePopover(toggle) {
    this.setState({ displayPopover: toggle });
  }

  toggleAllPopover(toggle) {
    this.setState({ displayAllCounterparts: toggle });
  }

  render() {
    let duration = Object.keys(this.props.rating_data).length;
    let headRating = [];
    let headWmdrCdr = [];
    for (var i = 1; i <= duration; i++) {
      headRating.push(<th>{i}</th>);
      headWmdrCdr.push(<th>{"Yr " + i}</th>);
    }
    let ratingRows = [];
    Object.keys(this.props.rating_data).forEach((k) => {
      let ratingRow = [];
      let dataRow = [];
      dataRow.push(<td key={k}>{k}</td>);
      Object.keys(this.props.rating_data[k]).forEach((d, i1) => {
        let spanRow = [];
        if (
          this.props.rating_data[k][d] != null &&
          !(
            this.props.rating_data[k][d].outstanding === 0 &&
            this.props.rating_data[k][d].matured === 0 &&
            this.props.rating_data[k][d].defaulted === 0
          )
        ) {
          spanRow.push(
            <div
              id={"Rating_" + this.props.rating + "_" + i1}
              className={
                this.props.enableRating
                  ? "white-space-none rating-lev first-no"
                  : "white-space-none rating-lev first-no rating-data-disabled"
              }
            >
              {""}
              <span className="rating-data">
                {this.props.rating_data[k][d].outstanding}
              </span>{" "}
            </div>
          );

          spanRow.push(
            <div
              id={"Rating_" + this.props.rating + "Matured_" + i1}
              className={
                this.props.enableRating
                  ? "white-space-none rating-lev sec-no"
                  : "white-space-none rating-lev sec-no rating-data-disabled"
              }
            >
              {""}
              <span className="rating-data">
                {this.props.rating_data[k][d].matured}
              </span>{" "}
            </div>
          );
          spanRow.push(
            <div
              className={
                this.props.enableRating
                  ? "white-space-none rating-lev slash"
                  : "white-space-none rating-lev slash rating-data-disabled"
              }
            >
              {" / "}
            </div>
          );

          spanRow.push(
            <div
              id={"Rating_" + this.props.rating + "Defaulted_" + i1}
              className={
                this.props.enableRating
                  ? "white-space-none rating-lev third-no"
                  : "white-space-none rating-lev third-no rating-data-disabled"
              }
            >
              {}
              <span className="rating-data">
                {this.props.rating_data[k][d].defaulted}
              </span>{" "}
            </div>
          );
        }
        if (spanRow.length > 0)
          dataRow.push(
            this.props.enableRating ? (
              <td
                className="pointer-cursor"
                onClick={(e) =>
                  this.showAllCounterparts(this.props.rating_data[k], k, d, i1)
                }
              >
                {spanRow}
              </td>
            ) : (
              <td>{spanRow}</td>
            )
          );
        else dataRow.push(<td />);
      });
      ratingRow.push(<tr>{dataRow}</tr>);
      ratingRows = [...ratingRows, ratingRow];
    });

    let dynamic_mdrRows = [];
    Object.keys(this.props.mdr_dynamic).forEach((k) => {
      let dynamic_mdrRow = [];
      let dataRow = [];
      dataRow.push(<td key={k}>{k}</td>);
      Object.keys(this.props.mdr_dynamic[k]).forEach((d) => {
        if (this.props.mdr_dynamic[k][d] == null) {
          dataRow.push(<td />);
        } else {
          dataRow.push(
            <td>
              {this.props.mdr_dynamic[k][d] !== 0 ? (
                <Tooltip
                  title={toolTipToFixRoundOff(this.props.mdr_dynamic[k][d])}
                  disableFocusListener={true}
                >
                  <span>{convertTo2Decimal(this.props.mdr_dynamic[k][d])}</span>
                </Tooltip>
              ) : (
                convertTo2Decimal(this.props.mdr_dynamic[k][d])
              )}
            </td>
          );
        }
      });
      dynamic_mdrRow.push(<tr>{dataRow}</tr>);
      dynamic_mdrRows = [...dynamic_mdrRows, dynamic_mdrRow];
    });

    let static_mdrRows = [];
    Object.keys(this.props.mdr_static).forEach((k) => {
      let static_mdrRow = [];
      let dataRow = [];
      dataRow.push(<td key={k}>{k}</td>);
      Object.keys(this.props.mdr_static[k]).forEach((d) => {
        if (this.props.mdr_static[k][d] == null) {
          dataRow.push(<td />);
        } else {
          dataRow.push(
            <td>
              {this.props.mdr_static[k][d] !== 0 ? (
                <Tooltip
                  title={toolTipToFixRoundOff(this.props.mdr_static[k][d])}
                  disableFocusListener={true}
                >
                  <span>{convertTo2Decimal(this.props.mdr_static[k][d])}</span>
                </Tooltip>
              ) : (
                convertTo2Decimal(this.props.mdr_static[k][d])
              )}
            </td>
          );
        }
      });
      static_mdrRow.push(<tr>{dataRow}</tr>);
      static_mdrRows = [...static_mdrRows, static_mdrRow];
    });

    let dynamic_wmdrCdrRows = [];
    Object.keys(this.props.dynamic_wmdr_cdr).forEach((k) => {
      let dynamic_wmdrCdrRow = [];
      let dataRow = [];
      dataRow.push(<td key={k}>{k}</td>);
      Object.keys(this.props.dynamic_wmdr_cdr[k]).forEach((d) => {
        if (this.props.dynamic_wmdr_cdr[k][d] == null) {
          dataRow.push(<td />);
        } else {
          dataRow.push(<td>{this.props.dynamic_wmdr_cdr[k][d]}</td>);
        }
      });
      dynamic_wmdrCdrRow.push(<tr>{dataRow}</tr>);
      dynamic_wmdrCdrRows = [...dynamic_wmdrCdrRows, dynamic_wmdrCdrRow];
    });

    let static_wmdrCdrRows = [];
    Object.keys(this.props.static_wmdr_cdr).forEach((k) => {
      let static_wmdrCdrRow = [];
      let dataRow = [];
      dataRow.push(<td key={k}>{k}</td>);
      Object.keys(this.props.static_wmdr_cdr[k]).forEach((d) => {
        if (this.props.static_wmdr_cdr[k][d] == null) {
          dataRow.push(<td />);
        } else {
          dataRow.push(<td>{this.props.static_wmdr_cdr[k][d]}</td>);
        }
      });
      static_wmdrCdrRow.push(<tr>{dataRow}</tr>);
      static_wmdrCdrRows = [...static_wmdrCdrRows, static_wmdrCdrRow];
    });

    return (
      <div className="result-for-table">
        {this.state.displayPopover ? (
          <RatingDetailDialog
            ids={this.state.currentIds}
            status={this.state.currentPopupStatus}
            id={this.state.currentTargetId}
            isOpen={this.state.displayPopover}
            toggleCallback={this.togglePopover.bind(this)}
          />
        ) : null}
        {this.state.displayAllCounterparts ? (
          <AllCounterpartsRatings
            ratingToShow={this.state.ratingToShow}
            selectedYear={this.state.selectedYear}
            selectedYearData={this.state.selectedYearData}
            clickedIndex={this.state.clickedIndex}
            isOpen={this.state.displayAllCounterparts}
            toggleCallback={this.toggleAllPopover.bind(this)}
          />
        ) : null}
        <div className="Rating-First">
          <div className="heading mt-4">{"Rating " + this.props.rating}</div>
          <Table bordered="true" responsive>
            <thead>
              <tr>
                <th>#</th>
                {headRating}
              </tr>
            </thead>
            <tbody>{ratingRows}</tbody>
          </Table>
        </div>

        <div>
          <div className="heading mt-4">Dynamic MDR</div>
          <Table bordered="true" responsive>
            <thead>
              <tr>
                <th>#</th>
                {headRating}
              </tr>
            </thead>
            <tbody>{dynamic_mdrRows}</tbody>
          </Table>
        </div>

        <div>
          <div className="heading mt-4 dynamic">Dynamic WMDR and CDR</div>
          <Table bordered="true" responsive>
            <thead>
              <tr>
                <th>#</th>
                {headWmdrCdr}
              </tr>
            </thead>
            <tbody>{dynamic_wmdrCdrRows}</tbody>
          </Table>
        </div>

        <div>
          <div className="heading mt-4">Static MDR</div>
          <Table bordered="true" responsive>
            <thead>
              <tr>
                <th>#</th>
                {headRating}
              </tr>
            </thead>
            <tbody>{static_mdrRows}</tbody>
          </Table>
        </div>

        <div>
          <div className="heading mt-4">Static WMDR and CDR</div>
          <Table bordered="true" responsive>
            <thead>
              <tr>
                <th>#</th>
                {headWmdrCdr}
              </tr>
            </thead>
            <tbody>{static_wmdrCdrRows}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default RatingTable;
