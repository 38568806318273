import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import { connect } from "react-redux";
import { showToast } from "../commonUtility/NewToast";
import {
  ERROR_OCCURED,
  TOAST_TYPE_ERROR,
  UNKNOWN_ERROR,
  AUDIT_UPLOAD,
  AUDIT_INSERT,
  AUDIT_CHK_MY_DATA,
  DATE_RANGE_NOT_MRE_THN_5,
  START_DATE_END_DATE_NOT_BLNK,
} from "../commonUtility/string-const";
import MaterialTable from "material-table";
import LoaderComponent from "../commonUtility/LoaderComponent";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import Select from "react-select";

import * as actions from "../../store/actions/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

/**
 * Patch for showing the select popup above other containers
 */
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 0,
    fontSize: 13,
    fontWeight: state.data.value === "ALL" ? "bold" : "normal",
    width: "auto",
  }),
  control: () => ({
    width: 135,
    display: "flex",
    height: 30,
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.6)",
    border: "1px solid #cac7c7",
    boxShadow: "none",
    "&:focus-within": {
      border: "1px solid #233d63",
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.6 : 1;
    const transition = "opacity 100ms";

    return { ...provided, opacity, transition };
  },
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class CustomInput extends React.Component {
  render() {
    return (
      <div className="custom-datepicker">
        <input
          className="w-100 form-control date-contol"
          value={this.props.value}
          type="text"
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          onKeyDown={() => {
            return false;
          }}
          onChange={() => {
            return false;
          }}
        />
        <i
          onClick={this.props.onClick}
          aria-hidden="true"
          className="fa fa-calendar"
        />
      </div>
    );
  }
}

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

class AuditTrail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: new Date(),
      action: null,
      trailData: [],
      actionList: [],
      actionListSelected: {
        label: "All",
        value: null,
      },
      filteredTrailData: [],
      instituteSelected: { label: "All", value: "*" },
      columns: [],
      isLoading: false,
      firstTymLoad: false,

      currentPageSize: 10,
      instituteList: [],
    };
  }

  handleChangeDate(field, value) {
    this.setState({
      [field]: value,
    });
  }

  filterChange(field, value) {
    this.setState({ [field]: value });
  }

  getFilterData(instituteSelected, actionListSelected, trailDataAll) {
    let instituteCode = instituteSelected.value;
    let actionListSelectedValue = actionListSelected.value;
    let filteredTrailData = [];
    if (actionListSelectedValue === null && instituteCode === "*") {
      filteredTrailData = trailDataAll;
    } else if (actionListSelectedValue === null && instituteCode !== "*") {
      for (var i = 0; i < trailDataAll.length; i++) {
        if (trailDataAll[i].instAbb === instituteCode) {
          filteredTrailData.push(trailDataAll[i]);
        }
      }
    } else if (actionListSelectedValue !== null && instituteCode === "*") {
      for (var i = 0; i < trailDataAll.length; i++) {
        if (trailDataAll[i].actionEnum === actionListSelectedValue) {
          filteredTrailData.push(trailDataAll[i]);
        }
      }
    } else if (actionListSelectedValue !== null && instituteCode !== "*") {
      for (var i = 0; i < trailDataAll.length; i++) {
        if (
          trailDataAll[i].instAbb === instituteCode &&
          trailDataAll[i].actionEnum === actionListSelectedValue
        ) {
          filteredTrailData.push(trailDataAll[i]);
        }
      }
    }
    return filteredTrailData;
  }

  componentDidUpdate() {
    if (this.state.filteredTrailData.length === 0) {
      this.hidePaging(true);
    } else {
      this.hidePaging(false);
    }
  }

  hidePaging(flag) {
    var pageDiv = document.getElementsByClassName(
      "MuiTablePagination-toolbar"
    )[0];
    if (pageDiv !== undefined) {
      pageDiv.hidden = flag;
    }
  }

  loadTableColumns() {
    let tempColumns = [
      {
        title: "Date",
        type: "date",
        field: "actionDate",
        editable: "never",
        sorting: true,
        hidden: false,
        render: (rowData) => {
          let serverMoment = moment(new Date()).tz("Europe/London");
          let d = moment(rowData.actionDate, "DD-MM-YYYY HH:mm");
          let isDst = serverMoment.isDST();
          if (isDst) {
            d.add(-new Date().getTimezoneOffset() - 60, "minutes");
          } else {
            d.add(-new Date().getTimezoneOffset(), "minutes");
          }

          return d.format("DD-MM-YYYY  HH:mm");
        },
      },
      {
        title: "User Name",
        type: "string",
        field: "name",
        editable: "never",
        sorting: true,
        hidden: false,
      },
      {
        title: "Email",
        field: "email",
        type: "string",
        editable: "never",
        sorting: true,
      },
      {
        title: "Institution",
        field: "instAbb",
        type: "string",
        editable: "never",
        sorting: true,
      },
      {
        title: "User Type",
        field: "userType",
        type: "string",
        editable: "never",
        sorting: true,
      },
      {
        title: "Action",
        field: "action",
        type: "string",
        editable: "never",
        sorting: true,
      },
    ];
    this.setState({ columns: tempColumns, firstTymLoad: true });
  }

  componentDidMount() {
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    let endDate = new Date();

    this.setState({
      firstTymLoad: true,
      startDate: startDate,
      endDate: endDate,
    });
    if (this.props.userData && this.props.userData.userRole === 1) {
      this.getInstituteList();
    }
    this.setActionList();
    this.getAuditRecords(
      startDate,
      endDate,
      this.state.actionListSelected.value
    );
  }

  setActionList() {
    let aList = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Upload",
        value: AUDIT_UPLOAD,
      },
      {
        label: "Insert",
        value: AUDIT_INSERT,
      },
      {
        label: "Check My Data",
        value: AUDIT_CHK_MY_DATA,
      },
    ];
    this.setState({ actionList: aList });
  }

  getAuditRecords(startDate, endDate, action) {
    let diffInMonths = moment(endDate).diff(moment(startDate), "months");
    let fewDaysExtra = false;
    if (diffInMonths === 60) {
      let _endDateDO = new Date(JSON.parse(JSON.stringify(endDate)));
      _endDateDO.setFullYear(_endDateDO.getFullYear() - 5);
      let minYears = _endDateDO;
      if (startDate < minYears) {
        fewDaysExtra = true;
      }
    }
    if (diffInMonths <= 60 && !fewDaysExtra) {
      if (
        startDate !== null &&
        endDate !== null &&
        startDate !== "" &&
        endDate !== ""
      ) {
        endDate = new Date(endDate.valueOf());
        this.setState({ firstTymLoad: true });
        this.loadTableColumns();
        endDate.setDate(endDate.getDate() + 1);
        let auditTrailReq = {
          startDate: startDate,
          endDate: endDate,
          action: action,
        };

        const config = {
          headers: {
            "content-type": "application/json",
            ...authHeader(),
          },
        };
        instance
          .post("infopage/auditTrail/get", auditTrailReq, config)
          .then((response) => {
            if (response.data != null) {
              this.setState({ firstTymLoad: false });
              let instituteSelected = { label: "All", value: "*" };
              if (this.props.userData && this.props.userData.userRole === 1) {
                instituteSelected = this.state.instituteSelected;
              } else {
                if (this.props.userData && this.props.userData.userRole !== 1) {
                  instituteSelected = {
                    label: this.props.userData.institutionAbbr,
                    value: this.props.userData.institutionAbbr,
                  };
                }
              }
              let filteredTrailData = this.getFilterData(
                instituteSelected,
                this.state.actionListSelected,
                response.data
              );
              this.setState({
                trailData: response.data,
                filteredTrailData: filteredTrailData,
              });
            }
          })
          .catch((error) => {
            this.setState({ firstTymLoad: false });
            if (error.response) {
              if (error.response.status === 401) {
                console.log("Auth error");
                sessionStorage.clear();
                window.location.replace("/");
              } else {
                showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
              }
            } else {
              showToast(UNKNOWN_ERROR, TOAST_TYPE_ERROR);
            }
          });
      } else {
        showToast(START_DATE_END_DATE_NOT_BLNK, TOAST_TYPE_ERROR);
      }
    } else {
      showToast(DATE_RANGE_NOT_MRE_THN_5, TOAST_TYPE_ERROR);
    }
  }

  getInstituteList() {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };

    instance
      .get("/infopage/getInstList", config)
      .then((response) => {
        if (response != null && response.data) {
          if (response.data.error == false) {
            let instObj = response.data.instList;
            let instituteList = [{ label: "All", value: "*" }];
            Object.keys(instObj).forEach((i) => {
              instituteList.push({ label: i, value: i });
            });
            this.setState({
              instituteList: instituteList,
              instituteSelected: instituteList[0],
            });
          } else {
            if (response.status == 401) {
              sessionStorage.clear();

              window.location.replace("/");
            } else {
              showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
            }
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();

            window.location.replace("/");
          } else {
            showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
          }
        } else {
          showToast(ERROR_OCCURED, TOAST_TYPE_ERROR);
        }
      });
  }

  startLoader(load) {
    this.setState({ isLoading: load });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <div className="audittrail ">
            <Paper className={classes.root + " event-list mt-3"}>
              {this.state.isLoading ? <LoaderComponent /> : null}
              {this.state.firstTymLoad ? (
                <FullScreenLoaderComponent msg="" />
              ) : null}
              {this.state.firstTymLoad ? null : (
                <div className="top-bar">
                  <div className="row">
                    <div className="col-auto">
                      <div className="row">
                        <div className="col-auto">
                          <div className="mt-1">Start Date</div>
                        </div>
                        <div className="col-auto">
                          <div className="form-group p-0 ">
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={this.handleChangeDate.bind(
                                this,
                                "startDate"
                              )}
                              value={this.state.startDate}
                              dateFormat="dd-MM-yyyy"
                              maxDate={this.state.endDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              customInput={<CustomInput />}
                              popperContainer={CalendarContainer}
                              placeholderText="Start Date"
                            />
                            <div className="error-text">
                              {/* <FormErrors  /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="row">
                        <div className="col-auto">
                          <div className="mt-1">End Date</div>
                        </div>
                        <div className="col-auto">
                          <div className="form-group p-0 ">
                            <DatePicker
                              selected={this.state.endDate}
                              onChange={this.handleChangeDate.bind(
                                this,
                                "endDate"
                              )}
                              value={this.state.endDate}
                              dateFormat="dd-MM-yyyy"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              customInput={<CustomInput />}
                              minDate={this.state.startDate}
                              maxDate={new Date()}
                              popperContainer={CalendarContainer}
                              placeholderText="End Date"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="row">
                        <div className="col-auto">
                          <div className="mt-1">Action</div>
                        </div>
                        <div className="col-auto">
                          <div className="form-group p-0">
                            <Select
                              required
                              name="acList"
                              id="acList"
                              value={this.state.actionListSelected}
                              filterOption={this.filterOption}
                              onChange={(e) =>
                                this.filterChange("actionListSelected", e)
                              }
                              className="react-select"
                              isSearchable={true}
                              noOptionsMessage={() => "No Results"}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              options={this.state.actionList}
                              classNamePrefix="react-select-menulist annaul-mode"
                              autosize={true}
                            />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    {this.props.userData &&
                    this.props.userData.userRole === 1 ? (
                      <div className="col-auto">
                        <div className="row">
                          <div className="col-auto">
                            <div className="mt-1">Institution</div>
                          </div>
                          <div className="col-auto">
                            <div className="form-group p-0">
                              <Select
                                required
                                name="instituteList"
                                id="instituteList"
                                value={this.state.instituteSelected}
                                filterOption={this.filterOption}
                                onChange={(e) =>
                                  this.filterChange("instituteSelected", e)
                                }
                                className="react-select"
                                isSearchable={true}
                                noOptionsMessage={() => "No Results"}
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                options={this.state.instituteList}
                                classNamePrefix="react-select-menulist annaul-mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-auto pt3px">
                      <button
                        className="btn btn-default"
                        onClick={(e) =>
                          this.getAuditRecords(
                            this.state.startDate,
                            this.state.endDate,
                            this.state.actionListSelected.value
                          )
                        }
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {this.state.firstTymLoad ? null : (
                <MaterialTable
                  columns={this.state.columns}
                  data={this.state.filteredTrailData}
                  onChangeRowsPerPage={(pageSize) => {
                    this.setState({ currentPageSize: pageSize });
                    let timesRun = 0;
                    let firstTimeExecuted = false;
                    var hasVScroll =
                      window.innerHeight <= document.body.offsetHeight;
                    this.interval = setInterval(() => {
                      if (timesRun == 5) {
                        clearInterval(this.interval);
                      }
                      timesRun++;
                      console.log("service ", hasVScroll);
                      if (hasVScroll) {
                        window.scrollTo(0, 0);
                        clearInterval(this.interval);
                      } else {
                        if (firstTimeExecuted) {
                          clearInterval(this.interval);
                        }
                        firstTimeExecuted = true;
                      }
                    }, 1);
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "No records to display",
                    },
                  }}
                  options={{
                    showTitle: false,
                    searchFieldAlignment: "left",
                    pageSize: this.state.currentPageSize,
                    pageSizeOptions: [10, 20, 25],
                    sorting:
                      this.state.filteredTrailData != null &&
                      this.state.filteredTrailData.length > 0,
                    search: false,
                    emptyRowsWhenPaging: false,
                    draggable: false,
                    thirdSortClick: false,
                  }}
                />
              )}
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Annual mode page received", state);
  return {
    isAnnualMode: state.auth.isAnnualMode,
    userData: state.auth.userData,
  };
};

AuditTrail.propTypes = {
  classes: PropTypes.object.isRequired,
  passMessage: PropTypes.func,
};

export default connect(mapStateToProps, null)(withStyles(styles)(AuditTrail));
