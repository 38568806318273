import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function renderErrorList(innerList) {
  return Object.keys(innerList).map((k, i) => {
    if (i > 0) {
      return <li>{innerList[i]}</li>;
    }
  });
}

function splitByWhiteCharacters(innerMsg) {
  if (innerMsg.trim() == "") {
    return innerMsg;
  } else {
    let breakCharacters = innerMsg.split("\n");
    if (breakCharacters.length > 0) {
      if (breakCharacters.length == 1) {
        return innerMsg;
      } else {
        return breakCharacters.map((item, index) => {
          return <div>{item}</div>;
        });
      }
    }
  }
}

function getInnerElement(cat, innerElements, sheet, classes) {
  return (
    <ExpansionPanel TransitionProps={{ timeout: 0 }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <div className="d-flex">
            <div className={"cat cat" + cat + " align-self-center"}>
              {cat}
            </div>{" "}
            <div className="error-text">{sheet}</div>
          </div>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <div className="error-desc">
            <p className="info">{splitByWhiteCharacters(innerElements[0])}</p>
            {innerElements.length > 1 ? (
              <span>
                <h5>Affected Rows:</h5>
                <ul>{renderErrorList(innerElements)}</ul>
              </span>
            ) : null}
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default function Accordion(props) {
  const classes = useStyles();
  if (props.expMap != null) {
    var details = Object.keys(props.expMap.errorMap).map((k) => {
      let errorlist =  props.expMap.errorMap[k];
      let severity;
      if (errorlist.length > 1){
        severity = errorlist[0];
        errorlist.shift();  // remove severity from list
      }
        return getInnerElement(
        severity,
        errorlist,
        k,
        classes
      );
    });
  }

  return (
    <div className={classes.root}>
      <div className="runchek-error">{details}</div>
    </div>
  );
}
