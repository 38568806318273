import React, { Component } from "react";
import { Table } from "reactstrap";
import { Progress } from "reactstrap";
import PropTypes from "prop-types";
import {
  DELETE_ONE,
  PROBABILITY_OF_DEFAULT,
  MIGRATION_MATRIX,
  RECOVERY_RATE,
  TOAST_TYPE_SUCCESS,
  DELETE_ALL,
  FULL_COMPUTE,
  DELETE_ONE_MESSAGE,
  DELETE_ALL_MESSAGE,
} from "../commonUtility/string-const";
import { authHeader } from "../commonUtility/auth-header";
import instance from "../../instance";
import { showToast } from "../commonUtility/NewToast";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LoaderInline from "../commonUtility/LoaderInline";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import { formatDate } from "../commonUtility/method-utils";
import { connect } from "react-redux";
import { callUserDetails } from "../../store/actions/index";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import { FormErrors } from "../commonUtility/FormErrors";

HighchartsExporting(Highcharts);

class ResultListtable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resultList: null,
      isLoading: false,
      nameInputmodal: false,
      formErrors: {
        queryName: "",
      },
      name: "",
      index: null,
      accr: null,
      computeKey: null,
      queryNameValid: false,
      regexQueryName: /^[a-z\d\-_\s%&\)\(@,:]+$/i,
      confirmDeleteModal: false,
      deleteParams: null,
    };
    this.showResult = this.showResult.bind(this);
    this.deleteResult = this.deleteResult.bind(this);
    this.toggle = this.toggle.bind(this);
    this.cancelToggle = this.cancelToggle.bind(this);
    this.validateName = this.validateName.bind(this);
    this.setName = this.setName.bind(this);
    this.errorClass = this.errorClass.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.computeResponses !== undefined) {
      return {
        resultList: props.computeResponses,
      };
    } else {
      return {
        ...state,
      };
    }
  }

  showResult(idx, type, name, dateTime) {
    this.props.currentComputeShowCallback(idx, type, name, dateTime);
  }

  deleteModalPopup(idx, type, howMany, key) {
    this.setState({
      confirmDeleteModal: true,
      deleteParams: {
        idx: idx,
        type: type,
        howMany: howMany,
        key: key,
      },
    });
  }

  deleteResult(idx, type, howMany, key) {
    this.cancelDeleteToggle();
    const config = {
      headers: {
        ...authHeader(),
      },
    };

    switch (howMany) {
      case DELETE_ONE:
        this.setState({ isLoading: false });

        instance
          .get(
            "/compute/deleteComputeExcel/false?key=" + encodeURIComponent(key),
            config
          )
          .then((response) => {
            if (response != null && response.status === 200) {
              this.props.deleteResult(idx, type, howMany, key);
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            if (error.response) {
              if (error.response.status === 401) {
                console.log("Auth error");
                sessionStorage.clear();
                window.location.replace("/");
              } else {
                showToast("Unable to delete.", TOAST_TYPE_SUCCESS);
              }
            } else {
              showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
            }
          });
        break;
      case DELETE_ALL:
        this.setState({ isLoading: false });

        instance
          .get("/compute/deleteComputeExcel/true?key=", config)
          .then((response) => {
            if (response != null && response.status === 200) {
              this.props.deleteResult(null, null, DELETE_ALL, null);
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            if (error.response) {
              if (error.response.status === 401) {
                console.log("Auth error");
                sessionStorage.clear();
                window.location.replace("/");
              } else {
                showToast("Unable to delete.", TOAST_TYPE_SUCCESS);
              }
            } else {
              showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
            }
          });
        break;
      default:
        break;
    }
  }

  getComputeNameByKey(k) {
    switch (k) {
      case "pd":
        return PROBABILITY_OF_DEFAULT;
      case "mm":
        return MIGRATION_MATRIX;
      case "rr":
        return RECOVERY_RATE;
      case "fc":
        return FULL_COMPUTE;
      default:
        return "";
    }
  }

  exportExcelAPICall(key, type, formData) {
    const config = {
      ...authHeader(),
      "Content-Type": "multipart/form-data",
    };
    instance({
      url: "/compute/generateExcel?key=" + key,
      method: "POST",
      responseType: "blob",
      headers: config,
      data: formData,
    })
      .then((response) => {
        this.setState({ isLoading: false });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        if (type === "PD") {
          link.setAttribute("download", "CalculationResult.zip");
        } else if (type === "MM") {
          link.setAttribute("download", "MM_Result.xlsx");
        } else if (type === "RR") {
          link.setAttribute("download", "RR_Result.xlsx");
        } else {
          link.setAttribute("download", "CalculationResult.xlsx");
        }
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        showToast("Unable to download", TOAST_TYPE_SUCCESS);
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            console.log("Unknown error occured");
          }
        } else {
          console.log("Unknown error occured");
        }
      });
  }

  exportExcel(key, type, index) {
    console.log(index);
    this.setState({ isLoading: true });
    let formData = new FormData();
    if (type === "MM") {
      const promises = [];
      promises.push(this.renderHiddenMMChart(index, key));
      Promise.all(promises).then((result) => {
        result.forEach((items) => {
          formData.append(items.key, items.value);
        });
        this.exportExcelAPICall(key, type, formData);
      });
    } else {
      this.exportExcelAPICall(key, type, formData);
    }
  }

  async renderHiddenMMChart(idx, computeKey) {
    let promise = new Promise((res, rej) => {
      setTimeout(() => {
        this.props.renderHiddenMMChart(idx);
        res(true);
      }, 500);
    });

    let result = await promise;

    if (result) {
      const chart = this.props.getMMChartSVG();
      let svgString = chart.getSVG();
      this.props.removeHiddenMMChart();
      return { key: computeKey, value: svgString };
    }
  }

  exportAllExcel(resultList) {
    console.log(resultList);
    const promises = [];
    resultList.forEach((items) => {
      if (items.type === "mm") {
        promises.push(
          this.renderHiddenMMChart(items.actualIndex, items.data.computeKey)
        );
      }
    });
    Promise.all(promises).then((result) => {
      let formData = new FormData();
      result.forEach((items) => {
        formData.append(items.key, items.value);
      });
      const config = {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      };
      this.setState({ isLoading: true });
      instance({
        url: "/compute/generateExcel/all",
        method: "POST",
        responseType: "blob",
        headers: config,
        data: formData,
      })
        .then((response) => {
          this.setState({ isLoading: false });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "CalculationResult.zip");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          showToast("Unable to download", TOAST_TYPE_SUCCESS);
          this.setState({ isLoading: false });
          if (error.response) {
            if (error.response.status === 401) {
              console.log("Auth error");
              sessionStorage.clear();
              window.location.replace("/");
            } else {
              console.log("Unknown error occured");
            }
          } else {
            console.log("Unknown error occured");
          }
        });
    });
  }

  convertToListsortByDateAndTime(renderResultList) {
    let resultList = [];
    let finalResultList = [];
    Object.keys(renderResultList).forEach((k) => {
      Object.keys(renderResultList[k]).forEach((r, i) => {
        if (typeof renderResultList[k][r] == "object")
          resultList.push({
            data: renderResultList[k][r],
            type: k,
            dateTime: renderResultList[k][r].dateTime,
            isObj: true,
            actualIndex: i,
            queryOp:
              renderResultList[k][r].queryOp === undefined
                ? ""
                : renderResultList[k][r].queryOp,
            queryOpKey:
              renderResultList[k][r].queryOpKey === undefined
                ? ""
                : renderResultList[k][r].queryOpKey,
          });
        else if (k === "fc") {
          let queryOpKey = "";
          let savedFCIds = sessionStorage.getItem("fullComputeIds");
          if (savedFCIds != null) {
            JSON.parse(savedFCIds).forEach((key) => {
              if (renderResultList[k][r] === key.dateTime) {
                queryOpKey = key.id;
              }
            });
          }
          finalResultList.push({
            data: renderResultList[k][r],
            type: k,
            dateTime: renderResultList[k][r],
            isObj: false,
            queryOp: "",
            queryOpKey: queryOpKey,
          });
        } else {
          resultList.push({
            data: renderResultList[k][r],
            type: k,
            dateTime: renderResultList[k][r],
            isObj: false,
            queryOp: "",
            queryOpKey: "",
          });
        }
      });
    });

    resultList.sort(function (a, b) {
      let dtArr1 = a["dateTime"].split(" ");
      let date1 = dtArr1[0];
      let time1 = dtArr1[1];
      let dateArr1 = date1.split("-");
      let year1 = dateArr1[0];
      let month1 = dateArr1[1];
      let day1 = dateArr1[2];

      let timeArr1 = time1.split(":");
      let hour1 = timeArr1[0];
      let min1 = timeArr1[1];
      let sec1 = timeArr1[2];

      let dateObjA = new Date(year1, month1, day1, hour1, min1, sec1);

      let dtArr2 = b["dateTime"].split(" ");
      let date2 = dtArr2[0];
      let time2 = dtArr2[1];
      let dateArr2 = date2.split("-");
      let year2 = dateArr2[0];
      let month2 = dateArr2[1];
      let day2 = dateArr2[2];

      let timeArr2 = time2.split(":");
      let hour2 = timeArr2[0];
      let min2 = timeArr2[1];
      let sec2 = timeArr2[2];

      let dateObjB = new Date(year2, month2, day2, hour2, min2, sec2);

      return dateObjB - dateObjA;
    });

    resultList.forEach((items) => {
      finalResultList.push(items);
    });

    return finalResultList;
  }

  saveQuery(name, index, accr, computeKey) {
    this.props.saveQuery(name, index, accr, computeKey);
  }

  openInputModal(index, accr, computeKey) {
    this.setState({
      nameInputmodal: true,
      index: index,
      accr: accr,
      computeKey: computeKey,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      nameInputmodal: !prevState.nameInputmodal,
    }));
  }

  cancelToggle() {
    this.setState((prevState) => ({
      nameInputmodal: false,
      name: "",
      formErrors: {
        queryName: "",
      },
    }));
  }

  toggleDeleteModal() {
    this.setState((prevState) => ({
      confirmDeleteModal: !prevState.confirmDeleteModal,
    }));
  }

  cancelDeleteToggle() {
    this.setState((prevState) => ({
      confirmDeleteModal: false,
      deleteParams: null,
    }));
  }

  setName(e) {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.queryName = "";
    this.setState({
      formErrors: fieldValidationErrors,
    });
    if (
      e.target.value != null &&
      (e.target.value === "" || this.state.regexQueryName.test(e.target.value))
    ) {
      this.setState({ name: e.target.value });
    }
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  validateName(e, name) {
    e.preventDefault();
    let regex = this.state.regexQueryName;
    let fieldValidationErrors = this.state.formErrors;
    if (
      name != null &&
      name.length > 0 &&
      regex.test(name) &&
      name.length <= 200
    ) {
      fieldValidationErrors.queryName = "";
      this.saveQuery(
        this.state.name,
        this.state.index,
        this.state.accr,
        this.state.computeKey
      );
      this.cancelToggle();
    } else {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors.queryName = "Please enter Query Name";
    }

    this.setState({
      formErrors: fieldValidationErrors,
    });
  }

  saveComputation() {
    const config = {
      headers: {
        ...authHeader(),
      },
    };
    this.setState({ isLoading: true });
    instance
      .post("/compute/saveComputation", {}, config)
      .then((response) => {
        console.log(response);
        this.setState({ isLoading: false });
        showToast(response.data, TOAST_TYPE_SUCCESS);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Auth error");
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        } else {
          showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
        }
      });
  }

  render() {
    let renderResultList = [];
    let resultList = this.convertToListsortByDateAndTime(this.state.resultList);
    let ongoingComputation = false;

    Object.keys(resultList).forEach((r, i) => {
      if (resultList[r]["isObj"]) {
        let listrow = [];
        if (resultList[r]["queryOp"] === "fc") {
          listrow.push(
            <td colSpan="3" className="resultlist-progressed">
              <b>{resultList[r]["type"].toUpperCase()}</b> : Category -{" "}
              {resultList[r].data.params.category.length === 3
                ? "All"
                : resultList[r].data.params.category.length === 0
                ? "No Restrictions"
                : resultList[r].data.params.category.toString()}{" "}
              | Region -{" "}
              {resultList[r].data.params.region.length === 8
                ? "All"
                : resultList[r].data.params.region.length === 0
                ? "No Restrictions"
                : resultList[r].data.params.region.toString()}{" "}
              | Sector -{" "}
              {resultList[r].data.params.sector.length === 15
                ? "All"
                : resultList[r].data.params.sector.length === 0
                ? "No Restrictions"
                : resultList[r].data.params.sector.toString()}
            </td>
          );
        } else {
          listrow.push(
            <td className="resultlist-progressed">
              {formatDate(resultList[r].data.dateTime)}
            </td>
          );
          listrow.push(
            <td className="resultlist-progressed">
              {/* {resultList[r].data.name} */}
              {resultList[r]["queryOp"] === "fc"
                ? "Full Compute - " + resultList[r]["type"].toUpperCase()
                : this.getComputeNameByKey(resultList[r]["type"])}
            </td>
          );
          listrow.push(
            <td className="progress-sec">
              <Progress color="primary" value={100}>
                100%
              </Progress>
            </td>
          );
        }
        listrow.push(
          <td className="result-action">
            <Tooltip title="View" disableFocusListener={true}>
              <span>
                <VisibilityIcon
                  onClick={(e) =>
                    this.showResult(
                      resultList[r].actualIndex,
                      resultList[r].data.accr,
                      resultList[r].data.name,
                      resultList[r].data.dateTime
                    )
                  }
                />
              </span>
            </Tooltip>
            {resultList[r].data.accr === "RR" &&
            resultList[r].data.params.mode === "Annual Report Environment" ? (
              this.props.userData ? (
                this.props.userData.userRole === 1 ? (
                  <Tooltip title="Export" disableFocusListener={true}>
                    <span>
                      <i
                        className="fa fa-file-excel-o"
                        aria-hidden="true"
                        onClick={(e) => {
                          this.exportExcel(
                            resultList[r].data.computeKey,
                            resultList[r].data.accr,
                            resultList[r].actualIndex
                          );
                        }}
                      />
                    </span>
                  </Tooltip>
                ) : resultList[r].data.params.members.length === 1 ? (
                  <Tooltip title="Export" disableFocusListener={true}>
                    <span>
                      <i
                        className="fa fa-file-excel-o"
                        aria-hidden="true"
                        onClick={(e) => {
                          this.exportExcel(
                            resultList[r].data.computeKey,
                            resultList[r].data.accr,
                            resultList[r].actualIndex
                          );
                        }}
                      />
                    </span>
                  </Tooltip>
                ) : null
              ) : (
                this.props.callUserDetails()
              )
            ) : (
              <Tooltip title="Export" disableFocusListener={true}>
                <span>
                  <i
                    className="fa fa-file-excel-o"
                    aria-hidden="true"
                    onClick={(e) => {
                      this.exportExcel(
                        resultList[r].data.computeKey,
                        resultList[r].data.accr,
                        resultList[r].actualIndex
                      );
                    }}
                  />
                </span>
              </Tooltip>
            )}
            <Tooltip title="Save Query" disableFocusListener={true}>
              <span>
                <SaveIcon
                  onClick={(e) =>
                    this.openInputModal(
                      resultList[r].actualIndex,
                      resultList[r].data.accr,
                      resultList[r].data.computeKey
                    )
                  }
                />
              </span>
            </Tooltip>
            <Tooltip title="Delete" disableFocusListener={true}>
              <span>
                <DeleteOutlineIcon
                  onClick={(e) =>
                    this.deleteModalPopup(
                      resultList[r].actualIndex,
                      resultList[r].data.accr,
                      DELETE_ONE,
                      resultList[r].data.computeKey
                    )
                  }
                />
              </span>
            </Tooltip>
          </td>
        );
        renderResultList.push(<tr>{listrow}</tr>);
      } else {
        ongoingComputation = true;
        let listrow = [];
        listrow.push(
          <td className="resultlist-progressed">
            {formatDate(resultList[r].dateTime)}
          </td>
        );
        listrow.push(
          <td className="resultlist-progressed">
            {resultList[r]["queryOp"] === "fc"
              ? "Full Compute - " + resultList[r]["type"].toUpperCase()
              : this.getComputeNameByKey(resultList[r]["type"])}
          </td>
        );
        listrow.push(
          <td className="progress-sec">
            <LoaderInline />
          </td>
        );
        listrow.push(
          <td className="result-action">
            {resultList[r]["type"] === "fc"
              ? resultList.filter(
                  (items) =>
                    items.queryOp === "fc" &&
                    items.queryOpKey === resultList[r]["queryOpKey"]
                ).length + "/104"
              : null}
          </td>
        );
        renderResultList.push(<tr>{listrow}</tr>);
      }
    });

    return (
      <div className="result-list-table">
        {this.state.nameInputmodal ? (
          <Modal
            isOpen={this.state.nameInputmodal}
            toggle={this.toggle}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelToggle}>Save Query</ModalHeader>
            <ModalBody>
              <Form
                className="lib-model"
                onSubmit={(e) => this.validateName(e, this.state.name)}
              >
                <FormGroup className="pt-1">
                  <div className="row">
                    {/* <label className="col-auto col-form-label">
                      Name
                    </label> */}
                    <div className="col-12">
                      <div
                        className={`form-group  ${this.errorClass(
                          this.state.formErrors.queryName
                        )}`}
                      >
                        <input
                          type="text"
                          onChange={(e) => this.setName(e)}
                          name="queryname"
                          value={this.state.name}
                          className="form-control"
                          maxlength="200"
                          placeholder="Name"
                        />
                      </div>
                      <span>
                        {this.state.formErrors.queryName !== "" ? (
                          <FormErrors
                            formErrors={this.state.formErrors.queryName}
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <div className="mt-2 lib-model-foot">
                  <Button className="btn btn-default" type="submit">
                    OK
                  </Button>
                  <Button
                    className="btn btn-cancel"
                    onClick={this.cancelToggle}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        ) : null}

        {this.state.confirmDeleteModal ? (
          <Modal
            isOpen={this.state.confirmDeleteModal}
            toggle={this.toggleDeleteModal.bind(this)}
            backdrop={false}
          >
            <ModalHeader toggle={this.cancelDeleteToggle.bind(this)}>
              Delete{" "}
              {this.state.deleteParams.howMany === DELETE_ONE
                ? "Query"
                : "Queries"}
            </ModalHeader>
            <ModalBody>
              <Form
                className="lib-model"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.deleteResult(
                    this.state.deleteParams.idx,
                    this.state.deleteParams.type,
                    this.state.deleteParams.howMany,
                    this.state.deleteParams.key
                  );
                }}
              >
                <FormGroup className="pt-1">
                  <div className="row">
                    <div className="col-12">
                      <span>
                        {this.state.deleteParams.howMany === DELETE_ONE
                          ? DELETE_ONE_MESSAGE
                          : DELETE_ALL_MESSAGE}
                      </span>
                    </div>
                  </div>
                </FormGroup>

                <div className="mt-2 lib-model-foot">
                  <Button className="btn btn-default" type="submit">
                    Yes
                  </Button>
                  <Button
                    className="btn btn-cancel"
                    onClick={this.cancelDeleteToggle.bind(this)}
                  >
                    No
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        ) : null}

        {this.state.isLoading ? <FullScreenLoaderComponent msg="" /> : null}
        <div
          className={
            ongoingComputation
              ? "text-right mb-2 content-disabled"
              : "text-right mb-2"
          }
        >
          {this.props.userData ? (
            this.props.userData.userRole === 1 ? (
              <Tooltip title="Export All" disableFocusListener={true}>
                <span>
                  <i
                    className="fa fa-file-excel-o"
                    aria-hidden="true"
                    onClick={this.exportAllExcel.bind(this, resultList)}
                  />
                </span>
              </Tooltip>
            ) : null
          ) : (
            this.props.callUserDetails()
          )}

          <Tooltip title="Delete All" disableFocusListener={true}>
            <span>
              <DeleteOutlineIcon
                onClick={(e) =>
                  this.deleteModalPopup(null, null, DELETE_ALL, null)
                }
              />
            </span>
          </Tooltip>
          {this.props.userData ? (
            this.props.userData.userRole === 1 ? (
              <Tooltip title="Save Computation" disableFocusListener={true}>
                <span>
                  <SaveIcon onClick={(e) => this.saveComputation()} />
                </span>
              </Tooltip>
            ) : null
          ) : (
            this.props.callUserDetails()
          )}
        </div>
        <div className="result-list-table-sec">
          <Table>
            <tbody>{renderResultList}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

ResultListtable.propsType = {
  currentComputeShowCallback: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

export default connect(mapStateToProps, { callUserDetails })(ResultListtable);
