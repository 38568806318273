"use strict";

import React from "react";

export default (props) => (
  <button type="button" className={props.classNames.selectedTag}>
    <span className={props.classNames.selectedTagName}>{props.tag.name}</span>
    <span
      onClick={props.onDelete}
      className="remove-icon-lib"
      title="Click to remove tag"
    >
      &#x2715;
    </span>
  </button>
);
