import React, { Component } from "react";
import MainHeading from "./Heading";
import Navigation from "../navbar/Navigation";
import InfoCard from "./InfoCard";
import Footer from "../commonUtility/Footer";
import ChartSection from "./ChartSection";
import instance from "../../instance";
import { authHeader } from "../commonUtility/auth-header";
import { showToast } from "../commonUtility/NewToast";
import { TOAST_TYPE_SUCCESS } from "../commonUtility/string-const";
import FullScreenLoaderComponent from "../commonUtility/FullScreenLoaderComponent";
import _ from "lodash";
import ScrollUpButton from "react-scroll-up-button";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      option: "ALL",
      isDefaultPeriod180: false,
      category: null,
      tilesData: {
        nbActCounterparts: 0,
        nbDefaultCounterparts: 0,
        nbCounterpartRR: 0,
        pdAverage: 0,
        mmMean: 0,
        rrMean: 0,
      },
      mapData: [],
      graphData: {
        mmGraph: {
          mmRatioList: [],
          mmMean: 0,
        },
        pdGraph: {
          dynamicCohortMap: {
            rate: [],
          },
          pdAverage: 0,
        },
      },
      chartData: {
        activeSector: {},
        defaultSector: {},
      },
      barData: {
        rrBySector: {},
      },
      graphOptions: {
        ACTIVE: "ALL",
        DEFAULT: "ALL",
        MM: "ALL",
        PD: "ALL",
        REGIONRR: "ALL",
        ACTIVESEC: "ALL",
        DEFAULTSEC: "ALL",
      },
      currentDashboardId: "",
      institutionInfo: [],
      paramList: null,
      selectedDashboardFilters: {
        option: "ALL",
        isDefaultPeriod180: false,
        category: null,
      },
      refreshGraphs: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadInstituteList();
    let isRefresh = false;
    const dashboardSessionIDs = sessionStorage.getItem("DashboardIDs");
    if (dashboardSessionIDs !== undefined && dashboardSessionIDs !== null) {
      JSON.parse(dashboardSessionIDs).forEach((item) => {
        if (item.selected) {
          isRefresh = true;
          this.setState(
            {
              option: item.option,
              isDefaultPeriod180: item.isDefaultPeriod180,
              category: item.categoryList,
            },
            () => this.getDashboardData(true, false)
          );
        }
      });
    }
    this.getParameterList(isRefresh);
  }

  loadInstituteList() {
    let value = sessionStorage.getItem("gems_bearer_token");
    const config = {
      headers: {
        b_token: value,
        ...authHeader(),
      },
    };
    instance
      .get("/api/getIntituteList", config)
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          !_.isEmpty(response.data)
        ) {
          let instituteList = [];
          response.data.institution.forEach((list) => {
            if (list.code !== 111) instituteList.push(list.abbreviation);
          });
          this.setState({
            institutionInfo: instituteList,
          });
        }
      })
      .catch((error) => {
        this.setState({ firstTymLoad: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        } else {
          showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
        }
      });
  }

  getParameterList(isRefresh) {
    this.setState({ isLoading: true });
    var config = {
      headers: { ...authHeader() },
    };
    instance
      .get("/compute/getParamList", config)
      .then((response) => {
        if (response.data !== undefined) {
          let categoryOptions = [];
          if (this.state.category === null) {
            for (let prop in response.data.category) {
              if (prop === "PRIVATE")
                categoryOptions.push(response.data.category[prop]);
            }
          } else {
            categoryOptions = this.state.category;
          }
          let tempSelectedDashboardFilters = JSON.parse(
            JSON.stringify(this.state.selectedDashboardFilters)
          );
          tempSelectedDashboardFilters.category = categoryOptions;
          this.setState(
            {
              paramList: response.data,
              category: categoryOptions,
              selectedDashboardFilters: tempSelectedDashboardFilters,
            },
            () => {
              if (!isRefresh) {
                this.getDashboardData(false, false);
              }
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          }
        }
      });
  }

  getDashboardData(isRefresh, globalFilterChange) {
    this.setState({ isLoading: true });
    const config = {
      headers: {
        ...authHeader(),
      },
    };

    let key = null;
    let dashboardSessionIDs = JSON.parse(
      sessionStorage.getItem("DashboardIDs")
    );
    if (dashboardSessionIDs === null) {
      dashboardSessionIDs = [];
    }
    if (dashboardSessionIDs.length > 0) {
      const foundKeyObj = dashboardSessionIDs.find(
        (item) =>
          item.option === this.state.option &&
          item.isDefaultPeriod180 === this.state.isDefaultPeriod180 &&
          _.isEqual(item.categoryList, this.state.category)
      );
      if (foundKeyObj !== undefined) {
        isRefresh = true;
        key = foundKeyObj.key;
      }
    }

    instance
      .post(
        "dashboard/tiles/get",
        {
          option: this.state.option,
          graph: "ALL",
          isRefresh: globalFilterChange ? false : isRefresh,
          key: globalFilterChange ? key : isRefresh ? key : null,
          isDefaultPeriod180: this.state.isDefaultPeriod180,
          categoryList: _.sortBy(this.state.category),
        },
        config
      )
      .then((response) => {
        this.setState({ isLoading: false, isRefresh: false });
        if (response.status === 200) {
          if (dashboardSessionIDs.length === 0) {
            dashboardSessionIDs.push({
              key: response.data.key,
              option: this.state.option,
              isDefaultPeriod180: this.state.isDefaultPeriod180,
              categoryList: _.sortBy(this.state.category),
              selected: false,
            });
          } else if (
            _.isEmpty(
              dashboardSessionIDs.find((item) => item.key === response.data.key)
            )
          ) {
            dashboardSessionIDs.push({
              key: response.data.key,
              option: this.state.option,
              isDefaultPeriod180: this.state.isDefaultPeriod180,
              categoryList: _.sortBy(this.state.category),
              selected: false,
            });
          }

          dashboardSessionIDs.forEach((item) => {
            if (item.key === response.data.key) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          sessionStorage.setItem(
            "DashboardIDs",
            JSON.stringify(dashboardSessionIDs)
          );
          const tilesData = {
            nbActCounterparts: response.data.nbActCounterparts,
            nbDefaultCounterparts: response.data.nbDefaultCounterparts,
            nbCounterpartRR: response.data.nbCounterpartRR,
            pdAverage: response.data.pdAverage,
            mmMean: response.data.mmMean,
            rrMean: response.data.rrMean,
          };

          const mapData = response.data.listCountryRegion;
          const graphData = {
            mmGraph: {
              mmRatioList: response.data.mmRatioList,
              mmMean: response.data.mmMeanGraph,
            },
            pdGraph: {
              dynamicCohortMap: response.data.dynamicCohortMap,
              pdAverage: response.data.pdAverageGraph,
            },
          };
          const chartData = {
            activeSector: response.data.sectorActiveCounterpartMap,
            defaultSector: response.data.sectorDefaultCounterpartMap,
          };
          const barData = {
            rrBySector: response.data.mapSectorMean,
          };
          let tempSelectedDashboardFilters = JSON.parse(
            JSON.stringify(this.state.selectedDashboardFilters)
          );
          tempSelectedDashboardFilters.option = this.state.option;
          tempSelectedDashboardFilters.isDefaultPeriod180 =
            this.state.isDefaultPeriod180;
          tempSelectedDashboardFilters.category = this.state.category;
          this.setState({
            tilesData: tilesData,
            mapData: mapData,
            graphData: graphData,
            chartData: chartData,
            barData: barData,
            graphOptions: response.data.graphOptionMap,
            currentDashboardId: response.data.key,
            selectedDashboardFilters: tempSelectedDashboardFilters,
            refreshGraphs: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.replace("/");
          } else {
            showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
          }
        } else {
          showToast("Something went wrong.", TOAST_TYPE_SUCCESS);
        }
      });
  }

  setMultiFilterValues(field, value) {
    this.setState({ [field]: value });
  }

  callGlobalDropdownChange() {
    this.getDashboardData(false, true);
    this.setState({
      refreshGraphs: false,
    });
  }

  resetGlobalDropdownChange() {
    this.setState({
      option: this.state.selectedDashboardFilters.option,
      isDefaultPeriod180:
        this.state.selectedDashboardFilters.isDefaultPeriod180,
      category: this.state.selectedDashboardFilters.category,
      refreshGraphs: false,
    });
  }

  render() {
    return (
      <div className="Dashboard">
        <Navigation />
        {this.state.isLoading ? <FullScreenLoaderComponent /> : null}
        <MainHeading
          option={this.state.option}
          isDefaultPeriod180={this.state.isDefaultPeriod180}
          institutionInfo={this.state.institutionInfo}
          category={this.state.category}
          paramList={this.state.paramList}
          setMultiFilterValues={this.setMultiFilterValues.bind(this)}
          callGlobalDropdownChange={this.callGlobalDropdownChange.bind(this)}
          resetGlobalDropdownChange={this.resetGlobalDropdownChange.bind(this)}
          selectedDashboardFilters={this.state.selectedDashboardFilters}
        />
        <div className="container">
          <div className="infocard">
            <InfoCard tilesData={this.state.tilesData} />
          </div>
          <ChartSection
            option={this.state.option}
            isDefaultPeriod180={this.state.isDefaultPeriod180}
            institutionInfo={this.state.institutionInfo}
            graphOptions={this.state.graphOptions}
            category={this.state.category}
            currentDashboardId={this.state.currentDashboardId}
            mapData={this.state.mapData}
            graphData={this.state.graphData}
            chartData={this.state.chartData}
            barData={this.state.barData}
            refreshGraphs={this.state.refreshGraphs}
          />
          <ScrollUpButton ShowAtPosition={20} className="scrolltopbutton" />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Dashboard;
